import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TabMenu from "../../../componets/tabMenu/TabMenu";
import MyBreadCrumbs from "../../../componets/breadCrumbs/MyBreadCrumbs";
import ProfiloSede from "./profilo-sede/ProfiloSede";
import styles from "./DettaglioSede.module.css";
import { tabsSedi } from "../../../utils/Tabs";
import { toast_error } from "../../../utils/custom-toast";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { useTranslation } from "react-i18next";
import { IRegistry } from "../../../type/commons";
import { EAuthRole } from "../../../type/enum/common.enum";
import DipendentiSede from "./dipendenti-sede/DipendentiSede";
import TabellaUtentiGet from "../../../componets/tabelle/TabellaUtentiGet";
import SearchBar from "../../../componets/barraRicerca/SearchBar";
import { Box } from "@mui/material";

const DettaglioSede: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { idSede } = useParams<{ idSede: string }>();
  const [selectedSede, setSelectedSede] = useState<IRegistry | null>(null);
  const [isLoadingPadre, setIsLoadingPadre] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  const role = sessionStorage.getItem("role");
  const registryApiService = new RegistryApiService();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingPadre(true);
      try {
        const response = await registryApiService.getRegistryById(idSede ?? "");
        if (response) {
          setSelectedSede(response);
        } else {
          console.log("error");
          toast_error(t("COMMONS.OPERATION_ERROR"));
        }
      } catch (error: any) {
        console.log(error);
        toast_error(error);
      } finally {
        setIsLoadingPadre(false);
      }
    };
    fetchData();
  }, []);

  const handleTabChange = (newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.length >= 3) {
      setSearchTerm(searchTerm.toLocaleLowerCase().trim());
    } else {
      setSearchTerm("");
    }
  };

  const pathBreadcrumbs = [
    { label: t("COMMONS.COMPANY"), action: "goBack" },
    { label: "Dettaglio Sede", url: `azienda/dettaglio-sede` },
  ];

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.userInformation}>
          <div className={styles.textContainer}>{selectedSede?.business_name}</div>
        </div>
        <MyBreadCrumbs path={pathBreadcrumbs} />
      </div>
      {/* <TabMenu value={selectedTab} tabs={tabsSedi} onChange={handleTabChange} /> */}

      {selectedTab === 1 && (
        <div>
          <SearchBar onSearch={handleSearch} placeholder={t("COMMONS.SEARCH")} />
        </div>
      )}

      {selectedSede ? (
        <Box>
          {selectedTab === 0 && <ProfiloSede sede={selectedSede} idSede={idSede!} isLoading={isLoadingPadre} />}
          {selectedTab === 1 && (
            <TabellaUtentiGet
              selectedUsers={[]}
              setSelectedUsers={function (value: React.SetStateAction<string[]>): void {
                throw new Error("Function not implemented.");
              }}
              usersDeleted={false}
              refreshCounter={0}
            />
          )}
          {/* {selectedTab === 3 && <div>Storico</div>} */}
          {/* {selectedTab === 4 && <div>Documenti</div>} */}
        </Box>
      ) : null}
    </>
  );
};

export default DettaglioSede;
