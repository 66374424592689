export const getGradientByDescription = (description: string) => {
  switch (description) {
    case "Carte di plastica":
      return "linear-gradient(110deg, #000 43.62%, #45474F 68.82%, #444 82.87%, #5D6271 95.95%, #26272B 106.12%)";
    case "Carte di metallo":
      return "linear-gradient(110deg, #A4A4A4 43.62%, #D1D1D1 68.82%, #BEBEBE 82.87%, #BEBEBE 95.95%, #D1D1D1 106.12%)";
    default:
      return "black";
  }
};

export const getCurrency = (currency: string) => {
  switch (currency) {
    case "eur":
      return "€";
    case "usd":
      return "$";
    default:
      return "€";
  }
};

export function convertDate(isoDateString: string, format: string) {
  const date = new Date(isoDateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; // I mesi in JavaScript partono da 0
  const year = date.getFullYear();

  // Aggiunge uno zero davanti ai numeri minori di 10
  const pad = (num: number) => (num < 10 ? `0${num}` : num);

  if (format === "it") {
    return `${pad(day)}/${pad(month)}/${year}`;
  } else if (format === "en") {
    return `${pad(month)}/${pad(day)}/${year}`;
  } else {
    return `${pad(day)}/${pad(month)}/${year}`;
  }
}
