import React, { useEffect, useState } from "react";
import style from "./MainDash.module.css";
import CardCust from "../../componets/card/Card";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { RegistryApiService } from "../../services/RegistryApiService";
import { EAuthRole, ERegistryType } from "../../type/enum/common.enum";
import DashboardGestore from "./DashboardGestore";

const MainDashboard: React.FC = () => {
  const [totalItems, setTotalItems] = useState<number>(0);
  const role = sessionStorage.getItem("role");
  const business_name = sessionStorage.getItem("business_name");
  const { t } = useTranslation();
  const registryApiService = new RegistryApiService();

  const countData = async (): Promise<void> => {
    if (role === EAuthRole.SUPERADMIN) {
      const itemCount: any = await registryApiService.countRegistriesByCode(ERegistryType.AZIENDA);
      console.log(itemCount);
      setTotalItems(itemCount);
    } else if (role === EAuthRole.ADMIN || EAuthRole.BASIC) {
      {
        const itemCount = await registryApiService.countRegistriesByCode(ERegistryType.DIPENDENTE);
        setTotalItems(itemCount);
      }
    }
  };

  useEffect(() => {
    countData();
  }, []);

  return (
    <div className={style.containerDashboard}>
      {role === EAuthRole.SUPERADMIN ? <Typography variant="h1">{t("COMMONS.WELCOME")}</Typography> : ""}
      <Grid container spacing={2} marginTop={"10px"}>
        {role === EAuthRole.SUPERADMIN ? (
          <Grid item xs={3}>
            <CardCust title={t("COMMONS.COMPANY")} totalValue={totalItems} backgroundColor={"var(--orangeAccent-100)"} color={"var(--black)"} />
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
            <DashboardGestore />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default MainDashboard;
