import { Box, Button } from "@mui/material";
import { useState } from "react";
import SearchBar from "../../../componets/barraRicerca/SearchBar";
import TabellaAdminAzienda from "../TabellaAdminAzienda/TabellaAdminAzienda";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const AdminAzienda: React.FC = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.length >= 3) {
      setSearchTerm(searchTerm.toLocaleLowerCase().trim());
      // setCurrentPage(1);
    } else {
      setSearchTerm("");
      // setCurrentPage(1);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0 2rem 0",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <SearchBar onSearch={handleSearch} placeholder={t("COMMONS.SEARCH")} />
        </Box>
        <Box sx={{ display: "flex", gap: "0.7rem" }}>
          <Button
            onClick={() => navigate(`/gestione-aziende/${id}/nuovo-amministratore`)}
            variant="contained"
            startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
          >
            {t("COMMONS.ADD")} {t("COMPANY.COMPANY_ADMIN.ADMINISTRATOR")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: "70%", overflowY: "scroll", bgcolor: "var(--white)", borderBottom: "1px solid var(--primary-700)" }}>
        {/* Tabella Lista Aziende */}
        <TabellaAdminAzienda searchTerm={searchTerm} />
      </Box>
    </>
  );
};
export default AdminAzienda;
