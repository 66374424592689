import LandingPage from "../landingPage/LandingPage";
import { Form, Spinner } from "react-bootstrap";
import { Alert, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Formik, FormikTouched } from "formik";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import signUpSchema from "./form-models/signUpSchema";
import BasicButton from "../../componets/buttons/BasicButton";
import { useTranslation } from "react-i18next";
import { MuiPhone } from "../../componets/muiPhone/muiphone";
import { CoreApiService } from "../../services/CoreApiService";

export interface IAuthSignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  businessName: string;
  phone: string;
}

function SignUp() {
  const { t } = useTranslation();
  const [mostraPwd, setMostraPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState<any>({
    show: false,
    severity: "error",
    message: "SIGN_UP.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const initialValues: IAuthSignUp = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    businessName: "",
    phone: "",
  };

  const schema = signUpSchema;
  const coreApiService = new CoreApiService();

  const onSubmit = (values: IAuthSignUp) => {
    setIsLoading(true);
    values.confirmPassword = values.password;
    coreApiService.createPublicCompany(values)
      .then((response) => {
        if (response.data.code === 0) {
          navigate("/confirm-registration");
          setAlert({
            show: true,
            severity: "success",
            message: response.data.message,
            dismissible: true,
            onClose: () => {
              setAlert({ ...alert, show: false });
            },
          });
        } else {
          setAlert({
            show: true,
            severity: "error",
            message: response.data.message,
            dismissible: true,
            onClose: () => {
              setAlert({ ...alert, show: false });
            },
          });
        }
      })
      .catch((error) => {
        setAlert({
          show: true,
          severity: "error",
          message: error.message,
          dismissible: true,
          onClose: () => {
            setAlert({ ...alert, show: false });
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const acceptComponent = () => {
  //   return (
  //     <Box marginTop="-20px" color="black">
  //       {t("SIGN_UP.ACCEPT_1")}
  //       <a style={{ textDecoration: "underline" }} target="_blank" href="https://whistlebase.com/informativa-sulla-privacy/" rel="noreferrer">
  //         {t("SIGN_UP.ACCEPT_2")}
  //       </a>
  //       {t("SIGN_UP.ACCEPT_3")}
  //       <a style={{ textDecoration: "underline" }} href="https://whistlebase.com/termini-e-condizioni/" target="_blank" rel="noreferrer">
  //         {t("SIGN_UP.ACCEPT_4")}
  //       </a>
  //     </Box>
  //   );
  // };

  return (
    <LandingPage>
      <Typography variant="h6" className={styles.title} sx={{ marginBottom: "20px", fontWeight: "bold", textTransform: "uppercase" }}>
        {t("SIGN_UP.TITLE")}
      </Typography>
      <div>
        {alert.show && (
          <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
            {t(alert.message)}
          </Alert>
        )}
        <Formik<IAuthSignUp> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, values, handleBlur, touched, errors, isValid, setFieldValue, setFieldTouched }) => {
            const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IAuthSignUp>]);
            const isSubmitDisabled = !formIsTouched || !isValid;

            return (
              <Form noValidate onSubmit={handleSubmit}>
                <TextField
                  name="firstName"
                  label={t("SIGN_UP.FIRST_NAME") + " *"}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && t(errors.firstName ? errors.firstName : "")}
                  inputProps={{ maxLength: 16 }}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                />
                <TextField
                  name="lastName"
                  label={t("SIGN_UP.LAST_NAME") + " *"}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && t(errors.lastName ? errors.lastName : "")}
                  inputProps={{ maxLength: 16 }}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                />
                <TextField
                  name="businessName"
                  label={t("SIGN_UP.BUSINESS_NAME") + " *"}
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.businessName && !!errors.businessName}
                  helperText={touched.businessName && t(errors.businessName ? errors.businessName : "")}
                  inputProps={{ maxLength: 16 }}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                />
                {/* <TextField
                  name="phone"
                  placeholder={t("SIGN_UP.PHONE") + " *"}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && !!errors.phone}
                  helperText={touched.phone && t(errors.phone ? errors.phone : "")}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                /> */}
                <MuiPhone
                  value={values.phone}
                  onChange={(phone) => setFieldValue("phone", phone)}
                  onBlur={() => setFieldTouched("phone")}
                  error={touched.phone && !!errors.phone}
                  helperText={touched.phone && t(errors.phone ?? "")}
                  label={t("SIGN_UP.PHONE") + " *"}
                  disabled={isLoading}
                  className={styles.textFields}
                />
                <TextField
                  name="email"
                  label={t("SIGN_UP.EMAIL") + " *"}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && t(errors.email ? errors.email : "")}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                />
                <TextField
                  name="password"
                  label={t("SIGN_UP.PASSWORD") + " *"}
                  type={mostraPwd ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && !!errors.password}
                  helperText={touched.password && t(errors.password ? errors.password : "")}
                  disabled={isLoading}
                  fullWidth
                  className={styles.textFields}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <FormControlLabel control={<Checkbox name="terms" onChange={handleChange} disabled={isLoading} />} label={acceptComponent()} /> */}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Grid item xs={12} sm={6}>
                    <BasicButton startIcon={isLoading ? <Spinner animation="border" /> : null} disabled={isLoading} text={t("SIGN_UP.SIGN_UP")} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button component={Link} to="../login" sx={{ textTransform: "Capitalize"}}>
                      {t("LOGIN.I_ALREADY_HAVE_AN_ACCOUNT")}
                    </Button>
                  </Grid>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </LandingPage>
  );
}

export default SignUp;
