import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./DettaglioDipendente.module.css";
import TabMenu from "../../../componets/tabMenu/TabMenu";
import { useTranslation } from "react-i18next";
import { ContractualData, Dipendente } from "../../../type/dipendente";
import { IAlert } from "../../../type/type";
import { calculateAge } from "../../../utils/utilsDate";
import moment from "moment";
import { EAuthRole, ERegistryType } from "../../../type/enum/common.enum";
import { Alert, CircularProgress } from "@mui/material";
import MyBreadCrumbs from "../../../componets/breadCrumbs/MyBreadCrumbs";
import { tabsDipendenti } from "../../../utils/Tabs";
import ContractualInfoFormik from "./profilo-dipendente/tabs/ContractualInfoFormik";
import HomeInfoFormik from "./profilo-dipendente/tabs/HomeInfoFormik";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { IRegistry } from "../../../type/commons";
import { ContractualDataService } from "../../../services/ContractualDataService";

const DettaglioDipendente: React.FC = () => {
  const { t } = useTranslation();
  const [reloadTab, setReloadTab] = useState<boolean>(false);
  const { id, idCompany } = useParams<{ id: string; idCompany: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [reloadTabContract, setReloadTabContract] = useState<boolean>(false);

  const [registryData, setRegistryData] = useState<Dipendente | null>(null);

  const [contractualData, setContractualData] = useState<ContractualData | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const aToken = sessionStorage.getItem("aToken");
  const role = sessionStorage.getItem("role");
  const [sedi, setSedi] = useState<IRegistry[]>([]);
  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: t("ERRORS.COMPETENCE_FORM.INITIAL_ERROR"),
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const registryApiService = new RegistryApiService();
  const contractualDataService = new ContractualDataService();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const paramsSedi: any = {
          registry_type: ERegistryType.SEDE,
        };

        if (role === EAuthRole.SUPERADMIN) {
          paramsSedi.id_registry_owner = idCompany;
        }

        const response = await registryApiService.getEmployeeById(id ?? "");

        let userData: Dipendente = response;
        if (userData.birth_date) {
          userData.birth_date = moment.utc(userData.birth_date).toDate();
          const age = await calculateAge(moment.utc(userData.birth_date).toDate());
        }
        setRegistryData(response);
        let sede: string = response.id_registry_owner.business_name;
        //ora cerco i dati dell'utente (se inseriti)

        const serverData = await contractualDataService.getDataForUser(id ?? "");

        const resSedi = await registryApiService.fetchAllRegistry(paramsSedi);

        if (resSedi) {
          setSedi(resSedi);
        }

        if (serverData) {
          // serverData.endWorkTime = moment.utc(serverData.endWorkTime).toDate();
          // serverData.startWorkTime = moment.utc(serverData.startWorkTime).toDate();
          //devo trasformare le due date
          if (serverData.startWorkTime) {
            let timeStr: string[] = String(serverData.startWorkTime).split(":");
            let now: Date = new Date();
            now.setHours(Number(timeStr[0]));
            now.setMinutes(Number(timeStr[1]));
            serverData.startWorkTime = moment.utc(now).toDate();
          }
          if (serverData.endWorkTime) {
            let timeStr: string[] = String(serverData.endWorkTime).split(":");
            let now: Date = new Date();
            now.setHours(Number(timeStr[0]));
            now.setMinutes(Number(timeStr[1]));
            serverData.endWorkTime = moment.utc(now).toDate();
          }

          serverData.assumptionDate = moment.utc(serverData?.assumptionDate).toDate();
          serverData.endDate = moment.utc(serverData?.endDate).toDate();
          serverData.estimatedEndDate = moment.utc(serverData?.estimatedEndDate).toDate();
          serverData.lastLevelDate = moment.utc(serverData?.lastLevelDate).toDate();
          serverData.seniority = await calculateAge(moment.utc(serverData.assumptionDate).toDate());

          setContractualData(serverData);
        } else {
          let cData = {
            seniority: 0,
            // assumptionLocation: serverData?.assumptionLocation,
          };
          setContractualData(cData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, reloadTab, reloadTabContract]);

  useEffect(() => {
    if (location.pathname.includes("informazioni-personali")) {
      setSelectedTab(0);
    } else if (location.pathname.includes("dati-contrattuali")) {
      setSelectedTab(1);
    }
  }, [location]);

  const getInitialTab = (path: any) => {
    if (path.includes("informazioni-personali")) {
      return 0;
    } else if (path.includes("dati-contrattuali")) {
      return 1;
    }
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab(location.pathname));
  console.log("selectedTab", selectedTab);

  const handleTabChange = (newValue: number) => {
    switch (newValue) {
      case 0:
        navigate(`/azienda/dipendenti/dettaglio-dipendente/informazioni-personali/${id}`);
        break;
      case 1:
        navigate(`/azienda/dipendenti/dettaglio-dipendente/dati-contrattuali/${id}`);
        break;
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={70} />
      </div>
    );
  }

  if (!registryData) {
    return <div>Utente non trovato.</div>;
  }
  // Aggiorna il valore della tab selezionata quando viene cambiata
  // const handleTabChange = (newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  // Crea un array di oggetti per la navigazione a breadcrumb
  const pathBreadcrumbs = [
    { label: t("COMMONS.COMPANY"), action: "goBack" },
    { label: t("COMPANY.USER_DETAIL"), url: "dettaglio-dipendente" },
  ];

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.userInformation}>
          <div className={styles.textContainer}>{`${registryData.firstname} ${registryData.surname}`}</div>
        </div>
        <MyBreadCrumbs path={pathBreadcrumbs} />
        {alert.show && (
          <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
            {t(alert.message)}
          </Alert>
        )}
      </div>
      {<TabMenu value={selectedTab} tabs={tabsDipendenti} onChange={handleTabChange} />}
      {/*selectedTab === 1 && <ProfiloDipendente person={user} id={id!} />*/}
      {selectedTab === 0 && (
        <HomeInfoFormik registryData={registryData!} idParam={id!} sedi={sedi!} reloadTab={reloadTab!} setReloadTab={setReloadTab} />
      )}
      {selectedTab === 1 && (
        <ContractualInfoFormik
          contractualData={contractualData!}
          id={id!}
          sedi={sedi!}
          reloadTabContract={reloadTabContract!}
          setReloadTabContract={setReloadTabContract}
        />
      )}
      {/*selectedTab === 3 && <ContractualInfoFormik person={user} contractualData={contractualData!} id={id!} />*/}
    </>
  );
};

export default DettaglioDipendente;
