import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TabMenu from "../../../componets/tabMenu/TabMenu";
import { useEffect, useState } from "react";
import AdminAzienda from "../../adminAzienda/AdminAzienda/AdminAzienda";
import ProfiloAziendaReadOnly from "./ProfiloAziendaReadOnly";
import BackButton from "../../../componets/buttons/BackButton";
import { Typography } from "@mui/material";
import { IRegistry } from "../../../type/commons";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { t } from "i18next";

const tabs = [
  { value: 0, label: t("COMMONS.COMPANY") },
  { value: 1, label: t("COMPANY.COMPANY_ADMIN.LIST_ADMIN") },
];

const DettaglioAzienda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { key_code } = useParams<{ key_code: string }>();
  const registryApiService = new RegistryApiService();
  const [azienda, setAzienda] = useState<IRegistry>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchAzienda = async () => {
    setIsLoading(true);

    try {
      const response = await registryApiService.searchRegistryFromKeycode(key_code ?? "");

      if (response) {
        setAzienda(response);
      } else {
        console.log("errore");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAzienda();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("dettaglio-azienda")) {
      setSelectedTab(0);
    } else if (location.pathname.includes("amministratori-azienda")) {
      setSelectedTab(1);
    }
  }, [location]);

  const getInitialTab = (path: any) => {
    if (path.includes("dettaglio-azienda")) {
      return 0;
    } else if (path.includes("amministratori-azienda")) {
      return 1;
    }
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab(location.pathname));

  const handleTabChange = (newValue: number) => {
    switch (newValue) {
      case 0:
        navigate(`/gestione-aziende/${key_code}/dettaglio-azienda`);
        break;
      case 1:
        navigate(`/gestione-aziende/${key_code}/amministratori-azienda/${azienda?.tenant}`);
        break;
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate("/gestione-aziende")} text={t("back")} />
      {azienda && <Typography variant="h1">{azienda.business_name}</Typography>}
      <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs} />
      {azienda && selectedTab === 0 && <ProfiloAziendaReadOnly azienda={azienda} />}
      {selectedTab === 1 && <AdminAzienda />}
    </>
  );
};

export default DettaglioAzienda;
