import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Container } from "@mui/material";
import styles from "./QRCode.module.css";

const MyQRCode: React.FC = () => {
  const [size, setSize] = useState(300);
  const url = window.location.href;
  const imageSize = size * 0.2;

  // Ascolta per i cambiamenti della dimensione della finestra
  useEffect(() => {
    const handleResize = () => {
      // Prendi la larghezza del contenitore o un valore massimo
      const newSize = Math.min(window.innerWidth * 0.8, 300);
      setSize(newSize);
    };

    // Inizialmente imposta la dimensione
    handleResize();

    // Imposta e rimuovi l'ascoltatore
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container maxWidth="sm">
      <div className={styles.container}>
        <QRCode className={styles.qrCode} value={url} size={size} />
      </div>
    </Container>
  );
};

export default MyQRCode;
