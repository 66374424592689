import Topbar from "./global/Topbar";
import Sidebar from "./global/Sidebar";
import MainDashboard from "./modules/dashboard/MainDash";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styles from "./App.module.css";
import Login from "./modules/login/Login";
import Aziende from "./modules/aziende/Aziende";

import DettaglioDipendente from "./modules/aziende/dettaglio-dipendenti/DettaglioDipendente";
import DettaglioSede from "./modules/aziende/dettaglio-sedi/DettaglioSede";
import Timbrature from "./modules/timbrature/timbrature";
import NuovoDipendente from "./modules/aziende/dettaglio-dipendenti/nuovo-dipendente/NuovoDipendente";
import PaginaPubblicaDipendente from "./modules/pagina-pubblica/pagina-pubblica-dipendente/PaginaPubblicaDipendente";
import ListaAziende from "./modules/aziende/lista-aziende/ListaAziende";
import { CircularProgress, ThemeProvider } from "@mui/material";
import theme from "./theme";
import PaginaPubblicaAzienda from "./modules/pagina-pubblica/pagina-pubblica-azienda/PaginaPubblicaAzienda";
import NuovaPassword from "./modules/login/reset-password/nuova-password/NuovaPassword";
import NuovoAdmin from "./modules/aziende/nuovo-amministratore/NuovoAdmin";
import SignUp from "./modules/sign-up/SignUp";
import ConfirmEmail from "./modules/login/confirm-email/ConfirmEmail";
import ModificaProfilo from "./modules/modificaProfilo/ModificaProfilo";
import { PrivateRoute, RoleBasedRoute, UserAuthorizeRoute } from "./componets/private-route/Private-route";
import CardTemplate from "./modules/card-template/CardTemplate";
import AddEditTemplate from "./modules/card-template/components/add-edit-template/AddEditTemplate";
import NuovaAziendaFix from "./modules/aziende/nuova-azienda/NuovaAziendaFix";
import Plafond from "./modules/plafond/Plafond";
import DettaglioAzienda from "./modules/aziende/DetaglioAzienda/DettaglioAzienda";
import RecoverPassword from "./modules/login/recover-password/RecoverPassword";
import Request from "./modules/plafond/pages/Request";
import { RegistryRoleEnum } from "./type/enum/registry-role.enum";
import NuovaSedeFormik from "./modules/aziende/dettaglio-sedi/nuova-sede/NuovoSedeFormik";
import StoricoAcquisti from "./modules/acquisti/StoricoAcquisti";
import RiepilogoAcquisti from "./modules/acquisti/RiepilogoAcquisti";
import DettaglioPagamenti from "./modules/acquisti/dettaglioPagamenti/DettaglioPagamenti";
import { Box, Divider } from "@mui/material";

//TODO: Modificare le route in inglese?
import React, { createContext, lazy, Suspense, useContext, useEffect, useMemo, useState } from "react";
import Footer from "./componets/Footer/Footer";
import { RouteApiService } from "./services/RouteApiService";
import UserNotAuthorized from "./componets/userNotAuthorized/UserNotAuthorized";
import MyContext from "./context/MyContext";
import RoutesContext from "./context/RouteContext";
import { IRoutes } from "./type/routes";
// import ModificaProfilo from "./scenes/aziende/profiloAziende/ProfiloAziendaFix";

/**
 * App is the main functional component in the codebase.
 *
 * This function defines the routes and navigation paths used within the application.
 *
 * The ThemeProvider wraps the entire application and provides a unified theme throughout.
 *
 * The Router component is responsible for managing and rendering routes.
 * For each path, it defines a corresponding DOM element to be rendered.
 * For example, a path of '/login' will render the Login element and a path of '/registrazione' will render the SignUp element.
 *
 * It's important to notice that the path '/' is redirected to '/login' using the Navigate component.
 * Also, all undefined paths are directed towards the MainLayout component.
 *
 * Please keep in mind that the routes and paths presented represent the main structure of the application and are vital for its performance.
 * Any changes should be carefully considered and tested thoroughly to avoid disruption of the navigation functionalities.
 */

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registrazione" element={<SignUp />} />
        <Route path="/ecard/:businessName/:displayName" element={<PaginaPubblicaDipendente />} />
        <Route path="/ecard/:businessName" element={<PaginaPubblicaAzienda />} />
        <Route path="/confirm-registration" element={<ConfirmEmail />} />
        <Route path="/reset-password/:token" element={<NuovaPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/*" element={<MainLayout />} />
      </Routes>
    </ThemeProvider>
  );
}

const SettingsRoutesLazy = lazy(() => import("./scenes/settingsPage/SettingsRoutes"));
const UserCalendarRoutesLazy = lazy(() => import("./scenes/calendarPages/CalendarRoutes"));
const RequestsRoutesLazy = lazy(() => import("./scenes/requestsPages/RequestsRoutes"));
const SupplierHistoryLazy = lazy(() => import("./modules/supplier/SupplierHistory"));
const RequestDetailLazy = lazy(() => import("./modules/supplier/pages/RequestDetail"));
const EmployeeDetailLazy = lazy(() => import("./modules/aziende/dettaglio-dipendenti/DettaglioDipendente"));

function MainLayout() {
  const location = useLocation();
  const [routes, setRoutes] = useState<IRoutes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const showSidebarAndTopbar = location.pathname !== "/page-not-found";
  const apiRouteService = new RouteApiService();
  console.log("Main Layout"); // Aggiungere context in cui salvare route (cosi il menu prende da qui i valori)

  useEffect(() => {
    fetchRoute();
  }, []);

  const fetchRoute = async () => {
    setLoading(true);
    const loadedRoutes = await apiRouteService.getRoute();
    sessionStorage.setItem("route", JSON.stringify(loadedRoutes));
    console.log("loaded routes", loadedRoutes);
    setRoutes(loadedRoutes);
    setLoading(false);
  };

  const authRoutesMemo = useMemo(() => ({ routes, setRoutes }), [routes, setRoutes]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <RoutesContext.Provider value={authRoutesMemo}>
          { routes.length > 0 && (
          <div className={styles.app}>
            {showSidebarAndTopbar && <Sidebar />}
            <main className={styles.content}>
              <Box
                // height="100vh"
                sx={{ minHeight: "calc(100vh - 34px)" }}
              >
                <Topbar />
                <Routes>
                  {/* ROTTE SUPERADMIN */}
                  <Route element={<PrivateRoute />}>
                    <Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.SUPERADMIN, RegistryRoleEnum.ADMIN]} />}>
                    <Route path="/homepage" element={<MainDashboard />} />
                      <Route
                        path="/azienda/dipendenti/dettaglio-dipendente/informazioni-personali/:id/:idCompany"
                        element={
                          <UserAuthorizeRoute
                            route={"/azienda/dipendenti/dettaglio-dipendente/informazioni-personali/:id/:idCompany"}
                            element={<DettaglioDipendente />}
                          />
                        }
                      />
                      <Route
                        path="/azienda/dipendenti/dettaglio-dipendente/dati-contrattuali/:id"
                        element={
                          <UserAuthorizeRoute
                            route={"/azienda/dipendenti/dettaglio-dipendente/dati-contrattuali/:id"}
                            element={<DettaglioDipendente />}
                          />
                        }
                      />
                      <Route
                        path="/azienda/sedi/dettaglio-sede/:idSede"
                        element={<UserAuthorizeRoute route={"/azienda/sedi/dettaglio-sede/:idSede"} element={<DettaglioSede />} />}
                      />
                      {/* <Route path="/azienda/sedi/dettaglio-sede/:idSede/:idCompany" element={<DettaglioSede />} /> */}
                <Route
                        path="/azienda/dipendenti/:id/nuovo-dipendente"
                        element={<UserAuthorizeRoute route={"/azienda/dipendenti/:id/nuovo-dipendente"} element={<NuovoDipendente />} />}
                      />
                      <Route
                        path="/azienda/sedi/:id/nuova-sede"
                        element={<UserAuthorizeRoute route={"/azienda/sedi/:id/nuova-sede"} element={<NuovaSedeFormik />} />}
                      />
                      <Route
                        path="/azienda/:id/nuovo-amministratore"
                        element={<UserAuthorizeRoute route={"/azienda/:id/nuovo-amministratore"} element={<NuovoAdmin />} />}
                      />
                      <Route path="/ricrea-azienda" element={<UserAuthorizeRoute route={"/ricrea-azienda"} element={<NuovaAziendaFix />} />} />
                    </Route>

                    {/*  ROTTE SUPERADMIN */}
                    <Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.SUPERADMIN]} />}>
                      <Route path="/azienda/:id/:tenant" element={<UserAuthorizeRoute route={"/azienda/:id/:tenant"} element={<Aziende />} />} />
                      <Route
                        path="/azienda/dipendenti/:id/:tenant"
                        element={<UserAuthorizeRoute route={"/azienda/dipendenti/:id/:tenant"} element={<Aziende />} />}
                      />
                      <Route
                        path="/azienda/sedi/:id/:tenant"
                        element={<UserAuthorizeRoute route={"/azienda/sedi/:id/:tenant"} element={<Aziende />} />}
                      />
                      <Route
                        path="/azienda/amministratori/:id/:tenant"
                        element={<UserAuthorizeRoute route={"/azienda/amministratori/:id/:tenant"} element={<Aziende />} />}
                      />
                      <Route path="/gestione-aziende" element={<UserAuthorizeRoute route={"/gestione-aziende"} element={<ListaAziende />} />} />
                      <Route
                        path="/gestione-aziende/nuova-azienda"
                        element={<UserAuthorizeRoute route={"/gestione-aziende/nuova-azienda"} element={<NuovaAziendaFix />} />}
                      />
                      {/* <Route path="/gestione-aziende/:id/amministratori-azienda" element={<AdminAzienda />} /> */}
                      <Route
                        path="/gestione-aziende/:key_code/dettaglio-azienda"
                        element={<UserAuthorizeRoute route={"/gestione-aziende/:key_code/dettaglio-azienda"} element={<DettaglioAzienda />} />}
                      />
                      <Route
                        path="/gestione-aziende/:key_code/amministratori-azienda/:id"
                        element={
                          <UserAuthorizeRoute route={"/gestione-aziende/:key_code/amministratori-azienda/:id"} element={<DettaglioAzienda />} />
                        }
                      />
                      <Route path="/azienda/:id" element={<UserAuthorizeRoute route={"/azienda/:id"} element={<Aziende />} />} />

                      <Route
                        path="/azienda/dipendenti/:id/:tenant/nuovo-dipendente"
                        element={<UserAuthorizeRoute route={"/azienda/dipendenti/:id/:tenant/nuovo-dipendente"} element={<NuovoDipendente />} />}
                      />
                      <Route
                        path="/azienda/sedi/:id/:tenant/nuova-sede"
                        element={<UserAuthorizeRoute route={"/azienda/sedi/:id/:tenant/nuova-sede"} element={<NuovaSedeFormik />} />}
                      />
                      <Route
                        path="/azienda/:id/:tenant/nuovo-amministratore"
                        element={<UserAuthorizeRoute route={"/azienda/:id/:tenant/nuovo-amministratore"} element={<NuovoAdmin />} />}
                      />
                    </Route>

                    {/*  ROTTE ADMIN */}
                    <Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.ADMIN]} />}>
                      <Route path="/azienda" element={<UserAuthorizeRoute route={"/azienda"} element={<Aziende />} />} />
                      <Route path="/azienda/dipendenti" element={<UserAuthorizeRoute route={"/azienda/dipendenti"} element={<Aziende />} />} />
                      <Route path="/azienda/sedi" element={<UserAuthorizeRoute route={"/azienda/sedi"} element={<Aziende />} />} />
                      <Route
                        path="/azienda/amministratori"
                        element={<UserAuthorizeRoute route={"/azienda/amministratori"} element={<Aziende />} />}
                      />

                      <Route
                        path="/azienda/dipendenti/nuovo-dipendente"
                        element={<UserAuthorizeRoute route={"/azienda/dipendenti/nuovo-dipendente"} element={<NuovoDipendente />} />}
                      />
                      <Route
                        path="/azienda/sedi/nuova-sede"
                        element={<UserAuthorizeRoute route={"/azienda/sedi/nuova-sede"} element={<NuovaSedeFormik />} />}
                      />
                      <Route
                        path="/azienda/nuovo-amministratore"
                        element={<UserAuthorizeRoute route={"/azienda/nuovo-amministratore"} element={<NuovoAdmin />} />}
                      />

                      <Route path="/acquisti/storico" element={<UserAuthorizeRoute route={"/acquisti/storico"} element={<StoricoAcquisti />} />} />
                      <Route
                        path="/acquisti/riepilogo"
                        element={<UserAuthorizeRoute route={"/acquisti/riepilogo"} element={<RiepilogoAcquisti />} />}
                      />
                      <Route
                        path="/acquisti/fatturazione"
                        element={<UserAuthorizeRoute route={"/acquisti/fatturazione"} element={<DettaglioPagamenti />} />}
                      />
                      <Route path="/plafond" element={<UserAuthorizeRoute route={"/plafond"} element={<Plafond />} />} />
                      <Route path="/new-request" element={<UserAuthorizeRoute route={"/new-request"} element={<Request />} />} />
                      <Route path="/card-template/:id" element={<UserAuthorizeRoute route={"/card-template/:id"} element={<AddEditTemplate />} />} />
                      <Route path="/card-template" element={<UserAuthorizeRoute route={"/card-template"} element={<AddEditTemplate />} />} />
                      <Route
                        path="/card-personalization"
                        element={<UserAuthorizeRoute route={"/card-personalization"} element={<CardTemplate />} />}
                      />
                      <Route
                        path="/requests-history"
                        element={
                          <UserAuthorizeRoute
                            route={"/requests-history"}
                            element={
                              <Suspense>
                                <SupplierHistoryLazy />
                              </Suspense>
                            }
                          />
                        }
                      />
                      <Route
                        path="/requests-history/:id"
                        element={
                          <UserAuthorizeRoute
                            route={"/requests-history/:id"}
                            element={
                              <Suspense>
                                <RequestDetailLazy />
                              </Suspense>
                            }
                          />
                        }
                      />
                    </Route>
                    <Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.ADMIN, RegistryRoleEnum.SUPERADMIN]} />}>
                      <Route
                        path="/settings/*"
                        element={
                          <UserAuthorizeRoute
                            route={"/settings/*"}
                            element={
                              <Suspense>
                                <SettingsRoutesLazy />
                              </Suspense>
                            }
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/calendar/*"
                      element={
                        <UserAuthorizeRoute
                          route={"/calendar/*"}
                          element={
                            <Suspense>
                              <UserCalendarRoutesLazy />
                            </Suspense>
                          }
                        />
                      }
                    />
                    <Route
                      path="/requests/*"
                      element={
                        <UserAuthorizeRoute
                          route={"/requests/*"}
                          element={
                            <Suspense>
                              <RequestsRoutesLazy />
                            </Suspense>
                          }
                        />
                      }
                    />
                    <Route element={<RoleBasedRoute allowedRoles={[RegistryRoleEnum.BASIC, RegistryRoleEnum.ADMIN]} />}>
                      <Route path="/user/edit-profile" element={<UserAuthorizeRoute route={"/user/edit-profile"} element={<ModificaProfilo />} />} />
                    </Route>
                    {/*  ROTTE TUTTI  */}
                    <Route path="/timbrature" element={<UserAuthorizeRoute route={"/timbrature"} element={<Timbrature />} />} />
                    <Route path="/unauthorized" element={<UserNotAuthorized />} />
                  </Route>
                </Routes>
              </Box>
              <Footer />
            </main>
          </div>
        )}
        </RoutesContext.Provider>
      )}
    </>
  );
}

export default App;
