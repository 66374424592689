import { Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Grid, Link, MenuItem, Switch, TextField } from "@mui/material";

import { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider, renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik, FormikTouched } from "formik";
import { ContractualData } from "../../../../../type/dipendente";
import { ToastContainer } from "react-toastify";
import { toast_error, toast_success } from "../../../../../utils/custom-toast";
import { EGenericType, IAlert } from "../../../../../type/type";
import { calculateAge } from "../../../../../utils/utilsDate";
import { DataApiService } from "../../../../../services/DataApiService";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import contractualDataValidationSchema from "../formModels/contractualDataValidationSchema";

function ContractualInfoFormik({
  contractualData,
  id,
  sedi,
  reloadTabContract,
  setReloadTabContract,
}: {
  contractualData: ContractualData;
  id: string;
  sedi: any[];
  reloadTabContract: boolean;
  setReloadTabContract: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  console.log("contractualData1", contractualData);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [listcontractTypes, setListcontractTypes] = useState<any[]>([]);
  const [listccnlTypes, setListccnlTypes] = useState<any[]>([]);
  const [listareas, setListareas] = useState<any[]>([]);
  const [roleTypes, setRoleTypes] = useState<any[]>([]);
  const [qualificationTypes, setQualificationTypes] = useState<any[]>([]);
  const [studyTypes, setStudyTypes] = useState<any[]>([]);
  console.log("studyTypes", studyTypes);
  const [listLinks, setListLinks] = useState<any[]>([]);
  const [isLinkEnabled, setLinkEnabled] = useState(false);
  const [linksChanged, setLinksChanged] = useState(false);

  //const schema = homeDataValidationSchema;
  const { t } = useTranslation();
  const locale = "it";
  const schema = contractualDataValidationSchema;
  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: t("ERRORS.COMPETENCE_FORM.INITIAL_ERROR"),
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });
  const apiService = new DataApiService();

  useEffect(() => {
    if (contractualData.documentLinks && contractualData.documentLinks?.length > 0) {
      //console.log()
      setListLinks(contractualData.documentLinks);
    }
  }, []);

  useEffect(() => {
    if (listcontractTypes && listcontractTypes.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.CONTRACT_TYPE)!);
      items.forEach((res) => {
        listcontractTypes.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }

    if (listccnlTypes && listccnlTypes.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.CCNL_TYPE)!);
      items.forEach((res) => {
        listccnlTypes.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }

    if (qualificationTypes && qualificationTypes.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.QUALIFICATION_TYPE)!);
      items.forEach((res) => {
        qualificationTypes.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }

    if (roleTypes && roleTypes.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.ROLE_TYPE)!);
      items.forEach((res) => {
        roleTypes.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }

    if (listareas && listareas.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.AREA)!);
      items.forEach((res) => {
        listareas.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }

    if (studyTypes && studyTypes.length == 0) {
      const items: any[] = JSON.parse(sessionStorage.getItem(EGenericType.STUDY_TYPE)!);
      items.forEach((res) => {
        studyTypes.push(
          <MenuItem key={res.code} value={res.code}>
            {res.name}
          </MenuItem>,
        );
      });
    }
  }, [contractualData]);

  const delete_link = (link: any) => {
    const links = listLinks.filter((r) => {
      if (r.id) {
        return r.id != link.id;
      } else {
        return r.name != link.name && r.link != link.link;
      }
    });

    setListLinks(links);
    contractualData.documentLinks = contractualData.documentLinks?.filter((r) => {
      if (r.id) {
        return r.id != link.id;
      } else {
        return r.name != link.name && r.link != link.link;
      }
    });
    setLinksChanged(true);
  };

  const openLink = (link: string) => {
    window.open(link, "_blank");
  };

  const listLinkView = () => {
    return Object.entries(listLinks).map(([prop, link], index) => (
      <Chip key={link.id} label={link.name} onDelete={() => delete_link(link)} onClick={() => openLink(link.link)} sx={{ mr: 2, mt: 2 }} />
    ));
  };

  //BODY
  const onSubmit = async (values: ContractualData) => {
    let otherStudyArray: any[];

    if (values.otherStudy) {
      otherStudyArray = !Array.isArray(values.otherStudy) ? [values.otherStudy] : values.otherStudy;
    } else {
      otherStudyArray = [];
    }

    let payload: Partial<ContractualData> & { registry_id: string; idData?: string; documentLinks: any[] } = {
      ...values,
      registry_id: id,
      idData: contractualData?._id,
      documentLinks: listLinks,
      otherStudy: otherStudyArray,
    };

    if (!values.otherStudy) {
      delete payload.otherStudy;
    }

    delete payload.tenant;

    console.log("payload", payload);
    apiService
      .createOrUpdateContractualDataType(payload)
      .then((res: any) => {
        //setAlert({ ...alert, show: true, severity: "success", message: "USER_CARD.CONTRACTUAL_SUCCESS_INSERT" });
        setIsLoadingSubmit(false);
        toast_success(t("USER_CARD.CONTRACTUAL_SUCCESS_INSERT"));
        setLinksChanged(false);
        setTimeout(() => {
          setReloadTabContract(!reloadTabContract); // Aggiorna dopo aver settato il loading a false
        }, 1000);
      })
      .catch((error: any) => {
        //setAlert({ ...alert, show: true, severity: "error", message: "USER_CARD.CONTRACTUAL_ERROR_INSERT" });
        toast_error(error);
        //toast_error(t("USER_CARD.CONTRACTUAL_ERROR_INSERT"));
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  };

  const addLinks = (values: ContractualData) => {
    if (values.linkName && values.linkUrl) {
      const newLink = { name: values.linkName, link: values.linkUrl };
      setListLinks((prevLinks) => [...prevLinks, newLink]);

      setLinksChanged(true);
      values.linkName = "";
      values.linkUrl = "";
    }
  };

  return (
    <Formik<ContractualData> initialValues={contractualData} onSubmit={onSubmit} validationSchema={schema}>
      {({ handleSubmit, handleChange, values, setTouched, handleBlur, setFieldValue, setFieldTouched, touched, errors, isValid }) => {
        const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<ContractualData>]);

        const isSubmitDisabled = (!formIsTouched && !linksChanged) || !isValid;
        console.log("errors", errors);
        return (
          <Form onSubmit={handleSubmit} noValidate>
            <ToastContainer />
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardHeader title={t("USER_CARD.CONTRACTUAL_PRINCIPAL_INFO")}></CardHeader>
              <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="uniqueCode"
                      value={values.uniqueCode}
                      inputProps={{ maxLength: 64 }}
                      onChange={handleChange}
                      label={t("USER_CARD.UNIQUE_CODE")}
                      required
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.uniqueCode && !!errors.uniqueCode}
                      helperText={touched.uniqueCode && t(errors.uniqueCode ?? "")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="assumptionLocation"
                      value={values.assumptionLocation}
                      onChange={handleChange}
                      label={t("USER_CARD.ASSUMPTION_LOCATION")}
                      required
                      select
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.assumptionLocation && !!errors.assumptionLocation}
                      helperText={touched.assumptionLocation && t(errors.assumptionLocation ?? "")}
                    >
                      {sedi.map((sede) => (
                        <MenuItem key={sede._id} value={sede._id}>
                          {sede.business_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="area"
                      value={values.area}
                      inputProps={{ maxLength: 64 }}
                      onChange={handleChange}
                      label={t("USER_CARD.AREA")}
                      required
                      select
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.area && !!errors.area}
                      helperText={touched.area && t(errors.area ?? "")}
                    >
                      {listareas}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="contractType"
                      value={values.contractType}
                      inputProps={{ maxLength: 16 }}
                      onChange={handleChange}
                      label={t("USER_CARD.CONTRACT_TYPE")}
                      required
                      fullWidth
                      select
                      onBlur={handleBlur}
                      error={touched.contractType && !!errors.contractType}
                      helperText={touched.contractType && t(errors.contractType ?? "")}
                    >
                      {listcontractTypes}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="ccnl"
                      value={values.ccnl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.CCNL")}
                      fullWidth
                      select
                      required
                      error={touched.ccnl && !!errors.ccnl}
                      helperText={touched.ccnl && t(errors.ccnl ?? "")}
                    >
                      {listccnlTypes}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="qualification"
                      value={values.qualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.QUALIFICA")}
                      fullWidth
                      select
                      required
                      error={touched.qualification && !!errors.qualification}
                      helperText={touched.qualification && t(errors.qualification ?? "")}
                    >
                      {qualificationTypes}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="roleType"
                      value={values.roleType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.POSITION")}
                      fullWidth
                      select
                      required
                      error={touched.roleType && !!errors.roleType}
                      helperText={touched.roleType && t(errors.roleType ?? "")}
                    >
                      {roleTypes}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="contractualLevel"
                      value={values.contractualLevel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.CONTRACTUAL_LEVEL")}
                      fullWidth
                      required
                      error={touched.contractualLevel && !!errors.contractualLevel}
                      helperText={touched.contractualLevel && t(errors.contractualLevel ?? "")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Switch
                      name="protectedCategory"
                      value={values.protectedCategory}
                      checked={values.protectedCategory}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("protectedCategory", event.target.checked);
                      }}
                    />
                    {t("USER_CARD.PROTECTED_CATEGORY")}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Switch
                      name="invalidated"
                      value={values.invalidated}
                      checked={values.invalidated}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("invalidated", event.target.checked);
                      }}
                    />
                    {t("USER_CARD.INVALIDITY")}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Switch
                      name="lex104"
                      value={values.lex104}
                      checked={values.lex104}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("lex104", event.target.checked);
                      }}
                    />
                    {t("USER_CARD.LEX_104")}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Switch
                      name="ticket"
                      value={values.ticket}
                      checked={values.ticket}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("ticket", event.target.checked);
                      }}
                    />
                    {t("USER_CARD.TICKET")}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.DESCRIPTION")}
                      fullWidth
                      onBlur={handleBlur}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardHeader title={t("USER_CARD.REFERENCE_DATE")}></CardHeader>
              <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name="assumptionDate"
                        label={t("USER_CARD.ASSUMPTION_DATE")}
                        value={values.assumptionDate}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: touched.assumptionDate && !!errors.assumptionDate,
                            helperText: touched.assumptionDate && t(errors.assumptionDate ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        onChange={async (date) => {
                          values.seniority = await calculateAge(date!);
                          setFieldValue("assumptionDate", date);
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <TextField
                      name="seniority"
                      value={values.seniority}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("USER_CARD.SENIORITY")}
                      fullWidth
                      disabled
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name="lastLevelDate"
                        label={t("USER_CARD.NEXT_LEVEL_DATE")}
                        value={values.lastLevelDate}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: touched.lastLevelDate && !!errors.lastLevelDate,
                            helperText: touched.lastLevelDate && t(errors.lastLevelDate ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        onChange={(date) => {
                          setFieldValue("lastLevelDate", date);
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name="estimatedEndDate"
                        label={t("USER_CARD.PRESUMED_END_DATE")}
                        value={values.estimatedEndDate}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: touched.estimatedEndDate && !!errors.estimatedEndDate,
                            helperText: touched.estimatedEndDate && t(errors.estimatedEndDate ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        onChange={(date) => {
                          setFieldValue("estimatedEndDate", date);
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name="endDate"
                        label={t("USER_CARD.END_DATE")}
                        value={values.endDate}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: touched.endDate && !!errors.endDate,
                            helperText: touched.endDate && t(errors.endDate ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        onChange={(date) => {
                          setFieldValue("endDate", date);
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardHeader title={t("USER_CARD.ORARIO")}></CardHeader>
              <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="workSchedule"
                      value={values.workSchedule}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.WORK_SCHEDULE")}
                      required
                      fullWidth
                      select
                      onBlur={handleBlur}
                      error={touched.workSchedule && !!errors.workSchedule}
                      helperText={touched.workSchedule && t(errors.workSchedule ?? "")}
                    >
                      <MenuItem value="FT">Full Time</MenuItem>
                      <MenuItem value="PT">Part Time</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="workPercentage"
                      value={values.workPercentage}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 3,
                        min: 0,
                        max: 100,
                        type: "number",
                      }}
                      label={t("USER_CARD.WORK%")}
                      required
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.workPercentage && !!errors.workPercentage}
                      helperText={touched.workPercentage && t(errors.workPercentage ?? "")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        name="startWorkTime"
                        label={t("USER_CARD.START_TIME")}
                        value={values.startWorkTime}
                        onChange={(time) => {
                          setFieldValue("startWorkTime", time);
                          setFieldTouched("startWorkTime", true, false);
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            error: touched.startWorkTime && !!errors.startWorkTime,
                            helperText: touched.startWorkTime && t(errors.startWorkTime ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                        minutesStep={5}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        name="endWorkTime"
                        label={t("USER_CARD.END_TIME")}
                        value={values.endWorkTime}
                        onChange={(time) => {
                          // const formattedTime = moment(time).format("HH:mm");
                          setFieldValue("endWorkTime", time);
                          setFieldTouched("endWorkTime", true, false);
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            error: touched.endWorkTime && !!errors.endWorkTime,
                            helperText: touched.endWorkTime && t(errors.endWorkTime ?? ""),
                            onBlur: handleBlur,
                          },
                        }}
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                        minutesStep={5}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardHeader title={t("USER_CARD.ECONOMICS_DATA")}></CardHeader>
              <CardContent style={{ backgroundColor: "#FFFFFC" }}>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="ral"
                      value={values.ral}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.RAL")}
                      onBlur={handleBlur}
                      error={touched.ral && !!errors.ral}
                      helperText={touched.ral && t(errors.ral ?? "")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="referenceCost"
                      value={values.referenceCost}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.REFERENCE_COST")}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="welfare"
                      value={values.welfare}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      onBlur={handleBlur}
                      label={t("USER_CARD.WELFARE")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="reimbursement"
                      value={values.reimbursement}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.REIMBURSEMENT")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="ticketCost"
                      value={values.ticketCost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.TICKET_COST")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="contractualBenefit"
                      value={values.contractualBenefit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.CONTRACTUAL_BENEFIT")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="agencyBenefit"
                      value={values.agencyBenefit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.AGENCY_BENEFITS")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="additionalCost"
                      value={values.additionalCost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.ADDITIONAL_COST")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="baseCost"
                      value={values.baseCost}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.BASE_COST")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      type="number"
                      name="variable"
                      value={values.variable}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.VARIABLE")}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardHeader title={t("USER_CARD.EDUCATIONAL_LEVEL")} />
              <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="study"
                      value={values.study}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.STUDY")}
                      fullWidth
                      select
                      error={touched.study && !!errors.study}
                      helperText={touched.study && t(errors.study ?? "")}
                    >
                      {studyTypes}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="studyDescription"
                      value={values.studyDescription}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.STUDY_DESCRIPTION")}
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.studyDescription && !!errors.studyDescription}
                      helperText={touched.studyDescription && t(errors.studyDescription ?? "")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="otherStudy"
                      value={values.otherStudy}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      label={t("USER_CARD.OTHER_STUDY")}
                      fullWidth
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ marginBottom: 2 }}>
              <CardHeader title={t("USER_CARD.LINKS")} />
              <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="linkDataRoom"
                      value={values.linkDataRoom}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 200 }}
                      label={t("USER_CARD.LINK_DATA_ROOM")}
                      fullWidth
                      required
                      error={touched.linkDataRoom && !!errors.linkDataRoom}
                      helperText={touched.linkDataRoom && t(errors.linkDataRoom ?? "")}
                    />
                  </Grid>
                  {isLinkEnabled ? (
                    <Grid item xs={12} sm={6}>
                      <Link href={values.linkDataRoom} target="_blank" title={t("USER_CARD.TITLE_LINK_DATA_ROOM")}>
                        <InsertLinkIcon />
                      </Link>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}></Grid>
                  )}

                  {/* Inizio della seconda riga */}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="linkName"
                      value={values.linkName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 200 }}
                      label={t("USER_CARD.LINK_NAME")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="linkUrl"
                      value={values.linkUrl}
                      onChange={handleChange}
                      inputProps={{ maxLength: 200 }}
                      label={t("USER_CARD.LINK_URL")}
                      fullWidth
                      onBlur={handleBlur}
                      error={touched.linkUrl && !!errors.linkUrl}
                      helperText={touched.linkUrl && t(errors.linkUrl ?? "")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box display={"flex"} alignItems={"center"} alignContent={"center"} mt={1}>
                      <Button
                        onClick={() => addLinks(values)}
                        variant="contained"
                        disabled={!values.linkName || !values.linkUrl || !!errors.linkUrl}
                        type="button"
                      >
                        {t("COMMONS.ADD")}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={24} sm={8}>
                    {listLinkView()}
                  </Grid>
                  <Grid item xs={12} sm={12} textAlign="end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoadingSubmit || isSubmitDisabled}
                      size="large"
                      startIcon={isLoadingSubmit ? <CircularProgress color="secondary" size={15} /> : null}
                    >
                      {t("COMMONS.CONFIRM")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContractualInfoFormik;
