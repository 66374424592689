import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import "./MyBreadCrumbs.css";
import { BreadcrumbsComponentProps } from "../../type/type";

const MyBreadCrumbs: React.FC<BreadcrumbsComponentProps> = ({ path }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderBreadcrumbItems = () => {
    return path.map((item, index) => {
      const isCurrentPage = index === path.length - 1;
      const linkColor = isCurrentPage ? "gray" : "black";
      const isBackLink = item.action === "goBack"; // Aggiungi questa logica nel tuo array path se necessario

      return (
        <Typography
          key={index}
          variant="body1"
          component={isCurrentPage || isBackLink ? "div" : Link}
          color={linkColor}
          to={!isCurrentPage && !isBackLink ? item.url : undefined}
          className={!isCurrentPage && !isBackLink ? "customLinkStyle" : ""}
          onClick={isBackLink ? handleBackClick : undefined}
          style={isBackLink ? { cursor: "pointer" } : {}}
        >
          {item.label}
        </Typography>
      );
    });
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="●">
      <Link to="/homepage">
        <Typography variant="body1" color="black">
          Homepage
        </Typography>
      </Link>
      {renderBreadcrumbItems()}
    </Breadcrumbs>
  );
};

export default MyBreadCrumbs;
