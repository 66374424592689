import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { EAuthSessionData } from "../../type/enum/auth.enum";
import { IApiCommonsFields, IApiResponse, TApiListSearchParam } from "../../type/commons";
import { stringify } from "qs";
import Cookies from "js-cookie";

import { jwtDecode } from "jwt-decode";
import { IAuthRefreshToken, IAuthSignInResponse } from "../../type/auth";
import { AUTH_URL } from "../../api/api";
import axiosRetry from "axios-retry";
import { useNavigate } from "react-router";
import { RefreshApiService } from "./RefreshApiService";

export class ApiService {
  navigate = useNavigate();
  private _axiosInstance: AxiosInstance;
  private _baseUrl: string;

  constructor({ baseUrl }: { baseUrl?: string }) {
    this._baseUrl = baseUrl ?? "";
    this._axiosInstance = axios.create({
      baseURL: this._baseUrl,
    });
    this._setHeaders();
    this._handleErrors();
    //this._setRetry();
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this._axiosInstance.delete<any, T>(url, config);
  }

  public from(baseUrl: string): this {
    this._axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    this._setHeaders();
    this._handleErrors();
    //this._setRetry();
    return this;
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this._axiosInstance.get<any, T>(url, config);
  }

  public getItemId(item: string | IApiCommonsFields): string {
    return typeof item === "string" ? (item as string) : (item._id as string);
  }

  public list<T>(url: string, listSearchCountUrl: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T | T[]> {
    let suffixParamStr = "";
    if (params) {
      const copyParams = params;
      suffixParamStr = "?";
      Object.entries(copyParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          suffixParamStr = suffixParamStr + value.map((keyValuePair) => new URLSearchParams({ [key]: keyValuePair })).join("&") + "&";
          delete params[key];
        }
      });

      suffixParamStr = suffixParamStr + stringify(params);
    }
    return this.get<T>(`${url}${listSearchCountUrl}${suffixParamStr}`, config);
  }

  public listCount<T>(url: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T> {
    return this.list(url, "/list/count", params, config) as Promise<T>;
  }

  public listSearch<T>(url: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T[]> {
    return this.list(url, "/list/search", params, config) as Promise<T[]>;
  }

  public patch<B, R = B>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
    return this._axiosInstance.patch<any, R>(url, data, config);
  }

  public post<B, R = B>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
    return this._axiosInstance.post<any, R>(url, data, config) as any;
  }

  public postMany<B, R = B>(url: string, data: B[], config?: AxiosRequestConfig): Promise<R[]> {
    const promises = data.map((item) => this.post<B, R>(url, item, config));
    return Promise.all(promises);
  }

  public put<B, R = B>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
    return this._axiosInstance.put<any, R>(url, data, config);
  }

  private _handleErrors(): void {
    this._axiosInstance.interceptors.response.use(
      async (response: AxiosResponse<IApiResponse<any>>) => {
        const originalRequest = response.config;
        if (response.status > 299) {
          return Promise.reject("ERRORS.GENERIC");
        }

        const data = response.data as any as IApiResponse<any>;
        // Caso response file
        const isFile = response.data as any;
        if (isFile?.type) {
          return response;
        }

        // console.log("ApiData error", data.data.row[0]);
        if (data.code !== "") {
          switch (data.code) {
            case "AUTHERR":
              this._rejectedUnAuthorized();
              return Promise.reject(data.message);
            case 0:
            case "0":
              break;
            case "ERR_999":
              if (data.message === "Token scaduto") {
                const res = await this._tokenExpired(originalRequest);
                console.log("response t", res);
                return res;
              } else {
                return Promise.reject(data.message);
              }
            case "REG_075":
            case "REG_071":
              const rowNumbers = data.data.row;
              const rowLabel = rowNumbers.length > 1 ? "righe" : "riga";
              console.log(`Errore REG_075, ${rowLabel}: ${rowNumbers.join(", ")}`);
              return Promise.reject(`Errore di creazione massiva non valida: ${rowLabel} ${rowNumbers.join(", ")}`);
            case "VALID_ERR_01":
              return Promise.reject(data.data);
            case "DB_ERR_11000":
              return Promise.reject("Codice fiscale già presente");
            default:
              return Promise.reject(data.message);
          }
        }
        // TODO: Da verificare se corretto o se da cambiare
        if (data.data) {
          return data.data;
        }
        return response;
      },
      (rejected: AxiosError<IApiResponse<any>>) => {
        console.log("log");
        if (!rejected.response?.status || rejected.response?.status > 500 || !rejected?.response?.data) {
          return Promise.reject("ERRORS.GENERIC");
        }
        const { data } = rejected.response;
        if (data.code !== "") {
          switch (data.code) {
            case "AUTHERR":
              this._rejectedUnAuthorized();
              return Promise.reject(data.message);

            default:
              return Promise.reject(data.message);
          }
        }
        return Promise.reject("ERRORS.GENERIC");
      },
    );
  }

  private _rejectedUnAuthorized(): void {
    sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
    sessionStorage.removeItem(EAuthSessionData.REFRESH_TOKEN);
    sessionStorage.removeItem(EAuthSessionData.AUTH_DATA);
    sessionStorage.removeItem(EAuthSessionData.ROLE);
    this.navigate("/Login");
  }

  private _setHeaders(): void {
    this._axiosInstance.interceptors.request.use((config) => {
      config.headers["x-access-token"] = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN) || "";
      return config;
    });
  }

  /**
   * Metodo per la gestione del refresh dei token
   * @private
   */
  // private async _refreshToken<IAuthSignInResponse>(): Promise<IAuthSignInResponse> {
  //   const rToken = Cookies.get(EAuthSessionData.REFRESH_TOKEN);

  //   if (rToken) {
  //     const instance = this._axiosInstance;
  //     this._axiosInstance = axios.create({
  //       baseURL: AUTH_URL,
  //     });
  //     const data = { refreshToken: rToken };
  //     const res = await this.post<IAuthRefreshToken, IApiResponse<IAuthSignInResponse>>("/auth/refresh", data);
  //     //this._axiosInstance = instance;
  //     return res.data;
  //   } else {
  //     throw new Error();
  //   }
  // }

  /**
   * Metodo privato per gestire il retry delle chiamate secondo determinate specifiche
   * attualmente questo metodo viene utilizzato per gestire il retry dopo le risposte negative delle chiamte per token scaduto
   * rientra nel processo di richiesta di refresh del token
   * @private
   */
  private _setRetry() {
    axiosRetry(this._axiosInstance, {
      retries: 2,
      retryCondition(error) {
        if (error.response) {
          const data = error.response.data as IApiResponse<any>;
          if (data.code === 0) {
            return false;
          } else {
            switch (data.code) {
              case "ERR_999":
                return data.message === "Token scaduto";
              default:
                return false;
            }
          }
        }
        return false;
      },
    });
  }

  private async _tokenExpired(originalRequest: InternalAxiosRequestConfig<any>) {
    const rStatus = sessionStorage.getItem("refreshStatus");
    if (!rStatus) {
      //debugger;
      sessionStorage.setItem("refreshStatus", "in refresh");
      const refreshService = new RefreshApiService();
      //const res: IApiResponse<IAuthSignInResponse> = await this._refreshToken();
      const res: IApiResponse<IAuthSignInResponse> = await refreshService.refreshTokenApi();
      console.log("res refresh", res);
      const { rToken, aToken, role } = res.data;
      if (rToken) {
        const { exp } = jwtDecode(rToken);
        let expiration = 30;
        sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, aToken);

        if (exp) {
          expiration = exp - Math.floor(Date.now() / 1000);
        }

        const refresh_token = Cookies.get(EAuthSessionData.REFRESH_TOKEN);
        if (!refresh_token) {
          Cookies.set(EAuthSessionData.REFRESH_TOKEN, rToken, { expires: expiration });
        } else {
          Cookies.remove(EAuthSessionData.REFRESH_TOKEN);
          Cookies.set(EAuthSessionData.REFRESH_TOKEN, rToken, { expires: expiration });
        }

        originalRequest.headers["x-access-token"] = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN) ?? "";
        sessionStorage.removeItem("refreshStatus");
        //debugger;
        console.log("request or", originalRequest);
        return this._axiosInstance(originalRequest);
      }
      sessionStorage.removeItem("refreshStatus");
    }
  }
}
