import { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers, FormikTouched } from "formik";
import adminValidationSchema from "./adminValidationSchema";
import { Button, Grid, TextField, Typography, Alert, MenuItem, Divider } from "@mui/material";
import styles from "./NuovoAdmin.module.css";
import { Spinner } from "react-bootstrap";
import { toast_error, toast_success } from "../../../utils/custom-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MyBreadCrumbs from "../../../componets/breadCrumbs/MyBreadCrumbs";
import { useTranslation } from "react-i18next";
import { MuiPhone } from "../../../componets/muiPhone/muiphone";
import { ContactTypeEnum } from "../../../type/enum/contact-type.enum";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { calculateAge } from "../../../utils/utilsDate";
import { EAuthRole, ERegistryType } from "../../../type/enum/common.enum";
import { IRegistry } from "../../../type/commons";

interface IRegistryAdmin {
  firstname: string;
  surname: string;
  birth_date?: Date | null;
  fiscal_code: string;
  profession: string;
  sex_code: string;
  displayName: string;
  birth_place: string;
  image: string;
  email: string;
  phone: string;
  sede: string;
}
function NuovoAdmin() {
  const { t } = useTranslation();
  const adminIntialState: IRegistryAdmin = {
    firstname: "",
    surname: "",
    birth_date: null,
    fiscal_code: "",
    profession: "",
    displayName: "",
    sex_code: "",
    birth_place: "",
    image: "",
    email: "",
    phone: "",
    sede: "",
  };

  const [sedi, setSedi] = useState<IRegistry[]>([]);
  const [idSelectedSede, setIdSelectedSede] = useState<string>("");
  const [loadingError, setLoadingError] = useState(false);
  const [age, setAge] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id, key_code, tenant } = useParams<{ id: string; key_code: string; tenant: string }>();
  const schema = adminValidationSchema;
  const textFieldStyle = styles.textField;
  const location = useLocation();
  const role = sessionStorage.getItem("role");
  const [uniqueDisplayName, setUniqueDisplayName] = useState<boolean>(true);

  const pathBreadcrumbs = [
    { label: t("COMMONS.COMPANY"), action: "goBack" },
    { label: t("COMPANY.COMPANY_ADMIN.NEW_ADMIN"), url: `${location.pathname}` },
  ];

  const [alert, setAlert] = useState<any>({
    show: false,
    severity: "error",
    message: "SIGN_UP.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const loadDataSedi = async () => {
    setIsLoading(true);
    setLoadingError(false);
    try {
      const params: any = {
        registry_type: ERegistryType.SEDE,
      };

      if (role === EAuthRole.SUPERADMIN) {
        params.id_registry_owner = id;
      }

      const data = await registryApiService.fetchAllRegistry(params);
      if (data) {
        setSedi(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
        setLoadingError(true);
      }
    } catch (error: any) {
      console.error("Errore durante il caricamento delle sedi");
      toast_error(error);
      setLoadingError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadDataSedi();
  }, []);

  const registryApiService = new RegistryApiService();

  const verifyUniqueDisplayName = async (displayName: string) => {
    if (displayName && displayName !== "") {
      const res: any = await registryApiService.verifyUniqueDisplayName(displayName);
      console.log(res);
      if (res.data) {
        setUniqueDisplayName(res.data.data === 0);
      } else {
        setUniqueDisplayName(res === 0);
      }
    }
  };

  const onSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    setIsLoading(true);
    let requestBody: any = {
      firstname: values.firstname,
      surname: values.surname,
      registry_address: [],
      birth_date: values.birth_date,
      fiscal_code: values.fiscal_code,
      profession: values.profession,
      sex_code: values.sex_code,
      birth_place: values.birth_place,
      image: values.image,
      displayName: values.displayName,
      user: {
        email: values.email.toLowerCase(),
        // applicationsEnabled: ["idconnect"],
        // role: ERegistryRole.ADMIN,
      },
      contact: [
        // {
        //   contact_type: ContactTypeEnum.EMAIL,
        //   contact: values.email.toLowerCase(),
        // },
        {
          contact_type: ContactTypeEnum.PHONE,
          contact: values.phone,
        },
      ],
    };

    // Modifica del corpo della richiesta in base al ruolo
    if (role === "admin") {
      requestBody = { ...requestBody, id_registry_owner: idSelectedSede };
    } else if (role === "superadmin") {
      requestBody = { ...requestBody, tenant: id, id_registry_owner: idSelectedSede };
    }
    registryApiService
      .createAdmin(requestBody)
      .then((response) => {
        console.log(response);
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/amministratori/${id}/${tenant}`) : navigate(`/azienda/amministratori`);
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
      })
      .catch((error) => {
        console.log(error);
        if (error && error.includes("Validation")) {
          setAlert({
            show: true,
            severity: "error",
            message: t("ERRORS.PHONE_NUMBER"),
            dismissible: true,
            onClose: () => {
              setAlert({ ...alert, show: false });
            },
          });
        } else {
          setAlert({
            show: true,
            severity: "error",
            message: error,
            dismissible: true,
            onClose: () => {
              setAlert({ ...alert, show: false });
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Formik<IRegistryAdmin> initialValues={adminIntialState} onSubmit={onSubmit} enableReinitialize validationSchema={schema}>
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
        const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IRegistryAdmin>]);
        const isSubmitDisabled = !formIsTouched || !isValid;
        // console.log("values", values);
        return (
          <Form noValidate onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "center", borderRight: "2px solid light grey" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <ToastContainer position="top-right" autoClose={3000} />
                <Typography variant="h1">Nuovo Amministratore </Typography>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                  <MyBreadCrumbs path={pathBreadcrumbs} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                {alert.show && (
                  <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
                    {t(alert.message)}
                  </Alert>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  name="firstname"
                  label={t("FORM.NAME")}
                  variant="outlined"
                  className={styles.textField}
                  fullWidth
                  value={values.firstname}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  required
                  error={touched.firstname && !!errors.firstname}
                  helperText={touched.firstname && errors.firstname ? t(errors.firstname) : " "}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="surname"
                  label={t("FORM.SURNAME")}
                  variant="outlined"
                  className={textFieldStyle}
                  fullWidth
                  value={values.surname}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  required
                  error={touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname ? t(errors.surname) : " "}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="fiscal_code"
                  label={t("FORM.FISCAL_CODE")}
                  variant="outlined"
                  className={textFieldStyle}
                  fullWidth
                  value={values.fiscal_code.toUpperCase()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                  onBlur={handleBlur}
                  required
                  error={touched.fiscal_code && !!errors.fiscal_code}
                  helperText={touched.fiscal_code && errors.fiscal_code ? t(errors.fiscal_code) : " "}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    name="birth_date"
                    label={t("USER_CARD.BIRTH_DATE")}
                    value={values.birth_date}
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: touched.birth_date && !!errors.birth_date,
                        helperText: touched.birth_date && errors.birth_date ? t(errors.birth_date) : " ",
                        onBlur: handleBlur,
                        required: true,
                      },
                    }}
                    onChange={async (date) => {
                      const age = await calculateAge(date!);
                      setFieldValue("birth_date", date);
                      setFieldTouched("birth_date", true, false);
                      setAge(age);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="birth_place"
                  value={values.birth_place}
                  inputProps={{ maxLength: 250 }}
                  onChange={handleChange}
                  label={t("USER_CARD.BIRTH_LOCATION")}
                  required
                  fullWidth
                  onBlur={handleBlur}
                  error={touched.birth_place && !!errors.birth_place}
                  helperText={touched.birth_place && t(errors.birth_place ?? "")}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField name="age" value={age} fullWidth label={t("USER_CARD.AGE")} disabled onBlur={handleBlur}></TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  select
                  value={values.sex_code}
                  required
                  name="sex_code"
                  onChange={handleChange}
                  label={t("FORM.SEX_CODE")}
                  fullWidth
                  onBlur={handleBlur}
                  error={touched.sex_code && !!errors.sex_code}
                  helperText={touched.sex_code && t(errors.sex_code ?? "")}
                >
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="F">F</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="profession"
                  value={values.profession}
                  inputProps={{ maxLength: 35 }}
                  onChange={handleChange}
                  label={t("USER_CARD.PROFESSION")}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={touched.profession && errors.profession ? t(errors.profession) : t("ERRORS.FORM.MUST_BE_MAX_35_CHARACTERS")}
                />
              </Grid>
              {/* Contact */}
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Grid item xs={12} sm={6}>
                <MuiPhone
                  value={values.phone}
                  onChange={(phone) => setFieldValue("phone", phone)}
                  onBlur={() => setFieldTouched("phone")}
                  error={touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone ? t(errors.phone) : t("FORM.HELPER_TEXT_PHONE")}
                  disabled={isLoading}
                  label={t("SIGN_UP.PHONE") + " *"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label={t("FORM.EMAIL")}
                  variant="outlined"
                  className={textFieldStyle}
                  fullWidth
                  value={values.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const emailValue = e.target.value;
                    if (emailValue) {
                      const emailParts = emailValue.split("@");
                      if (emailParts.length > 0 && emailParts[0]) {
                        const newDisplayName = emailParts[0].toLowerCase();
                        setFieldValue("displayName", newDisplayName);
                        verifyUniqueDisplayName(newDisplayName);
                      }
                    } else {
                      setFieldValue("displayName", "");
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  required
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email ? t(errors.email) : t("FORM.HELPER_TEXT_EMAIL")}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={8} sm={6}>
                <TextField
                  name="displayName"
                  variant="outlined"
                  color="primary"
                  label={t("FORM.DISPLAY_NAME")}
                  fullWidth
                  value={values.displayName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    verifyUniqueDisplayName(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  inputProps={{ maxLength: 100 }}
                  required
                  disabled
                  error={(touched.displayName && !!errors.displayName) || !uniqueDisplayName}
                  helperText={
                    touched.displayName && errors.displayName && uniqueDisplayName
                      ? t(errors.displayName)
                      : !uniqueDisplayName
                        ? t("ERRORS.FORM.INVALID_DISPLAY_NAME")
                        : t("FORM.HELPER_TEXT_DISPLAY_NAME")
                  }
                />
              </Grid>
              {/* {role === "admin" && ( */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="sede"
                  select
                  label={t("USER_CARD.WORK_PLACE")}
                  fullWidth
                  value={idSelectedSede}
                  error={touched.sede && !!errors.sede}
                  helperText={touched.sede && errors.sede ? t(errors.sede) : " "}
                  onBlur={() => setFieldTouched("sede", true, false)}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setIdSelectedSede(newValue);
                    setFieldValue("sede", newValue);
                    setFieldTouched("sede", true, false); //
                  }}
                  required
                >
                  {sedi.map((sede) => (
                    <MenuItem key={sede._id} value={sede._id}>
                      {sede.business_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* )} */}
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button disabled={isSubmitDisabled || isLoading} variant="contained" type="submit" sx={{ textTransform: "Capitalize" }}>
                    {!isLoading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                  </Button>
                </Grid>
              </Grid>
              {/* End of Contact */}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
export default NuovoAdmin;
