import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface GoogleMapsCardProps {
  address: string;
}

const GoogleMapsCard: React.FC<GoogleMapsCardProps> = ({ address }) => {
  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  useEffect(() => {
    const getLatLngFromAddress = async () => {
      try {
        //   const baseUrl = process.env.REACT_APP_GOOGLE_MAPS_URL;
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);

        if (response.ok) {
          const data = await response.json();
          if (data.results.length > 0) {
            const location = data.results[0].geometry.location;
            setCenter({ lat: location.lat, lng: location.lng });
          }
        }
      } catch (error) {
        console.error("Errore nella richiesta di geocodifica:", error);
      }
    };

    getLatLngFromAddress();
  }, [address]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const handleMapClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/?q=${center.lat},${center.lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <div className="card" style={{ height: "100%", width: "100%" }}>
      <div className="card-body">
        <h5 className="card-title">Indirizzo</h5>
        <p className="card-text">{address}</p>
        <div style={mapContainerStyle}>
          {apiKey && (
            <LoadScript googleMapsApiKey={apiKey}>
              <GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={center} onClick={handleMapClick}>
                <Marker position={center} onClick={handleMarkerClick} />
                {/* {showInfoWindow && (
                    <InfoWindow onCloseClick={handleInfoWindowClose}>
                      <div>
                        <h4>Titolo delle informazioni</h4>
                        <p>Qui puoi inserire le informazioni che desideri mostrare.</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker> */}
              </GoogleMap>
            </LoadScript>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleMapsCard;
