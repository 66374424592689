import { DataGridProps } from "@mui/x-data-grid/models/props/DataGridProps";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

const style: SxProps<Theme> = {
  width: "100%",
  border: 0,
  fontWeight: "bold",
  borderColor: "primary.light",
  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "var(--primary-500)",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
};
const StyledDataGrid = (props: DataGridProps<any> & React.RefAttributes<HTMLDivElement>) => {
  let sx = style as SxProps<Theme>;
  if (props.sx) {
    sx = { ...sx, ...props.sx } as SxProps<Theme>;
  }
  return <DataGrid {...props} sx={sx} />;
};

export default StyledDataGrid;
