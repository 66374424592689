import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import MyBreadCrumbs from "../../../../componets/breadCrumbs/MyBreadCrumbs";
import styles from "./NuovoDipendente.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast_error, toast_success } from "../../../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Form, Formik, FormikTouched } from "formik";
import nuovoDipendendenteValidationSchema from "./nuovoDipendendenteValidationSchema";
import { MuiPhone } from "../../../../componets/muiPhone/muiphone";
import { Spinner } from "react-bootstrap";
import { ContactTypeEnum } from "../../../../type/enum/contact-type.enum";
import { RegistryApiService } from "../../../../services/RegistryApiService";
import { EAuthRole, ERegistryType } from "../../../../type/enum/common.enum";
import { IRegistry } from "../../../../type/commons";
import { calculateAge } from "../../../../utils/utilsDate";
import { DipendenteNew } from "../../../../type/dipendente";
import { Util } from "../../../../utils/util";
import { differenceInMinutes } from "date-fns";
import { useParams } from "react-router-dom";

//TODO - Aggiungere Label e relative traduzioni
const NuovoDipendente = () => {
  const { t } = useTranslation();
  const schema = nuovoDipendendenteValidationSchema;
  const employeeInitialEmploye: DipendenteNew = {
    firstname: "",
    surname: "",
    birth_date: null,
    image: "",
    birth_place: "",
    sex_code: "",
    fiscal_code: "",
    profession: "",
    sede: "",
    displayName: "",
    email: { id: "", contact: "" },
    phone: { id: "", contact: "" },
    facebook: { id: "", contact: "" },
    instagram: { id: "", contact: "" },
    linkedin: { id: "", contact: "" },
    twitter: { id: "", contact: "" },
  };

  const [sedi, setSedi] = useState<IRegistry[]>([]);
  const [idSelectedSede, setIdSelectedSede] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingPost, setLoadingPost] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState(false);
  const [age, setAge] = useState<number>(0);
  const [uniqueDisplayName, setUniqueDisplayName] = useState<boolean>(true);
  const navigate = useNavigate();
  const registryApiService = new RegistryApiService();
  const role = sessionStorage.getItem("role");
  const { id, tenant } = useParams<{ id: string; tenant: string }>();

  const loadDataSedi = async () => {
    setIsLoading(true);
    setLoadingError(false);
    try {
      const params: any = {
        registry_type: ERegistryType.SEDE,
      };

      if (role === EAuthRole.SUPERADMIN) {
        params.id_registry_owner = id;
      }
      const data = await registryApiService.fetchAllRegistry(params);
      if (data) {
        setSedi(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
        setLoadingError(true);
      }
    } catch (error: any) {
      console.error("Errore durante il caricamento delle sedi");
      toast_error(error);
      setLoadingError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadDataSedi();
  }, []);

  const location = useLocation();

  const pathBreadcrumbs = [
    { label: t("COMMONS.COMPANY"), action: "goBack" },
    { label: t("COMMONS.NEW_EMPLOYEE"), url: location.pathname },
  ];

  const [uploadedFileName, setUploadedFileName] = useState<string>("Nessun file selezionato");

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setFieldValue("image", reader.result.toString());
          }
        };
        reader.readAsDataURL(file);
      } else {
        console.error("File non valido. Seleziona un file PNG.");
      }
    }
  };

  const onSubmit = (values: any) => {
    const payload = {
      id_registry_owner: idSelectedSede,
      // registry_type: ERegistryType.DIPENDENTE,
      firstname: values.firstname,
      surname: values.surname,
      // registry_address: [],
      birth_date: values.birth_date,
      sex_code: values.sex_code,
      fiscal_code: values.fiscal_code,
      profession: values.profession,
      birth_place: values.birth_place,
      image: values.image,
      displayName: values.displayName,
      user: {
        email: values.email.contact.toLowerCase(),
        // applicationsEnabled: ["idconnect"],
        // role: ERegistryRole.BASIC,
      },
      contact: [
        /*...(values.email.contact
          ? [
              {
                contact_type: EContactType.EMAIL,
                contact: values.email.contact,
                ...(values.email.id && { id: values.email.id }),
              },
            ]
          : []),*/
        ...(values.phone.contact
          ? [
              {
                contact_type: ContactTypeEnum.PHONE,
                contact: values.phone.contact,
                ...(values.phone.id && { id: values.phone.id }),
              },
            ]
          : []),
        ...(values.facebook.contact
          ? [
              {
                contact_type: ContactTypeEnum.FACEBOOK,
                contact: Util.normalizeUrl(values.facebook.contact),
                ...(values.facebook.id && { id: values.facebook.id }),
              },
            ]
          : []),

        ...(values.instagram.contact
          ? [
              {
                contact_type: ContactTypeEnum.INSTAGRAM,
                contact: Util.normalizeUrl(values.instagram.contact),
                ...(values.instagram.id && { id: values.instagram.id }),
              },
            ]
          : []),
        ...(values.linkedin.contact
          ? [
              {
                contact_type: ContactTypeEnum.LINKEDIN,
                contact: Util.normalizeUrl(values.linkedin.contact),
                ...(values.linkedin.id && { id: values.linkedin.id }),
              },
            ]
          : []),
        ...(values.twitter.contact
          ? [
              {
                contact_type: ContactTypeEnum.TWITTER,
                contact: Util.normalizeUrl(values.twitter.contact),
                ...(values.twitter.id && { id: values.twitter.id }),
              },
            ]
          : []),
      ],
    };

    console.log("RequestBody:", payload);
    setLoadingPost(true);
    registryApiService
      .createEmployee(payload)
      .then((response) => {
        setIdSelectedSede("");
        setUploadedFileName("Nessun file selezionato");
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/dipendenti/${id}/${tenant}`) : navigate(`/azienda/dipendenti`);
        toast_success(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        toast_error(error);
      })
      .finally(() => {
        setLoadingPost(false);
      });
  };

  const verifyUniqueDisplayName = async (displayName: string) => {
    if (displayName && displayName !== "") {
      const res: any = await registryApiService.verifyUniqueDisplayName(displayName);
      console.log(res);
      if (res.data) {
        setUniqueDisplayName(res.data.data === 0);
      } else {
        setUniqueDisplayName(res === 0);
      }
    }
  };

  return (
    <>
      <Typography variant="h1">{t("COMMONS.NEW_EMPLOYEE")}</Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MyBreadCrumbs path={pathBreadcrumbs} />
      </div>

      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={100} />
        </div>
      ) : loadingError ? (
        <div>{t("ERRORS.HEADQUARTERS_ERROR")}</div>
      ) : sedi.length === 0 ? (
        <>
          <Typography variant="h5" sx={{ marginTop: "10px" }}>
            {t("COMPANY.NO_HEADQUARTERS")}
          </Typography>
          {role === EAuthRole.SUPERADMIN ? (
            <a href={`/azienda/sedi/${id}/${tenant}/nuova-sede`} className={styles.buttonNewSede}>
              {t("COMPANY.CREATE_NEW_HEADQUARTER")}
            </a>
          ) : (
            <a href="/azienda/sedi/nuova-sede" className={styles.buttonNewSede}>
              {t("COMPANY.CREATE_NEW_HEADQUARTER")}
            </a>
          )}
          {/* <Button onClick={() => navigate("/azienda/sedi/nuova-sede")}>Crea una sede</Button> */}
        </>
      ) : (
        <Formik<DipendenteNew> initialValues={employeeInitialEmploye} onSubmit={onSubmit} enableReinitialize validationSchema={schema}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
            const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<DipendenteNew>]);
            const isSubmitDisabled = !formIsTouched || !isValid;
            console.log("errors", errors);
            console.log("touched", touched);
            return (
              <>
                <ToastContainer position="top-right" autoClose={3000} />
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ overflowY: "scroll", padding: "0 10px 20px 0 " }}>
                    <Grid item xs={12} sm={12} sx={{ paddingTop: "0 " }}>
                      {/* prima griglia */}
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={12} sm={12}>
                          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <Avatar sx={{ bgcolor: "#E5E4E2", width: 45, height: 45 }} variant="circular">
                              {values.image ? <img src={values.image} alt="Anteprima immagine" style={{ width: "50px" }} /> : null}
                            </Avatar>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <Button component="label" variant="contained">
                                {values.image ? t("FORM.EDIT_PICTURE") : t("FORM.UPLOAD_PICTURE")}
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    handleFileUpload(event, setFieldValue);
                                    setUploadedFileName(event.target.files![0].name);
                                  }}
                                  accept=".png, .jpg, .jpeg"
                                />
                              </Button>
                              {/* {values.image && (
                            <Button variant="outlined" color="secondary" onClick={handleImageDelete}>
                              Elimina Immagine
                            </Button>
                          )} */}
                            </div>
                            <div style={{ marginLeft: "10px", marginTop: "5px" }}>{uploadedFileName}</div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div className={styles.title}>{t("FORM.PERSONAL_INFORMATION")}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="firstname"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.NAME")}
                            fullWidth
                            value={values.firstname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            required
                            error={touched.firstname && !!errors.firstname}
                            helperText={touched.firstname && errors.firstname ? t(errors.firstname) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="surname"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.SURNAME")}
                            fullWidth
                            value={values.surname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            required
                            error={touched.surname && !!errors.surname}
                            helperText={touched.surname && errors.surname ? t(errors.surname) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="fiscal_code"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.FISCAL_CODE")}
                            fullWidth
                            value={values.fiscal_code.toUpperCase()}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 16 }}
                            required
                            error={touched.fiscal_code && !!errors.fiscal_code}
                            helperText={touched.fiscal_code && errors.fiscal_code ? t(errors.fiscal_code) : " "}
                          />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name="birth_date"
                              label={t("USER_CARD.BIRTH_DATE")}
                              value={values.birth_date}
                              format="dd/MM/yyyy"
                              maxDate={new Date()}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  error: touched.birth_date && !!errors.birth_date,
                                  helperText: touched.birth_date && errors.birth_date ? t(errors.birth_date) : " ",
                                  onBlur: handleBlur,
                                  required: true,
                                },
                              }}
                              onChange={async (date) => {
                                const age = await calculateAge(date!);
                                setFieldValue("birth_date", date);
                                setFieldTouched("birth_date", true, false);
                                setAge(age);
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="birth_place"
                            value={values.birth_place}
                            inputProps={{ maxLength: 250 }}
                            onChange={handleChange}
                            label={t("USER_CARD.BIRTH_LOCATION")}
                            required
                            fullWidth
                            onBlur={handleBlur}
                            error={touched.birth_place && !!errors.birth_place}
                            helperText={touched.birth_place && t(errors.birth_place ?? "")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField name="age" value={age} fullWidth label={t("USER_CARD.AGE")} disabled onBlur={handleBlur}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            select
                            value={values.sex_code}
                            required
                            name="sex_code"
                            onChange={handleChange}
                            label={t("FORM.SEX_CODE")}
                            fullWidth
                            onBlur={handleBlur}
                            error={touched.sex_code && !!errors.sex_code}
                            helperText={touched.sex_code && t(errors.sex_code ?? "")}
                          >
                            <MenuItem value="M">M</MenuItem>
                            <MenuItem value="F">F</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="profession"
                            value={values.profession}
                            inputProps={{ maxLength: 35 }}
                            onChange={handleChange}
                            label={t("USER_CARD.PROFESSION")}
                            fullWidth
                            onBlur={handleBlur}
                            helperText={touched.profession && errors.profession ? t(errors.profession) : t("ERRORS.FORM.MUST_BE_MAX_35_CHARACTERS")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div className={styles.title}>{t("USER_CARD.CONTACT")}</div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiPhone
                            value={values.phone.contact}
                            onChange={(value: string) => setFieldValue("phone.contact", value)}
                            onBlur={() => setFieldTouched("phone.contact")}
                            error={touched.phone?.contact && !!errors.phone?.contact}
                            helperText={
                              touched.phone?.contact && errors.phone?.contact
                                ? t(errors.phone.contact)
                                : "Inserisci un numero con prefisso internazionale"
                            }
                            disabled={isLoading}
                            className={styles.textFields}
                            label={t("SIGN_UP.PHONE") + " *"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="email.contact"
                            variant="outlined"
                            label={t("FORM.EMAIL")}
                            fullWidth
                            required
                            value={values.email.contact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const emailValue = e.target.value;
                              if (emailValue) {
                                const emailParts = emailValue.split("@");
                                if (emailParts.length > 0 && emailParts[0]) {
                                  const newDisplayName = emailParts[0].toLowerCase();
                                  setFieldValue("displayName", newDisplayName);
                                  verifyUniqueDisplayName(newDisplayName);
                                }
                              } else {
                                setFieldValue("displayName", "");
                              }
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            error={touched.email?.contact && !!errors.email?.contact}
                            helperText={
                              touched.email?.contact && errors.email?.contact ? t(errors.email.contact) : "Inserisci un indirizzo email valido"
                            }
                          />
                        </Grid>
                        <Grid item xs={8} sm={6}>
                          <TextField
                            name="displayName"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.DISPLAY_NAME")}
                            fullWidth
                            value={values.displayName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              verifyUniqueDisplayName(e.target.value);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 100 }}
                            required
                            disabled
                            error={(touched.displayName && !!errors.displayName) || !uniqueDisplayName}
                            helperText={
                              touched.displayName && errors.displayName && uniqueDisplayName
                                ? t(errors.displayName)
                                : !uniqueDisplayName
                                  ? t("ERRORS.FORM.INVALID_DISPLAY_NAME")
                                  : "Nome utente univoco per l'eCard"
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="sede"
                            select
                            label={t("USER_CARD.WORK_PLACE")}
                            fullWidth
                            value={idSelectedSede}
                            onBlur={() => setFieldTouched("sede", true, false)}
                            onChange={(event) => {
                              const newValue = event.target.value;
                              setIdSelectedSede(newValue);
                              setFieldValue("sede", newValue);
                              setFieldTouched("sede", true, false); //
                            }}
                            required
                            error={touched.sede && !!errors.sede}
                            helperText={touched.sede && errors.sede ? t(errors.sede) : " "}
                          >
                            {sedi.map((sede) => (
                              <MenuItem key={sede._id} value={sede._id}>
                                {sede.business_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      {/* seconda griglia */}
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={12} sm={12}>
                          <div className={styles.title}>{t("FORM.SOCIAL_NETWORKS")}</div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="facebook.contact"
                            variant="outlined"
                            label="Facebook"
                            fullWidth
                            value={values.facebook.contact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.facebook?.contact && !!errors.facebook?.contact}
                            helperText={
                              touched.facebook?.contact && errors.facebook?.contact ? t(errors.facebook.contact) : t("FORM.HELPER_TEXT_URL")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="instagram.contact"
                            variant="outlined"
                            label="Instagram"
                            fullWidth
                            value={values.instagram.contact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.instagram?.contact && !!errors.instagram}
                            helperText={
                              touched.instagram?.contact && errors.instagram?.contact ? t(errors.instagram.contact) : t("FORM.HELPER_TEXT_URL")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="twitter.contact"
                            variant="outlined"
                            label={t("FORM.TWITTER")}
                            fullWidth
                            value={values.twitter.contact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.twitter?.contact && !!errors.twitter?.contact}
                            helperText={touched.twitter?.contact && errors.twitter?.contact ? t(errors.twitter.contact) : t("FORM.HELPER_TEXT_URL")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="linkedin.contact"
                            variant="outlined"
                            label="Linkedin"
                            fullWidth
                            value={values.linkedin.contact}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.linkedin?.contact && !!errors.linkedin?.contact}
                            helperText={
                              touched.linkedin?.contact && errors.linkedin?.contact ? t(errors.linkedin.contact) : t("FORM.HELPER_TEXT_URL")
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitDisabled || loadingPost || !uniqueDisplayName}
                        sx={{ textTransform: "Capitalize" }}
                      >
                        {!loadingPost ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default NuovoDipendente;
