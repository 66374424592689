import { Form, Spinner } from "react-bootstrap";
import { Alert, Button, Grid, TextField } from "@mui/material";
import { Formik, FormikTouched } from "formik";
import { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../sign-up/SignUp.module.css";
import { useTranslation } from "react-i18next";
import { toast_success } from "../../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import { MuiPhone } from "../../../componets/muiPhone/muiphone";
import { RegistryApiService } from "../../../services/RegistryApiService";
import * as Yup from "yup";
import { boolean, string } from "yup";

import { intlPhoneRegex } from "../../../utils/regex";

export interface IAuthSignUp {
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  phone: string;
}

export interface IAuthSignUpExtended extends IAuthSignUp {
  recreateCompany?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function NuovaAziendaFix() {
  const { t } = useTranslation();
  const [mostraPwd, setMostraPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>("Nessun file selezionato");
  const location = useLocation();
  const recreateCompany = location.pathname.includes("ricrea-azienda");
  console.log("recreateCompany", recreateCompany);
  const [alert, setAlert] = useState<any>({
    show: false,
    severity: "error",
    message: "SIGN_UP.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const initialValues: IAuthSignUp = {
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    phone: "",
  };

  const registryApiService = new RegistryApiService();

  function getValidationSchema(recreateCompany: boolean) {
    // Schema di base senza il campo email
    let schema = Yup.object().shape({
      firstName: Yup.string()
        .required("ERRORS.FORM.REQUIRED")
        .max(50, "ERRORS.FORM.SHOULD_BE_AT_MOST_50_CHARACTERS")
        .trim()
        .min(2, "ERRORS.FORM.SHOULD_BE_AT_LEAST_2_CHARACTERS"),
      lastName: Yup.string()
        .required("ERRORS.FORM.REQUIRED")
        .max(50, "ERRORS.FORM.SHOULD_BE_AT_MOST_50_CHARACTERS")
        .trim()
        .min(2, "ERRORS.FORM.SHOULD_BE_AT_LEAST_2_CHARACTERS"),
      businessName: Yup.string()
        .required("ERRORS.FORM.REQUIRED")
        .min(3, "ERRORS.FORM.SHOULD_BE_AT_LEAST_3_CHARACTERS")
        .max(50, "ERRORS.FORM.SHOULD_BE_AT_MOST_50_CHARACTERS")
        .trim(),
      phone: string().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE").trim("ERRORS.FORM.INVALID_PHONE"),
    });

    if (!recreateCompany) {
      schema = schema.concat(
        Yup.object().shape({
          email: Yup.string().trim().email("ERRORS.FORM.INVALID_EMAIL").required("ERRORS.FORM.REQUIRED"),
        }),
      );
    }

    return schema;
  }

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    // Opzionale: Potresti voler chiedere conferma prima di cancellare l'immagine
    setImageBase64(null);
    setUploadedFileName("Nessun file selezionato");
    setOpen(false);
  };

  const handleRemoveImage = () => {
    // Rimuove l'immagine senza chiudere la dialog
    setImageBase64(null);
    setUploadedFileName("Nessun file selezionato");
  };

  const handleFileUpload = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (field: string, isTouched: boolean) => void,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setFieldValue("image", reader.result.toString());
            setFieldTouched("image", true);
            setUploadedFileName(file.name);
          }
        };
        reader.readAsDataURL(file);
      } else {
        console.error("File non valido");
      }
    }
  };

  const onSubmit = async (values: IAuthSignUp) => {
    setIsLoading(true);

    const validationSchema = getValidationSchema(recreateCompany);

    try {
      await validationSchema.validate(values, { abortEarly: false });

      let payload: any = { ...values };

      if (recreateCompany) {
        const { email, ...rest } = payload;
        payload = { ...rest, recreateCompany: true };
      }

      await registryApiService.createCompany(payload);
      toast_success(t("COMMONS.OPERATION_SUCCESS"));
      navigate(recreateCompany ? "/homepage" : "/gestione-aziende");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        console.error("Validation errors:", error.inner);
      } else {
        console.error("Submission error:", error);
        setAlert({
          show: true,
          severity: "error",
          message: error,
          dismissible: true,
          onClose: () => {
            setAlert({ ...alert, show: false });
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1>{t("COMMONS.NEW_COMPANY")}</h1>
      <br></br>
      {alert.show && (
        <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
          {t(alert.message)}
        </Alert>
      )}
      <Formik<IAuthSignUp> initialValues={initialValues} onSubmit={onSubmit} validationSchema={getValidationSchema(recreateCompany)}>
        {({ handleSubmit, handleChange, values, handleBlur, touched, errors, isValid, setFieldValue, setFieldTouched }) => {
          const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IAuthSignUp>]);
          const isSubmitDisabled = !formIsTouched || !isValid;
          console.log("errors", errors);

          return (
            <>
              <ToastContainer position="top-center" autoClose={3000} />
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="firstName"
                        label={t("SIGN_UP.FIRST_NAME") + " *"}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && !!errors.firstName}
                        helperText={touched.firstName && t(errors.firstName ? errors.firstName : "")}
                        inputProps={{ maxLength: 50 }}
                        disabled={isLoading}
                        fullWidth
                        className={styles.textFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="lastName"
                        label={t("SIGN_UP.LAST_NAME") + " *"}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && !!errors.lastName}
                        helperText={touched.lastName && t(errors.lastName ? errors.lastName : "")}
                        inputProps={{ maxLength: 50 }}
                        disabled={isLoading}
                        fullWidth
                        className={styles.textFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="businessName"
                        label={t("SIGN_UP.BUSINESS_NAME") + " *"}
                        value={values.businessName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.businessName && !!errors.businessName}
                        helperText={touched.businessName && t(errors.businessName ? errors.businessName : "")}
                        inputProps={{ maxLength: 50 }}
                        disabled={isLoading}
                        fullWidth
                        className={styles.textFields}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPhone
                        value={values.phone}
                        onChange={(phone) => setFieldValue("phone", phone)}
                        onBlur={() => setFieldTouched("phone")}
                        error={touched.phone && !!errors.phone}
                        helperText={touched.phone && t(errors.phone ?? "")}
                        disabled={isLoading}
                        className={styles.textFields}
                        label={t("SIGN_UP.PHONE") + " *"}
                      />
                    </Grid>
                    {!recreateCompany && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="email"
                          label={t("SIGN_UP.EMAIL") + " *"}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && !!errors.email}
                          helperText={touched.email && t(errors.email ? errors.email : "")}
                          disabled={isLoading}
                          fullWidth
                          className={styles.textFields}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitDisabled || isLoading}
                    sx={{ textTransform: "Capitalize" }}
                  >
                    {isLoading ? <Spinner animation="border" /> : t("COMMONS.CONFIRM")}
                  </Button>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default NuovaAziendaFix;
