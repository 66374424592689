import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Image from "react-bootstrap/Image";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import idConnect from "../../../../img/logoID.svg";
import { ForeheadCardTemplateProps } from "../../../../type/props/card-template-props";

const ForeheadCard: React.FC<ForeheadCardTemplateProps> = ({ color, image, design }) => {
  const [widthCard, setWidthCard] = useState<string | undefined>();
  const [heightCard, setHeightCard] = useState<string | undefined>();

  const defaultWidthCard = "680px";
  const defaultHeightCard = "378px";

  useEffect(() => {
    if (design !== undefined && design.card !== undefined) {
      setWidthCard(design.card.width);
      setHeightCard(design.card.height);
    } else {
      setWidthCard(defaultWidthCard);
      setHeightCard(defaultHeightCard);
    }
  }, []);

  return (
    <>
      <Card style={{ backgroundColor: color, borderRadius: "12px", height: heightCard, width: widthCard }} elevation={3}>
        <CardContent>
          <Grid container height={design ? design.firstRow.height : "74px"}></Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} justifyContent={"center"} alignContent={"center"}>
              <Box
                color={color}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
                minHeight={design ? design.logo.box.minHeight : "200px"}
              >
                {!image && (
                  <Box
                    minHeight={design ? design.logo.box.minHeight : "200px"}
                    minWidth={design ? design.logo.box.minWidth : "200px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#E5E4E2",
                        minHeight: design ? design.logo.avatarHeight : "100px",
                        minWidth: design ? design.logo.avatarWidth : "100px",
                      }}
                      variant="rounded"
                    >
                      <Image src={idConnect} />
                    </Avatar>
                  </Box>
                )}
                {image && (
                  <Image
                    src={image}
                    rounded
                    style={{
                      maxHeight: design ? design.logo.box.maxHeight : "200px",
                      maxWidth: design ? design.logo.box.maxWidth : "200px",
                      minWidth: design ? design.logo.box.minWidth : "auto",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container height={design ? design.lastRow.height : "74px"}></Grid>
        </CardContent>
      </Card>
    </>
  );
};
export default ForeheadCard;