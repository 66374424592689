import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { NewNameTemplateProps } from "../../../../type/props/card-template-props";

const NewNameTemplate: React.FC<NewNameTemplateProps> = ({ open, onClose, onConfirm, onChangeName }) => {
  const [name, setName] = useState<string>("");
  const { t } = useTranslation();

  const newName = (event: any) => {
    setName(event.target.value);
  };

  useEffect(() => {
    onChangeName(name);
  }, [name]);

  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: "8px" }}>
      <DialogTitle>{t("TEMPLATE.NEW_TEMPLATE")}</DialogTitle>
      <DialogContent>
        <Box marginTop={"20px"} marginBottom={"10px"} minWidth={"400px"} component={"form"}>
          <TextField name="name" value={name} onChange={newName} size={"small"} label={t("TEMPLATE.NAME_TEMPLATE")} fullWidth />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box marginRight={"18px"}>
          <Button sx={{ textTransform: "Capitalize", mr: 1 }} onClick={onClose}>
            {t("COMMONS.CANCEL")}
          </Button>
          <Button sx={{ textTransform: "Capitalize" }} variant={"contained"} onClick={onConfirm} disabled={!name}>
            {t("COMMONS.PROCEED")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default NewNameTemplate;
