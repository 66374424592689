// export const BE_ADDRESS = "192.168.6.96";
// export const BE_ADDRESS = "192.168.122.1";
// export const BE_ADDRESS = "wbcoll.ns12.it";
export const BE_ADDRESS = process.env.REACT_APP_API_URL;
export const PROTOCOL = process.env.REACT_APP_PROTOCOL + "://";

// export const AUTH_URL = "https://" + BE_ADDRESS;
export const IDCONNECT_URL = PROTOCOL + BE_ADDRESS;

//IDCONNECT_URL
export const WORKFLOW_PREFIX = process.env.WF_PREFIX;

export const AUTH_URL = process.env.REACT_APP_AUTH_API_URL
  ? process.env.REACT_APP_AUTH_API_URL + "/authentication"
  : IDCONNECT_URL + "/authentication";
export const MENU_URL = process.env.REACT_APP_MENU_API_URL ? process.env.REACT_APP_MENU_API_URL + "/menu" : IDCONNECT_URL + "/menu";
export const REGISTER_URL = process.env.REACT_APP_REGISTER_API_URL ? process.env.REACT_APP_REGISTER_API_URL + "/reg" : IDCONNECT_URL + "/reg";
export const RESOURCE_URL = process.env.REACT_APP_RESOURCE_API_URL ? process.env.REACT_APP_RESOURCE_API_URL + "/hr" : IDCONNECT_URL + "/hr";
export const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW_API_URL ? process.env.REACT_APP_WORKFLOW_API_URL  : IDCONNECT_URL + "/wf";
export const IDCONNECT_MODULE_URL = process.env.REACT_APP_IDCONNECT_API_URL ? process.env.REACT_APP_IDCONNECT_API_URL + "/idconnect" : IDCONNECT_URL + "/idconnect";;
export const PAYMENT_URL = IDCONNECT_URL + "/payment";

export const API_LOGIN = AUTH_URL + "/auth/login";
export const ID_CONNECT_BE_URL = process.env.REACT_APP_IDCONNECT_API_URL ? process.env.REACT_APP_IDCONNECT_API_URL + "/idconnect" : IDCONNECT_URL + "/idconnect";

//---------------------Registry Module---------------------
//REGISTER_URL
export const API_GET_REGISTRY_LIST_SEARCH = REGISTER_URL + "/registry/list/search";
export const API_GET_REGISTRY_LIST_COUNT = REGISTER_URL + "/registry/list/count";
export const API_REGISTRY_PAGINATION = REGISTER_URL + "/registry";
export const REGISTRY_SEARCH_KEYCODE = REGISTER_URL + "/registry/search/keyCode";
export const API_GET_REGISTRY_TYPE_LIST_SEARCH = REGISTER_URL + "/registry-type/list/search";
export const API_GET_REGISTRY_CONTACT_TYPE_LIST_SEARCH = REGISTER_URL + "/contact-type/list/search";
export const API_GET_REGISTRY_ADDRESS_TYPE_LIST_SEARCH = REGISTER_URL + "/registry-address-type/list/search";
export const API_GET_LOGO_AZIENDA = REGISTER_URL + "/registry/search/imageByTenant";
export const API_REGISTRY_FULL = REGISTER_URL + "/registry/full";
export const API_GET_REGISTRY_BY_ID = REGISTER_URL + `/registry/`;
export const API_GET_REGISTRY_DETAIL_BY_ID = REGISTER_URL + `/registry/detail/`;
export const API_CONTACT_BY_ID = REGISTER_URL + `/contact/`;
export const API_INFO_ME = REGISTER_URL + `/registry/info/me`;

export const API_POST_BULK_DELETE = REGISTER_URL + `/registry/bulkDelete`;
export const API_POST_IMPORT_CSV = REGISTER_URL + `/registry/import/csv`;
export const API_POST_IMPORT_SEND_CARD = REGISTER_URL + `/registry/sendCards`;

export const API_DOWNLOAD_TEMPLATE_CSV = REGISTER_URL + `/registry/download-template/csv`;

//Create API
export const API_CREATE_PUBLIC_COMPANY = ID_CONNECT_BE_URL + `/registry/public/createCompany`;
export const API_CREATE_COMPANY = REGISTER_URL + `/registry/createCompany`;
export const API_POST_CREATE_BULK_EMPLOYEE = REGISTER_URL + `/registry/bulkCreateEmployee`;
export const API_CREATE_EMPLOYEE = REGISTER_URL + `/registry/createEmployee`;
export const API_CREATE_ADMIN_USER = REGISTER_URL + `/registry/createAdminUser`;
export const API_DELETE_IMAGE = REGISTER_URL + `/registry/image/`;

//Payment API
export const API_PRODUCT_LIST_SEARCH = PAYMENT_URL + "/product/list/search";
export const API_PRICE_LIST_SEARCH = PAYMENT_URL + "/price/list/search";
export const API_CUSTOMER_ME = PAYMENT_URL + "/customer/me";
export const API_CREATE_CHECKOUT = PAYMENT_URL + "/customer/createCheckout";
export const API_PURCHASED_PRODUCT = PAYMENT_URL + "/purchased-product/list/search";

//WORKFLOW
export const API_GET_TRASITION_STATE = WORKFLOW_URL + "/transition-role/list/search";


//AUTH_URL
export const API_AUTH_FORGOT_PASSWORD = AUTH_URL + "/auth/forgotPassword";
export const API_AUTH_RECOVERY_PASSWORD = AUTH_URL + "/auth/recoveryPassword";
export const API_AUTH_CHANGE_PASSWORD = AUTH_URL + "/auth/changePassword";
export const API_AUTH_NEW_PASSWORD = AUTH_URL + "/auth/newPassword";

// ID-CONNECT URL
export const API_GET_TEMPLATE_SEARCH = ID_CONNECT_BE_URL + "/template/list/search";
export const API_GET_TEMPLATE = ID_CONNECT_BE_URL + "/template/";
export const API_GET_TEMPLATE_SELECT = ID_CONNECT_BE_URL + "/template";
export const API_UPDATE_TEMPLATE = ID_CONNECT_BE_URL + "/template/";

export const API_DELETE_TEMPLATE = ID_CONNECT_BE_URL + "/template/";
export const API_CREATE_TEMPLATE = ID_CONNECT_BE_URL + "/template";

export const API_GET_MENU = MENU_URL + "/menu-item/list/search";

//RESOURCE URL

export const API_GET_CATEGORY_LIST_COUNT = RESOURCE_URL + "/category/list/count";
export const API_GET_CATEGORY_LIST = RESOURCE_URL + "/category/list/search";

export const API_GET_REQUEST_TYPE_LIST = RESOURCE_URL + "/request-type/list/search";
export const API_GET_REQUEST_TYPE_LIST_COUNT = RESOURCE_URL + "/request-type/list/count";

export const API_GET_DATA_CONTRACTUAL_BY_USER_ID = RESOURCE_URL + "/contractual-data/getDataForUser/";
export const API_GET_DATA_HOME_BY_USER_ID = RESOURCE_URL + "/resource/getHomeData/";

export const API_GET_ALL_GENERIC_TYPES = RESOURCE_URL + "/generic-type/list/search/";
//
export const API_HR_URL = process.env.REACT_APP_HR_URL;
export const API_GET_SHIPPING_ADDRESS = ID_CONNECT_BE_URL + "/registry-shipping-address/list/search";
export const API_POST_SHIPPING_ADDRESS = ID_CONNECT_BE_URL + "/registry-shipping-address";

export const API_GET_REQUEST_SELECT = ID_CONNECT_BE_URL + "/card-request";

export const API_GET_REGISTRIES_CARD_REQUEST = ID_CONNECT_BE_URL + "/registry-card/associated";
