import * as Yup from "yup";
import { boolean, date, object, string } from "yup";

import YupPassword from "yup-password";
import { codiceFiscaleRegex, intlPhoneRegex } from "../../../../utils/regex";

YupPassword(Yup);

const emailSchema = object({
  contact: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
});

const phoneSchema = object({
  contact: string().trim().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
});

const socialSchema = object({
  contact: string().trim(),
});

const oggi = new Date();
const diciottoAnniFa = new Date(oggi.getFullYear() - 18, oggi.getMonth(), oggi.getDate());
const centoAnniFa = new Date(oggi.getFullYear() - 100, oggi.getMonth(), oggi.getDate());

export default object().shape({
  firstname: string().trim().required("ERRORS.FORM.REQUIRED"),
  surname: string().trim().required("ERRORS.FORM.REQUIRED"),
  fiscal_code: string().trim().required("ERRORS.FORM.REQUIRED").matches(codiceFiscaleRegex, "ERRORS.FORM.INVALID_FISCAL_CODE"),
  sex_code: string().trim().required("ERRORS.FORM.REQUIRED"),
  birth_date: date()
    .typeError("ERRORS.FORM.INVALID_DATE")
    .required("ERRORS.FORM.REQUIRED")
    .max(diciottoAnniFa, "ERRORS.FORM.INVALID_NOT_ADULT")
    .min(centoAnniFa, "ERRORS.FORM.INVALID_DATE"),
  birth_place: string().trim().required("ERRORS.FORM.REQUIRED"),
  sede: string().trim().required("ERRORS.FORM.REQUIRED"),
  email: emailSchema,
  phone: phoneSchema,
  facebook: socialSchema,
  instagram: socialSchema,
  twitter: socialSchema,
  linkedin: socialSchema,
  displayName: string().trim().required("ERRORS.FORM.REQUIRED"),
  terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
