import { ChangeEvent, useEffect, useState } from "react";
import { Form, Formik, FormikTouched } from "formik";
import { Avatar, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import profiloAziendeValidationSchema from "./profiloAziendeValidationSchema";
import { toast_error, toast_success } from "../../../utils/custom-toast";
import { MuiPhone } from "../../../componets/muiPhone/muiphone";
import { ContactTypeEnum } from "../../../type/enum/contact-type.enum";
import { RegistryAddressTypeEnum } from "../../../type/enum/registry-address-type.enum";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { IContact } from "../../../type/commons";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CoreApiService } from "../../../services/CoreApiService";
import ConfirmDialog from "../../../componets/dialog/ConfirmDialog";
import PasswordDialog from "../../../componets/dialog/PasswordDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Util } from "../../../utils/util";
import React from "react";
import { string } from "yup";
import { EAuthRole } from "../../../type/enum/common.enum";
import DownloadIcon from "@mui/icons-material/Download";
import { use } from "i18next";

interface ContactDetails {
  id: string;
  contact: string;
}

interface RegistryAddressType {
  _id: string;
  code: string;
  description: string;
}

interface Address {
  _id: string;
  id_registry_address_type: RegistryAddressType;
  latitude?: null | number;
  longitude?: null | number;
  fullAddress: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
}

export interface AziendaInfo {
  _id?: string;
  business_name: string;
  image: string;
  email: ContactDetails;
  phone: ContactDetails;
  website: ContactDetails;
  facebook: ContactDetails;
  instagram: ContactDetails;
  linkedin: ContactDetails;
  twitter: ContactDetails;
  customLink: { label: string; value: string }[];
  address: Address;
}

function ModificaProfilo({
  setIdOwner,
  idOwner,
  isBusinessNameDeleted,
}: {
  setIdOwner: (id: string) => void;
  idOwner: string;
  isBusinessNameDeleted: boolean | undefined;
}) {
  const { t } = useTranslation();

  const initialValues: AziendaInfo = {
    _id: "",
    business_name: "",
    image: "",
    email: { id: "", contact: "" },
    phone: { id: "", contact: "" },
    website: { id: "", contact: "" },
    facebook: { id: "", contact: "" },
    instagram: { id: "", contact: "" },
    linkedin: { id: "", contact: "" },
    twitter: { id: "", contact: "" },

    customLink: [
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
    ],
    address: {
      _id: "",
      id_registry_address_type: { _id: "", code: "", description: "" },
      latitude: null,
      longitude: null,
      fullAddress: "",
      number: 0,
      postal_code: "",
      city: "",
      province: "",
      state: "",
    },
  };

  const [aziendaInfo, setAziendaInfo] = useState<AziendaInfo>(initialValues);

  const [loading, setLoading] = useState<boolean>(true);
  const [makeRequest, setMakeRequest] = useState<boolean>(false);
  const [patchLoading, setPatchLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const registryApiService = new RegistryApiService();
  const coreApiService = new CoreApiService();
  const schema = profiloAziendeValidationSchema;
  const role = sessionStorage.getItem("role");
  const { id, tenant } = useParams();

  const fetchDataAzienda = async () => {
    setLoading(true);
    try {
      const response = await registryApiService.getRegistryById(idOwner);
      if (response) {
        const emailContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.EMAIL);
        const phoneContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.PHONE);
        const website = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.SITO_WEB);
        const facebookContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.FACEBOOK);
        const instagramContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.INSTAGRAM);
        const linkedinContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.LINKEDIN);
        const twitterContact = response.id_contact.find((contact: IContact) => contact.id_contact_type.code === ContactTypeEnum.TWITTER);

        const customLink: any = response.customLink
          ? response.customLink.map((link) => ({
              label: link.label || "",
              value: link.value || "",
            }))
          : [
              { label: "", value: "" },
              { label: "", value: "" },
              { label: "", value: "" },
              { label: "", value: "" },
            ];

        const updatedValues: AziendaInfo = {
          _id: response._id,
          business_name: response.business_name ?? "",
          image: response.image ? response.image : "",
          email: {
            id: emailContact ? emailContact._id : "",
            contact: emailContact ? emailContact.contact : "",
          },
          phone: {
            id: phoneContact ? phoneContact._id : "",
            contact: phoneContact ? phoneContact.contact : "",
          },
          website: {
            id: website ? website._id : "",
            contact: website ? website.contact : "",
          },
          facebook: {
            id: facebookContact ? facebookContact._id : "",
            contact: facebookContact ? facebookContact.contact : "",
          },
          instagram: {
            id: instagramContact ? instagramContact._id : "",
            contact: instagramContact ? instagramContact.contact : "",
          },
          linkedin: {
            id: linkedinContact ? linkedinContact._id : "",
            contact: linkedinContact ? linkedinContact.contact : "",
          },
          twitter: {
            id: twitterContact ? twitterContact._id : "",
            contact: twitterContact ? twitterContact.contact : "",
          },
          customLink: customLink,
          address: {
            _id: response.id_registry_address[0]?._id || "",
            id_registry_address_type: {
              _id: response.id_registry_address[0]?.id_registry_address_type?._id || "",
              code: response.id_registry_address[0]?.id_registry_address_type?.code || "",
              description: response.id_registry_address[0]?.id_registry_address_type?.description || "",
            },
            latitude: response.id_registry_address[0]?.latitude || null,
            longitude: response.id_registry_address[0]?.longitude || null,
            fullAddress: `${response.id_registry_address[0]?.topon || ""} ${response.id_registry_address[0]?.street || ""}`,
            number: response.id_registry_address[0]?.number || 0,
            postal_code: response.id_registry_address[0]?.postal_code || "",
            city: response.id_registry_address[0]?.city || "",
            province: response.id_registry_address[0]?.province || "",
            state: response.id_registry_address[0]?.state || "",
          },
        };
        setAziendaInfo(updatedValues);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idOwner !== "" && idOwner !== undefined && idOwner) {
      fetchDataAzienda();
    }
  }, [idOwner, makeRequest]);

  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  const deleteSocialContact = async (value: string, contactId: string) => {
    if (value === "" && contactId) {
      registryApiService
        .deleteContactById(contactId)
        .then((response) => {
          console.log("Contatto eliminato:", response.data);
        })
        .catch((error) => {
          console.error("Errore durante l'eliminazione del contatto:");
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
          }
        });
    }
  };

  const handleSendCards = async (tenant: string) => {
    const body: any = {
      type_card_code: "METAL",
      force: true,
    };
    try {
      const response = await coreApiService.sendCardLite(body, tenant);
      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        handleClosePasswordDialog();
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      toast_error(error);
    }
  };

  const handleFileUpload = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (field: string, isTouched: boolean) => void,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setFieldValue("image", reader.result.toString());
            setFieldTouched("image", true);
            setUploadedFileName(file.name);
          }
        };
        reader.readAsDataURL(file);
      } else {
        console.error("File non valido");
        setUploadedFileName("File non valido");
      }
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    // Opzionale: Potresti voler chiedere conferma prima di cancellare l'immagine
    setUploadedFileName("Nessun file selezionato");
    setOpen(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirmModal(true);
    // setSelectedId(id);
  };

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenConfirmModal(false);
    setOpenDeleteModal(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await coreApiService.deleteCompany(id);

      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        navigate("/homepage");
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      toast_error(error);
    } finally {
      setOpenConfirmModal(false);
    }
  };

  const handleExportData = async () => {
    try {
      // Assicurati che la chiamata restituisca i dati come un Blob
      const response = await coreApiService.exportAllData(idOwner);
      console.log("response", response);

      // Estrae il nome del file dall'header Content-Disposition
      const contentDisposition = response.headers["content-disposition"];
      let filename = "data.xls"; // Usa un valore di default se necessario
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, ""); // Rimuove eventuali apici dal nome del file
        }
      }

      // Crea un URL per il Blob e inizia il download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Imposta il nome del file estratto
      document.body.appendChild(link);
      link.click();

      // Pulizia dopo il download
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore durante il download:", error);
    }
  };

  const onSubmit = async (values: any) => {
    const parts = values.address.fullAddress.split(" ");
    const topon = parts.shift();
    const street = parts.join(" ");
    const filteredCustomLinks = values.customLink.filter((link: any) => Util.normalizeUrl(link.label.trim()) !== "" || link.value.trim() !== "");
    const normalizedCustomLinks = filteredCustomLinks.map((link: any) => ({
      ...link,
      value: Util.normalizeUrl(link.value.trim()), // Ensure links are normalized
    }));
    const updatedData = {
      business_name: values.business_name,
      image: values.image,
      contact: [
        ...(values.email.contact
          ? [
              {
                contact_type: ContactTypeEnum.EMAIL,
                contact: values.email.contact,
                ...(values.email.id && { id: values.email.id }),
              },
            ]
          : []),
        ...(values.phone.contact
          ? [
              {
                contact_type: ContactTypeEnum.PHONE,
                contact: values.phone.contact,
                ...(values.phone.id && { id: values.phone.id }),
              },
            ]
          : []),
        ...(values.website.contact
          ? [
              {
                contact_type: ContactTypeEnum.SITO_WEB,
                contact: Util.normalizeUrl(values.website.contact),
                ...(values.website.id && { id: values.website.id }),
              },
            ]
          : []),
        ...(values.facebook.contact
          ? [
              {
                contact_type: ContactTypeEnum.FACEBOOK,
                contact: Util.normalizeUrl(values.facebook.contact),
                ...(values.facebook.id && { id: values.facebook.id }),
              },
            ]
          : []),

        ...(values.instagram.contact
          ? [
              {
                contact_type: ContactTypeEnum.INSTAGRAM,
                contact: Util.normalizeUrl(values.instagram.contact),
                ...(values.instagram.id && { id: values.instagram.id }),
              },
            ]
          : []),
        ...(values.linkedin.contact
          ? [
              {
                contact_type: ContactTypeEnum.LINKEDIN,
                contact: Util.normalizeUrl(values.linkedin.contact),
                ...(values.linkedin.id && { id: values.linkedin.id }),
              },
            ]
          : []),
        ...(values.twitter.contact
          ? [
              {
                contact_type: ContactTypeEnum.TWITTER,
                contact: Util.normalizeUrl(values.twitter.contact),
                ...(values.twitter.id && { id: values.twitter.id }),
              },
            ]
          : []),
      ],
      customLink: normalizedCustomLinks,
      registry_address: [
        {
          ...(aziendaInfo.address?._id && { id: values.address._id }),
          registry_address_type: RegistryAddressTypeEnum.LEGALE_PRINCIPALE,
          topon: topon,
          street: street,
          number: Number(values.address.number),
          postal_code: values.address.postal_code,
          city: values.address.city,
          province: values.address.province,
          state: values.address.state,
          latitude: values.address.latitude,
          longitude: values.address.longitude,
        },
      ],
    };

    if (!aziendaInfo._id) {
      toast.error("ID not found. Cannot update details.");
    } else {
      setPatchLoading(true);
      try {
        const response = await registryApiService.updateRegistryFull(aziendaInfo._id, updatedData);
        if (response) {
          toast.success(t("COMMONS.OPERATION_SUCCESS"));
          setMakeRequest(!makeRequest);
        } else {
          toast.error(t("COMMONS.OPERATION_ERROR"));
        }
      } catch (error: any) {
        toast.error(error);
      } finally {
        setPatchLoading(false);
      }
    }

    deleteSocialContact(values.website.contact, values.website.id);
    deleteSocialContact(values.facebook.contact, values.facebook.id);
    deleteSocialContact(values.instagram.contact, values.instagram.id);
    deleteSocialContact(values.twitter.contact, values.twitter.id);
    deleteSocialContact(values.linkedin.contact, values.linkedin.id);
    deleteSocialContact(values.company_profile.contact, values.company_profile.id);
    deleteSocialContact(values.portfolio.contact, values.portfolio.id);
  };

  return (
    <>
      <ToastContainer position="top-center" />
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <Formik<AziendaInfo> initialValues={aziendaInfo} onSubmit={onSubmit} enableReinitialize validationSchema={schema}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
            const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<AziendaInfo>]);
            const isSubmitDisabled = !formIsTouched || !isValid;
            return (
              <Form noValidate onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "center", borderRight: "2px solid light grey" }}>
                <Grid container spacing={2}>
                  {!isBusinessNameDeleted && (
                    <Grid item xs={12} sm={12}>
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Avatar sx={{ bgcolor: "#E5E4E2", width: 45, height: 45 }} variant="circular">
                          {values?.image ? <img src={values?.image} alt="Anteprima immagine" style={{ width: "50px" }} /> : null}
                        </Avatar>
                        <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                          <Button component="label" variant="contained" sx={{ textTransform: "Capitalize" }}>
                            {values?.image ? t("FORM.EDIT_PICTURE") : t("FORM.UPLOAD_PICTURE")}
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                handleFileUpload(event, setFieldValue, setFieldTouched);
                                setUploadedFileName(event.target.files![0].name);
                              }}
                              accept=".png, .jpg, .jpeg"
                            />
                          </Button>

                          {/* {values.image && (
                            <Button variant="outlined" color="secondary" onClick={handleImageDelete}>
                              Elimina Immagine
                            </Button>
                          )} */}
                        </div>
                        <div style={{ marginLeft: "10px", marginTop: "5px" }}>{uploadedFileName}</div>
                      </div>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={6} sx={{ display: "flex", marginBottom: "20px" }}>
                    <Avatar sx={{ bgcolor: "#E5E4E2", width: 45, height: 45 }} variant="circular">
                      <img src={isPreviewConfirmed ? values.image : imagePreview || values.image} alt="" className={styles.imagePreview} />
                    </Avatar>
                    <Button onClick={handleOpen}>{values.image || imagePreview ? t("FORM.EDIT_PICTURE") : t("FORM.UPLOAD_PICTURE")}</Button>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <div>{t("COMMONS.INFORMATIONS")}</div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="business_name"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.NAME")}
                      fullWidth
                      value={values.business_name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.business_name && !!errors.business_name}
                      helperText={touched.business_name && errors.business_name ? t(errors.business_name) : " "}
                      disabled
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      name="address.fullAddress"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.ADDRESS")}
                      fullWidth
                      value={values.address.fullAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address?.fullAddress && !!errors.address?.fullAddress}
                      helperText={touched.address?.fullAddress && errors.address?.fullAddress ? t(errors.address.fullAddress) : ""}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="address.number"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.NUMBER")}
                      fullWidth
                      value={values.address?.number}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.address?.number && !!errors.address?.number}
                      helperText={touched.address?.number && errors.address?.number ? t(errors.address.number) : ""}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="address.postal_code"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.POSTAL_CODE")}
                      fullWidth
                      value={values.address?.postal_code}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.address?.postal_code && !!errors.address?.postal_code}
                      helperText={touched.address?.postal_code && t(errors.address?.postal_code ?? "")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="address.city"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.CITY")}
                      fullWidth
                      value={values.address?.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.address?.city && !!errors.address?.city}
                      helperText={touched.address?.city && t(errors.address?.city ?? "")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="address.province"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.PROVINCE")}
                      fullWidth
                      value={values.address?.province}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.address?.province && !!errors.address?.province}
                      helperText={touched.address?.province && t(errors.address?.province ?? "")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="address.state"
                      variant="outlined"
                      color="primary"
                      label={t("FORM.COUNTRY")}
                      fullWidth
                      value={values.address?.state}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.address?.state && !!errors.address?.state}
                      helperText={touched.address?.state && t(errors.address?.state ?? "")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div>{t("FORM.CONTACTS")}</div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="email.contact"
                      variant="outlined"
                      label={t("FORM.EMAIL")}
                      // className={styles.textField}
                      fullWidth
                      value={values.email.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.email?.contact && !!errors.email?.contact}
                      // helperText={touched.email && t(errors.email?.contact ?? "")}
                      helperText={touched.email?.contact && errors.email?.contact ? t(errors.email.contact) : t("FORM.HELPER_TEXT_EMAIL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MuiPhone
                      label={t("FORM.PHONE")}
                      value={values.phone.contact}
                      onChange={(phone) => setFieldValue("phone.contact", phone)}
                      onBlur={() => setFieldTouched("phone.contact")}
                      error={touched.phone?.contact && !!errors.phone?.contact}
                      helperText={touched.phone?.contact && errors.phone?.contact ? t(errors.phone.contact) : t("FORM.HELPER_TEXT_PHONE")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="website.contact"
                      variant="outlined"
                      label={t("FORM.WEBSITE")}
                      // className={styles.textField}
                      fullWidth
                      value={values.website.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.website?.contact && !!errors.website?.contact}
                      // helperText={touched.website?.contact && t(errors.website?.contact ?? "")}
                      helperText={touched.website?.contact && errors.website?.contact ? t(errors.website.contact) : t("FORM.HELPER_TEXT_URL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div>Social</div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="facebook.contact"
                      variant="outlined"
                      label="Facebook"
                      // className={styles.textField}
                      fullWidth
                      value={values.facebook.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.facebook?.contact && !!errors.facebook?.contact}
                      // helperText={touched.facebook?.contact && t(errors.facebook?.contact ?? "")}
                      helperText={touched.facebook?.contact && errors.facebook?.contact ? t(errors.facebook.contact) : t("FORM.HELPER_TEXT_URL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.facebook.contact && (
                              <IconButton
                                aria-label="toggle cancel link"
                                onClick={() => {
                                  setFieldValue("facebook.contact", "");
                                  setFieldTouched("facebook.contact", true);
                                }}
                                edge="end"
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="instagram.contact"
                      variant="outlined"
                      label="Instagram"
                      // className={styles.textField}
                      fullWidth
                      value={values.instagram.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.instagram?.contact && !!errors.instagram}
                      // helperText={touched.instagram?.contact && t(errors.instagram?.contact ?? "")}
                      helperText={touched.instagram?.contact && errors.instagram?.contact ? t(errors.instagram.contact) : t("FORM.HELPER_TEXT_URL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.instagram.contact && (
                              <IconButton
                                aria-label="toggle cancel link"
                                onClick={() => {
                                  setFieldValue("instagram.contact", "");
                                  setFieldTouched("instagram.contact", true);
                                }}
                                edge="end"
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="twitter.contact"
                      variant="outlined"
                      label={t("FORM.TWITTER")}
                      // className={styles.textField}
                      fullWidth
                      value={values.twitter.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.twitter?.contact && !!errors.twitter?.contact}
                      // helperText={touched.twitter?.contact && t(errors.twitter?.contact ?? "")}
                      helperText={touched.twitter?.contact && errors.twitter?.contact ? t(errors.twitter.contact) : t("FORM.HELPER_TEXT_URL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.twitter.contact && (
                              <IconButton
                                aria-label="toggle cancel link"
                                onClick={() => {
                                  setFieldValue("twitter.contact", "");
                                  setFieldTouched("twitter.contact", true);
                                }}
                                edge="end"
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="linkedin.contact"
                      variant="outlined"
                      label="Linkedin"
                      // className={styles.textField}
                      fullWidth
                      value={values.linkedin.contact}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                      onBlur={handleBlur}
                      error={touched.linkedin?.contact && !!errors.linkedin?.contact}
                      helperText={touched.linkedin?.contact && errors.linkedin?.contact ? t(errors.linkedin.contact) : t("FORM.HELPER_TEXT_URL")}
                      inputProps={{ readOnly: isBusinessNameDeleted }}
                      disabled={isBusinessNameDeleted}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {values.linkedin.contact && (
                              <IconButton
                                aria-label="toggle cancel link"
                                onClick={() => {
                                  setFieldValue("linkedin.contact", "");
                                  setFieldTouched("linkedin.contact", true);
                                }}
                                edge="end"
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div>Link Esterni</div>
                  </Grid>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={3}>
                        <TextField
                          name={`customLinks[${index}].label`}
                          // label={t(`${"FORM.LABEL_"}${index + 1}`)}
                          label="Nome link"
                          fullWidth
                          value={values.customLink[index]?.label || ""}
                          onChange={(e) => setFieldValue(`customLink[${index}].label`, e.target.value)}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          name={`customLinks[${index}].value`}
                          // label={t(`${"FORM.LINK_"}${index + 1}`)}
                          label="Link"
                          fullWidth
                          value={values.customLink[index]?.value || ""}
                          onChange={(e) => setFieldValue(`customLink[${index}].value`, e.target.value)}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {/* NOTE - BOTTONI ELIMINA ACCOUNT E RICREA AZIENDA */}
                      {!isBusinessNameDeleted && role === EAuthRole.SUPERADMIN ? (
                        <Button
                          color="error"
                          variant="outlined"
                          type="button"
                          sx={{ textTransform: "Capitalize", marginRight: "auto" }}
                          onClick={handleOpenConfirm}
                        >
                          {t("FORM.DELETE_COMPANY")}
                        </Button>
                      ) : (
                        <div style={{ flex: 1 }}></div> // Spaziatore invisibile
                      )}
                      {/* {role === EAuthRole.ADMIN ? ( */}
                      <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        sx={{ textTransform: "Capitalize", marginRight: "auto" }}
                        onClick={handleExportData}
                      >
                        Esporta Dati
                      </Button>
                      {/* ) : null} */}
                      {role === EAuthRole.SUPERADMIN ? (
                        <Button
                          onClick={() => handleSendCards(tenant!)}
                          variant="outlined"
                          startIcon={<DownloadIcon sx={{ color: "black" }} />}
                          sx={{ textTransform: "Capitalize" }}
                        >
                          {"Invia Dati Fornitore"}
                        </Button>
                      ) : null}
                    </div>
                    <Button
                      disabled={isSubmitDisabled || patchLoading}
                      style={{ backgroundColor: isSubmitDisabled || patchLoading ? "var(--gray-400)" : "var(--black)", color: "white" }}
                      variant="contained"
                      type="submit"
                      sx={{ textTransform: "Capitalize" }}
                    >
                      {!patchLoading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                    </Button>
                  </Grid>
                </Grid>

                {/* DIALOG  */}
                <ConfirmDialog
                  open={openConfirmModal}
                  setOpen={setOpenConfirmModal}
                  onOpen={handleOpenConfirm}
                  onClose={handleCloseConfirm}
                  onConfirm={handleOpenPasswordDialog}
                  message={t("DIALOG_DELETE.COMPANY")}
                />
                <PasswordDialog
                  open={openDeleteModal}
                  setOpen={setOpenDeleteModal}
                  onOpen={handleOpen}
                  onClose={handleClosePasswordDialog}
                  onConfirm={() => handleDelete(idOwner)}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default ModificaProfilo;
