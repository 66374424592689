import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import "../../colors.css";
import TabMenu from "../../componets/tabMenu/TabMenu";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import styles from "./Aziende.module.css";
import TabellaUtentiGet from "../../componets/tabelle/TabellaUtentiGet";
import TabellaSediGet from "../../componets/tabelle/TabellaSediGet";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { toast_error, toast_success } from "../../utils/custom-toast";
import ProfiloAziendaFix from "./profilo-aziende/ProfiloAziendaFix";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IAlert } from "../../type/type";
import { Spinner } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { ContactTypeEnum } from "../../type/enum/contact-type.enum";
import { IBulkCreateEmployeePayload, IBulkDeleteRegistryPayload } from "../../type/commons";
import { RegistryApiService } from "../../services/RegistryApiService";
import PasswordDialog from "../../componets/dialog/PasswordDialog";
import TabellaAdminAzienda from "../adminAzienda/TabellaAdminAzienda/TabellaAdminAzienda";
import { CoreApiService } from "../../services/CoreApiService";
import { useParams } from "react-router-dom";
import { EAuthRole } from "../../type/enum/common.enum";
import Footer from "../../componets/Footer/Footer";

const Aziende: React.FC = () => {
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
  const [openDialogDeletePassword, setOpenDialogDeletePassword] = useState<boolean>(false);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [usersDeleted, setUsersDeleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [openDialogCopyPaste, setOpenDialogCopyPaste] = useState<boolean>(false);
  const [csvText, setCsvText] = useState<string>("");
  const [postLoadingCopyPaste, setPostLoadingCopyPaste] = useState<boolean>(false);
  const [postLoadingCSV, setPostLoadingCSV] = useState<boolean>(false);
  const [businessNameOwner, setBusinessNameOwner] = useState<string>("");
  const role = sessionStorage.getItem("role") || "";
  const { id, tenant } = useParams();

  const isBusinessNameDeleted = businessNameOwner?.includes("_deleted");

  const tabs = [
    { value: 0, label: t("COMMONS.COMPANY") },
    { value: 1, label: t("COMMONS.HEADQUARTERS") },
    { value: 2, label: t("COMMONS.EMPLOYEES") },
    { value: 3, label: t("COMPANY.COMPANY_ADMIN.ADMINISTRATORS") },
  ].filter((tab) => !(isBusinessNameDeleted && (tab.value === 1 || tab.value === 2 || tab.value === 3)));

  const [idOwner, setIdOwner] = useState<string>("");

  const location = useLocation();

  const navigate = useNavigate();
  const coreApiService = new CoreApiService();
  const registryApiService = new RegistryApiService();

  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: "Error",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const fetchIdRegistryOwner = async () => {
    try {
      const data = await registryApiService.infoMe();
      if (data && data.id_registry_owner) {
        let currentOwner = data.id_registry_owner;
        const processOwner: any = (owner: any) => {
          if (typeof owner === "string") return null;
          if (owner.id_registry_type.some((type: any) => type.code === "AZIENDA")) {
            return owner._id; // If found, return the _id of the current owner
          }
          if (owner.id_registry_type.some((type: any) => type.code === "SEDE") && owner.id_registry_owner) {
            return processOwner(owner.id_registry_owner); // Recursively process the nested owner
          }
          return null; // If neither condition is met, return null
        };
        const findBusinessNameAzienda: any = (registryOwner: any) => {
          if (registryOwner.id_registry_type) {
            const aziendaType = registryOwner.id_registry_type.find((type: any) => type.code === "AZIENDA");
            if (aziendaType) {
              return registryOwner.business_name;
            }
          }
          if (registryOwner.id_registry_owner) {
            return findBusinessNameAzienda(registryOwner.id_registry_owner);
          }
        };
        const businessNameOwner = findBusinessNameAzienda(data.id_registry_owner);
        if (businessNameOwner) {
          setBusinessNameOwner(businessNameOwner);
        } else {
          console.log("Business name di tipo 'AZIENDA' non trovato.");
        }

        const ownerId = processOwner(currentOwner);
        if (ownerId) {
          setIdOwner(ownerId); // Assuming setIdOwner is a function to set the owner ID
        } else {
          console.log("No valid owner found with type 'AZIENDA'.");
          toast_error(t("COMMONS.INVALID_CODE_TYPE"));
        }
      } else {
        console.log("error");
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error.message);
    }
  };

  useEffect(() => {
    if (role === EAuthRole.ADMIN) {
      fetchIdRegistryOwner();
    } else {
      setIdOwner(id!);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("amministratori")) {
      setSelectedTab(3);
    } else if (location.pathname.includes("dipendenti")) {
      setSelectedTab(2);
    } else if (location.pathname.includes("sedi")) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [location]);

  const triggerRefresh = () => {
    setRefreshCounter((prevCounter) => prevCounter + 1);
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.length >= 3) {
      setSearchTerm(searchTerm.toLocaleLowerCase().trim());
    } else {
      setSearchTerm("");
    }
  };

  const handleTabChange = (newValue: number) => {
    setSearchTerm("");
    switch (newValue) {
      case 0:
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/${idOwner}/${tenant}`) : navigate("/azienda");
        break;
      case 2:
        // navigate("/azienda/dipendenti");
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/dipendenti/${idOwner}/${tenant}`) : navigate("/azienda/dipendenti");
        break;
      case 1:
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/sedi/${idOwner}/${tenant}`) : navigate("/azienda/sedi");
        break;
      case 3:
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/amministratori/${idOwner}/${tenant}`) : navigate("/azienda/amministratori");
        break;
      default:
        break;
    }
  };

  const getInitialTab = (path: any) => {
    if (path.includes("amministratori")) {
      return 3;
    } else if (path.includes("dipendenti")) {
      return 2;
    } else if (path.includes("sedi")) {
      return 1;
    } else {
      return 0;
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await registryApiService.downloadTemplate();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.csv");
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Errore durante il download:", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDialogDelete(true);
  };

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleOpenDialogPassword = () => {
    setOpenDialogDeletePassword(true);
    handleCloseDelete();
  };

  const handleCloseDialogPassword = () => {
    setOpenDialogDeletePassword(false);
    setOpenDialogDelete(false);
  };

  const handleClickCloseAddDialog = () => {
    setOpenAddDialog(false);
    setAlert({ ...alert, show: false });
  };

  const handleClickOpenDialogCopyPaste = () => {
    setOpenDialogCopyPaste(true);
    setAlert({ ...alert, show: false });
  };

  const handleClickCloseDialogCopyPaste = () => {
    setOpenDialogCopyPaste(false);
    setAlert({ ...alert, show: false });
  };

  const parseCSV = (csvText: string) => {
    const lines = csvText.split("\n").filter((line) => line.trim() !== "");
    let hasError = false;
    const validEntries = [];
    let errorMessage = "";

    for (let i = 0; i < lines.length; i++) {
      const parts = lines[i].split(",").map((part) => part.trim());
      const nonEmptyParts = parts.filter((part) => part !== "");
      if (nonEmptyParts.length < 5 || nonEmptyParts.length > 6) {
        errorMessage = `Errore a riga ${i + 1}: Formato inserito incorretto`;
        setAlert({ ...alert, show: true, message: errorMessage });
        hasError = true;
        break;
      }
    }

    if (!hasError) {
      for (const line of lines) {
        const parts = line.split(",").map((part) => part.trim());
        const isBusinessNamePresent = parts.length === 6;

        validEntries.push({
          firstname: parts[0],
          surname: parts[1],
          fiscal_code: parts[2],
          contact: [
            {
              contact_type: ContactTypeEnum.EMAIL,
              contact: parts[3],
            },
            {
              contact_type: ContactTypeEnum.PHONE,
              contact: parts[4],
            },
          ],
          ...(isBusinessNamePresent && { business_name: parts[5] }),
          user: {
            email: parts[3],
          },
        });
      }
    }

    return hasError ? [] : validEntries;
  };

  const handleCSVSubmitCopyPaste = () => {
    const validEntries = parseCSV(csvText);
    handleSendValidEntries(validEntries);
  };

  const handleSendValidEntries = async (validEntries: any[]) => {
    if (!validEntries.length) return;

    setIsLoading(true);
    const payload: IBulkCreateEmployeePayload = {
      registries: validEntries,
    };

    setPostLoadingCopyPaste(true);

    try {
      const headers: any = {
        headers: {
          "x-tenant": tenant,
        },
      };

      const response = await registryApiService.bulkCreateEmployee(headers, payload);

      if (response) {
        const duplicatedUsers = response.duplicated_user;
        const duplicatedFiscalCodes = response.fiscal_code_duplicated;

        if (duplicatedUsers.length === 0 && duplicatedFiscalCodes.length === 0) {
          toast_success(t("COMMONS.OPERATION_SUCCESS"));
          setOpenDialogCopyPaste(false);
        } else {
          let alertMessage = `Attenzione: alcuni dati sono errati o duplicati. \n`;

          if (duplicatedUsers.length > 0) {
            const duplicatedEmails = duplicatedUsers.map((user: any) => user.user.email);
            alertMessage += "Email: " + duplicatedEmails.join(", ") + "\n";
          }
          if (duplicatedFiscalCodes.length > 0) {
            alertMessage += "Codici Fiscali: " + duplicatedFiscalCodes.join(", ") + "\n";
          }
          setAlert({ ...alert, show: true, message: alertMessage, dismissible: false });
        }
        triggerRefresh();
        setCsvText("");
      } else {
        setAlert({ ...alert, show: true, message: t("COMMONS.OPERATION_ERROR"), dismissible: false });
      }
    } catch (error: any) {
      setAlert({ ...alert, show: true, message: error, dismissible: false });
    } finally {
      setIsLoading(false);
      setPostLoadingCopyPaste(false);
    }
  };

  const handleDeleteSelectedUsers = async (selectedUsers: string[]) => {
    if (selectedUsers.length === 0) {
      return;
    } else {
      setIsLoading(true);
      const payload: IBulkDeleteRegistryPayload = {
        registries: selectedUsers,
      };
      try {
        const response = await coreApiService.bulkDelete(payload);
        if (response) {
          toast_success(t("COMMONS.OPERATION_SUCCESS"));
          setUsersDeleted(true);
          setSelectedUsers([]);
          triggerRefresh();
        } else {
          toast_error(t("COMMONS.OPERATION_ERROR"));
        }
      } catch (error: any) {
        console.log(error);
        toast_error(error);
      } finally {
        setIsLoading(false);
        setOpenDialogDelete(false);
      }
    }
  };

  const handleImportCSV = async () => {
    if (!file) return;

    const formData = new FormData();

    if (file.type === "text/csv" || file.name.endsWith(".csv")) {
      formData.append("file", file);
      setPostLoadingCSV(true);
      try {
        const headers: any = {
          headers: {
            "x-tenant": tenant,
          },
        };
        const response = await registryApiService.importCsv(headers, formData);
        if (response) {
          const duplicatedUsers = response.duplicated_user;
          const duplicatedFiscalCodes = response.fiscal_code_duplicated;

          if (duplicatedUsers.length === 0 && duplicatedFiscalCodes.length === 0) {
            toast_success(t("COMMONS.OPERATION_SUCCESS"));
            handleClickCloseAddDialog();
          } else {
            let alertMessage = "Attenzione: alcuni dati sono duplicati.\n";
            if (duplicatedUsers.length > 0) {
              const duplicatedEmails = duplicatedUsers.map((user: any) => user.user.email);
              alertMessage += "Email duplicate: " + duplicatedEmails.join(", ") + "\n";
            }
            if (duplicatedFiscalCodes.length > 0) {
              alertMessage += "Codici Fiscali duplicati: " + duplicatedFiscalCodes.join(", ") + "\n";
            }
            setAlert({ ...alert, show: true, message: alertMessage });
          }
          triggerRefresh();
        } else {
          const rowInvalid = response.row;
          if (rowInvalid !== undefined) {
            if (rowInvalid.length > 0) {
              const errorMessage = `Errori alle righe ${rowInvalid.toString()}: Formato inserito incorretto`;
              setAlert({ ...alert, show: true, message: errorMessage });
            }
          } else {
            setAlert({ ...alert, show: true, message: t("COMMONS.OPERATION_SUCCESS") });
          }
        }
      } catch (error: any) {
        console.log(error);
        setAlert({ ...alert, show: true, message: error });
      } finally {
        setPostLoadingCSV(false);
      }
    }
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab(location.pathname));
  return (
    <>
      <Typography variant="h1">{t("COMMONS.COMPANY")}</Typography>
      <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs} />
      {selectedTab === 0 ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 0 1rem 0",
          }}
        >
          {/* Dialog per eliminazione multipla */}
          <Dialog open={openDialogDelete} onClose={handleCloseDelete}>
            <DialogTitle>{"Sei sicuro di procedere?"}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDelete}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                L'eliminazione di un utente è un'azione che non può essere annullata. Vuoi proseguire?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleCloseDelete} sx={{ textTransform: "Capitalize" }}>
                {t("COMMONS.CANCEL")}
              </Button>
              <Button variant="contained" color="primary" onClick={handleOpenDialogPassword} autoFocus sx={{ textTransform: "Capitalize" }}>
                {t("COMMONS.CONFIRM")}
              </Button>
            </DialogActions>
          </Dialog>
          <PasswordDialog
            open={openDialogDeletePassword}
            setOpen={setOpenDialogDeletePassword}
            onOpen={handleOpenDialogPassword}
            onClose={handleCloseDialogPassword}
            onConfirm={() => handleDeleteSelectedUsers(selectedUsers)}
          />

          {/* Dialog per importazione CSV */}
          <Dialog fullWidth maxWidth={"md"} open={openAddDialog} onClose={handleClickCloseAddDialog}>
            <DialogTitle>{t("DIALOG_USERS.IMPORT_CSV.TITLE")}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClickCloseAddDialog}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {alert.show && (
              <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
                {t(alert.message)}
              </Alert>
            )}
            <DialogContent>
              <DialogContentText>
                <div>{t("DIALOG_USERS.IMPORT_CSV.UPLOAD_CSV")}</div>
                <div>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                    style={{ marginBottom: "1rem" }}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClickCloseAddDialog} sx={{ textTransform: "Capitalize" }}>
                {t("COMMONS.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                autoFocus
                onClick={handleImportCSV}
                disabled={!file || postLoadingCSV}
                sx={{ textTransform: "Capitalize" }}
              >
                {!postLoadingCSV ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog per importazione copia e incolla */}
          <Dialog fullWidth maxWidth={"lg"} open={openDialogCopyPaste} onClose={handleClickCloseDialogCopyPaste}>
            <DialogTitle>{t("DIALOG_USERS.IMPORT_COPY_PASTE.TITLE")}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClickCloseDialogCopyPaste}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {alert.show && (
              <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
                {t(alert.message)}
              </Alert>
            )}
            <DialogContent>
              <DialogContentText>{t("DIALOG_USERS.IMPORT_COPY_PASTE.DESCRIPTION_1")}</DialogContentText>
              <DialogContentText>{t("DIALOG_USERS.IMPORT_COPY_PASTE.DESCRIPTION_2")}</DialogContentText>
              <DialogContentText>{t("DIALOG_USERS.IMPORT_COPY_PASTE.DESCRIPTION_3")}</DialogContentText>
              <TextField
                multiline
                rows={5}
                fullWidth
                variant="outlined"
                value={csvText}
                onChange={(e) => setCsvText(e.target.value)}
                sx={{
                  "& .MuiInputBase-inputMultiline": {
                    overflow: "auto",
                    maxHeight: "150px",
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClickCloseDialogCopyPaste} sx={{ textTransform: "Capitalize" }}>
                {t("COMMONS.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                autoFocus
                onClick={handleCSVSubmitCopyPaste}
                disabled={!csvText || postLoadingCopyPaste}
                sx={{ textTransform: "Capitalize" }}
              >
                {!postLoadingCopyPaste ? t("COMMONS.SAVE") : <Spinner animation="border" />}
              </Button>
            </DialogActions>
          </Dialog>

          <div>
            <SearchBar onSearch={handleSearch} placeholder={t("COMMONS.SEARCH")} />
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.7rem",
            }}
          >
            {selectedTab === 1 && (
              <Button
                onClick={() =>
                  role === EAuthRole.SUPERADMIN ? navigate(`/azienda/sedi/${idOwner}/${tenant}/nuova-sede`) : navigate("/azienda/sedi/nuova-sede")
                }
                variant="contained"
                startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
                sx={{ textTransform: "Capitalize" }}
              >
                {t("COMPANY.ADD_HEADQUARTER")}
              </Button>
            )}

            {selectedTab === 2 && (
              <>
                {role === EAuthRole.ADMIN && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon sx={{ color: "black" }} />}
                    onClick={() => handleClickOpenDelete()}
                    disabled={selectedUsers.length === 0 || isLoading}
                    sx={{ textTransform: "Capitalize" }}
                  >
                    {t("COMPANY.DELETE_USERS")}
                  </Button>
                )}
                {role === EAuthRole.SUPERADMIN && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<NoteAddOutlinedIcon sx={{ color: "black" }} />}
                      onClick={() => handleClickOpenAddDialog()}
                      sx={{ textTransform: "Capitalize" }}
                    >
                      {t("COMPANY.IMPORT_CSV")}
                    </Button>

                    <Button variant="outlined" color="primary" onClick={handleDownloadTemplate} sx={{ textTransform: "Capitalize" }}>
                      {t("COMPANY.DOWNLOAD_TEMPLATE")}
                    </Button>

                    {/* BOTTONE COPY PASTE */}
                    <Button
                      variant="contained"
                      startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
                      color="primary"
                      onClick={handleClickOpenDialogCopyPaste}
                      sx={{ textTransform: "Capitalize" }}
                    >
                      {t("COMPANY.IMPORT_COPY_PASTE")}
                    </Button>
                  </>
                )}

                <Button
                  onClick={() =>
                    role === EAuthRole.SUPERADMIN
                      ? navigate(`/azienda/dipendenti/${idOwner}/${tenant}/nuovo-dipendente`)
                      : navigate("/azienda/dipendenti/nuovo-dipendente")
                  }
                  variant="contained"
                  startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
                  sx={{ textTransform: "Capitalize" }}
                >
                  {t("COMPANY.ADD_EMPLOYEE")}
                </Button>
              </>
            )}

            {selectedTab === 3 && (
              <Button
                onClick={() =>
                  role === EAuthRole.SUPERADMIN
                    ? navigate(`/azienda/${idOwner}/${tenant}/nuovo-amministratore`)
                    : navigate("/azienda/nuovo-amministratore")
                }
                variant="contained"
                startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
                sx={{ textTransform: "Capitalize" }}
              >
                {t("COMPANY.COMPANY_ADMIN.NEW_ADMIN")}
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Box className={styles.box}>
        {/* AZIENDA */}
        {selectedTab === 0 && <ProfiloAziendaFix setIdOwner={setIdOwner} idOwner={idOwner} isBusinessNameDeleted={isBusinessNameDeleted} />}
        {/* SEDI */}
        {selectedTab === 1 && <TabellaSediGet searchTerm={searchTerm} idOwner={idOwner} />}
        {/* DIPENDENTI */}
        {selectedTab === 2 && (
          <TabellaUtentiGet
            searchTerm={searchTerm}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            usersDeleted={usersDeleted}
            // refreshData={refreshData}
            refreshCounter={refreshCounter}
            idCompany={idOwner}
          />
        )}
        {/* MEMBRI */}
        {selectedTab === 3 && <TabellaAdminAzienda searchTerm={searchTerm} idOwner={idOwner} />}
      </Box>
    </>
  );
};
export default Aziende;
