import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { Form, Formik, FormikTouched } from "formik";
import { Alert, Avatar, Button, CircularProgress, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { IAlert } from "../../type/type";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import ModificaProfiloValidationSchema from "./formModules/ModificaProfiloValidationSchema";
import { Link, useNavigate } from "react-router-dom";
import { MuiPhone } from "../../componets/muiPhone/muiphone";
import { ContactTypeEnum } from "../../type/enum/contact-type.enum";
import { RegistryApiService } from "../../services/RegistryApiService";
import { IContact, IRegistry } from "../../type/commons";
import { EAuthRole, EContactType, ERegistryType } from "../../type/enum/common.enum";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ConfirmDialog from "../../componets/dialog/ConfirmDialog";
import PasswordDialog from "../../componets/dialog/PasswordDialog";
import { CoreApiService } from "../../services/CoreApiService";
import { toast_error, toast_success } from "../../utils/custom-toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { calculateAge } from "../../utils/utilsDate";
import { Util } from "../../utils/util";

interface ContactDetails {
  id: string;
  contact: string;
}

interface DipendenteInfo {
  _id?: string;
  id_registry_owner: { _id: string };
  firstname: string;
  surname: string;
  fiscal_code: string;
  profession: string;
  birth_date?: Date | null;
  birth_place: string;
  sex_code: string;
  displayName: string;
  image: string;
  key_code: string;
  email: ContactDetails;
  phone: ContactDetails;
  facebook: ContactDetails;
  instagram: ContactDetails;
  linkedin: ContactDetails;
  twitter: ContactDetails;
}

function ModificaProfilo() {
  const { t } = useTranslation();
  const initialValues: DipendenteInfo = {
    _id: "",
    id_registry_owner: {
      _id: "",
    },
    firstname: "",
    surname: "",
    fiscal_code: "",
    profession: "",
    birth_date: null,
    birth_place: "",
    sex_code: "",
    displayName: "",
    image: "",
    key_code: "",
    email: { id: "", contact: "" },
    phone: { id: "", contact: "" },
    facebook: { id: "", contact: "" },
    instagram: { id: "", contact: "" },
    linkedin: { id: "", contact: "" },
    twitter: { id: "", contact: "" },
  };

  const [personInfo, setPersonInfo] = useState<DipendenteInfo>(initialValues);
  const [loading, setLoading] = useState<boolean>(true);
  const [patchLoading, setPatchLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [age, setAge] = useState<number>(0);
  const [sedi, setSedi] = useState<IRegistry[]>([]);
  // console.log("sedi", sedi);
  const [uniqueDisplayName, setUniqueDisplayName] = useState<boolean>(true);
  const role = sessionStorage.getItem("role");

  // const businessName = sessionStorage.getItem("business_name");
  const [businessNameOwner, setBusinessNameOwner] = useState<string>("");

  const navigate = useNavigate();
  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: "ERRORS.BILLING_INFO.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });
  const registryApiService = new RegistryApiService();
  const coreApiService = new CoreApiService();

  const schema = ModificaProfiloValidationSchema;

  const handleOpenConfirm = () => {
    setOpenConfirmModal(true);
    // setSelectedId(id);
  };
  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenConfirmModal(false);
    setOpenDeleteModal(true);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteModal(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await coreApiService.closeAccount(id);
      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setOpenConfirmModal(false);
    }
  };

  const fetchDataSedi = async () => {
    const resSedi: any = await registryApiService.fetchAllRegistry({ registry_type: ERegistryType.SEDE });
    if (resSedi) {
      setSedi(resSedi);
    }
  };

  useEffect(() => {
    fetchDataSedi();
  }, []);

  const fetchInfoMe = async () => {
    setLoading(true);
    try {
      const response = await registryApiService.infoMe();
      if (response && response.id_registry_owner) {
        console.log("response", response);

        const findBusinessNameAzienda: any = (registryOwner: any) => {
          if (registryOwner.id_registry_type) {
            const aziendaType = registryOwner.id_registry_type.find((type: any) => type.code === "AZIENDA");
            if (aziendaType) {
              return registryOwner.business_name;
            }
          }
          if (registryOwner.id_registry_owner) {
            return findBusinessNameAzienda(registryOwner.id_registry_owner);
          }
        };
        const businessNameOwner = findBusinessNameAzienda(response.id_registry_owner);

        if (businessNameOwner) {
          setBusinessNameOwner(businessNameOwner);
        } else {
          console.log("Business name di tipo 'AZIENDA' non trovato.");
        }

        if (response.id_contact && response.id_contact.length > 0) {
          const findContact = (type: EContactType) => {
            return response.id_contact.find((contact: IContact) => contact.id_contact_type && contact.id_contact_type.code === type);
          };

          const emailContact = findContact(EContactType.EMAIL);
          const phoneContact = findContact(EContactType.PHONE);
          const facebookContact = findContact(EContactType.FACEBOOK);
          const instagramContact = findContact(EContactType.INSTAGRAM);
          const linkedinContact = findContact(EContactType.LINKEDIN);
          const twitterContact = findContact(EContactType.TWITTER);

          // setBusinessNameOwner(response.id_registry_owner.business_name);

          const updatedValues: any = {
            _id: response._id,
            id_registry_owner: response.id_registry_owner._id ? response.id_registry_owner._id : "",
            firstname: response.firstname ? response.firstname : "",
            surname: response.surname ? response.surname : "",
            fiscal_code: response.fiscal_code ? response.fiscal_code : "",
            profession: response.profession ? response.profession : "",
            image: response.image ? response.image : "",
            key_code: response.key_code ? response.key_code : "",
            birth_date: response.birth_date ? new Date(response.birth_date) : null,
            birth_place: response.birth_place ? response.birth_place : "",
            sex_code: response.sex_code ? response.sex_code : "",
            displayName: response.displayName ?? "",
            email: {
              id: emailContact ? emailContact._id : "",
              contact: emailContact ? emailContact.contact : "",
            },
            phone: {
              id: phoneContact ? phoneContact._id : "",
              contact: phoneContact ? phoneContact.contact : "",
            },
            facebook: {
              id: facebookContact ? facebookContact._id : "",
              contact: facebookContact ? facebookContact.contact : "",
            },
            instagram: {
              id: instagramContact ? instagramContact._id : "",
              contact: instagramContact ? instagramContact.contact : "",
            },
            linkedin: {
              id: linkedinContact ? linkedinContact._id : "",
              contact: linkedinContact ? linkedinContact.contact : "",
            },
            twitter: {
              id: twitterContact ? twitterContact._id : "",
              contact: twitterContact ? twitterContact.contact : "",
            },
          };

          setPersonInfo(updatedValues);
          console.log("PersonInfo", updatedValues);
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfoMe();
  }, [refresh]);

  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  const handleFileUpload = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setFieldTouched: (field: string, isTouched: boolean) => void,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setFieldValue("image", reader.result.toString());
            setFieldTouched("image", true);
            setUploadedFileName(file.name);
          }
        };
        reader.readAsDataURL(file);
      } else {
        console.error("File non valido");
        setUploadedFileName("File non valido");
      }
    }
  };

  const deleteSocialContact = async (value: string, contactId: string) => {
    if (value === "" && contactId) {
      registryApiService
        .deleteContactById(contactId)
        .then((response) => {
          //console.log("Contatto eliminato:", response.data);
        })
        .catch((error) => {
          console.error("Errore durante l'eliminazione del contatto:");
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
          }
        });
    }
  };

  const handleImageDelete = async () => {
    try {
      const response = await registryApiService.deleteRegistryImage(personInfo._id ?? "");
      if (response.data.code === 0) {
        toast.success(response.data.message);
        setRefresh(!refresh);
        setUploadedFileName("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const verifyUniqueDisplayName = async (displayName: string) => {
    if (displayName && displayName !== "") {
      const res: any = await registryApiService.verifyUniqueDisplayName(displayName);
      console.log(res);
      if (res.data) {
        setUniqueDisplayName(res.data.data === 0);
      } else {
        setUniqueDisplayName(res === 0);
      }
    }
  };

  const onSubmit = async (values: any) => {
    const updatedData = {
      id_registry_owner: values.id_registry_owner,
      firstname: values.firstname,
      surname: values.surname,
      fiscal_code: values.fiscal_code,
      birth_date: values.birth_date,
      birth_place: values.birth_place,
      sex_code: values.sex_code,
      profession: values.profession,
      displayName: values.displayName,
      image: values.image,
      contact: [
        ...(values.email.contact
          ? [
              {
                contact_type: ContactTypeEnum.EMAIL,
                contact: values.email.contact,
                ...(values.email.id && { id: values.email.id }),
              },
            ]
          : []),
        ...(values.phone.contact
          ? [
              {
                contact_type: ContactTypeEnum.PHONE,
                contact: values.phone.contact,
                ...(values.phone.id && { id: values.phone.id }),
              },
            ]
          : []),
        ...(values.facebook.contact
          ? [
              {
                contact_type: ContactTypeEnum.FACEBOOK,
                contact: Util.normalizeUrl(values.facebook.contact),
                ...(values.facebook.id && { id: values.facebook.id }),
              },
            ]
          : []),

        ...(values.instagram.contact
          ? [
              {
                contact_type: ContactTypeEnum.INSTAGRAM,
                contact: Util.normalizeUrl(values.instagram.contact),
                ...(values.instagram.id && { id: values.instagram.id }),
              },
            ]
          : []),
        ...(values.linkedin.contact
          ? [
              {
                contact_type: ContactTypeEnum.LINKEDIN,
                contact: Util.normalizeUrl(values.linkedin.contact),
                ...(values.linkedin.id && { id: values.linkedin.id }),
              },
            ]
          : []),
        ...(values.twitter.contact
          ? [
              {
                contact_type: ContactTypeEnum.TWITTER,
                contact: Util.normalizeUrl(values.twitter.contact),
                ...(values.twitter.id && { id: values.twitter.id }),
              },
            ]
          : []),
      ],
    };

    if (!personInfo._id) {
      toast.error("ID not found. Cannot update details.");
    } else {
      setPatchLoading(true);
      try {
        const response = await registryApiService.updateRegistryFull(personInfo._id, updatedData);
        if (response) {
          toast_success(t("COMMONS.OPERATION_SUCCESS"));
          setTimeout(() => {
            setRefresh((prevRefresh) => !prevRefresh);
          }, 1500);
        } else {
          setAlert({ ...alert, show: true, severity: "error", message: t("COMMONS.OPERATION_ERROR") });
        }
      } catch (error: any) {
        setAlert({ ...alert, show: true, severity: "error", message: error });
      } finally {
        setPatchLoading(false);
      }
    }

    await deleteSocialContact(values.facebook.contact, values.facebook.id);
    await deleteSocialContact(values.instagram.contact, values.instagram.id);
    await deleteSocialContact(values.twitter.contact, values.twitter.id);
    await deleteSocialContact(values.linkedin.contact, values.linkedin.id);
  };

  useEffect(() => {
    if (personInfo.birth_date) {
      const birthDate = new Date(personInfo.birth_date);
      calculateAge(birthDate).then(setAge);
    }
  }, [personInfo.birth_date]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          <ToastContainer position="top-center" autoClose={3000} />
          <Formik<DipendenteInfo> initialValues={personInfo} validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
              const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<DipendenteInfo>]);
              const isSubmitDisabled = !formIsTouched || !isValid;
              console.log("errors", errors);
              console.log("touched", touched);
              console.log("values", values.birth_date);
              return (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{ display: "flex", justifyContent: "center", borderRight: "2px solid light grey", marginBottom: "10px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h1">{t("FORM.PROFILE")}</Typography>
                      {personInfo.displayName && (
                        <Link to={`/ecard/${businessNameOwner}/${personInfo.displayName}`} target="_blank" style={{ marginTop: "10px" }}>
                          {t("FORM.ECARD")}
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Avatar sx={{ bgcolor: "#E5E4E2", width: 45, height: 45 }} variant="circular">
                          {values?.image ? <img src={values?.image} alt="Anteprima immagine" style={{ width: "50px" }} /> : null}
                        </Avatar>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <Button component="label" variant="contained" sx={{ textTransform: "Capitalize" }}>
                            {values?.image ? t("FORM.EDIT_PICTURE") : t("FORM.UPLOAD_PICTURE")}
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) => {
                                handleFileUpload(event, setFieldValue, setFieldTouched);
                                setUploadedFileName(event.target.files![0].name);
                              }}
                              accept=".png, .jpg, .jpeg"
                            />
                          </Button>
                          {/* {values.image && (
                            <Button variant="outlined" color="secondary" onClick={handleImageDelete}>
                              Elimina Immagine
                            </Button>
                          )} */}
                        </div>
                        <div style={{ marginLeft: "10px", marginTop: "5px" }}>{uploadedFileName}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {alert.show && (
                        <Alert className="mb-4" severity={alert.severity} onClose={alert.onClose}>
                          {t(alert.message)}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div>{t("FORM.PERSONAL_INFORMATION")}</div>
                    </Grid>{" "}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="firstname"
                        variant="outlined"
                        color="primary"
                        // className={styles.textField}
                        label={t("FORM.NAME")}
                        fullWidth
                        value={values.firstname}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={touched.firstname && !!errors.firstname}
                        helperText={touched.firstname && t(errors.firstname ?? "")}
                        disabled={role === EAuthRole.BASIC}
                        inputProps={{ readOnly: role === EAuthRole.BASIC }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="surname"
                        variant="outlined"
                        label={t("FORM.SURNAME")}
                        // className={styles.textField}
                        fullWidth
                        value={values.surname}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={touched.surname && !!errors.surname}
                        helperText={touched.surname && t(errors.surname ?? "")}
                        disabled={role === EAuthRole.BASIC}
                        inputProps={{ readOnly: role === EAuthRole.BASIC }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fiscal_code"
                        value={values.fiscal_code}
                        inputProps={{ maxLength: 16 }}
                        onChange={handleChange}
                        label={t("FORM.FISCAL_CODE")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.fiscal_code && !!errors.fiscal_code}
                        helperText={touched.fiscal_code && t(errors.fiscal_code ?? "")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          name="birth_date"
                          label={t("FORM.BIRTH_DATE")}
                          value={values.birth_date}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: touched.birth_date && !!errors.birth_date,
                              helperText: touched.birth_date && t(errors.birth_date ?? ""),
                              onBlur: handleBlur,
                              required: true,
                            },
                          }}
                          onChange={async (date) => {
                            const age = await calculateAge(date!);
                            setFieldValue("birth_date", date);
                            setFieldTouched("birth_date", true, false);
                            setAge(age);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="birth_place"
                        value={values.birth_place}
                        inputProps={{ maxLength: 250 }}
                        onChange={handleChange}
                        label={t("FORM.BIRTH_PLACE")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.birth_place && !!errors.birth_place}
                        helperText={touched.birth_place && t(errors.birth_place ?? "")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        name="age"
                        value={age}
                        onBlur={handleBlur}
                        fullWidth
                        label={t("FORM.AGE")}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        select
                        value={values.sex_code}
                        required
                        name="sex_code"
                        onChange={handleChange}
                        label={t("FORM.SEX_CODE")}
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.sex_code && !!errors.sex_code}
                        helperText={touched.sex_code && t(errors.sex_code ?? "")}
                      >
                        <MenuItem value="M">M</MenuItem>
                        <MenuItem value="F">F</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="profession"
                        value={values.profession}
                        onChange={handleChange}
                        label={t("USER_CARD.PROFESSION")}
                        fullWidth
                        onBlur={handleBlur}
                        helperText={touched.profession && errors.profession ? t(errors.profession) : t("ERRORS.FORM.MUST_BE_MAX_35_CHARACTERS")}
                        disabled={role === EAuthRole.BASIC}
                        inputProps={{ readOnly: role === EAuthRole.BASIC, maxLength: 35 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div>{t("FORM.CONTACTS")}</div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPhone
                        label={t("FORM.PHONE")}
                        value={values.phone.contact}
                        onChange={(phone) => setFieldValue("phone.contact", phone)}
                        onBlur={() => setFieldTouched("phone.contact")}
                        error={touched.phone?.contact && !!errors.phone?.contact}
                        helperText={touched.phone?.contact && t(errors.phone?.contact ?? "")}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="email.contact"
                        variant="outlined"
                        label={t("FORM.EMAIL")}
                        // className={styles.textField}
                        fullWidth
                        value={values.email.contact}
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        onBlur={handleBlur}
                        error={touched.email?.contact && !!errors.email?.contact}
                        helperText={touched.email && t(errors.email?.contact ?? "")}
                        disabled
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={6}>
                      <TextField
                        name="displayName"
                        variant="outlined"
                        color="primary"
                        label={t("FORM.DISPLAY_NAME")}
                        fullWidth
                        value={values.displayName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          verifyUniqueDisplayName(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 100 }}
                        required
                        disabled
                        error={(touched.displayName && !!errors.displayName) || !uniqueDisplayName}
                        helperText={
                          touched.displayName && errors.displayName && uniqueDisplayName
                            ? t(errors.displayName)
                            : !uniqueDisplayName
                              ? t("ERRORS.FORM.INVALID_DISPLAY_NAME")
                              : "Nome utente univoco per l'eCard"
                        }
                      />
                    </Grid>
                    {role !== EAuthRole.BASIC && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="id_registry_owner"
                          select
                          label={t("FORM.WORK_PLACE")}
                          fullWidth
                          value={values.id_registry_owner ?? ""}
                          onChange={(e) => setFieldValue("id_registry_owner", e.target.value)}
                          onBlur={handleBlur}
                          error={touched.id_registry_owner && !!errors.id_registry_owner}
                          // helperText={touched.id_registry_owner && t(errors.id_registry_owner ?? "")}
                        >
                          {sedi.map((sede) => (
                            <MenuItem key={sede._id} value={sede._id}>
                              {sede.business_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <div>{t("FORM.SOCIAL_NETWORKS")}</div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="facebook.contact"
                        variant="outlined"
                        label="Facebook"
                        // className={styles.textField}
                        fullWidth
                        value={values.facebook.contact}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        onBlur={handleBlur}
                        // error={touched.facebook?.contact && !!errors.facebook?.contact}
                        helperText={touched.facebook?.contact && t(errors.facebook?.contact ?? "")}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.facebook.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("facebook.contact", "");
                                    setFieldTouched("facebook.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="instagram.contact"
                        variant="outlined"
                        label="Instagram"
                        // className={styles.textField}
                        fullWidth
                        value={values.instagram.contact}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        onBlur={handleBlur}
                        // error={touched.instagram?.contact && !!errors.instagram}
                        helperText={touched.instagram?.contact && t(errors.instagram?.contact ?? "")}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.instagram.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("instagram.contact", "");
                                    setFieldTouched("instagram.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="twitter.contact"
                        variant="outlined"
                        label={t("FORM.TWITTER")}
                        // className={styles.textField}
                        fullWidth
                        value={values.twitter.contact}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        onBlur={handleBlur}
                        // error={touched.twitter?.contact && !!errors.twitter?.contact}
                        helperText={touched.twitter?.contact && t(errors.twitter?.contact ?? "")}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.twitter.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("twitter.contact", "");
                                    setFieldTouched("twitter.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="linkedin.contact"
                        variant="outlined"
                        label="Linkedin"
                        // className={styles.textField}
                        fullWidth
                        value={values.linkedin.contact}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                        onBlur={handleBlur}
                        // error={touched.linkedin?.contact && !!errors.linkedin?.contact}
                        helperText={touched.linkedin?.contact && t(errors.linkedin?.contact ?? "")}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.linkedin.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("linkedin.contact", "");
                                    setFieldTouched("linkedin.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      {role === EAuthRole.ADMIN && (
                        <div style={{ display: "flex", gap: "10px" }}>
                          {/* NOTE - BOTTONI ELIMINA ACCOUNT E RICREA AZIENDA */}
                          {/* <Button
                            color="error"
                            variant="outlined"
                            onClick={() => {
                              handleOpenConfirm();
                            }}
                            sx={{ textTransform: "Capitalize" }}
                          >
                            Chiudi definitivamente questo account
                          </Button> */}
                          {/* <Button
                            color="error"
                            variant="outlined"
                            disabled={!businessNameOwner?.includes("_deleted")}
                            onClick={() => {
                              navigate("/ricrea-azienda/");
                            }}
                            sx={{ textTransform: "Capitalize" }}
                          >
                            {t("FORM.RECREATE_COMPANY")}
                          </Button> */}
                        </div>
                      )}
                      <Button
                        disabled={isSubmitDisabled || patchLoading || !uniqueDisplayName}
                        style={{ backgroundColor: isSubmitDisabled || patchLoading ? "var(--gray-400)" : "var(--black)", color: "white" }}
                        variant="contained"
                        type="submit"
                        sx={{ textTransform: "Capitalize" }}
                      >
                        {!patchLoading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <ConfirmDialog
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            onOpen={handleOpenConfirm}
            onClose={handleCloseConfirm}
            onConfirm={handleOpenPasswordDialog}
            message={t("DIALOG_DELETE.ACCOUNT")}
          />

          <PasswordDialog
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onOpen={handleOpenDeleteDialog}
            onClose={handleClosePasswordDialog}
            onConfirm={() => handleDelete(personInfo._id ?? "")}
          />
        </>
      )}
    </>
  );
}

export default ModificaProfilo;
