import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Form, Formik, FormikTouched } from "formik";
import homeDataValidationSchema from "../formModels/homeInfoValuesValidationSchema";
import { ToastContainer } from "react-toastify";
import { DataApiService } from "../../../../../services/DataApiService";
import { Dipendente } from "../../../../../type/dipendente";
import { toast_error, toast_success } from "../../../../../utils/custom-toast";
import { calculateAge } from "../../../../../utils/utilsDate";
import { MuiPhone } from "../../../../../componets/muiPhone/muiphone";
import { ContactTypeEnum } from "../../../../../type/enum/contact-type.enum";
import { RegistryApiService } from "../../../../../services/RegistryApiService";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useParams } from "react-router-dom";
import { EAuthRole } from "../../../../../type/enum/common.enum";
import { Util } from "../../../../../utils/util";

interface ContactInfo {
  id: string;
  contact: string;
}

interface DipendenteContacts extends Dipendente {
  email: ContactInfo;
  phone: ContactInfo;
  facebook: ContactInfo;
  instagram: ContactInfo;
  twitter: ContactInfo;
  linkdn: ContactInfo;
}

function HomeInfoFormik({
  registryData,
  idParam,
  sedi,
  reloadTab,
  setReloadTab,
}: {
  registryData: Dipendente;
  idParam: string;
  sedi: any[];
  reloadTab: boolean;
  setReloadTab: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [age, setAge] = useState<number | null>(null);
  const [uniqueDisplayName, setUniqueDisplayName] = useState<boolean>(true);
  const schema = homeDataValidationSchema;
  const { t } = useTranslation();
  const role = sessionStorage.getItem("role");
  // const { id } = useParams<{ id: string }>();

  const apiService = new DataApiService();
  const registryService = new RegistryApiService();

  useEffect(() => {
    if (registryData.birth_date) {
      const birthDate = new Date(registryData.birth_date);
      calculateAge(birthDate).then(setAge);
    }
  }, [registryData.birth_date]);

  const verifyUniqueDisplayName = async (displayName: string) => {
    if (displayName && displayName !== "") {
      const res: any = await registryService.verifyUniqueDisplayName(displayName);
      console.log(res);
      if (res.data) {
        setUniqueDisplayName(res.data.data === 0);
      } else {
        setUniqueDisplayName(res === 0);
      }
    }
  };

  const getInitialContactValues = (contacts: any) => {
    let initialValues: any = {
      email: { id: "", contact: "" },
      phone: { id: "", contact: "" },
      facebook: { id: "", contact: "" },
      instagram: { id: "", contact: "" },
      twitter: { id: "", contact: "" },
      linkdn: { id: "", contact: "" },
    };

    contacts.forEach((contact: any) => {
      // Check if `id_contact_type` is defined and not null before accessing `code`
      if (contact.id_contact_type && typeof contact.id_contact_type.code === "string") {
        const type = contact.id_contact_type.code.toLowerCase();
        // Ensure the type exists in initialValues to prevent setting undefined types
        if (initialValues.hasOwnProperty(type)) {
          initialValues[type] = { id: contact._id, contact: contact.contact };
        }
      }
    });

    return initialValues;
  };

  const initialValues = {
    ...registryData,
    // birth_date: registryData.birth_date || "",
    ...getInitialContactValues(registryData.id_contact),
  };

  if (!initialValues.displayName && initialValues.email && initialValues.email.contact) {
    const emailPart = initialValues.email.contact.split("@")[0].toLowerCase();
    verifyUniqueDisplayName(emailPart);
    initialValues.displayName = emailPart;
  }

  //BODY
  const onSubmit = async (values: DipendenteContacts) => {
    let employeeId = registryData._id;

    const payload = {
      id_registry_owner: values.id_registry_owner._id,
      // id_work_place: registryData.id_work_place,
      firstname: values.firstname,
      surname: values.surname,
      fiscal_code: values.fiscal_code,
      profession: values.profession,
      birth_date: values.birth_date,
      birth_place: values.birth_place,
      sex_code: values.sex_code,
      displayName: values.displayName,
      contact: [
        {
          contact_type: ContactTypeEnum.EMAIL,
          contact: values.email.contact,
          id: values.email.id,
        },
        {
          contact_type: ContactTypeEnum.PHONE,
          contact: values.phone.contact,
          id: values.phone.id,
        },
        ...(values.facebook?.contact
          ? [
              {
                contact_type: ContactTypeEnum.FACEBOOK,
                contact: Util.normalizeUrl(values.facebook.contact),
                ...(values.facebook.id && { id: values.facebook.id }),
              },
            ]
          : []),
        ...(values.instagram?.contact
          ? [
              {
                contact_type: ContactTypeEnum.INSTAGRAM,
                contact: Util.normalizeUrl(values.instagram.contact),
                ...(values.instagram.id && { id: values.instagram.id }),
              },
            ]
          : []),
        ...(values.twitter?.contact
          ? [
              {
                contact_type: ContactTypeEnum.TWITTER,
                contact: Util.normalizeUrl(values.twitter.contact),
                ...(values.twitter.id && { id: values.twitter.id }),
              },
            ]
          : []),
        ...(values.linkdn?.contact
          ? [
              {
                contact_type: ContactTypeEnum.LINKEDIN,
                contact: Util.normalizeUrl(values.linkdn.contact),
                ...(values.linkdn.id && { id: values.linkdn.id }),
              },
            ]
          : []),
      ],
    };

    registryService
      .updateRegistryFull(registryData._id, payload)
      .then((response) => {
        deleteSocialContact(values.facebook.contact, values.facebook.id);
        deleteSocialContact(values.instagram.contact, values.instagram.id);
        deleteSocialContact(values.twitter.contact, values.twitter.id);
        deleteSocialContact(values.linkdn.contact, values.linkdn.id);
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        setTimeout(() => {
          setReloadTab(!reloadTab);
        }, 1000);
      })
      .catch((error) => {
        toast_error(error);
        console.log("Error during update registry:", error);
        if (error.response) {
          toast_success(t("COMMONS.OPERATION_ERROR"));
          toast_error(error.response.data.message);
        }
      });
  };

  const deleteSocialContact = async (value: string, contactId: string) => {
    if (value === "" && contactId) {
      registryService
        .deleteContactById(contactId)
        .then((response) => {
          console.log("Contatto eliminato:", response.data);
        })
        .catch((error) => {
          console.error("Errore durante l'eliminazione del contatto:");
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Formik<DipendenteContacts> initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ handleSubmit, handleChange, values, setFieldTouched, handleBlur, setFieldValue, touched, errors, isValid }) => {
          const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<DipendenteContacts>]);
          const isSubmitDisabled = !formIsTouched || !isValid;
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <Grid item xs={12} sm={12}>
                <ToastContainer autoClose={3000} />
              </Grid>
              <Card variant="outlined" sx={{ marginBottom: 1 }}>
                <CardHeader title={t("USER_CARD.ANAGRAPHIC_INFO")}></CardHeader>
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="firstname"
                        value={values.firstname}
                        inputProps={{ maxLength: 64 }}
                        onChange={handleChange}
                        label={t("FORM.NAME")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.firstname && !!errors.firstname}
                        helperText={touched.firstname && t(errors.firstname ?? "")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="surname"
                        value={values.surname}
                        inputProps={{ maxLength: 64 }}
                        onChange={handleChange}
                        label={t("FORM.SURNAME")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.surname && !!errors.surname}
                        helperText={touched.surname && t(errors.surname ?? "")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fiscal_code"
                        value={values.fiscal_code}
                        inputProps={{ maxLength: 16 }}
                        onChange={handleChange}
                        label={t("FORM.FISCAL_CODE")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.fiscal_code && !!errors.fiscal_code}
                        helperText={touched.fiscal_code && t(errors.fiscal_code ?? "")}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          name="birth_date"
                          label={t("FORM.BIRTH_DATE")}
                          value={values.birth_date}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: touched.birth_date && !!errors.birth_date,
                              helperText: touched.birth_date && t(errors.birth_date ?? ""),
                              onBlur: handleBlur,
                              required: true,
                            },
                          }}
                          onChange={async (date) => {
                            const age = await calculateAge(date!);
                            setFieldValue("birth_date", date);
                            setFieldTouched("birth_date", true, false);
                            setAge(age);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="birth_place"
                        value={values.birth_place}
                        inputProps={{ maxLength: 250 }}
                        onChange={handleChange}
                        label={t("FORM.BIRTH_PLACE")}
                        required
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.birth_place && !!errors.birth_place}
                        helperText={touched.birth_place && t(errors.birth_place ?? "")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        name="age"
                        value={age}
                        onBlur={handleBlur}
                        fullWidth
                        label={t("FORM.AGE")}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        select
                        value={values.sex_code}
                        required
                        name="sex_code"
                        onChange={handleChange}
                        label={t("FORM.SEX_CODE")}
                        fullWidth
                        onBlur={handleBlur}
                        error={touched.sex_code && !!errors.sex_code}
                        helperText={touched.sex_code && t(errors.sex_code ?? "")}
                      >
                        <MenuItem value="M">M</MenuItem>
                        <MenuItem value="F">F</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="profession"
                        value={values.profession}
                        inputProps={{ maxLength: 35 }}
                        onChange={handleChange}
                        label={t("USER_CARD.PROFESSION")}
                        fullWidth
                        onBlur={handleBlur}
                        helperText={touched.profession && errors.profession ? t(errors.profession) : t("ERRORS.FORM.MUST_BE_MAX_35_CHARACTERS")}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ marginBottom: 1 }}>
                <CardHeader title={t("FORM.CONTACTS")}></CardHeader>
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MuiPhone
                        required
                        name="phone.contact"
                        label={t("FORM.PHONE")}
                        value={values.phone.contact}
                        onBlur={() => setFieldTouched("phone.contact")}
                        error={touched.phone?.contact && !!errors.phone?.contact}
                        helperText={touched.phone?.contact && errors.phone?.contact ? t(errors.phone.contact) : t("FORM.HELPER_TEXT_PHONE")}
                        onChange={(value: string) => {
                          setFieldValue("phone.contact", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="email.contact"
                        label="Email"
                        required
                        type="email"
                        variant="outlined"
                        fullWidth
                        disabled
                        inputProps={{ readOnly: true }}
                        error={touched.email?.contact && !!errors.email?.contact}
                        value={values.email.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email?.contact && errors.email?.contact ? t(errors.email.contact) : t("FORM.HELPER_TEXT_EMAIL")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="displayName"
                        value={values.displayName}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          verifyUniqueDisplayName(e.target.value);
                          e.target.value = e.target.value.toLowerCase();
                          handleChange(e);
                        }}
                        label={t("FORM.DISPLAY_NAME")}
                        required
                        fullWidth
                        disabled
                        onBlur={handleBlur}
                        error={(touched.displayName && !!errors.displayName) || !uniqueDisplayName}
                        helperText={
                          touched.displayName && errors.displayName && uniqueDisplayName
                            ? t(errors.displayName)
                            : !uniqueDisplayName
                              ? t("ERRORS.FORM.INVALID_DISPLAY_NAME")
                              : "Nome utente univoco per l'eCard"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="id_registry_owner._id"
                        select
                        label={t("FORM.WORK_PLACE")}
                        fullWidth
                        value={values.id_registry_owner._id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.id_registry_owner?._id && !!errors.id_registry_owner?._id}
                        helperText={touched.id_registry_owner?._id && t(errors.id_registry_owner?._id ?? "")}
                      >
                        {sedi.map((sede) => (
                          <MenuItem key={sede._id} value={sede._id}>
                            {sede.business_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" sx={{ marginBottom: 1 }}>
                <CardHeader title={t("FORM.SOCIAL_NETWORKS")}></CardHeader>
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="facebook.contact"
                        label="Facebook"
                        variant="outlined"
                        fullWidth
                        value={values.facebook.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched.facebook && errors.facebook?.contact)}
                        helperText={touched.facebook?.contact && errors.facebook?.contact ? t(errors.facebook.contact) : t("FORM.HELPER_TEXT_URL")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.facebook.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("facebook.contact", "");
                                    setFieldTouched("facebook.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="instagram.contact"
                        label="Instagram"
                        variant="outlined"
                        fullWidth
                        value={values.instagram.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched.instagram?.contact && errors.instagram?.contact)}
                        helperText={touched.instagram?.contact && errors.instagram?.contact ? t(errors.instagram.contact) : t("FORM.HELPER_TEXT_URL")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.instagram.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("instagram.contact", "");
                                    setFieldTouched("instagram.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="twitter.contact"
                        label={t("FORM.TWITTER")}
                        variant="outlined"
                        fullWidth
                        value={values.twitter.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.twitter?.contact && !!errors.twitter?.contact}
                        helperText={touched.twitter?.contact && errors.twitter?.contact ? t(errors.twitter.contact) : t("FORM.HELPER_TEXT_URL")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.twitter.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("twitter.contact", "");
                                    setFieldTouched("twitter.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="linkdn.contact"
                        label="Linkedin"
                        variant="outlined"
                        fullWidth
                        value={values.linkdn.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.linkdn?.contact && !!errors.linkdn?.contact}
                        helperText={touched.linkdn?.contact && errors.linkdn?.contact ? t(errors.linkdn.contact) : t("FORM.HELPER_TEXT_URL")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.linkdn.contact && (
                                <IconButton
                                  aria-label="toggle cancel link"
                                  onClick={() => {
                                    setFieldValue("linkdn.contact", "");
                                    setFieldTouched("linkdn.contact", true);
                                  }}
                                  edge="end"
                                >
                                  <CancelOutlinedIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card variant="outlined" sx={{ marginBottom: 1 }}>
                <CardHeader></CardHeader>
                <CardContent>
                  <Grid container columnSpacing={3} rowSpacing={3}></Grid>
                  <Grid item xs={12} sm={12} textAlign="end">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isLoadingSubmit || isSubmitDisabled || !uniqueDisplayName}
                      size="large"
                      startIcon={isLoadingSubmit ? <CircularProgress color="secondary" size={15} /> : null}
                    >
                      {t("COMMONS.CONFIRM")}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default HomeInfoFormik;
