import * as Yup from "yup";
import { boolean, object, string } from "yup";

import YupPassword from "yup-password";
import { intlPhoneRegex } from "../../../../utils/regex";

YupPassword(Yup);

export default object().shape({
  business_name: string().trim().required("ERRORS.FORM.REQUIRED").min(3, "ERRORS.FORM.SHOULD_BE_AT_LEAST_3_CHARACTERS"),
  address: string().trim().required("ERRORS.FORM.REQUIRED"),
  number: string().trim().required("ERRORS.FORM.REQUIRED"),
  postal_code: string().trim().required("ERRORS.FORM.REQUIRED"),
  city: string().trim().required("ERRORS.FORM.REQUIRED"),
  province: string().trim().required("ERRORS.FORM.REQUIRED"),
  state: string().trim().required("ERRORS.FORM.REQUIRED"),
  // website: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),
  email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
  phoneNumber: string().trim().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),

  // facebook: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),
  // instagram: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),
  // twitter: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),
  // linkedin: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),

  terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
