export const tabsSedi = [
  { value: 0, label: "Dettaglio" },
  { value: 1, label: "Utenti" },
  //   { value: 2, label: "Dipendenti" },
  //   { value: 3, label: "Storico" },
  //   { value: 4, label: "Documenti" },
];
export const tabsDipendenti = [
  //{ value: 1, label: "Profilo" },
  { value: 0, label: "FORM.PERSONAL_INFORMATION" },
  // { value: 1, label: "FORM.CONTRACTUAL_INFORMATION" },
  //{ value: 4, label: "Contatti" },
  //{ value: 5, label: "Competenze" },
  //{ value: 6, label: "Altro" },
];
