import { ApiService } from "../shared/ApiService";
import { IApiPaginationResponse, TApiListSearchParam } from "../../type/commons";

export class PaginationService extends ApiService {
  constructor() {
    super({});
  }

  getDataAndCount<T>(paginationUrl: string, params?: TApiListSearchParam<T>): Promise<IApiPaginationResponse<T>> {
    return new Promise<IApiPaginationResponse<T>>(async (resolve, reject) => {
      try {
        const data = await this.listSearch<T>(paginationUrl, params);
        const count = await this.listCount(paginationUrl, params);
        const res: IApiPaginationResponse<T> = {
          data: data,
          pagination: count,
        };
        return resolve(res);
      } catch (error) {
        return reject(error);
      }
    });
  }
}