import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContactTypeEnum } from "../../../type/enum/contact-type.enum";

const ProfiloAziendaReadOnly = ({ azienda }: any) => {
  const { t } = useTranslation();

  const indirizzoLegalePrincipale = azienda.id_registry_address.find((addr: any) => addr.id_registry_address_type.code === "LEGALE PRINCIPALE");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <div>{t("COMMONS.INFORMATIONS")}</div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField name="business_name" variant="outlined" color="primary" label={t("FORM.NAME")} fullWidth value={azienda.business_name} disabled />
      </Grid>

      {indirizzoLegalePrincipale ? (
        <Grid item xs={12} sm={9}>
          <TextField
            name="address.fullAddress"
            variant="outlined"
            color="primary"
            label={t("FORM.ADDRESS")}
            fullWidth
            value={`${indirizzoLegalePrincipale.topon} ${indirizzoLegalePrincipale.street},${indirizzoLegalePrincipale.number} `}
            disabled
          />
        </Grid>
      ) : null}
      <Grid item xs={12} sm={12}>
        <div>{t("FORM.CONTACTS")}</div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="email.contact"
          variant="outlined"
          label={t("FORM.EMAIL")}
          // className={styles.textField}
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.EMAIL)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="phone.contact"
          variant="outlined"
          label={t("FORM.PHONE")}
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.PHONE)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name="website.contact"
          variant="outlined"
          label={t("FORM.WEBSITE")}
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.SITO_WEB)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <div>Social</div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="facebook.contact"
          variant="outlined"
          label="Facebook"
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.FACEBOOK)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="instagram.contact"
          variant="outlined"
          label="Instagram"
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.INSTAGRAM)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="twitter.contact"
          variant="outlined"
          label={t("FORM.TWITTER")}
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.TWITTER)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="linkedin.contact"
          variant="outlined"
          label="Linkedin"
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.LINKEDIN)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="company_profile.contact"
          variant="outlined"
          label="Company Profile"
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.COMPANY_PROFILE)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="portfolio.contact"
          variant="outlined"
          label="Portfolio"
          // className={styles.textField}
          fullWidth
          value={azienda.id_contact.find((contact: any) => contact.id_contact_type.code === ContactTypeEnum.PORTFOLIO)?.contact}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ display: "flex", alignItems: "center" }}>
        {azienda.image ? (
          <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "center" }}>
            {azienda.image ? <img src={azienda.image} alt="Anteprima immagine" style={{ width: "50px" }} /> : null}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ProfiloAziendaReadOnly;
