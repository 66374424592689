import { MENU_URL } from "../api/api";
import { ApiService } from "./shared/ApiService";

export class RouteApiService extends ApiService {

    private _apiMenuItem = "/menu-item";
    private _apiRoute = "/route";
    private _apiGetRouteModulesCapability = "/modules-capability/get/capability"

    constructor() {
        super({ baseUrl: MENU_URL });
    }


    getRoute() {
        return this.get<any[]>(this._apiGetRouteModulesCapability);
    }
}