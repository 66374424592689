import { Container, Grid } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./LandingPage.module.css";
import Image from "react-bootstrap/Image";
import idConnectLogo from "../../img/id_connect.svg";

type TProps = {
  children: ReactNode;
};

const LandingPage: React.FC<TProps> = ({children}) => {
	return (
		<>
			<div>
				<Grid container className={styles.container}>
					<Grid item xs={12} sm={6} className={styles.primarySide}>
						<Container sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
							<Image src={idConnectLogo} style={{ maxHeight: "300px", maxWidth: "300px" }} />
						</Container>
					</Grid>
					<Grid item xs={12} sm={6} className={styles.whiteSide}>
						<Container maxWidth="xs">{children}</Container>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default LandingPage;
