import React, { ReactNode, useEffect, useState } from "react";
import { Box, CircularProgress, Container, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import AppWidgetSummary from "../../componets/dashboard/AppWidgetSummary";
import AppCurrentVisits from "../../componets/dashboard/AppCurrentVisits";
import { ReportDashboardService } from "../../services/ReportDashboardService";
import { EGenericType } from "../../type/type";
import { MapById } from "igloo-commonclasses";
import svg from "../../img/no_template.svg";
import Image from "react-bootstrap/Image";

interface IProps {
  children?: ReactNode;
}

interface ReportOwner {
  name: string;
  numero: number;
}

interface ContractType {
  code: string;
  name: string;
}

interface GenericType {
  name: string;
  numero: number;
}

interface DashboardData {
  reportOwner: ReportOwner[];
  contractTypesValues: { label: string; value: number }[];
  areaAziendaleValues: { label: string; value: number }[];
  isLoading: boolean;
}

// Custom hook for fetching data
function useDashboardData() {
  const [data, setData] = useState<DashboardData>({
    reportOwner: [],
    contractTypesValues: [],
    areaAziendaleValues: [],
    isLoading: true,
  });
  const apiService = new ReportDashboardService();

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch report owner
        const reportOwnerResponse: ReportOwner[] = await apiService.getListGroupOwner();

        // Fetch contract types and map them
        const contractTypesItems: ContractType[] = JSON.parse(sessionStorage.getItem("CONTRACT_TYPE") ?? "[]");
        let mapContract: { [key: string]: string } = {};
        contractTypesItems.forEach((item) => {
          mapContract[item.code] = item.name;
        });
        const contractTypesResponse: GenericType[] = await apiService.getListGenericTypes("CONTRACT_TYPE");
        const contractTypesValues = contractTypesResponse.map((report) => ({
          label: mapContract[report.name] || report.name, // Fallback to report.name if not found in map
          value: report.numero,
        }));

        // Fetch  areaAziendale
        const areaAziendaleResponse: GenericType[] = await apiService.getListGenericTypes("AREA");
        const areaAziendaleValues = areaAziendaleResponse.map((report) => ({
          label: report.name,
          value: report.numero,
        }));

        setData({
          reportOwner: reportOwnerResponse,
          contractTypesValues,
          areaAziendaleValues,
          isLoading: false,
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return data;
}

const DashboardGestore: React.FC<IProps> = ({ children }) => {
  const { t } = useTranslation();
  const { reportOwner, contractTypesValues, areaAziendaleValues, isLoading } = useDashboardData();
  console.log(reportOwner, contractTypesValues, areaAziendaleValues, isLoading);

  // Assuming colors are defined outside or within this component
  let colors: string[] = ["#C7FA93", "#93DFFA", "#FACB93", "#FA93BF", "#3E4209"];
  let colorsArea: string[] = ["#C7FA93", "#93DFFA", "#FACB93", "#FA93BF", "#3E4209"];

  let icons: string[] = ["#C7FA93", "#93DFFA", "#FACB93", "#FA93BF", "#3E4209"];

  const renderReportOwner =
    reportOwner.length > 0 &&
    reportOwner.map((report, index) => (
      <Grid item xs={12} sm={12} md={4} key={report.name}>
        {/* if report name = "associare sede" write "dipendenti non associati" */}
        <AppWidgetSummary
          title={report.name}
          total={report.numero}
          icon={"material-symbols:home"}
          bgcolor={colors[index % colors.length]}
          sx={{}}
          objectName={report.name}
          color="secondary"
        />
      </Grid>
    ));

  const renderLoading = () => (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
      <CircularProgress />
    </Grid>
  );

  return (
    <Container maxWidth="xl" sx={{ m: 0, padding: "0 !important" }}>
      {isLoading ? (
        renderLoading()
      ) : (
        <>
          {contractTypesValues.length > 0 || areaAziendaleValues.length > 0 || reportOwner.length > 0 ? (
            <>
              <Typography variant="h1">{t("COMMONS.WELCOME")}</Typography>
              <Typography variant="h5" sx={{ mb: 2 }}>{`${t("HOMEPAGE.COMPANY_INFO")}`}</Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t("HOMEPAGE.EMPLOYEES_BY_AREA")}
              </Typography>
              <Grid container spacing={3}>
                {renderReportOwner}
              </Grid>
              {/* <Grid container spacing={3}> */}
              {/* {areaAziendaleValues.length > 0 && (
                  <Grid item xs={12} sm={6} md={6}>
                    <AppCurrentVisits
                      title={t("HOMEPAGE.ORGANIZZATION_AREA")}
                      chartData={areaAziendaleValues}
                      chartColors={[theme.palette.primary.main, theme.palette.info.main, theme.palette.warning.main, theme.palette.error.main]}
                      subheader=""
                    />
                  </Grid>
                )} */}
              {/* {contractTypesValues.length > 0 && (
                  <Grid item xs={12} sm={6} md={6}>
                    <AppCurrentVisits
                      title={t("HOMEPAGE.CONTRACT_TYPES")}
                      chartData={contractTypesValues}
                      chartColors={[theme.palette.primary.main, theme.palette.info.main, theme.palette.warning.main, theme.palette.error.main]}
                      subheader=""
                    />
                  </Grid>
                )} */}
              {/* </Grid> */}
            </>
          ) : (
            <Typography variant="h1" sx={{ mb: 5, ml: 0, justifyContent: "center", fontWeight: "500" }} textAlign="center">
              <>
                <Stack spacing={1} justifyContent="center" alignItems="center">
                  <div>{t("HOMEPAGE.NO_DATA")}</div>
                  {/* <Image src={svg} /> */}
                </Stack>
              </>
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardGestore;
