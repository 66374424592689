import React, { useEffect, useState } from "react";
import styles from "../card-template/components/add-edit-template/AddEditTemplate.module.css";
import { Box, Card, Chip, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardTypeCounter } from "../../type/card-type-counter";
import { ICardType } from "../../type/i-card-type";
import CommonDialog from "../../componets/common-dialog/CommonDialog";
import { CoreApiService } from "../../services/CoreApiService";

const Plafond: React.FC = () => {
  const { t } = useTranslation();
  const [cardType, setCardType] = useState<ICardType[]>([]);
  const [cardTypeCounter, setCardTypeCounter] = useState<CardTypeCounter[]>([]);
  const [dialogNoPlafond, setDialogNoPlafond] = useState<boolean>(false);
  const navigate = useNavigate();
  const coreApiService = new CoreApiService();

  const fetchData = async () => {
    // recupero dati plafond
    const getCardType = await coreApiService.getCardType();
    setCardType(getCardType);

    const getCardTypeCounter = await coreApiService.getCardTypeCounter();
    setCardTypeCounter(getCardTypeCounter);
  };

  const getCounter = (cardType: string) => {
    if (cardTypeCounter.length > 0) {
      const counter = cardTypeCounter.filter((c) => {
        let card_type = c.card_type as ICardType;
        return card_type.code === cardType;
      });

      if (counter[0]?.max) {
        return counter[0].max - counter[0].assigned;
      } else return 0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectPlafond = (cardType: ICardType) => {
    const maxCard = getCounter(cardType.code);
    // if (maxCard > 0) {
    navigate("/new-request", { state: { cardType: cardType.code, maxCard: maxCard, cardTypeDescription: cardType.description } });
    // } else {
    // setDialogNoPlafond(true);
    // }
  };

  const closeDialogNoPlafond = () => {
    setDialogNoPlafond(false);
  };

  const confirmDialogNoPlafond = () => {
    navigate("/acquisti/storico");
  };

  // TODO: da gestire caso colore rosso senza contatori e poi nuova pagina per scelta da worklist dei vari dipendenti per cui costruire le card.
  // aggiungere flusso per redirect acquisto se cliente clicca card con contatore vuoto

  return (
    <>
      <h1 className={styles.title}>{t("PLAFOND.TITLE")}</h1>
      <Typography variant={"h6"} sx={{ fontWeight: 400 }}>
        {t("PLAFOND.SELECT_PRODUCT")}
      </Typography>
      <Grid container spacing={2} marginTop={5}>
        {Object.entries(cardType).map(([prop, card], index) => (
          <Grid item xs={12} marginTop={3}>
            <Card
              sx={{ border: "1px solid", borderColor: "#D3D3D3", "&:hover": { borderColor: "black", cursor: "pointer" } }}
              elevation={0}
              onClick={() => selectPlafond(card)}
            >
              <Grid container spacing={2}>
                <Grid item xs={2} sx={{ bgcolor: "#D3D3D3" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                  <Box marginTop={"10px"} marginBottom={"10px"}>
                    <Card style={{ backgroundColor: card.color, width: "100px", height: "60px", marginTop: "10px", marginBottom: "10px" }}> </Card>
                  </Box>
                </Grid>
                <Grid item xs={8} display={"flex"} alignItems={"center"}>
                  <Typography variant={"h5"} fontWeight={200}>
                    {t(card.description)}
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                  {getCounter(card.code) > 0 && (
                    <Chip
                      label={`X${getCounter(card.code)}`}
                      sx={{ padding: 2, fontWeight: "700", color: "black", bgcolor: "#86DC84", fontSize: "16px" }}
                    />
                  )}
                  {getCounter(card.code) === 0 && (
                    <Chip
                      label={`X${getCounter(card.code)}`}
                      sx={{ padding: 2, fontWeight: "700", color: "black", bgcolor: "#FFBABA", fontSize: "16px" }}
                    />
                  )}
                </Grid> */}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>

      <CommonDialog
        title={t("PLAFOND.DIALOG_NO_PLAFOND_TITLE")}
        descriptionContent={t("PLAFOND.DIALOG_NO_PLAFOND_DESCRIPTION")}
        open={dialogNoPlafond}
        onClose={closeDialogNoPlafond}
        onConfirm={confirmDialogNoPlafond}
      />
    </>
  );
};

export default Plafond;
