import * as Yup from "yup";
import YupPassword from "yup-password";
import { boolean, date, object, string } from "yup";
import { intlPhoneRegex } from "../../../../../utils/regex";

YupPassword(Yup);

export default object().shape({
  uniqueCode: string().trim().required("ERRORS.FORM.REQUIRED"),
  assumptionLocation: string().trim().required("ERRORS.FORM.REQUIRED"),
  area: string().trim().required("ERRORS.FORM.REQUIRED"),
  contractType: string().trim().required("ERRORS.FORM.REQUIRED"),
  qualification: string().trim().required("ERRORS.FORM.REQUIRED"),
  ccnl: string().trim().required("ERRORS.FORM.REQUIRED"),
  roleType: string().trim().required("ERRORS.FORM.REQUIRED"),
  contractualLevel: string().trim().required("ERRORS.FORM.REQUIRED"),
  workSchedule: string().trim().required("ERRORS.FORM.REQUIRED"),
  workPercentage: string()
    .trim()
    .required("ERRORS.FORM.REQUIRED")
    .matches(/^(100|[1-9]?[0-9])$/, "ERRORS.FORM.INVALID_NUMBER"),
  ral: string().trim().required("ERRORS.FORM.REQUIRED"),
  linkDataRoom: string().trim().required("ERRORS.FORM.REQUIRED").url("ERRORS.FORM.INVALID_URL"),
  linkUrl: string().trim().url("ERRORS.FORM.INVALID_URL"),
  //endWorkTime can't be before startWorkTime
  startWorkTime: string()
    .trim()
    .required("ERRORS.FORM.REQUIRED")
    .test("is-less", "ERRORS.FORM.DAILY_SHIFT.START_MAX", function (value) {
      return value < this.parent.endWorkTime;
    }),
  endWorkTime: string()
    .trim()
    .required("ERRORS.FORM.REQUIRED")
    .test("is-greater", "ERRORS.FORM.DAILY_SHIFT.END_MIN", function (value) {
      return value > this.parent.startWorkTime;
    }),
});

// birthDate: undefined,
// birthLocation: "",
// fiscalCode: "",
// assumptionLocation: "",
// contractualLevel: "",
// contractType: "",
// ral: undefined,
// area: "",
// qualification: "",
// role: "",
// uniqueCode: "",
// descriptionStudy: "",
// study: "",
// protectedCategory: false,
// invalidated: false,
// lex104: false,
// welfare: undefined,
// ticket: false,
// ccnl: "",
// seniority: undefined,
// tenant: "",
// endDate: undefined,
// estimatedEndDate: undefined,
// lastDateLevel: undefined,
