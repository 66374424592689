import { Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./ProfiloSede.module.css";
import { useEffect, useState } from "react";
import { toast_error, toast_success } from "../../../../utils/custom-toast";
import GoogleMapsCard from "../../../../componets/GoogleMapsCard/GoogleMapsCard";
import { MuiPhone } from "../../../../componets/muiPhone/muiphone";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ContactTypeEnum } from "../../../../type/enum/contact-type.enum";
import { RegistryAddressTypeEnum } from "../../../../type/enum/registry-address-type.enum";
import editSedeValidationSchema from "./editSedeValidationSchema";
import * as yup from "yup";
import { RegistryApiService } from "../../../../services/RegistryApiService";
import { IRegistry } from "../../../../type/commons";
import { useNavigate } from "react-router-dom";
import { EContactType } from "../../../../type/enum/common.enum";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Util } from "../../../../utils/util";

//TODO: redirect dopo salvataggio

function ProfiloSede({ sede, idSede, isLoading }: { sede: IRegistry; idSede: string; isLoading: boolean }) {
  const { t } = useTranslation();
  const { business_name } = sede;
  const [sedeNome, setSedeNome] = useState(business_name);
  const [sedeSitoWeb, setSedeSitoWeb] = useState({ value: "", id: "" });
  const [sedeEmail, setSedeEmail] = useState({ value: "", id: "" });
  const [sedePhone, setSedePhone] = useState({ value: "", id: "" });

  const [address, setAddress] = useState<string>("");
  const [number, setNumber] = useState<number | null>(null);
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [patchLoading, setPatchLoading] = useState<boolean>(false);
  const registryApiService = new RegistryApiService();
  const schema = editSedeValidationSchema;
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const navigate = useNavigate();
  type SchemaKeys = keyof typeof schema.fields;

  function getFieldSchema<T extends SchemaKeys>(field: T, fullSchema: yup.AnyObjectSchema) {
    return yup.object({ [field]: fullSchema.fields[field] });
  }

  const validateField = async (field: SchemaKeys, value: string) => {
    const fieldSchema = getFieldSchema(field, schema);

    try {
      await fieldSchema.validate({ [field]: value });
      setErrors((prev) => ({ ...prev, [field]: "" }));
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: err.message }));
      } else if (err instanceof Error) {
        console.error("Errore di validazione:", err.message);
      }
    }
  };

  useEffect(() => {
    validateField("name", sedeNome ?? "");
    validateField("address", address);
    validateField("number", number?.toString() ?? "");
    validateField("postal_code", postalCode);
    validateField("city", city);
    validateField("province", province);
    validateField("state", state);
    // validateField("sedeSitoWeb", sedeSitoWeb.value);
    validateField("sedeEmail", sedeEmail.value);
    validateField("sedePhone", sedePhone.value);
  }, [sedeNome, address, number, postalCode, city, province, state, sedeEmail.value, sedePhone.value]);

  const getContactValue = (type: ContactTypeEnum) => {
    const contact = sede.id_contact.find((c) => c.id_contact_type.code === type) || { contact: "", _id: "" };
    return contact ? { value: contact.contact, id: contact._id } : { value: "", id: "" };
  };
  const emailValue = getContactValue(ContactTypeEnum.EMAIL);
  const phoneValue = getContactValue(ContactTypeEnum.PHONE);
  const sitoWebValue = getContactValue(ContactTypeEnum.SITO_WEB);

  const extractToponAndAddress = (addressString: string | null) => {
    if (!addressString) return { topon: "", street: "" };
    const addressArray = addressString.trim().split(" ");
    const topon = addressArray[0];
    const street = addressArray.slice(1).join(" ");
    return { topon, street };
  };

  const deleteSocialContact = async (value: string, contactId: string) => {
    if (value === "" && contactId) {
      registryApiService
        .deleteContactById(contactId)
        .then((response) => {
          console.log("Contatto eliminato:", response.data);
        })
        .catch((error) => {
          console.error("Errore durante l'eliminazione del contatto:");
          if (error.response) {
            console.log("Response data:", error.response.data);
            console.log("Response status:", error.response.status);
            console.log("Response headers:", error.response.headers);
          }
        });
    }
  };

  useEffect(() => {
    if (sede) {
      setSedeNome(sede.business_name || "");

      if (sede.id_registry_address && sede.id_registry_address.length > 0) {
        const addr = sede.id_registry_address[0];
        setAddress(`${addr?.topon ?? ""} ${addr?.street || ""}`);
        setNumber(addr?.number || null);
        setPostalCode(addr.postal_code);
        setCity(addr.city);
        setProvince(addr.province);
        setState(addr.state);
      }
      setSedeSitoWeb(sitoWebValue);
      setSedeEmail(emailValue);
      setSedePhone(phoneValue);
      // setSedeFacebook(getContactValue(EContactType.FACEBOOK));
      // setSedeInstagram(getContactValue(EContactType.INSTAGRAM));
      // setSedeTwitter(getContactValue(EContactType.TWITTER));
      // setSedeLinkedin(getContactValue(EContactType.LINKEDIN));
    }
  }, [sede]);

  const { topon, street } = extractToponAndAddress(address);
  console.log("topon, street", topon, street);

  const bodySedePatch = {
    business_name: sedeNome,
    contact: [
      {
        contact_type: EContactType.EMAIL,
        contact: sedeEmail.value,
        id: sedeEmail.id,
      },
      {
        contact_type: EContactType.PHONE,
        contact: sedePhone.value,
        id: sedePhone.id,
      },
      ...(sedeSitoWeb.value
        ? [
            {
              contact_type: EContactType.SITO_WEB,
              contact: Util.normalizeUrl(sedeSitoWeb.value),
              ...(sedeSitoWeb.id && { id: sedeSitoWeb.id }),
            },
          ]
        : []),
      // ...(sedeInstagram.value
      //   ? [
      //       {
      //         contact_type: EContactType.INSTAGRAM,
      //         contact: sedeInstagram.value,
      //         ...(sedeInstagram.id && { id: sedeInstagram.id }),
      //       },
      //     ]
      //   : []),
      // ...(sedeTwitter.value
      //   ? [
      //       {
      //         contact_type: EContactType.TWITTER,
      //         contact: sedeTwitter.value,
      //         ...(sedeTwitter.id && { id: sedeTwitter.id }),
      //       },
      //     ]
      //   : []),
      // ...(sedeLinkedin.value
      //   ? [
      //       {
      //         contact_type: EContactType.LINKEDIN,
      //         contact: sedeLinkedin.value,
      //         ...(sedeLinkedin.id && { id: sedeLinkedin.id }),
      //       },
      //     ]
      //   : []),
    ],
    registry_address: [
      {
        ...(sede?.id_registry_address && sede.id_registry_address.length > 0 && { id: sede.id_registry_address[0]._id }),
        registry_address_type: RegistryAddressTypeEnum.LEGALE_PRINCIPALE,
        topon: topon,
        street: street,
        number: number,
        postal_code: postalCode,
        city: city,
        province: province,
        state: state,
      },
    ],
  };

  const patchSede = async (idSede: string) => {
    setPatchLoading(true);
    try {
      const response = await registryApiService.updateRegistryFull(idSede, bodySedePatch);
      toast_success(t("COMMONS.OPERATION_SUCCESS"));
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setPatchLoading(false);
    }
  };

  const hasErrors = () => {
    return Object.values(errors).some((error) => error !== undefined && error !== "");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!hasErrors()) {
      patchSede(idSede);

      // deleteSocialContact(sedeFacebook.value, sedeFacebook.id);
      // deleteSocialContact(sedeInstagram.value, sedeInstagram.id);
      // deleteSocialContact(sedeTwitter.value, sedeTwitter.id);
      // deleteSocialContact(sedeLinkedin.value, sedeLinkedin.id);
    } else {
      toast_error("Errore durante la compilazione del form");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!sede) return <div>Sede non trovata</div>;

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            {/* prima griglia */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} sm={12}>
                <div className={styles.title}>INFORMAZIONI SEDE</div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nome Sede"
                  variant="outlined"
                  type="text"
                  value={sedeNome}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? t(errors.name) : " "}
                  onChange={(event) => setSedeNome(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Indirizzo"
                  variant="outlined"
                  type="text"
                  value={address}
                  name="address"
                  error={!!errors.address}
                  helperText={errors.address ? t(errors.address) : " "}
                  fullWidth
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Numero"
                  variant="outlined"
                  type="text"
                  name="number"
                  value={number ?? ""}
                  error={!!errors.number}
                  helperText={errors.number ? t(errors.number) : " "}
                  fullWidth
                  onChange={(e) => setNumber(parseInt(e.target.value) || null)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="CAP"
                  variant="outlined"
                  type="text"
                  name="postal_code"
                  error={!!errors.postal_code}
                  helperText={errors.postal_code ? t(errors.postal_code) : " "}
                  value={postalCode ?? ""}
                  fullWidth
                  onChange={(e) => setPostalCode(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Città"
                  variant="outlined"
                  type="text"
                  name="city"
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city ? t(errors.city) : " "}
                  value={city ?? ""}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Provincia"
                  variant="outlined"
                  type="text"
                  name="province"
                  value={province ?? ""}
                  error={!!errors.province}
                  helperText={errors.province ? t(errors.province) : " "}
                  fullWidth
                  onChange={(e) => setProvince(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Stato"
                  variant="outlined"
                  type="text"
                  name="state"
                  value={state ?? ""}
                  error={!!errors.state}
                  helperText={errors.state ? t(errors.state) : " "}
                  fullWidth
                  onChange={(e) => setState(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={styles.title}>CONTATTI</div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label={"Email"}
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={sedeEmail.value}
                  error={!!errors.sedeEmail}
                  helperText={errors.sedeEmail ? t(errors.sedeEmail) : " "}
                  onChange={(event) => setSedeEmail({ value: event.target.value, id: sedeEmail.id })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPhone
                  label="Telefono"
                  value={sedePhone.value}
                  onChange={(phone) => setSedePhone({ value: phone, id: sedePhone.id })}
                  error={!!errors.sedePhone}
                  helperText={errors.phone ? t(errors.phone) : "Inserisci un numero con prefisso internazionale"}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Sito Web"
                  type="url"
                  // required
                  variant="outlined"
                  fullWidth
                  value={sedeSitoWeb.value}
                  // error={!!errors.sedeSitoWeb}
                  helperText={errors.sedeSitoWeb ? t(errors.sedeSitoWeb) : t("FORM.HELPER_TEXT_URL")}
                  onChange={(event) => setSedeSitoWeb({ value: event.target.value, id: sedeSitoWeb.id })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {sedeSitoWeb.value && (
                          <IconButton
                            aria-label="toggle cancel link"
                            onClick={() => {
                              setSedeSitoWeb({ value: "", id: "" });
                            }}
                            edge="end"
                          >
                            <CancelOutlinedIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <div className={styles.title}>SOCIAL NETWORK</div>
              </Grid>
               <Grid item xs={12} sm={6}>
                <TextField
                  label="Facebook"
                  variant="outlined"
                  type="url"
                  fullWidth
                  value={sedeFacebook.value}
                  error={!!errors.sedeFacebook}
                  helperText={errors.sedeFacebook ? t(errors.sedeFacebook) : t("FORM.HELPER_TEXT_URL")}
                  onChange={(event) => setSedeFacebook({ value: event.target.value, id: sedeFacebook.id })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Instagram"
                  variant="outlined"
                  fullWidth
                  value={sedeInstagram.value}
                  error={!!errors.sedeInstagram}
                  helperText={errors.sedeInstagram ? t(errors.sedeInstagram) : t("FORM.HELPER_TEXT_URL")}
                  onChange={(event) => setSedeInstagram({ value: event.target.value, id: sedeInstagram.id })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                     label={t("FORM.TWITTER")}
                  variant="outlined"
                  fullWidth
                  value={sedeTwitter.value}
                  error={!!errors.sedeTwitter}
                  helperText={errors.sedeTwitter ? t(errors.sedeTwitter) : t("FORM.HELPER_TEXT_URL")}
                  onChange={(event) => setSedeTwitter({ value: event.target.value, id: sedeTwitter.id })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="LinkedIn"
                  variant="outlined"
                  fullWidth
                  value={sedeLinkedin.value}
                  error={!!errors.sedeLinkedin}
                  helperText={errors.sedeLinkedin ? t(errors.sedeLinkedin) : t("FORM.HELPER_TEXT_URL")}
                  onChange={(event) => setSedeLinkedin({ value: event.target.value, id: sedeLinkedin.id })}
                />
              </Grid> */}
              <Grid item xs={1} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  disabled={patchLoading || hasErrors()}
                  style={{ backgroundColor: patchLoading || hasErrors() ? "var(--gray-400)" : "var(--black)", color: "white" }}
                  variant="contained"
                  type="submit"
                  sx={{ textTransform: "Capitalize" }}
                >
                  {!patchLoading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* terza griglia */}
            <Grid container spacing={2} sx={{ height: "100%", width: "100%", display: "flex" }}>
              <Grid item xs={12} sm={12}>
                <GoogleMapsCard address={`${address}, ${number}, ${city} ${province} ${state}`} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ProfiloSede;
