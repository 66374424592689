import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./SelectSize.module.css";
import { getCurrency } from "../../utils/commonFunctions";
import { Util } from "../../../../utils/util";

const SelectSize = ({ selectedProduct, setSelectedPrice, setProductInfo, productInfo, selectedPrice, prices, loadingPrices }: any) => {
  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedID = event.target.value;
    setSelectedPrice(selectedID);
    setProductInfo({ ...productInfo, selectedPrice: selectedID });
  };

  return (
    <div>
      {loadingPrices ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <div className={styles.radioGroupContainer}>
          <RadioGroup value={selectedPrice} onChange={handleSelectionChange} sx={{ flexDirection: "row", flexWrap: "wrap" }}>
            {prices.map((price: any) => (
              <div key={price._id} className={`${styles.radioItem} ${selectedPrice === price.stripe_id ? styles.radioItemSelected : ""}`}>
                <div className={styles.radioContent}>
                  <FormControlLabel
                    key={price._id}
                    value={price.stripe_id}
                    control={<Radio />}
                    label={
                      <div>
                        <div className={styles.radioTitle}>{`X ${price.max_unit_quantity}`}</div>
                        <div className={styles.radioSubtitle}>
                          {getCurrency(price.currency)}
                          {Util.formatStripePrice(price.amount)}
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default SelectSize;
