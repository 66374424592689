import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast_error } from "../../utils/custom-toast";
import styles from "./TabellaListaAziende.module.css";
import { CircularProgress, IconButton, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EAuthRole, ERegistryType } from "../../type/enum/common.enum";
import { ContactTypeEnum } from "../../type/enum/contact-type.enum";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { RegistryApiService } from "../../services/RegistryApiService";
import { IRegistry } from "../../type/commons";
import { useTranslation } from "react-i18next";

interface Props {
  searchTerm?: string;
}

const TabellaListaAziende: React.FC<Props> = ({ searchTerm = "" }) => {
  const { t } = useTranslation();
  const [aziende, setAziende] = useState<IRegistry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataHasLoaded, setDataHasLoaded] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const [idAzienda, setIdAzienda] = useState<string>("");
  const registryApiService = new RegistryApiService();
  const role = sessionStorage.getItem("role");

  const countData = async (): Promise<void> => {
    const itemCount = await registryApiService.countRegistries({
      registry_type: ERegistryType.AZIENDA,
      searchFullText: searchTerm,
      searchField: "business_name",
    });
    setTotalItems(itemCount);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await registryApiService.fetchAllRegistry({
        page: currentPage,
        limit: itemsPerPage,
        orderBy: "createdAt",
        searchFullText: searchTerm,
        searchFields: ["business_name", "vat_number"],
        registry_type: ERegistryType.AZIENDA,
      });
      if (data) {
        setAziende(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setIsLoading(false);
      setDataHasLoaded(true);
    }
  };

  const fetch = async () => {
    await countData();
    if (!searchTerm || searchTerm.length >= 3) {
      await fetchData();
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage, searchTerm]);

  function handleRowClick(businessName: string, tenant: string, id: string) {
    setIdAzienda(id);
    role === EAuthRole.SUPERADMIN ? navigate(`/azienda/${id}/${tenant}`) : navigate(`/azienda/${id}`);
    sessionStorage.setItem("business_name", businessName);
  }

  const renderTableBodyContent = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={12} rowSpan={12} className={styles.loadingRow}>
            <CircularProgress size={70} />
          </td>
        </tr>
      );
    }

    if (dataHasLoaded && aziende.length === 0) {
      return (
        <tr>
          <td colSpan={7} className={styles.emptyRow}>
            Nessun dato trovato
          </td>
        </tr>
      );
    }

    return aziende.map((azienda) => (
      <tr
        key={azienda._id}
        // onDoubleClick={() => handleRowClick(azienda.key_code, azienda._id)}
      >
        <td className={styles.rowStyleFirstElement}>{azienda.business_name}</td>
        {/* <td className={styles.rowStyle}>{azienda.vat_number}</td> */}
        <td className={styles.rowStyle}>
          {azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === ContactTypeEnum.EMAIL)?.contact}
        </td>
        <td className={styles.rowStyle}>
          {azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === ContactTypeEnum.PHONE)?.contact}
        </td>
        <td className={styles.rowStyle}>{azienda.tenant}</td>
        <td className={styles.rowStyle}> {azienda.employees}</td>
        <td className={styles.rowStyle}>
          {!azienda.business_name?.includes("_deleted") && (
            <div>
              <IconButton onClick={() => handleRowClick(azienda.business_name!, azienda.tenant, azienda._id)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </div>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <Table>
        <thead className={styles.thead}>
          {!dataHasLoaded && isLoading ? (
            <tr>
              <th className={styles.rowStyle}></th>
            </tr>
          ) : (
            <tr>
              <th className={styles.rowStyle}>{t("COMMONS.COMPANY")}</th>
              {/* <th className={styles.rowStyle}>VAT</th> */}
              <th className={styles.rowStyle}>Email</th>
              <th className={styles.rowStyle}>{t("FORM.PHONE")}</th>
              <th className={styles.rowStyle}>Tenant</th>
              <th className={styles.rowStyle}>{`N° ${t("COMMONS.EMPLOYEES")}`}</th>

              <th></th>
            </tr>
          )}
        </thead>
        <tbody>{renderTableBodyContent()}</tbody>
      </Table>
      {!isLoading && dataHasLoaded && aziende.length > 0 ? (
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          shape="rounded"
          className={styles.pagination}
          showFirstButton
        />
      ) : null}
    </div>
  );
};

export default TabellaListaAziende;
