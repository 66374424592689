import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

interface IConfirmDialog {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmDialog = ({ open, setOpen, onOpen, onClose, onConfirm, message }: IConfirmDialog) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle>{"Sei sicuro di procedere?"}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose} sx={{ textTransform: "Capitalize" }}>
          {t("COMMONS.CANCEL")}
        </Button>
        <Button color="primary" variant="contained" sx={{ textTransform: "Capitalize" }} onClick={onConfirm}>
          {t("COMMONS.CONFIRM")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
