import React, { useEffect, useState } from "react";
import { DataTableProps } from "../../type/data-table";
import { Checkbox, FormControl, Grid, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, SxProps, Theme } from "@mui/material";
import { PaginationService } from "../../services/data-table/PaginationService";
import { IApiPaginationResponse } from "../../type/commons";
import StyledDataGrid from "../styledDataGrid/StyledDataGrid";
import { GridSortModel } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import { useTranslation } from "react-i18next";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const style: SxProps<Theme> = {
  fontWeight: "400",
  borderStyle: "none",
  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "var(--primary-500)",
  },
  "& .MuiTablePagination-selectLabel": {
    marginBottom: "0",
  },
  "& .MuiTablePagination-displayedRows": {
    marginBottom: "0",
  },
};

const DataTable: React.FC<DataTableProps> = ({
  columns,
  paginationUrl = "",
  searchParams,
  customListSearch = false,
  selectionEnabled = false,
  rowSelected,
  onRowSelectionChange,
  listSearch,
  customSx,
  defaultPageSize,
  rowHeight,
  sortingModel,
  customSorting = false,
  onCustomSorting,
  orderBy = "createdAt",
  sortingOrder,
  customFilterList,
  enabledSearch = true,
  onlyCustomFilter = false,
  children,
  sortingMode = "server",
}) => {
  //const service = useService<PaginationService>(PaginationService.name);
  const service = new PaginationService();

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: defaultPageSize ?? 10,
    page: 1,
  });

  const [totalItems, setTotalItems] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterFields, setFilterFields] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const { t } = useTranslation();

  const openMenu = Boolean(anchorEl);

  const fetchData = async () => {
    let res: IApiPaginationResponse<any>;
    if (customSorting) {
      searchParams = {
        ...searchParams,
        limit: paginationModel.pageSize,
        page: paginationModel.page !== 0 ? paginationModel.page : 1,
        searchFullText: filterValue,
        searchFields: filterFields,
        orderBy: orderBy,
        sortOrder: sortingOrder,
      };
    } else {
      const keySearchF = filterFields.length === 1 ? "searchField" : "searchFields";
      searchParams = {
        ...searchParams,
        limit: paginationModel.pageSize,
        page: paginationModel.page !== 0 ? paginationModel.page : 1,
        searchFullText: filterValue,
        [keySearchF]: filterFields.length === 1 ? filterFields[0] : filterFields,
        orderBy: orderBy,
        sortOrder: 1,
      };
    }

    if (customListSearch && listSearch) {
      res = await listSearch(searchParams);
    } else {
      res = await service.getDataAndCount(paginationUrl, searchParams);
    }
    setData(res.data);
    setTotalItems(res.pagination);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (paginationModel.page > 0) {
      fetchData();
    }
  }, [paginationModel]);

  const sortModelChange = async (newSortModel: any) => {
    setSortModel(newSortModel);
    await fetchData();
  };

  let sx = style as SxProps<Theme>;
  if (customSx) {
    sx = { ...sx, ...customSx } as SxProps<Theme>;
  }

  const handleClickSearch = () => {
    fetchData();
  };

  const handleClickFilter = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectMenuItem = (field?: string) => {
    if (field) {
      setFilterFields([...filterFields, field]);
    }
  };

  const handleCancelFilterList = () => {
    setFilterFields([]);
    handleCloseFilterMenu();
    handleClickSearch();
  };

  const handleChangeSearch = (event: any) => {
    setFilterValue(event.target.value);
  };

  const handleCancelSearch = () => {
    setFilterValue("");
  };
  console.log("data", data);
  data.forEach((item, index) => {
    console.log(`Item ${index}: ID = ${item._id}`);
  });

  console.log(`PageSize: ${paginationModel.pageSize}, Page: ${paginationModel.page}`);

  return (
    <>
      <Grid container spacing={2} marginTop={4} marginBottom={4} alignItems={"center"}>
        {enabledSearch && (
          <>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  id="outlined-search"
                  type={"text"}
                  size={"medium"}
                  value={filterValue}
                  onChange={handleChangeSearch}
                  fullWidth
                  placeholder={t("COMMONS.SEARCH")}
                  startAdornment={
                    <InputAdornment position="start" sx={{ ml: 1 }}>
                      <IconButton aria-label="toggle search" onClick={handleClickSearch} edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    filterValue && (
                      <InputAdornment position="end" sx={{ mr: 1 }}>
                        <IconButton aria-label="toggle cancel" onClick={handleCancelSearch} edge="end">
                          <CancelOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                startIcon={<FilterListOutlinedIcon />}
                sx={{ textTransform: "Capitalize" }}
                onClick={handleClickFilter}
                size={"large"}
              >
                {t("COMMONS.FILTER_SEARCH")}
              </Button>
              <Menu
                id="basic-menu"
                open={openMenu}
                onClose={handleCloseFilterMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorEl={anchorEl}
              >
                <MenuItem onClick={handleCancelFilterList}>
                  <FilterListOffOutlinedIcon sx={{ mr: 1 }} />
                  <ListItemText>{t("COMMONS.CLEAR_FILTERS")}</ListItemText>
                </MenuItem>
                {!onlyCustomFilter &&
                  columns.map((col) => (
                    <MenuItem onClick={() => handleSelectMenuItem(col.field)}>
                      <Checkbox checked={filterFields.indexOf(col.field) > -1} />
                      <ListItemText>{col.headerName}</ListItemText>
                    </MenuItem>
                  ))}

                {customFilterList &&
                  customFilterList.map((col) => (
                    <MenuItem onClick={() => handleSelectMenuItem(col.field)}>
                      <Checkbox checked={filterFields.indexOf(col.field) > -1} />
                      <ListItemText>{col.headerName}</ListItemText>
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>
          </>
        )}
        {children}
      </Grid>

      <StyledDataGrid
        columns={columns}
        rows={data}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalItems}
        checkboxSelection={selectionEnabled}
        getRowId={(row) => row._id}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          if (onRowSelectionChange) {
            onRowSelectionChange(newRowSelectionModel);
          }
        }}
        rowSelectionModel={rowSelected}
        sx={sx}
        rowHeight={rowHeight ?? 80}
        sortModel={sortingModel}
        onSortModelChange={sortModelChange}
        sortingMode={sortingMode}
      />
    </>
  );
};
export default DataTable;
