import { ApiService } from "./shared/ApiService";
import { RESOURCE_URL } from "../api/api";
import { ContractualData } from "../type/dipendente";

export class ContractualDataService extends ApiService {
  private _apiPath = RESOURCE_URL;
  private _contractualDataApi = `${this._apiPath}/contractual-data/getDataForUser/`;
  constructor() {
    super({});
  }

  getDataForUser(id: string) {
    return super.get<ContractualData>(`${this._contractualDataApi}${id}`);
  }

}