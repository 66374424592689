import { Dispatch, SetStateAction } from "react";

export type CardProps = {
  title: string;
  totalValue?: number;
  notificationValue?: number;
  backgroundColor?: string;
  color?: string;
  notificationColor?: string;
  textValue?: string;
};

export interface IAlert {
  show: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
  onClose?: () => void;
  action?: React.ReactNode;
  dismissible?: boolean;
}

export interface MenuItem {
  _id: string;
  type: string;
  value: string;
}
export interface UserDetails {
  tenant: string;
  id_registry_owner: string;
  _id: string;
  firstname: string;
  surname: string;
  fiscaleCode: string;
  profession: any;
  business_name: string;
  birth_place: string;
  // id_contact: { id_contact_type: string; contact: string ; _id: string}[];
  id_contact: Contact[];
}

export interface Contact {
  _id: string;
  id_contact_type: ContactType;
  code?: string;
  contact: string;
}

interface ContactType {
  _id: string;
  code: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface Employee {
  firstname: string;
  surname: string;
  profession: string;
  email: string;
  image: string;
  phoneNumber: string;
  fiscal_code: string;
  sede: string;
  facebook?: string | undefined;
  linkedin?: string | undefined;
  instagram?: string | undefined;
  twitter?: string | undefined;
}

export interface Address {
  _id: "string";
  id_registry_address_type: "string";
  topon: string;
  street: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
}

export interface SedeNew {
  _id: string;
  business_name: string;
  vat_number: string;
  website?: string | undefined;
  email: string;
  phoneNumber: string;
  facebook?: string | undefined;
  linkedin?: string | undefined;
  instagram?: string | undefined;
  twitter?: string | undefined;
  topon: string;
  street: string;
  number: string;
  postal_code: string;
  city: string;
  province: string;
  state: string;
  address: string;
}

export interface AziendaNew {
  _id: string;
  image: any;
  business_name: string;
  vat_number: string;
  website: string;
  email: string;
  phoneNumber: string;
  facebook?: string | undefined;
  linkedin?: string | undefined;
  instagram?: string | undefined;
  twitter?: string | undefined;
  topon: string;
  street: string;
  number: string;
  postal_code: string;
  city: string;
  province: string;
  state: string;
  address: string;
}

interface RegistryOwner {
  _id: string;
  id_registry_owner: string;
  id_registry_type: string[];
  business_name: string;
  vat_number: string;
  id_contact: string[];
  enabled: boolean;
  id_registry_address: string[];
  tenant: string;
  isManual: boolean;
  associated_card: boolean;
  key_code: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
}

export interface User {
  id_registry_owner: RegistryOwner;
  _id: string;
  id_registry_type: RegistryType[];
  image?: string;
  firstname: string;
  surname: string;
  description: any;
  business_name: string;
  birth_place: string;
  id_contact: Contact[];
  profession: string;
  key_code: string;
}

export interface TabUtentiProps {
  data: any[];
  headerExcludedKeys?: string[];
  path?: string;
  highlightColor?: string;
  isLoading?: boolean;
}

export interface TextHiglightProps {
  content: string | number;
  customColor?: string;
}
export interface Breadcrumb {
  label: string;
  url?: string;
  action?: string;
}
export interface BreadcrumbsComponentProps {
  path: Breadcrumb[];
}

export interface AddButtonProps {
  path: string;
  nome: string;
  onClick?: () => void;
}

export interface SaveButtonProps {
  onClick: () => void;
}

export interface GoogleMapsCardProps {
  address: string;
}

export interface Coordinate {
  lat: number;
  lon: number;
}

export interface TabItem {
  value: number;
  label: string;
}
export interface TabMenuProps {
  value?: number;
  onChange: (newValue: number) => void;
  tabs: TabItem[];
}
export interface GenericType {
  code: string;
  name: string;
  typeValue: string;
}
export interface TabSegnalatore {
  id_registry_type: Array<{
    _id: string;
    code: string;
    description: string;
    createdAt: string;
    updatedAt: string;
  }>;
  _id: string;
  code: string;
  key_code: any;
  createdAt: string;
  state: string;
  identification_data: boolean;
  registry: Registry;
  reporter_name: string;
  business_name: string;
  vat_number: number;
  id_contact: any;
  id_contact_type: any;
  tenant: string;
  view?: boolean;
}

export interface Registry {
  _id: string;
  id_registry_type: RegistryType[];
  firstname: string;
  surname: string;
  fiscal_code: string;
  birth_date: string;
  id_contact: Contact[];
  enabled: boolean;
  birth_place: string;
  id_registry_address: RegistryAddress[];
  createdAt: string;
  updatedAt: string;
}

interface RegistryAddress {
  _id: string;
  id_registry_address_type: string;
  topon: string;
  street: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
  createdAt: string;
  updatedAt: string;
}

interface RegistryType {
  _id: string;
  code: string;
  description: string;
}

export enum EGenericType {
  STUDY_TYPE = "STUDY",
  CONTRACT_TYPE = "CONTRACT_TYPE",
  CCNL_TYPE = "CCNL_TYPE",
  ROLE_TYPE = "ROLE_TYPE",
  QUALIFICATION_TYPE = "QUALIFICATION_TYPE",
  AREA = "AREA",
}

export interface ThemeColorCardProps {
  defaultColor?: string;
  backgroundColor: Dispatch<SetStateAction<string>>;
  openDialog: boolean;
  closeDialog: Dispatch<SetStateAction<boolean>>;
}

export interface AddEditTemplateProps {
  isNew: boolean;
  nameTemplate: string;
}
