import React, { useState } from "react";
import { Image, Table } from "react-bootstrap";
import { Person } from "../../data/mockdata";
import styles from "./TabellaUtenti.module.css";
import { useNavigate } from "react-router-dom";
import TextHighlight from "../tableHiglight/TextHighlight";
import ReactPaginate from "react-paginate";
import { TabUtentiProps } from "../../type/type";

const TabellaUtenti: React.FC<TabUtentiProps> = ({ data, headerExcludedKeys = [], path, highlightColor, isLoading }) => {
  const navigate = useNavigate(); // Hook per la navigazione
  const [currentPage, setCurrentPage] = useState(1);

  // Configurazione della paginazione
  const perPage = 5;
  const offset = currentPage * perPage;
  const pageCount = Math.ceil(data.length / perPage);

  // Funzione per gestire il cambio di pagina
  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  // Se non ci sono dati, viene mostrato un messaggio di "Non ci sono corrispondenze"
  if (data.length === 0) {
    return <div>Non ci sono corrispondenze</div>;
  }

  // Ottiene le chiavi dei dati per creare l'intestazione della tabella, escludendo eventuali chiavi specifiche
  const keys: string[] = Object.keys(data[0]).filter((key) => !headerExcludedKeys.includes(key));

  // Funzione per gestire il click su una riga della tabella
  const handleRowClick = (id: any) => {
    navigate(`/${path}/${id}`);
  };

  // Funzione ausiliaria per renderizzare le celle della tabella
  const renderTableCell = (item: any, key: string, index: number) => {
    const isNomeCell = key === "nome";
    const isFirstCell = index === 0;
    const isLastCell = index === keys.length - 1;

    // Contenuto della cella
    const cellContent = isNomeCell ? (
      <>
        {item.foto && <Image src={item.foto} alt={item.nome} className={styles.profileImage} />}
        {item.nome}
      </>
    ) : (
      item[key as keyof Person]
    );

    if (isLastCell) {
      // Passa il contenuto della cella al componente TextHighlight
      return <TextHighlight content={cellContent} />;
    } else {
      return isFirstCell ? <strong>{cellContent}</strong> : cellContent;
    }
  };

  // Filtra i dati per la pagina corrente
  const paginatedData = data.slice(offset, offset + perPage);

  return (
    <div>
      <Table hover responsive>
        <thead>
          <tr className={styles.tableHead}>
            {keys.map((key) => (
              <th key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr className={styles.tableBody} key={item.id} onDoubleClick={() => handleRowClick(item.id)}>
              {keys.map((key, index) => (
                <td className="align-middle" key={key}>
                  {renderTableCell(item, key, index)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel="Precedente"
        nextLabel="Successivo"
        breakLabel="..."
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
};

export default TabellaUtenti;
