

export async function calculateAge(birthday: Date) {

  let today = new Date();
  let years: number = 0;
  years = (today.getFullYear() - birthday.getFullYear());
  if (today.getMonth() < birthday.getMonth() ||
    today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate()) {
    years--;
  }
  return years;
}