import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import styles from "./NuovaSede.module.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikTouched } from "formik";
import { Spinner } from "react-bootstrap";
import nuovoSedeValidationSchema from "./nuovoSedeValidationSchema";
import { MuiPhone } from "../../../../componets/muiPhone/muiphone";
import { SedeNew } from "../../../../type/type";
import { toast_error, toast_success } from "../../../../utils/custom-toast";
import { RegistryTypeEnum } from "../../../../type/enum/registry-type.enum";
import { ContactTypeEnum } from "../../../../type/enum/contact-type.enum";
import { RegistryAddressTypeEnum } from "../../../../type/enum/registry-address-type.enum";
import MyBreadCrumbs from "../../../../componets/breadCrumbs/MyBreadCrumbs";
import { RegistryApiService } from "../../../../services/RegistryApiService";
import { Util } from "../../../../utils/util";
import { useParams } from "react-router-dom";
import { EAuthRole } from "../../../../type/enum/common.enum";

interface ContactDetails {
  id: string;
  contact: string;
}

interface Dipendente {
  firstname: string;
  surname: string;
  profession: string;
  image: string;
  fiscal_code: string;
  sede: string;
  email: ContactDetails;
  phone: ContactDetails;
  facebook: ContactDetails;
  instagram: ContactDetails;
  linkedin: ContactDetails;
  twitter: ContactDetails;
}

//TODO - Aggiungere Label e relative traduzioni
const NuovaSedeFormik = () => {
  const { t } = useTranslation();
  const initialSedeState: SedeNew = {
    _id: "",
    business_name: "",
    vat_number: "",
    website: "",
    email: "",
    phoneNumber: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    topon: "",
    street: "",
    number: "",
    postal_code: "",
    city: "",
    province: "",
    state: "",
    address: "",
  };

  const [sede, setSede] = useState<SedeNew>(initialSedeState);
  const [idOwner, setIdOwner] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState(false);
  const navigate = useNavigate();
  const { id, tenant } = useParams();
  const role = sessionStorage.getItem("role");
  const schema = nuovoSedeValidationSchema;
  const location = useLocation();

  const pathBreadcrumbs = [
    { label: t("COMMONS.COMPANY"), action: "goBack" },
    { label: "Nuova Sede", url: `${location.pathname}` },
  ];
  const registryApiService = new RegistryApiService();

  const fetchIdRegistryOwner = async () => {
    setIsLoading(true);
    try {
      const response = await registryApiService.infoMe();
      if (response) {
        setIdOwner(
          typeof response.id_registry_owner?.id_registry_owner !== "string"
            ? response.id_registry_owner.id_registry_owner?._id
            : response.id_registry_owner?.id_registry_owner?._id,
        );
      } else {
        console.log("error");
        toast_error(t("OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role === EAuthRole.ADMIN) {
      fetchIdRegistryOwner();
    } else setIdOwner(id!);
  }, []);

  const extractToponAndAddress = (addressString: string) => {
    const addressArray = addressString.trim().split(" ");
    const topon = addressArray[0];
    const address = addressArray.slice(1).join(" ");

    return { topon, address };
  };

  const onSubmit = (values: any) => {
    const { topon, address } = extractToponAndAddress(values.address);

    // const idOwnerReal = role === EAuthRole.SUPERADMIN ? id : idOwner;
    const payload = {
      id_registry_owner: idOwner,
      registry_type: RegistryTypeEnum.SEDE,
      business_name: values.business_name,
      // vat_number: sede.vat_number,
      contact: [
        {
          contact_type: ContactTypeEnum.EMAIL,
          contact: values.email,
        },
        {
          contact_type: ContactTypeEnum.PHONE,
          contact: values.phoneNumber,
        },
        ...(values.website
          ? [
              {
                contact_type: ContactTypeEnum.SITO_WEB,
                contact: Util.normalizeUrl(values.website),
              },
            ]
          : []),
      ].filter(Boolean),
      registry_address: [
        {
          registry_address_type: RegistryAddressTypeEnum.LEGALE,
          latitude: null,
          longitude: null,
          main: true,
          topon: topon,
          street: address,
          number: parseInt(values.number),
          postal_code: values.postal_code,
          city: values.city,
          province: values.province,
          state: values.state,
        },
      ],
    };

    console.log("RequestBody:", payload);
    setPostLoading(true);
    registryApiService
      .createFullRegistry(payload)
      .then((response) => {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        setSede(initialSedeState);
        role === EAuthRole.SUPERADMIN ? navigate(`/azienda/sedi/${idOwner}/${tenant}`) : navigate(`/azienda/sedi`);
      })
      .catch((error) => {
        console.error("Errore durante l'aggiunta del dipendente:", error);
        if (error.response) {
          toast_error(t("COMMONS.OPERATION_ERROR"));
        }
      })
      .finally(() => {
        setPostLoading(false);
      });
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <Typography variant="h1">{t("COMPANY.ADD_HEADQUARTER")}</Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MyBreadCrumbs path={pathBreadcrumbs} />
      </div>

      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={100} />
        </div>
      ) : loadingError || !sede ? (
        <div>Errore nel caricamento delle sedi </div>
      ) : (
        <Formik<SedeNew> initialValues={initialSedeState} onSubmit={onSubmit} validationSchema={schema}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
            const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<SedeNew>]);
            const isSubmitDisabled = !formIsTouched || !isValid;
            return (
              <>
                <ToastContainer position="top-right" autoClose={3000} />

                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ overflowY: "scroll", padding: "0 10px 20px 0 " }}>
                    <Grid item xs={12} sm={12}>
                      {/* prima griglia */}
                      <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        {/* <Grid item xs={12} sm={12}>
                          <div className={styles.title}>{t("FORM.PERSONAL_INFORMATION")}</div>
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="business_name"
                            variant="outlined"
                            color="primary"
                            label="Nome Sede"
                            fullWidth
                            value={values.business_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.business_name && !!errors.business_name}
                            helperText={touched.business_name && errors.business_name ? t(errors.business_name) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <div>Indirizzo della sede</div>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <TextField
                            name="address"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.ADDRESS")}
                            fullWidth
                            value={values.address}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address ? t(errors.address) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            name="number"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.NUMBER")}
                            fullWidth
                            value={values.number}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.number && !!errors.number}
                            helperText={touched.number && errors.number ? t(errors.number) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="postal_code"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.POSTAL_CODE")}
                            fullWidth
                            value={values.postal_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.postal_code && !!errors.postal_code}
                            helperText={touched.postal_code && errors.postal_code ? t(errors.postal_code) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="city"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.CITY")}
                            fullWidth
                            value={values.city}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.city && !!errors.city}
                            helperText={touched.city && errors.city ? t(errors.city) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="province"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.PROVINCE")}
                            fullWidth
                            value={values.province}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.province && !!errors.province}
                            helperText={touched.province && errors.province ? t(errors.province) : " "}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="state"
                            variant="outlined"
                            color="primary"
                            label={t("FORM.COUNTRY")}
                            fullWidth
                            value={values.state}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            required
                            error={touched.state && !!errors.state}
                            helperText={touched.state && errors.state ? t(errors.state) : " "}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <div>{t("FORM.CONTACTS")}</div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="email"
                            variant="outlined"
                            label={t("FORM.EMAIL")}
                            fullWidth
                            value={values.email}
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email ? t(errors.email) : "Inserisci un indirizzo email valido"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiPhone
                            required
                            value={values.phoneNumber}
                            onChange={(value: string) => setFieldValue("phoneNumber", value)}
                            onBlur={() => setFieldTouched("phoneNumber")}
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            helperText={
                              touched.phoneNumber && errors.phoneNumber ? t(errors.phoneNumber) : "Inserisci un numero con prefisso internazionale"
                            }
                            disabled={isLoading}
                            className={styles.textFields}
                            label={t("SIGN_UP.PHONE")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            name="website"
                            variant="outlined"
                            label={t("FORM.WEBSITE")}
                            fullWidth
                            // required
                            value={values.website}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.website && !!errors.website}
                            helperText={
                              touched.website && errors.website ? t(errors.website) : "Inserisci un URL completo, es. https://www.miosito.com."
                            }
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                          <div>{t("FORM.SOCIAL_NETWORKS")}</div>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6}>
                          <TextField
                            name="facebook"
                            variant="outlined"
                            label={t("FORM.FACEBOOK")}
                            fullWidth
                            value={values.facebook}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.facebook && !!errors.facebook}
                            helperText={
                              touched.facebook && errors.facebook ? t(errors.facebook) : "Inserisci un URL completo, es. https://www.miosito.com."
                            }
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6}>
                          <TextField
                            name="facebook"
                            variant="outlined"
                            label={t("FORM.INSTAGRAM")}
                            fullWidth
                            value={values.instagram}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.instagram && !!errors.instagram}
                            helperText={
                              touched.instagram && errors.instagram ? t(errors.instagram) : "Inserisci un URL completo, es. https://www.miosito.com."
                            }
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6}>
                          <TextField
                            name="twitter"
                            variant="outlined"
                            label={t("FORM.TWITTER")}
                            fullWidth
                            value={values.twitter}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.twitter && !!errors.twitter}
                            helperText={
                              touched.twitter && errors.twitter ? t(errors.twitter) : "Inserisci un URL completo, es. https://www.miosito.com."
                            }
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6}>
                          <TextField
                            name="linkedin"
                            variant="outlined"
                            label={t("FORM.LINKEDIN")}
                            fullWidth
                            value={values.linkedin}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            onBlur={handleBlur}
                            error={touched.linkedin && !!errors.linkedin}
                            helperText={
                              touched.linkedin && errors.linkedin ? t(errors.linkedin) : "Inserisci un URL completo, es. https://www.miosito.com."
                            }
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    {/* seconda griglia */}
                    <Grid item xs={1} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button variant="contained" onClick={() => handleSubmit()} disabled={isSubmitDisabled}>
                        {!postLoading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    {/* terza griglia */}
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default NuovaSedeFormik;
