import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Alert, Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";
import LadingPage from "../../landingPage/LandingPage";
import { useTranslation } from "react-i18next";
import { Form, Spinner } from "react-bootstrap";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom";
import { IRequestPasswordPayload } from "../../../type/auth";
import { AuthApiService } from "../../../services/AuthApiService";

export default function RecoverPassword(): React.JSX.Element {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSended, setRequestSended] = useState<boolean>(false);
  const fullLanguageCode = i18n.language;
  const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
  const navigate = useNavigate();
  const authApiService = new AuthApiService();

  const [alert, setAlert] = useState<any>({
    show: false,
    severity: "error",
    message: "",
    // dismissible: true,
    // onClose: () => {
    // 	setAlert({...alert, show: false});
    // },
  });

  const handlePasswordRecovery = async (email: string) => {
    const requestBody: IRequestPasswordPayload = {
      email: email,
    };
    try {
      setLoading(true);
      const response = await authApiService.forgotPassword(requestBody);
      if (response) {
        setAlert({ ...alert, show: true, severity: "success", message: t('COMMONS.OPERATION_SUCCESS') });
        setRequestSended(true);
      } else {
        setAlert({ ...alert, show: true });
      }
    } catch (error: any) {
      setAlert({ ...alert, show: true, message: error });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (email) {
      handlePasswordRecovery(email);
      setEmail("");
    }
  };

  function _renderInput() {
    return (
      <>
        <ToastContainer />
        <Container>
          {!requestSended && (
            <>
              <Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
                {t("LOGIN.RECOVER_PASSWORD")}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "50px", fontWeight: "400", color: "#C0C0C0" }}>
                {t("LOGIN.RECOVER_PASSWORD_DESCRIPTION")}
              </Typography>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.email">
                  {alert.show && (
                    <Alert className="mb-3" severity={alert.severity}>
                      {t(alert.message)}
                    </Alert>
                  )}
                  <TextField name="firstName" label={t("COMMONS.EMAIL")} onChange={(e) => setEmail(e.target.value)} type={"email"} fullWidth />
                </Form.Group>
                <Box marginTop={"20px"}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: "6px", textTransform: "Capitalize" }}
                    fullWidth
                    size={"large"}
                    disabled={loading}
                  >
                    {!loading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}
                  </Button>
                </Box>
              </Form>

              <Box marginTop={"20px"}>
                {/*<Button
              onClick={() => login()}
              sx={{ textTransform: "Capitalize" }}
              variant={"contained"}
              fullWidth
              size={"large"}
            >Login</Button>*/}
              </Box>
              <Grid container className={"mt-5"}>
                <Grid item xs={12} className={"mb-3"} justifyContent={"center"} display={"flex"}>
                  {t("LOGIN.USER_ALREADY_REGISTERED")}
                  <Link href={"/login"} underline="none" color={"black"} marginLeft={"5px"}>
                    {t("LOGIN.TITLE")}
                  </Link>
                </Grid>
              </Grid>
            </>
          )}

          {requestSended && (
            <>
              <Typography variant="h4" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
                {t("LOGIN.RECOVER_PASSWORD_SUCCESSFUL")}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "50px", fontWeight: "400", color: "#C0C0C0" }}>
                {t("LOGIN.RECOVER_PASSWORD_SUCCESSFUL_DESCRIPTION")}
              </Typography>
              <Box marginTop={"20px"}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "6px", textTransform: "Capitalize" }}
                  fullWidth
                  size={"large"}
                  onClick={() => navigate("/login")}
                >
                  {t("COMMONS.GO_TO_LOGIN_PAGE")}
                </Button>
              </Box>
            </>
          )}
        </Container>
      </>
    );
  }

  return (
    <LadingPage>
      <div style={{ width: "100%", height: "100%" }}>
        <div>{_renderInput()}</div>
      </div>
    </LadingPage>
  );
}
