import * as Yup from "yup";
import { boolean, object, string } from "yup";

import YupPassword from "yup-password";
import { intlPhoneRegex } from "../../../utils/regex";

YupPassword(Yup);

const emailSchema = object({
  contact: string()
    .trim()
    // .required("ERRORS.FORM.REQUIRED")
    .email("ERRORS.FORM.INVALID_EMAIL"),
});

const phoneSchema = object({
  contact: string()
    .trim()
    // .required("ERRORS.FORM.REQUIRED")
    .max(13, "ERRORS.FORM.INVALID_PHONE"),
});

const socialSchema = object({
  contact: string().trim(),
});

const addressSchema = object({
  fullAddress: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
  number: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
  postal_code: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
  city: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
  province: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
  state: string().trim(),
  // .required("ERRORS.FORM.REQUIRED"),
});

export default object().shape({
  business_name: string().trim().required("ERRORS.FORM.REQUIRED"),
  email: emailSchema,
  phone: phoneSchema,
  website: socialSchema,
  facebook: socialSchema,
  instagram: socialSchema,
  twitter: socialSchema,
  linkedin: socialSchema,
  address: addressSchema,
  terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
