import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Stack } from "@mui/material";
import Image from "react-bootstrap/Image";
import svg from "../../img/no_template.svg";
import Typography from "@mui/material/Typography";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";
import { EmptySectionProps } from "../../type/props/empty-section.props";

const EmptySection: React.FC<EmptySectionProps> = ({ onClickButton, titleButton, description }) => {
  const { t } = useTranslation();

  return (
    <Container style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "80vh" }} maxWidth={false}>
      <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"} width={"100%"}>
        <Grid item xs={10}>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Image src={svg} />
            <Typography variant={"h4"}>{t("COMMONS.OPS")}</Typography>
            <Typography variant={"h6"}>{t("COMMONS.NO_DATA_DESCRIPTION_1")}</Typography>
            <Typography variant={"subtitle2"} color={"#A9A9A9"} style={{ marginBottom: "10px" }}>
              {description}
            </Typography>
            <Button variant={"contained"} startIcon={<AddOutlinedIcon />} onClick={onClickButton} sx={{ textTransform: "Capitalize" }}>
              {titleButton}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmptySection;
