import { Button, DialogTitle, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { CancelTemplateDialogProps } from "../../../../type/props/card-template-props";

const CancelTemplateDialog: React.FC<CancelTemplateDialogProps> = ({ cancelTemplateDialog, closeCancelTemplateDialog, onCancelTemplate }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={cancelTemplateDialog} onClose={() => closeCancelTemplateDialog(false)}>
        <DialogTitle>
          <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
            {t("TEMPLATE.CANCEL_CHANGES")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography paragraph>{t("TEMPLATE.CANCEL_TEMPLATE_DIALOG_DESCRIPTION_1")}</Typography>
              <Typography paragraph>{t("TEMPLATE.CANCEL_TEMPLATE_DIALOG_DESCRIPTION_2")}</Typography>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
                <Button autoFocus onClick={() => closeCancelTemplateDialog(false)} sx={{ mr: 2, textTransform: "Capitalize" }}>
                  {t("COMMONS.CANCEL")}
                </Button>
                <Button autoFocus onClick={onCancelTemplate} sx={{ mr: 2, textTransform: "Capitalize" }} color={"error"}>
                  {t("COMMONS.CONFIRM")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CancelTemplateDialog;