// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// utils
import { fShortenNumber } from '../../utils/formatNumber';
// components
import Iconify from '../iconify';


// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, bgcolor, color = 'primary', sx, objectName, ...other }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 5,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: bgcolor,
        ...sx,
      }}
      {...other}

    >
      <StyledIcon
        sx={{
          margin: '0 auto 10px auto',
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>
      <Typography variant="h5" >
        {title === "Associare Sede" ? "N° Utenti non associati ad una sede" : `Sede: ${title}`}
      </Typography>
      <div>
        {/* <CardActionArea onClick={event => { navigate("/azienda/sedi/dettaglio-sede/" + objectName) }} > */}
        <Typography variant="h6" sx={{ mb: 1 }}>
          <span style={{ fontWeight: "700" }}>{`${fShortenNumber(total)} `}</span>
          <span style={{ fontWeight: "500" }}>
            {total > 1 ? t("COMMONS.EMPLOYEES") : t("COMMONS.EMPLOYEE")}
          </span>
        </Typography>
        {/* </CardActionArea> */}
      </div>

    </Card >
  );
}
