import { RESOURCE_URL } from "../api/api";
import { ContractualData, HomeData, StudyType } from "../type/dipendente";
import { ApiService } from "./shared/ApiService";

export class DataApiService extends ApiService {
  private _apiPostCreateContractualData = `/contractual-data`;
  private _apiPostCreateHomeData = `/resource`;
  private _apiPostDeleteLinksDocuments = `/resource/deleteLinksDocument`;
  private _apiGetStudyTypes = `/study-type/list/search`;
  private _apiPath = RESOURCE_URL;

  constructor() {
    super({});
  }

  createOrUpdateContractualDataType(contractualData: ContractualData): Promise<ContractualData> {
    const payload = {
      ...contractualData,
    };
    return super.post<ContractualData>(`${this._apiPath}${this._apiPostCreateContractualData}`, payload);
  }

  createOrUpdateInfoData(homeData: HomeData): Promise<HomeData> {
    const payload = {
      ...homeData,
    };
    return super.post<HomeData>(`${this._apiPath}${this._apiPostCreateHomeData}`, payload);
  }

  getStudyTypes(): Promise<StudyType[]> {
    return super.get(`${this._apiPath}${this._apiGetStudyTypes}`);
  }

  deleteLinkDocuments(idLink: string, idData?: string): Promise<boolean> {
    return super.delete<boolean>(`${this._apiPath}${this._apiPostDeleteLinksDocuments}/` + idLink + "/" + idData);
  }
}
