import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import React from "react";
import { Box, Button, Dialog, Grid, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import idConnectLogo from "../../../img/id_connect.svg";
import Image from "react-bootstrap/Image";
import { IDialogProps } from "../../../type/props/common.props";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TODO: Add props da ereditare per apertura dialog
const CompletePurchase: React.FC<IDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToRequestsList = () => {
    navigate('/requests-history');
  }

  const newOrder = () => {
    navigate('/plafond');
  }

  return (
    <>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative"}} style={{ background: 'linear-gradient(90deg,#063e40 0,#309367 41.37%,#a9d59e 100%)' }}>
          <Toolbar>
            <Image src={idConnectLogo} style={{ maxHeight: "100px", maxWidth: "100px" }} />
          </Toolbar>
        </AppBar>
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"} mt={15}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <CheckCircleOutlineRoundedIcon sx={{ fontSize: 230, color: "#309367" }} />
                <Typography variant={"h1"}>{t("SUPPLIER.THANKS_FOR_YOUR_ORDER")}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Box mt={10}>
                <Stack spacing={1} justifyContent="center" alignItems="center">
                  <Box>
                    <Button sx={{ textTransform: "none", mr: 2 }} onClick={goToRequestsList}>{t("SUPPLIER.VIEW_YOUR_ORDERS")}</Button>
                    <Button sx={{ textTransform: "none" }} variant={"contained"} onClick={newOrder}>
                      {t("SUPPLIER.PLACE_NEW_ORDER")}
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
export default CompletePurchase;
