export enum ContactTypeEnum {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  SITO_WEB = "SITO WEB",
  FACEBOOK = "FACEBOOK",
  LINKEDIN = "LINKDN",
  INSTAGRAM = "INSTAGRAM",
  TWITTER = "TWITTER",
  COMPANY_PROFILE = "COMPANY_PROFILE",
  PORTFOLIO = "PORTFOLIO",
}
