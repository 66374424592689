import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";

const Calendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  return (
    <>
      <div className="datetime-picker" style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ marginRight: "10px" }}>
          {selectedDate
            ? selectedDate.toLocaleString("it", { day: "numeric", month: "long", year: "numeric" })
            : new Date().toLocaleString("it", { day: "numeric", month: "long", year: "numeric" })}
        </h2>
        <span className="dropdown-icon" onClick={toggleDatePicker} style={{ cursor: "pointer" }}>
          <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />
        </span>
      </div>
      {isOpen && (
        <DatePicker
          locale={it}
          selected={selectedDate}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Ora"
          dateFormat="dd/MM/yyyy HH:mm"
        />
      )}
    </>
  );
};

export default Calendar;
