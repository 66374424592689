import { ApiService } from "./shared/ApiService";
import { ICardType } from "../type/i-card-type";
import { ID_CONNECT_BE_URL } from "../api/api";
import { CardTypeCounter } from "../type/card-type-counter";
import { ITemplate, ITemplatePayload } from "../type/template";
import { IBulkDeleteRegistryPayload, TApiListSearchParam } from "../type/commons";
import { addMinutes } from "date-fns";

export class CoreApiService extends ApiService {
  private _apiCardTypeCounter = "/card-type-counter";
  private _apiCardType = "/card-type";
  private _apiSendCard = "/registry/sendCards";
  private _apiGetRequest = "/card-request";
  private _apiGetTemplates = "/template";
  private _apiDeleteTemplate = "/template/";
  private _apiCreateShippingAddress = "/registry-shipping-address";
  private _apiPatchShippingAddress = "/registry-shipping-address/";
  private _apiDeleteShippingAddress = "/registry-shipping-address/";
  private _apiGetShippingAddress = "/registry-shipping-address/list/search";
  private _apiCreateTemplate = "/template";
  private _apiUpdateTemplate = "/template/";
  private _apiGetTemplate = "/template/";
  private _apiCreatePublicCompany = "/registry/public/createCompany";
  private _apiDeleteEmployee = "registry/employee/";
  private _apiDeleteWorkplace = "registry/workplace/";
  private _apiDeleteCompany = "registry/organization/";
  private _apiCloseAccount = "registry/closeAccount/";
  private _apiBulkDeleteRegistry = "/registry/bulkDelete";
  private _apiExportAllData = "/registry/export/allData/";

  constructor() {
    super({ baseUrl: ID_CONNECT_BE_URL });
  }

  async getCardType() {
    return this.listSearch<ICardType>(this._apiCardType);
  }

  async getCardTypeCounter() {
    return this.listSearch<CardTypeCounter>(this._apiCardTypeCounter);
  }

  async sendCard(registries: string[], cardTypeCode: string, force: boolean) {
    return this.post<any>(this._apiSendCard, {
      registries,
      // template: template._id,
      type_card_code: cardTypeCode,
      force,
    });
  }

  async sendCardLite(body: any, tenant: string) {
    return this.post<any>(this._apiSendCard, body, {
      headers: { "x-tenant": tenant },
    });
  }

  async getRequest<T>(params?: any) {
    return this.listSearch<T>(this._apiGetRequest, params);
  }

  async getTemplates(params?: TApiListSearchParam<ITemplate>) {
    return this.listSearch<ITemplate>(this._apiGetTemplates, params);
  }

  async deleteTemplate(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await this.delete(this._apiDeleteTemplate + id);
        return resolve(response);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async createShippingAddress(body: any) {
    return this.post<any, any>(this._apiCreateShippingAddress, body);
  }

  async updateShippingAddress(id: string, body: any) {
    return this.patch(this._apiPatchShippingAddress + id, body);
  }

  async deleteShippingAddress(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await this.delete(this._apiDeleteShippingAddress + id);
        return resolve(response);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async getShippingAddress() {
    return this.get<any>(this._apiGetShippingAddress);
  }

  async createTemplate(body: ITemplatePayload) {
    return this.post<any, any>(this._apiCreateTemplate, body);
  }

  async updateTemplate(id: string, body: ITemplatePayload) {
    return this.patch(this._apiUpdateTemplate + id, body);
  }

  async getTemplate(id: string) {
    return this.get<any>(this._apiGetTemplate + id);
  }

  async createPublicCompany(body: any) {
    return this.post<any, any>(this._apiCreatePublicCompany, body);
  }

  async deleteEmployee(id: string): Promise<any> {
    return this.delete<any>(`${this._apiDeleteEmployee}${id}`);
  }

  async deleteWorkplace(id: string): Promise<any> {
    return this.delete<any>(`${this._apiDeleteWorkplace}${id}`);
  }

  async deleteCompany(id: string, headers?: any): Promise<any> {
    return this.delete<any>(`${this._apiDeleteCompany}${id}`, headers);
  }

  async bulkDelete(body: IBulkDeleteRegistryPayload) {
    return this.post<any, any>(this._apiBulkDeleteRegistry, body);
  }

  async closeAccount(id: string) {
    return this.delete<any>(`${this._apiCloseAccount}${id}`);
  }

  // async exportAllData(id: string) {
  //   return this.get<any>(`${this._apiExportAllData}${id}`);
  // }

  async exportAllData(id: string) {
    return this.get<any>(`${this._apiExportAllData}${id}`, { responseType: "blob" });
  }
}
