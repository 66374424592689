import { ApiService } from "./shared/ApiService";
import { MENU_URL } from "../api/api";
import { IMenuItem, TApiListSearchParam } from "../type/commons";

export class MenuApiService extends ApiService {
  private _apiMenuItem = "/menu-item";

  constructor() {
    super({ baseUrl: MENU_URL });
  }

  fetchMenuItem(params?: TApiListSearchParam<IMenuItem>) {
    return this.listSearch<IMenuItem>(this._apiMenuItem, params);
  }
}