import styles from "./components/add-edit-template/AddEditTemplate.module.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Card, CardActions, CardHeader, CircularProgress, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Image from "react-bootstrap/Image";
import svg from "../../img/no_template.svg";
import Box from "@mui/material/Box";
import { toast_error, toast_success } from "../../utils/custom-toast";
import ForeheadCard from "./components/template-card/ForeheadCard";
import CardContent from "@mui/material/CardContent";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteTemplateDialog from "./components/dialog/DeleteTemplateDialog";
import Avatar from "@mui/material/Avatar";
import { ToastContainer } from "react-toastify";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";
import NewNameTemplate from "./components/dialog/NewNameTemplate";
import { DesignForeheadCard } from "../../type/props/card-template-props";
import { CoreApiService } from "../../services/CoreApiService";

const CardTemplate: React.FC = () => {
  const navigate = useNavigate();
  const [nameTemplateDialog, setNameTemplateDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [template, setTemplate] = useState<any[]>();
  const [templateToDelete, setTemplateToDelete] = useState<string>();
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const coreApiService = new CoreApiService();

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await coreApiService.getTemplates({ orderBy: "createdAt" });
      if (data) {
        setTemplate(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addTemplate = () => {
    navigate("/card-template", { state: { name, urlToNavigate: "/card-personalization" } });
  };

  const newName = (event: any) => {
    setName(event.target.value);
  };

  const closeNameTemplateDialog = () => {
    setNameTemplateDialog(false);
    setName(undefined);
  };

  const editTemplate = (obj: any) => {
    navigate("/card-template/" + obj._id, { state: { name: obj.name, urlToNavigate: "/card-personalization" } });
  };

  const deleteSingleTemplate = (obj: any) => {
    setDeleteTemplateDialog(true);
    setTemplateToDelete(obj._id);
  };

  const deleteTemplate = () => {
    coreApiService
      .deleteTemplate(templateToDelete ?? "")
      .then((response) => {
        toast_success(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          toast_error(error.response.data.message);
        }
      })
      .finally(async () => {
        setDeleteTemplateDialog(false);
        await fetchData();
      });
  };

  const foreheadCardDesign: DesignForeheadCard = {
    firstRow: {
      height: "55px",
    },
    lastRow: {
      height: "55px",
    },
    logo: {
      box: {
        minHeight: "none",
        maxHeight: "80px",
        maxWidth: "300px",
      },
      iconFontSize: 22,
      avatarHeight: "50px",
      avatarWidth: "50px",
    },
    card: {
      width: "450px",
      height: "220px",
    },
  };

  const templateCard = (object: any) => {
    return (
      <Grid item xs={4}>
        <Card elevation={3} sx={{ mb: 4 }}>
          <CardHeader
            title={object.name}
            subheader={`${new Date(object.updatedAt).toDateString()} ${new Date(object.updatedAt).toTimeString().split("GMT")[0]}`}
            avatar={<Avatar sx={{ bgcolor: object.background_color }} aria-label="recipe" src={object.logo} />}
          />
          <CardContent>
            <Box
              minHeight={"350px"}
              minWidth={"500px"}
              bgcolor={"#E5E4E2"}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
              borderRadius={2}
            >
              <ForeheadCard color={object.background_color} image={object.logo} design={foreheadCardDesign} />
            </Box>
          </CardContent>
          <CardActions>
            <Button
              sx={{ textTransform: "Capitalize" }}
              color={"error"}
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => deleteSingleTemplate(object)}
            >
              {t("COMMONS.DELETE")}
            </Button>
            <Button sx={{ textTransform: "Capitalize" }} startIcon={<EditOutlinedIcon />} onClick={() => editTemplate(object)}>
              {t("COMMONS.UPDATE")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  // aggiungere skeleton
  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <h1 className={styles.title}>Template</h1>
      {!loading && template != undefined && template.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem 0 2rem 0",
          }}
        >
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            {/*<SearchBar onSearch={handleSearch} placeholder="Cerca" />*/}
          </Box>
          <Box sx={{ display: "flex", gap: "0.7rem" }}>
            <Button
              sx={{ textTransform: "Capitalize" }}
              variant={"contained"}
              startIcon={<AddOutlinedIcon />}
              onClick={() => setNameTemplateDialog(true)}
            >
              {t("TEMPLATE.CREATE_NEW_TEMPLATE")}
            </Button>
          </Box>
        </Box>
      )}
      <br />
      <br />
      {loading && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={50} />
        </div>
      )}
      {!loading && template != undefined && template.length > 0 && (
        <Grid container spacing={2}>
          {template.map(function (object, i) {
            return templateCard(object);
          })}
        </Grid>
      )}
      {!loading && (!template || template.length === 0) && (
        <Container style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "80vh" }} maxWidth={false}>
          <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"} width={"100%"}>
            <Grid item xs={10}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Image src={svg} />
                <Typography variant={"h4"}>{t("COMMONS.OPS")}</Typography>
                <Typography variant={"h6"}>{t("COMMONS.NO_DATA_DESCRIPTION_1")}</Typography>
                <Typography variant={"subtitle2"} color={"#A9A9A9"} style={{ marginBottom: "10px" }}>
                  {t("TEMPLATE.NO_TEMPLATE_DESCRIPTION_2")}
                </Typography>
                <Button
                  sx={{ textTransform: "Capitalize" }}
                  variant={"contained"}
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => setNameTemplateDialog(true)}
                >
                  {t("TEMPLATE.CREATE_NEW_TEMPLATE")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}

      <DeleteTemplateDialog
        deleteTemplateDialog={deleteTemplateDialog}
        closeDeleteTemplateDialog={setDeleteTemplateDialog}
        onDeleteTemplate={deleteTemplate}
        isNew={false}
      />

      <NewNameTemplate
        open={nameTemplateDialog}
        onClose={() => setNameTemplateDialog(false)}
        onConfirm={addTemplate}
        onChangeName={(name) => setName(name)}
      />
    </>
  );
};
export default CardTemplate;
