import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast_error, toast_success } from "../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Pagination,
} from "@mui/material";
import styles from "../tabelle/TabellaListaAziende.module.css";
import { useTranslation } from "react-i18next";
import { RegistryApiService } from "../../services/RegistryApiService";
import { EAuthRole, ERegistryType } from "../../type/enum/common.enum";
import { IRegistry } from "../../type/commons";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import PasswordDialog from "../dialog/PasswordDialog";
import { CoreApiService } from "../../services/CoreApiService";
import ConfirmDialog from "../dialog/ConfirmDialog";

interface Props {
  searchTerm?: string;
  idOwner?: string;
}

interface Sede {
  id_registry_owner: string;
  _id: string;
  id_registry_type: RegistryType[];
  business_name: string;
  vat_number: string;
  id_contact: Contact[];
  enabled: boolean;
  id_registry_address: RegistryAddress[];
  key_code: string;
}

interface Contact {
  _id: string;
  id_contact_type: string;
  contact: string;
}

interface RegistryType {
  _id: string;
  code: string;
  description: string;
}

interface RegistryAddress {
  _id: string;
  id_registry_address_type: string;
  topon: string;
  street: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
}

const TabellaSediGet: React.FC<Props> = ({ searchTerm, idOwner }) => {
  const [sedi, setSedi] = useState<IRegistry[]>([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dataHasLoaded, setDataHasLoaded] = useState(false);
  const { t } = useTranslation();
  const registryApiService = new RegistryApiService();
  const coreApiService = new CoreApiService();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const { id } = useParams();
  const role = sessionStorage.getItem("role");
  const itemsPerPage = 10;

  const countData = async (): Promise<void> => {
    const params: any = {
      registry_type: ERegistryType.SEDE,
      searchFullText: searchTerm,
      searchFields: ["firstname", "surname"],
    };

    if (role === EAuthRole.SUPERADMIN) {
      params.id_registry_owner = id;
    }
    const itemCount = await registryApiService.countRegistries(params);
    setTotalItems(itemCount);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params: any = {
        page: currentPage,
        limit: itemsPerPage,
        orderBy: "createdAt",
        searchFullText: searchTerm,
        searchField: "business_name",
        registry_type: ERegistryType.SEDE,
      };

      if (role === EAuthRole.SUPERADMIN) {
        params.id_registry_owner = id;
      }

      const data = await registryApiService.fetchAllRegistry(params);

      if (data) {
        setSedi(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setIsLoading(false);
      setDataHasLoaded(true);
    }
  };

  const fetch = async () => {
    await countData();
    if (!searchTerm || searchTerm.length >= 3) {
      await fetchData();
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const handleDelete = async (id: string) => {
    try {
      const response = await coreApiService.deleteWorkplace(id);

      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        handleClosePasswordDialog();
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setOpenConfirmModal(false);
      fetchData();
    }
  };

  const handleRowClick = (id: string) => {
    navigate(`/azienda/sedi/dettaglio-sede/${id}`);
  };

  const handleOpenConfirm = (id: string) => {
    setOpenConfirmModal(true);
    setSelectedId(id);
  };

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenConfirmModal(false);
    setOpenDeleteModal(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenDeleteModal(false);
  };

  const renderTableBodyContent = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={12} rowSpan={12} className={styles.loadingRow}>
            <CircularProgress size={70} />
          </td>
        </tr>
      );
    }

    if (dataHasLoaded && sedi.length === 0) {
      return (
        <tr>
          <td colSpan={5}>{t("COMMONS.NO_DATA")}</td>
        </tr>
      );
    }

    return sedi.map((sede) => (
      <tr key={sede._id} onDoubleClick={() => handleRowClick(sede._id)}>
        <td className={styles.rowStyleFirstElement}>{sede.business_name}</td>
        <td className={styles.rowStyle}>
          {sede.id_registry_address && sede.id_registry_address.length > 0 ? (
            <>
              {sede.id_registry_address[0].topon} {sede.id_registry_address[0].street} {sede.id_registry_address[0].number}
              {", "}
              {sede.id_registry_address[0].province}
            </>
          ) : (
            ""
          )}
        </td>
        <td className={styles.rowStyle}>
          {sede.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact}
        </td>
        <td className={styles.rowStyle}>
          {sede.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "PHONE")?.contact}
        </td>
        <td>
          <div style={{ display: "flex" }}>
            {role === EAuthRole.ADMIN && (
              <div>
                <IconButton onClick={() => handleOpenConfirm(sede._id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
            <div>
              <IconButton onClick={() => handleRowClick(sede._id)} color="default">
                <ModeIcon />
              </IconButton>
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <ToastContainer />
      <Table hover>
        <thead className={styles.thead}>
          <ConfirmDialog
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            onOpen={() => setOpenConfirmModal(true)}
            onClose={handleCloseConfirm}
            onConfirm={handleOpenPasswordDialog}
            message={t("DIALOG_DELETE.HEADQUARTER")}
          />
          <PasswordDialog
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onOpen={handleOpenPasswordDialog}
            onClose={handleClosePasswordDialog}
            onConfirm={() => handleDelete(selectedId)}
          />
          {!dataHasLoaded && isLoading ? (
            <tr>
              <th className={styles.rowStyle}></th>
            </tr>
          ) : (
            <tr>
              <th className={styles.rowStyle}>{t("COMMONS.HEADQUARTERS")}</th>
              <th className={styles.rowStyle}>{t("FORM.ADDRESS")}</th>
              <th className={styles.rowStyle}>Email</th>
              <th className={styles.rowStyle}>{t("FORM.PHONE")}</th>
              <th className={styles.rowStyle}></th>
            </tr>
          )}
        </thead>
        <tbody>{renderTableBodyContent()}</tbody>
      </Table>
      {!isLoading && dataHasLoaded && sedi.length > 0 && totalItems > itemsPerPage ? (
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          shape="rounded"
          showFirstButton
        />
      ) : null}
    </div>
  );
};

export default TabellaSediGet;
