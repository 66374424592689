import { RegistryRoleEnum } from "./registry-role.enum";

export enum EAuthSessionData {
  ACCESS_TOKEN = "aToken",
  REFRESH_TOKEN = "rToken",
  AUTH_DATA = "auth",
  ROLE = "role",
  EXPIRED_SESSION = "expiredSession",
  USERNAME = "username",
}

export type TAuthRole = RegistryRoleEnum.ADMIN | RegistryRoleEnum.SUPERADMIN | RegistryRoleEnum.BASIC | string;
