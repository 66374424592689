import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShop } from "@fortawesome/free-solid-svg-icons/faShop";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import billingInfoInitialValues from "./billingInfo/billingInfoInitialValues";
import { IBillingInfo, ICreateCheckoutPayload } from "../../../type/payments";
import FormFatturazione from "./FormFatturazione";

import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast_error } from "../../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import { PaymentApiService } from "../../../services/PaymentApiService";

const DettaglioPagamenti = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { productInfo } = location.state;
  const [open, setOpen] = useState<boolean>(false);
  const [bInfoInitialValues, setBInfoInitialValues] = useState<IBillingInfo>(billingInfoInitialValues);
  const [haveBillingInfo, setHaveBillingInfo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const paymentApiService = new PaymentApiService();
  const handleHaveBillingInfo = (haveBillingInfo: boolean): void => {
    setHaveBillingInfo(haveBillingInfo);
  };
  const { priceID } = useParams();

  const skeletonComponent = () => {
    return (
      <Box marginY={1}>
        {[4, 2, 8, 7, 3, 10, 7, 8, 9, 4].map((item, key) => (
          <Skeleton key={key} height={60} width={item * 10 + "%"} />
        ))}
      </Box>
    );
  };

  const fetchBillingInfo = async () => {
    try {
      const data = await paymentApiService.getCustomerMe();
      if (data) {
        setBInfoInitialValues(data ?? billingInfoInitialValues);
        setHaveBillingInfo(true);
      } else {
        setHaveBillingInfo(false);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  const handleCheckout = async () => {
    const payload: ICreateCheckoutPayload = {
      // productId: productInfo.selectedProduct,
      priceId: productInfo.selectedPrice,
    };
    try {
      const data: any = await paymentApiService.createCheckout(payload);
      if (data) {
        window.open(data, "_blank");
        setOpen(false);
      } else {
        console.log(data);
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} />
      <Dialog fullWidth open={open} maxWidth="xs">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <IconButton
            aria-label="Example"
            style={{
              margin: "15px 0",
              color: "var(--white)",
              background: "var(--gray-900)",
              borderRadius: "50%",
              width: "85px",
              height: "85px",
            }}
          >
            <FontAwesomeIcon icon={faShop} size="2xl" />
          </IconButton>
          <DialogTitle sx={{ padding: "0", marginBottom: "10px" }}>{t("BILLING.DIALOG.TITLE")}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ padding: "0", marginBottom: "10px" }}>{t("BILLING.DIALOG.SUBTITLE")}</DialogContent>

          <DialogActions sx={{ justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                // gap: "3rem",
                marginBottom: "10px",
              }}
            >
              <Button onClick={handleClose} style={{ textDecoration: "none", cursor: "pointer", marginRight: "10px" }} sx={{ textTransform: "none" }}>
                {t("COMMONS.BACK")}
              </Button>
              <Button color="primary" variant="contained" onClick={handleCheckout} sx={{ textTransform: "none" }}>
                {t("BILLING.DIALOG.CONFIRM_BUY")}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      <Typography variant="h1" style={{ marginBottom: "10px" }}>
        {t("BILLING.CARD_BILLING.TITLE")}
      </Typography>
      <Box sx={{ marginBottom: "2%" }}>
        {isLoading && skeletonComponent()}
        {!isLoading && (
          <FormFatturazione
            initialValues={bInfoInitialValues}
            handleHaveBillingInfo={handleHaveBillingInfo}
            haveBilling={haveBillingInfo}
            setOpen={setOpen}
          />
        )}
      </Box>
    </>
  );
};

export default DettaglioPagamenti;
