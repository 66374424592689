import { ApiService } from "./shared/ApiService";
import { PAYMENT_URL } from "../api/api";
import { IProduct, IPurchasedProduct, TApiListSearchParam } from "../type/commons";
import { IBillingInfo, ICreateCheckoutPayload } from "../type/payments";

export class PaymentApiService extends ApiService {
  private _apiProduct = "/product";
  private _apiPurchasedProduct = "/purchased-product";
  private _apiCustomerMe = "/customer/me";
  private _apiCreateCheckout = "/customer/createCheckout";

  constructor() {
    super({ baseUrl: PAYMENT_URL });
  }

  async fetchAllProduct(params?: TApiListSearchParam<IProduct>) {
    return this.listSearch<IProduct>(this._apiProduct);
  }

  async fetchPurchasedProduct(params?: TApiListSearchParam<IPurchasedProduct>) {
    return this.listSearch<IPurchasedProduct>(this._apiPurchasedProduct);
  }

  async getCustomerMe() {
    return this.get<IBillingInfo>(this._apiCustomerMe);
  }

  async createCheckout(body: ICreateCheckoutPayload) {
    return this.post(this._apiCreateCheckout, body);
  }

  async updateCustomerMe(body: IBillingInfo) {
    return this.patch(this._apiCustomerMe, body);
  }

  async createCustomerMe(body: IBillingInfo) {
    return this.post<any, any>(this._apiCustomerMe, body);
  }
}