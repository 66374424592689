import { Button, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DeleteTemplateDialogProps } from "../../../../type/props/card-template-props";

const DeleteTemplateDialog: React.FC<DeleteTemplateDialogProps> = ({ deleteTemplateDialog, closeDeleteTemplateDialog, onDeleteTemplate, isNew }) => {
  const { t } = useTranslation();

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Dialog open={deleteTemplateDialog} onClose={() => closeDeleteTemplateDialog(false)}>
        <DialogTitle>
          {!isNew && (
            <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
              {t("TEMPLATE.DELETE_TEMPLATE")}
            </Box>
          )}
          {isNew && (
            <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
              {t("TEMPLATE.CANCEL_TEMPLATE")}
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          {isNew && (
            <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
              <Typography paragraph>{t("TEMPLATE.DESCRIPTION_CANCEL_TEMPLATE_DIALOG")}</Typography>
            </Box>
          )}
          {!isNew && (
            <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
              <Typography paragraph>{t("TEMPLATE.DESCRIPTION_DELETE_TEMPLATE_DIALOG")}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
                <Button autoFocus onClick={() => closeDeleteTemplateDialog(false)} sx={{ mr: 2 }}>
                  {t("COMMONS.CANCEL")}
                </Button>
                <Button autoFocus onClick={onDeleteTemplate} variant={"contained"}>
                  {t("COMMONS.CONFIRM")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DeleteTemplateDialog;