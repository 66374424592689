import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import styles from "./Acquisti.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectSize from "./steps/selectSize/SelectSize";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toast_error } from "../../utils/custom-toast";
import SelectProduct from "./steps/selectProduct/SelectProduct";
import { convertDate, getCurrency, getGradientByDescription } from "./utils/commonFunctions";
import i18n from "../../i18n";
import { Util } from "../../utils/util";
import EmptySection from "../../componets/empty-section/EmptySection";
import { PaymentApiService } from "../../services/PaymentApiService";
import { IPurchasedProduct } from "../../type/commons";

const StoricoAcquisti = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const steps = [t("PURCHASE.PRODUCT"), t("PURCHASE.PRICE")];
  const [currentStep, setCurrentStep] = useState<number>(0);
  // PRODUCTS
  const [productList, setProductList] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [loadingProductList, setLoadingProductList] = useState<boolean>(false);
  // PRICES
  const [prices, setPrices] = useState<any>([]);
  const [selectedPrice, setSelectedPrice] = useState<any>([]);

  const [loadingPrices, setLoadingPrices] = useState<boolean>(false);
  const [acquisti, setAcquisti] = useState<IPurchasedProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productInfo, setProductInfo] = useState<any>({
    selectedProduct: "",
    selectedPrice: "",
  });

  const fullLanguageCode = i18n.language;
  const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
  const paymentApiService = new PaymentApiService();

  const fetchProductList = async () => {
    setLoadingProductList(true);
    try {
      const data = await paymentApiService.fetchAllProduct();
      if (data) {
        setProductList(data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingProductList(false);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const stepContents = [
    <SelectProduct
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      setProductInfo={setProductInfo}
      productList={productList}
      loadingProductList={loadingProductList}
      setPrices={setPrices}
    />,
    <SelectSize
      selectedProduct={selectedProduct}
      setSelectedPrice={setSelectedPrice}
      selectedPrice={selectedPrice}
      setProductInfo={setProductInfo}
      productInfo={productInfo}
      prices={prices}
      loadingPrices={loadingPrices}
    />,
  ];

  const fetchAcquisti = async () => {
    setIsLoading(true);
    try {
      const data = await paymentApiService.fetchPurchasedProduct();
      if (data) {
        setAcquisti(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      toast_error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAcquisti();
  }, []);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === steps.length - 1) {
      navigate(`/acquisti/riepilogo`, { state: { productInfo } });
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentStep(0);
  };

  const renderDialog = () => {
    return (
      <Dialog fullWidth open={open} maxWidth="md" onClose={handleClose}>
        <DialogTitle>{t("PURCHASE.NEW_PURCHASE_DIALOG.TITLE")}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div" className={styles.dialogSubtitle}>
            {currentStep === 0 ? t("PURCHASE.NEW_PURCHASE_DIALOG.PRODUCT") : t("PURCHASE.NEW_PURCHASE_DIALOG.QUANTITY")}
          </DialogContentText>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {stepContents[currentStep]}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {currentStep === 0 && (
            <>
              <Button color="primary" variant="outlined" onClick={handleClose} sx={{ textTransform: "none" }}>
                {t("COMMONS.CLOSE")}
              </Button>
              <Button color="primary" variant="contained" onClick={handleNext} disabled={selectedProduct.length === 0} sx={{ textTransform: "none" }}>
                {t("COMMONS.CONTINUE")}
              </Button>
            </>
          )}
          {currentStep === 1 && (
            <>
              <Button color="primary" variant="outlined" onClick={handleBack} sx={{ textTransform: "none" }}>
                {t("COMMONS.BACK")}
              </Button>
              <Button color="primary" variant="contained" onClick={handleNext} disabled={selectedPrice.length === 0} sx={{ textTransform: "none" }}>
                {t("COMMONS.CONTINUE")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Typography variant={"h1"}>{t("PURCHASE.TITLE")}</Typography>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
          <CircularProgress size={100} />
        </div>
      ) : acquisti.length === 0 ? (
        <>
          <EmptySection onClickButton={handleOpen} titleButton={t("PURCHASE.NEW_PURCHASE")} description={t("PURCHASE.NO_PURCHASES")} />
          {renderDialog()}
        </>
      ) : (
        !isLoading &&
        acquisti.length > 0 && (
          <div className={styles.subcontainer}>
            {renderDialog()}
            <h5 className={styles.subtitle}>{t("PURCHASE.YOUR_PURCHASES")}</h5>
            <Button variant="contained" startIcon={<AddOutlinedIcon sx={{ color: "white" }} />} onClick={handleOpen} sx={{ textTransform: "none" }}>
              {t("PURCHASE.NEW_PURCHASE")}
            </Button>
          </div>
        )
      )}
      {acquisti.length > 0 &&
        acquisti.map((acquisto: any) => (
          <Card variant="outlined" sx={{ margin: "20px 0" }}>
            <Grid container spacing={0} sx={{}}>
              {/* Colonna di Sinistra */}
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  backgroundColor: "var( --primary-500)",
                }}
              >
                <div className={styles.cardImage} style={{ background: getGradientByDescription(acquisto.product.description) }} />
              </Grid>

              {/* Colonna di Destra*/}
              <Grid item xs={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "20px", lineHeight: 1.2 }}>
                <Grid container spacing={0}>
                  {/* Div Rosso */}
                  <Grid item xs={12}>
                    <div style={{ fontSize: "2rem", fontWeight: "700", lineHeight: 0.9 }}>{acquisto.product.title}</div>
                  </Grid>
                  {/* Div Rosa */}
                  <Grid item xs={12}>
                    <div style={{ fontSize: "1.5rem" }}>{`x ${acquisto.price.max_unit_quantity} ${acquisto.product.description.toLowerCase()}`}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ fontSize: "0.8rem", fontWeight: "700" }}>{convertDate(acquisto.createdAt, languageCode)}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <div style={{ fontSize: "2rem" }}>{`${Util.formatStripePrice(acquisto.price.amount)} ${getCurrency(
                      acquisto.price.currency,
                    )}`}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        ))}
    </>
  );
};

export default StoricoAcquisti;
