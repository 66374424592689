import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import styles from "./AddEditTemplate.module.css";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import "react-color-palette/css";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import { Button, Chip, CircularProgress, DialogTitle, Stack, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Image from "react-bootstrap/Image";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import AddThemeColorCard from "../dialog/AddThemeColorCard";
import BackCard from "../template-card/BackCard";
import ForeheadCard from "../template-card/ForeheadCard";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PreviewCardDialog from "../dialog/PreviewCardDialog";
import DeleteTemplateDialog from "../dialog/DeleteTemplateDialog";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast_error, toast_success } from "../../../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import { toPng } from "html-to-image";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelTemplateDialog from "../dialog/CancelTemplateDialog";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import ColorizeOutlinedIcon from "@mui/icons-material/ColorizeOutlined";
import { useTranslation } from "react-i18next";
import idLogo from "../../../../img/logoIDTotalBlack.svg";
import { DesignBackCard, DesignForeheadCard } from "../../../../type/props/card-template-props";
import { CoreApiService } from "../../../../services/CoreApiService";
import { ITemplatePayload } from "../../../../type/template";

const AddEditTemplate: React.FC = () => {
  const [color, setColor] = useState<string>("#000000");
  const [value, setValue] = React.useState(0);
  const [openColorDialog, setOpenColorDialog] = useState<boolean>(false);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [textStyle, setTextStyle] = useState("Light");
  const [textColor, setTextColor] = useState("#000000");
  const [image, setImage] = useState<string | undefined>();
  const [imageFile, setImageFile] = useState<FileList | undefined>();
  const [viewDeleteIcon, setViewDeleteIcon] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState<boolean>(false);
  const [previewDialog, setPreviewDialog] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string>();
  const [template, setTemplate] = useState<any>();
  const { state } = useLocation();
  const name = state.name;
  const urlToNavigate = state.urlToNavigate;
  const [nameTemplate, setNameTemplate] = useState<string | null>(name);
  const [cancelTemplateDialog, setCancelTemplateDialog] = useState<boolean>(false);
  const [viewThemeColor2, setViewThemeColor2] = useState<boolean>(false);
  const [viewThemeColor, setViewThemeColor] = useState<boolean>(false);
  const [viewThemeTextColor, setViewThemeTextColor] = useState<boolean>(false);
  const [viewSelectColor, setViewSelectColor] = useState<boolean>(false);
  const [imageChipSelected, setImageChipSelected] = useState<boolean>(false);
  const [colorChipSelected, setColorChipSelected] = useState<boolean>(false);
  const [color1ChipSelected, setColor1ChipSelected] = useState<boolean>(false);
  const [color2ChipSelected, setColor2ChipSelected] = useState<boolean>(true);
  const [textColorChipSelected, setTextColorChipSelected] = useState<boolean>(true);
  const [color2, setColor2] = useState<string>("#FFFFFF");
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isNew = !id;
  const navigate = useNavigate();
  const coreApiService = new CoreApiService();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await coreApiService.getTemplate(id ?? "");
      if (response) {
        setTemplate(response);
        setColor(response.background_color);
        setColor2(response.background_color_2);
        setTextColor(response.text_color);
        setImage(response.logo);
        setNameTemplate(response.name);
      } else {
        toast_error(t("COMMONS.OPERATION_SUCCESS"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNew) {
      fetchData();
    }
  }, []);

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageChipSelected(false);
  };

  const deleteImage = () => {
    setImage(undefined);
    setImageBase64(undefined);
  };

  const handleConfirm = () => {
    setOpenColorDialog(false);
  };

  const handleConfirmImage = () => {
    setOpenImageDialog(false);
    setImageChipSelected(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const uploadImage = (e: any) => {
    const fileList = e.target.files;
    if (fileList) {
      const files = URL.createObjectURL(fileList[0]);

      const file = fileList[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setImageBase64(reader.result.toString());
          }
        };
        reader.readAsDataURL(file);
      } else {
        setImageBase64("");
      }
      setImage(files);
    }
  };

  const cancelOrDeleteTemplate = () => {
    if (!isNew) {
      coreApiService
        .deleteTemplate(id)
        .then((response) => {
          toast_success(t("COMMONS.OPERATION_SUCCESS"));
        })
        .catch((error) => {
          if (error) {
            toast_error(error);
          }
        })
        .finally(async () => {
          setDeleteTemplateDialog(false);
          navigate(urlToNavigate);
        });
    } else {
      setDeleteTemplateDialog(false);
      navigate(urlToNavigate);
    }
  };

  const openColorThemeSelector = () => {
    setViewSelectColor(!viewSelectColor);
    setViewThemeColor(!viewThemeColor);
    setColorChipSelected(!colorChipSelected);
    setColor1ChipSelected(!color1ChipSelected);
    setColor2ChipSelected(!color2ChipSelected);
    setTextColorChipSelected(!textColorChipSelected);
  };

  const openColor1 = () => {
    setViewThemeColor(!viewThemeColor);
    setViewThemeColor2(false);
    setViewThemeTextColor(false);
    setColor1ChipSelected(!color1ChipSelected);
    setColor2ChipSelected(false);
    setTextColorChipSelected(false);
  };

  const openColor2 = () => {
    setViewThemeColor(false);
    setViewThemeColor2(!viewThemeColor2);
    setViewThemeTextColor(false);
    setColor2ChipSelected(!color2ChipSelected);
    setColor1ChipSelected(false);
    setTextColorChipSelected(false);
  };

  const openTextColor = () => {
    setViewThemeColor(false);
    setViewThemeColor2(false);
    setViewThemeTextColor(!viewThemeTextColor);
    setTextColorChipSelected(!textColorChipSelected);
    setColor1ChipSelected(false);
    setColor2ChipSelected(false);
  };

  const saveOrUpdateTemplate = async () => {
    const foreheadHtmlElement = document.getElementById("forehead");
    const backHtmlElement = document.getElementById("back");
    let forehead = "";
    let back = "";

    if (foreheadHtmlElement != null) {
      forehead = await toPng(foreheadHtmlElement);
      if (forehead === null) {
        forehead = "image not valid";
      }
    }

    if (backHtmlElement != null) {
      back = await toPng(backHtmlElement);
      if (back === null) {
        back = "image not valid";
      }
    }

    const payload: ITemplatePayload = {
      background_color: color,
      background_color_2: color2,
      text_color: textColor,
      logo: imageBase64 ?? "",
      forehead_photo: forehead,
      back_photo: back,
      name: nameTemplate ?? "",
    };

    try {
      let response;
      if (isNew) response = await coreApiService.createTemplate(payload);
      else {
        if (imageBase64) {
          payload.logo = imageBase64;
        } else {
          payload.logo = template.logo;
        }
        response = await coreApiService.updateTemplate(id, payload);
      }
      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        returnToCardTemplateBasePage();
      } else {
        console.log("err", response);
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    }

    //navigate("/card-personalization");
  };

  const cancelChanges = async () => {
    // in case new reset state, also get data
    if (!isNew) {
      await fetchData();
      setCancelTemplateDialog(false);
    } else {
      console.log("reset");
      // aggiungere reset modifiche
      setCancelTemplateDialog(false);
    }
  };

  const returnToCardTemplateBasePage = () => {
    if (urlToNavigate == "/new-request") {
      sessionStorage.setItem("defaultNameTemplate", nameTemplate ?? "");
    }
    navigate(urlToNavigate, { state: { ...state } });
  };

  const selectChipImage = () => {
    setOpenImageDialog(true);
    setImageChipSelected(true);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const backCardDesign: DesignBackCard = {
    content: {
      email: {
        marginBottom: "2px",
        paddingLeft: "10px",
        iconFontSize: 20,
        textVariant: "subtitle1",
      },
      grid: {
        marginTop: "5px",
        marginBottom: "5px",
      },
      name: {
        marginBottom: "1px",
        paddingLeft: "2px",
        iconFontSize: 20,
        textVariant: "subtitle1",
      },
      phone: {
        marginBottom: "2px",
        paddingLeft: "10px",
        iconFontSize: 20,
        textVariant: "subtitle1",
      },
      subname: {
        marginBottom: "10px",
        paddingLeft: "2px",
        textVariant: "subtitle1",
      },
      web: {
        marginBottom: "2px",
        paddingLeft: "10px",
        iconFontSize: 20,
        textVariant: "subtitle1",
      },
    },
    footer: {
      logo: {
        minWidth: "1.3rem",
        minHeight: "1rem",
        margin: "0.25rem",
      },
    },
    imageLogo: {
      grid: {
        minHeight: "50px",
        maxHeight: "50px",
        maxWidth: "50px",
      },
      image: {
        maxHeight: "50px",
        maxWidth: "50px",
      },
    },
    imageQr: {
      qr: {
        maxHeight: "90px",
        maxWidth: "90px",
      },
      grid: {
        top: 30,
        right: 20,
      },
    },
    card: {
      width: "420px",
      height: "260px",
    },
  };

  const foreheadCardDesign: DesignForeheadCard = {
    firstRow: {
      height: "74px",
    },
    lastRow: {
      height: "74px",
    },
    logo: {
      box: {
        maxHeight: "80px",
        maxWidth: "300px",
      },
      iconFontSize: 22,
      avatarHeight: "50px",
      avatarWidth: "50px",
    },
    card: {
      width: "420px",
      height: "260px",
    },
  };

  const goBack = () => {
    navigate(urlToNavigate);
  };

  return (
    <>
      {loading && (!template || template.length === 0) && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={50} />
        </div>
      )}
      {!loading}
      <ToastContainer position="top-right" autoClose={3000} />
      <AppBar position="static" color={"inherit"} elevation={0}>
        <Toolbar>
          <div className={styles.headerContainer}>
            <Image src={idLogo} style={{ maxWidth: "30px", maxHeight: "30px", marginLeft: "15px", marginRight: "10px" }} />
            <div style={{ color: "black" }}>connect</div>
          </div>
          <Typography sx={{ marginLeft: "50px", fontWeight: 800 }}>{t("TEMPLATE.NEW_TEMPLATE")}</Typography>
          <Typography sx={{ marginLeft: "20px" }}>{nameTemplate}</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button key={"return select card"} sx={{ textTransform: "Capitalize", mr: 2 }} onClick={goBack} startIcon={<ArrowBackIcon />}>
              {t("TEMPLATE.GO_BACK")}
            </Button>
            {!isNew && (
              <Button
                key={"delete"}
                color={"error"}
                sx={{ textTransform: "Capitalize", mr: 2 }}
                onClick={() => setDeleteTemplateDialog(true)}
                startIcon={<DeleteOutlineOutlinedIcon />}
              >
                {t("TEMPLATE.DELETE_TEMPLATE")}
              </Button>
            )}
            <Button
              key={"cancel"}
              sx={{ textTransform: "Capitalize", mr: 2 }}
              onClick={() => setCancelTemplateDialog(true)}
              startIcon={<CancelOutlinedIcon />}
            >
              {t("TEMPLATE.CANCEL_CHANGES")}
            </Button>
            <Button
              key={"preview"}
              sx={{ textTransform: "Capitalize", mr: 2 }}
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => setPreviewDialog(true)}
            >
              {t("TEMPLATE.VIEW_PREVIEW")}
            </Button>
            <Button key={"save"} sx={{ textTransform: "Capitalize", mr: 2 }} variant={"contained"} onClick={saveOrUpdateTemplate}>
              {t("COMMONS.SAVE")}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider variant="fullWidth" style={{ backgroundColor: "black" }} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color={"inherit"} elevation={0}>
          <Toolbar>
            {colorChipSelected && (
              <Tooltip title={`${t("TEMPLATE.SELECT_COLORS")}`}>
                <Chip
                  icon={<PaletteOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColorThemeSelector}
                  label={`${t("TEMPLATE.COLOR")}`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ color: "white", backgroundColor: "black", "&:hover": { color: "#4c4c4c" } }}
                />
              </Tooltip>
            )}
            {!colorChipSelected && (
              <Tooltip title={`${t("TEMPLATE.SELECT_COLORS")}`}>
                <Chip
                  icon={<PaletteOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColorThemeSelector}
                  label={`${t("TEMPLATE.COLOR")}`}
                  variant="outlined"
                  color={"primary"}
                />
              </Tooltip>
            )}
            {!imageChipSelected && (
              <Tooltip title={`${t("TEMPLATE.SELECT_LOGO_IMAGE")}`}>
                <Chip
                  icon={<ImageOutlinedIcon sx={{ mr: 1 }} color={"primary"} />}
                  onClick={selectChipImage}
                  label={`${t("TEMPLATE.IMAGE")}`}
                  style={{ marginLeft: "20px" }}
                  variant="outlined"
                  color={"primary"}
                />
              </Tooltip>
            )}
            {imageChipSelected && (
              <Tooltip title={`${t("TEMPLATE.SELECT_LOGO_IMAGE")}`}>
                <Chip
                  icon={<ImageOutlinedIcon sx={{ mr: 1 }} color={"primary"} />}
                  onClick={selectChipImage}
                  label={`${t("TEMPLATE.IMAGE")}`}
                  style={{ marginLeft: "20px" }}
                  variant="outlined"
                  color={"primary"}
                  sx={{ color: "white", backgroundColor: "black", "&:hover": { color: "black" } }}
                />
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Divider variant="fullWidth" style={{ backgroundColor: "black" }} />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={3} marginLeft={"20px"}>
          {viewSelectColor && (
            <Box alignItems={"center"} justifyContent={"center"} display={"flex"} marginBottom={"20px"}>
              {color1ChipSelected && (
                <Chip
                  icon={<FormatColorFillOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColor1}
                  label={`${t("TEMPLATE.COLOR")} 1#`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ mr: 2, color: "white", backgroundColor: "black", "&:hover": { color: "#4c4c4c" } }}
                />
              )}
              {!color1ChipSelected && (
                <Chip
                  icon={<FormatColorFillOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColor1}
                  label={`${t("TEMPLATE.COLOR")} 1#`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ mr: 2 }}
                />
              )}
              {color2ChipSelected && (
                <Chip
                  icon={<ColorizeOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColor2}
                  label={`${t("TEMPLATE.COLOR")} 2#`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ mr: 2, color: "white", backgroundColor: "black", "&:hover": { color: "#4c4c4c" } }}
                />
              )}
              {!color2ChipSelected && (
                <Chip
                  icon={<ColorizeOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openColor2}
                  label={`${t("TEMPLATE.COLOR")} 2#`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ mr: 2 }}
                />
              )}
              {textColorChipSelected && (
                <Chip
                  icon={<TextFieldsOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openTextColor}
                  label={`${t("TEMPLATE.TEXT_COLOR")} 1#`}
                  variant="outlined"
                  color={"primary"}
                  sx={{ color: "white", backgroundColor: "black", "&:hover": { color: "#4c4c4c" } }}
                />
              )}
              {!textColorChipSelected && (
                <Chip
                  icon={<TextFieldsOutlinedIcon sx={{ mr: 1 }} />}
                  onClick={openTextColor}
                  label={`${t("TEMPLATE.TEXT_COLOR")} 1#`}
                  variant="outlined"
                  color={"primary"}
                />
              )}
            </Box>
          )}
          {viewSelectColor && viewThemeColor && (
            <AddThemeColorCard backgroundColor={setColor} openDialog={viewThemeColor} closeDialog={setViewThemeColor} defaultColor={color} />
          )}
          {viewSelectColor && viewThemeColor2 && (
            <AddThemeColorCard backgroundColor={setColor2} openDialog={viewThemeColor2} closeDialog={setViewThemeColor2} defaultColor={color2} />
          )}
          {viewSelectColor && viewThemeTextColor && (
            <AddThemeColorCard
              backgroundColor={setTextColor}
              openDialog={viewThemeTextColor}
              closeDialog={setViewThemeTextColor}
              defaultColor={textColor}
            />
          )}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <Box id={"forehead"} marginBottom={"40px"}>
            <ForeheadCard color={color} image={image} />
          </Box>

          <Box id={"back"} marginBottom={"50px"}>
            <BackCard image={image} color={color} textColor={textColor} color2={color2} />
          </Box>
        </Grid>
      </Grid>
      {/*<AddLogoDialog openDialog={openImageDialog} closeDialog={handleCloseImageDialog} setLogo={setImage} logo={image} />*/}
      <Dialog onClose={handleCloseImageDialog} open={openImageDialog}>
        <DialogTitle>
          <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
            {t("TEMPLATE.UPLOAD_LOGO")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box minHeight={"300px"} minWidth={"500px"} bgcolor={"#E5E4E2"} alignItems={"center"} justifyContent={"center"} display={"flex"}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              {image && (
                <>
                  <Box
                    sx={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    {viewDeleteIcon && (
                      <Box maxWidth={"0px"} zIndex={1}>
                        <DeleteOutlineIcon fontSize={"large"} style={{ color: "#000000" }} />
                      </Box>
                    )}
                    <Image src={image} rounded style={{ maxHeight: "100px", maxWidth: "100px" }} />
                  </Box>
                  <Stack spacing={2} justifyContent="center" alignItems="center" direction={"row"}>
                    <Button
                      component="label"
                      startIcon={<ChangeCircleOutlinedIcon />}
                      size={"small"}
                      sx={{ textTransform: "Capitalize" }}
                      onChange={uploadImage}
                    >
                      {t("TEMPLATE.CHANGE_LOGO")}
                      <VisuallyHiddenInput type="file" accept="image/*" />
                    </Button>
                    <Button
                      component="label"
                      startIcon={<DeleteOutlineIcon />}
                      size={"small"}
                      sx={{ textTransform: "Capitalize" }}
                      onClick={deleteImage}
                      color={"error"}
                    >
                      {t("TEMPLATE.DELETE_LOGO")}
                    </Button>
                  </Stack>
                </>
              )}
              {!image && (
                <>
                  <Button component="label" sx={{ textTransform: "Capitalize" }}>
                    {t("TEMPLATE.UPLOAD_LOGO")}
                    <input type="file" style={{ display: "none" }} onChange={uploadImage} accept="image/*" />
                  </Button>
                  <Typography paragraph color={"#818589"} sx={{ paddingTop: "10px" }}>
                    {t("TEMPLATE.DESCRIPTION_DIALOG_UPLOAD_LOGO")}
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
                <Button autoFocus onClick={handleCloseImageDialog} sx={{ mr: 2, textTransform: "Capitalize" }}>
                  {t("COMMONS.CLOSE")}
                </Button>
                <Button autoFocus onClick={handleConfirmImage} variant={"contained"} sx={{ textTransform: "Capitalize" }}>
                  {t("COMMONS.CONFIRM")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* Sezione Dialog */}
      <PreviewCardDialog
        previewDialog={previewDialog}
        closePreviewDialog={setPreviewDialog}
        image={image}
        color={color}
        color2={color2}
        textColor={textColor}
        foreheadCardDesign={foreheadCardDesign}
        backCardDesign={backCardDesign}
      />
      <DeleteTemplateDialog
        deleteTemplateDialog={deleteTemplateDialog}
        closeDeleteTemplateDialog={setDeleteTemplateDialog}
        onDeleteTemplate={cancelOrDeleteTemplate}
        isNew={isNew}
      />
      <CancelTemplateDialog
        cancelTemplateDialog={cancelTemplateDialog}
        closeCancelTemplateDialog={setCancelTemplateDialog}
        onCancelTemplate={cancelChanges}
      />
    </>
  );
};

export default AddEditTemplate;
