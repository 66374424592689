export enum EAuthRole {
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  OPERATOR = "operator",
  PLATFORM_ADMIN = "platformadmin",
  BASIC = "basic",
}

export enum EContactType {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  SITO_WEB = "SITO WEB",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKDN",
  TWITTER = "TWITTER",
  PORTFOLIO = "PORTFOLIO",
  COMPANY_PROFILE = "COMPANY_PROFILE",
}

export enum ERegistryType {
  DIPENDENTE = "DIPENDENTE",
  SEGNALATORE = "SEGNALATORE",
  AZIENDA = "AZIENDA",
  SEDE = "SEDE",
  AMMINISTRATORE = "AMMINISTRATORE",
}

export enum EApiSort {
  ASC = 1,
  DESC = -1,
}

export enum ERegistryAddressType {
  LEGALE = "LEGALE",
  LEGALE_PRINCIPALE = "LEGALE PRINCIPALE",
  RESIDENZA = "RESIDENZA",
  RESIDENZA_PRINCIPALE = "RESIDENZA PRINCIPALE",
}
