import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import styles from "./TabMenu.module.css";
import { TabMenuProps } from "../../type/type";
import { useTranslation } from "react-i18next";

const TabMenu: React.FC<TabMenuProps> = ({ value = 0, onChange, tabs }) => {
  const { t } = useTranslation();
  // const tabStyle = {

  //   "& .MuiTabs-indicator": { backgroundColor: "var(--black)", height: "2.5px" },
  //   "& .MuiTab-root": { fontSize: "1.2857rem", color: "black", fontWeight: "450", textTransform: "Capitalize", width: "auto", },
  //   "& .Mui-selected": { fontWeight: "600", letterSpacing: "0", color: "var(--black) !important" , },
  //   "& .MuiTab-wrapper": { textAlign: "left" },
  //   "& .MuiButtonBase-root": { padding: "0 !important"  },
  // };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Box className={styles.tabMenu}>
      <Tabs value={value} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabMenu;
