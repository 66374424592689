import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { API_GET_ALL_GENERIC_TYPES, API_GET_MENU } from "../api/api";
import { toast_error } from "../utils/custom-toast";
import Image from "react-bootstrap/Image";

// Icons
import * as Icons from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";

import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";

import MenuIcon from "@mui/icons-material/Menu";
import idLogo from "../img/logoIDTotalBlack.svg";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

// Styles
import styles from "./Sidebar.module.css";

import { useTranslation } from "react-i18next";
import { EGenericType, GenericType } from "../type/type";
import { MenuApiService } from "../services/MenuApiService";
import { IMenuItem } from "../type/commons";
import { EAuthSessionData } from "../type/enum/auth.enum";
import { GenericTypeService } from "../services/GenericTypeService";
import RoutesContext from "../context/RouteContext";
import { IRoutes } from "../type/routes";

type IconType = keyof typeof Icons;

const IconComponent: React.FC<{ iconName: IconType }> = ({ iconName }) => {
  const Icon = Icons[iconName];
  return <Icon />;
};

// Component
const SideMenu: React.FC = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [menuItems, setMenuItems] = useState<IRoutes[]>([]);
  const [broken, setBroken] = useState(false);
  const [activeItem, setActiveItem] = useState("homepage");
  const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
  const role = sessionStorage.getItem(EAuthSessionData.ROLE);
  const menuApiService = new MenuApiService();
  const genericTypesService = new GenericTypeService();
  const routesContext = useContext(RoutesContext);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleBreakPointChange = (isBroken: boolean) => {
    if (isBroken) {
      setCollapsed(true);
    }
    setBroken(isBroken);
  };

  const handleMenuItemClick = (itemName: string) => {
    setActiveItem(itemName);
  };

  const setGenericTypesToSession = async (data: GenericType[]) => {
    let studyTypes: GenericType[] = [];
    let contractTypes: GenericType[] = [];
    let ccnlTypes: GenericType[] = [];
    let qualificationTypes: GenericType[] = [];
    let roleTypes: GenericType[] = [];
    let areas: GenericType[] = [];

    data.forEach((res) => {
      if (res.typeValue == EGenericType.STUDY_TYPE) {
        studyTypes.push(res);
      }
      if (res.typeValue == EGenericType.CONTRACT_TYPE) {
        contractTypes.push(res);
      }
      if (res.typeValue == EGenericType.CCNL_TYPE) {
        ccnlTypes.push(res);
      }
      if (res.typeValue == EGenericType.QUALIFICATION_TYPE) {
        qualificationTypes.push(res);
      }
      if (res.typeValue == EGenericType.ROLE_TYPE) {
        roleTypes.push(res);
      }
      if (res.typeValue == EGenericType.AREA) {
        areas.push(res);
      }
    });
    sessionStorage.setItem(EGenericType.STUDY_TYPE, JSON.stringify(studyTypes));
    sessionStorage.setItem(EGenericType.CONTRACT_TYPE, JSON.stringify(contractTypes));
    sessionStorage.setItem(EGenericType.CCNL_TYPE, JSON.stringify(ccnlTypes));
    sessionStorage.setItem(EGenericType.QUALIFICATION_TYPE, JSON.stringify(qualificationTypes));
    sessionStorage.setItem(EGenericType.ROLE_TYPE, JSON.stringify(roleTypes));
    sessionStorage.setItem(EGenericType.AREA, JSON.stringify(areas));
  };

  useEffect(() => {
    if (aToken) {
      const fetchData = async () => {
        const menu = routesContext?.routes.filter(r => r.isMenu);
        setMenuItems(menu ?? []);
        // try {
        //   const data = await menuApiService.fetchMenuItem();
        //   if (data) {
        //     //Inserisce Homepage come primo elemento del menu
        //     // const homepage = data.findIndex((item) => t(item.title) === t("SIDEBAR.HOMEPAGE"));
        //     // if (homepage !== -1) {
        //     //   const homepageItem = data.splice(homepage, 1)[0];
        //     //   data.unshift(homepageItem);
        //     // }
        //     setMenuItems(data);
        //   } else {
        //     toast_error(t("COMMONS.OPERATION_ERROR"));
        //   }
        // } catch (error) {
        //   toast_error("Errore nel caricamento del menu");
        // }
      };

      const fetchDataGenericTypes = async () => {
        try {
          const response = await genericTypesService.fetchGenericTypesList();

          if (response) {
            await setGenericTypesToSession(response);
          } else {
            toast_error(t("COMMONS.OPERATION_ERROR"));
          }
        } catch (error) {
          toast_error("Errore nel caricamento delle tipologie generiche");
        }
      };

      fetchData();
      fetchDataGenericTypes();
    }
  }, []);

  return (
    <div>
      <Sidebar
        backgroundColor="white"
        style={{ height: "100%", color: "#000000", fontSize: "1rem" }}
        onBreakPoint={handleBreakPointChange}
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="sm"
        collapsed={broken ? true : collapsed}
      >
        <div className={styles.headerContainer}>
          <Image src={idLogo} style={{ maxWidth: "30px", maxHeight: "30px", marginLeft: "15px", marginRight: "10px" }} />
          {!collapsed && <div className={styles.logoText}>connect</div>}
        </div>
        <Menu>
          {/* <MenuItem
            component={<Link to="/homepage" />}
            icon={<HomeIcon style={{ color: "#000000" }} />}
            active={activeItem === "homepage"}
            onClick={() => handleMenuItemClick("homepage")}
            className={`${activeItem === "homepage" ? styles.boldText : ""} ${styles.menuItem}`}
          >
            {t("SIDEBAR.HOMEPAGE")}
          </MenuItem> */}
          {menuItems
            .filter((item) => item.role.includes(role ?? ''))
            .sort((a, b) => {
              if (a.title === "SIDEBAR.HOMEPAGE" && b.title !== "SIDEBAR.HOMEPAGE") {
                return -1;
              } else if (a.title !== "SIDEBAR.HOMEPAGE" && b.title === "SIDEBAR.HOMEPAGE") {
                return 1;
              } else {
                return 0;
              }
            })
            .map((item) => (
              <MenuItem
                icon={<IconComponent iconName={item.icon} />}
                component={<Link to={item.link} />}
                className={`${activeItem === item.title ? styles.boldText : ""} ${styles.menuItem}`}
                onClick={() => handleMenuItemClick(item.title)}
                key={item.code}
              >
                {t(item.title)}
              </MenuItem>
            ))}

          {/* <MenuItem
            icon={<IconComponent iconName="Groups" />}
            component={<Link to="/timbrature" />}
            onClick={() => handleMenuItemClick("timbrature")}
            className={`${activeItem === "timbrature" ? styles.boldText : ""} ${styles.menuItem}`}
          >
            Timbrature
          </MenuItem> */}

          {/*<hr />*/}

          {/*{role === "admin" && (
            <>
              <Divider
                variant={"middle"}
                sx={{ marginTop: "30px", marginBottom: "30px", backgroundColor: "#D3D3D3", width: collapsed ? "50%" : "80%" }}
              />
              <MenuItem
                component={<Link to="/acquisti/storico" />}
                icon={<IconComponent iconName="ShoppingCart" />}
                onClick={() => handleMenuItemClick("/acquisti/storico")}
                className={`${activeItem === "/acquisti/storico" ? styles.boldText : ""} ${styles.menuItem}`}
              >
                {t("SIDEBAR.PURCHASES")}
              </MenuItem>
              <MenuItem
                component={<Link to="/card-personalization" />}
                icon={<StyleOutlinedIcon />}
                onClick={() => handleMenuItemClick("card-personalization")}
                className={`${activeItem === "card-personalization" ? styles.boldText : ""} ${styles.menuItem}`}
              >
                {t("SIDEBAR.TEMPLATE")}
              </MenuItem>

              <MenuItem
                component={<Link to="/requests-history" />}
                icon={<ShoppingBagOutlinedIcon />}
                onClick={() => handleMenuItemClick("requests-history")}
                className={`${activeItem === "requests-history" ? styles.boldText : ""} ${styles.menuItem}`}
              >
                {t("SIDEBAR.REQUESTS")}
              </MenuItem>

              <MenuItem
                component={<Link to="/plafond" />}
                icon={<StyleOutlinedIcon />}
                onClick={() => handleMenuItemClick("plafond")}
                className={`${activeItem === "plafond" ? styles.boldText : ""} ${styles.menuItem}`}
              >
                {t("SIDEBAR.PLAFOND")}
              </MenuItem>
            </>
          )}*/}

          {!broken && (
            <MenuItem icon={<MenuIcon />} onClick={handleCollapsedChange}>
              {t("SIDEBAR.COLLAPSE_MENU")}
            </MenuItem>
          )}
        </Menu>
      </Sidebar>
      <div>
        <div>{broken && <MenuIcon onClick={() => setToggled(!toggled)} />}</div>
      </div>
    </div>
  );
};

export default SideMenu;
