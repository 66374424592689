import { CardProps } from "../../type/type";
import styles from "./Card.module.css";
import { useNavigate } from "react-router-dom";

const Card = ({ title, totalValue, notificationValue, backgroundColor, color, notificationColor, textValue }: CardProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/azienda/dipendenti/nuovo-dipendente");
  };
  return (
    <div className={styles.cardContainer} style={{ backgroundColor, color }}>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.cardContent}>
        <div className={styles.cardTotal}>
          <div className={styles.cardValue}>{totalValue}</div>
          <div className={`${styles.cardNotification}`} style={{ backgroundColor: notificationColor }}>
            <div className={styles.notification}>{notificationValue}</div>
            <span>{textValue}</span>
          </div>
        </div>
        {/* <div className={styles.cardButton} onClick={handleClick}>
          <BiPlus />
        </div> */}
      </div>
    </div>
  );
};

export default Card;
