import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "../../colors.css";

const Timeline: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment()), 1000);
    return () => clearInterval(timer);
  }, []);

  const renderTimeBlocks = () => {
    const timeBlocks = [];
    const startHour = 7;
    const endHour = 20;

    const currentHour = currentTime.tz("Europe/Rome").hour();
    const currentMinute = currentTime.minute();

    for (let hour = startHour; hour <= endHour; hour++) {
      const blockText = `${hour}:00`;
      let blockColor = "var(--red-100)";

      if (hour < currentHour) {
        blockColor = "var(--greenAccent-200)";
      } else if (hour === currentHour) {
        const percentage = (currentMinute / 60) * 100;
        blockColor = `linear-gradient(to right, var(--greenAccent-200) ${percentage}%, var(--red-100) ${percentage}%)`;
      }

      timeBlocks.push(
        <div
          key={hour}
          className="time-block"
          style={{
            background: blockColor,
            width: "calc(100% / 14)",
            display: "inline-block",
            textAlign: "center",
            height: "50px",
            lineHeight: "50px",
            verticalAlign: "middle",
            border: "1px solid lightgrey",
          }}
        >
          {blockText}
        </div>,
      );
    }

    return timeBlocks;
  };

  return (
    <div className="time-container" style={{ marginBottom: "10px", height: "50px" }}>
      <div className="time-row">{renderTimeBlocks()}</div>
    </div>
  );
};

export default Timeline;
