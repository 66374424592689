import React from "react";
import styles from "./TextHighlight.module.css";
import { TextHiglightProps } from "../../type/type";

const TextHighlight: React.FC<TextHiglightProps> = ({ content, customColor }) => {
  let colorClass = "";

  //creare uno switch per gestire i colori
  switch (content) {
    case "Entrato":
    case "Nuovo":
      colorClass = styles.green;
      break;
    case "Ferie":
    case "Rifiutato":
      colorClass = styles.gray;
      break;
    case "Non entrato":
    case "Accettato":
      colorClass = styles.orange;
      break;
    case "Uscita":
      colorClass = styles.red;
      break;
    default:
      colorClass = styles.green;
  }

  const containerStyle = customColor ? { backgroundColor: customColor } : {};

  return (
    <>
      <div className={`${styles.container} ${colorClass}`} style={containerStyle}>
        {content}
      </div>
    </>
  );
};

export default TextHighlight;
