import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RegistryRoleEnum } from "../../type/enum/registry-role.enum";
import Cookies from "js-cookie";
import { EAuthSessionData } from "../../type/enum/auth.enum";
import MyContext from "../../context/MyContext";
import RoutesContext from "../../context/RouteContext";
import { IRoutes } from "../../type/routes";

export function PrivateRoute() {
  const hasToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
  const hasRefreshToken = Cookies.get(EAuthSessionData.REFRESH_TOKEN);

  if (hasToken && hasRefreshToken) {
    return <Outlet />;
  }

  return <Navigate to="/unauthorized" replace />;
}

export function RoleBasedRoute({ allowedRoles }: { allowedRoles: RegistryRoleEnum[] }) {
  const userRole = sessionStorage.getItem("role") as RegistryRoleEnum;

  if (allowedRoles.includes(userRole)) {
    return <Outlet />;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
}

export function UserAuthorizeRoute({ route, element } : { route: string, element: JSX.Element }) {
  const context = useContext(RoutesContext);
  const routeSession = sessionStorage.getItem("route");
  let routes: IRoutes[] = [];
  if  (routeSession) {
    routes = JSON.parse(routeSession);
  }
  console.log("routes context", context?.routes);
  console.log("session storage route", routes);
  const routesFiltered = context?.routes && context?.routes.length > 0 ? context?.routes.filter(a => a.link === route) : routes.filter(a => a.link === route);
  if (routesFiltered && routesFiltered.length > 0) {
    return element;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
}
