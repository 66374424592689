import { CircularProgress, Container, Dialog, IconButton } from "@mui/material";
import styles from "./PaginaPubblicaAzienda.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import { useParams } from "react-router-dom";
import GoogleMapsCard from "../../../componets/GoogleMapsCard/GoogleMapsCard";
import QRCode from "../../../componets/qrcode/QRCode";
import { useEffect, useState } from "react";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { IRegistry } from "../../../type/commons";
import { EContactType } from "../../../type/enum/common.enum";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

type ContactType = {
  _id: string;
  code: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

type Contact = {
  _id: string;
  id_contact_type: string | ContactType;
  contact: string;
  createdAt: string;
  updatedAt: string;
};

type RegistryType = {
  _id: string;
  code: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  is_auth_enabled?: boolean;
};

type RegistryAddress = {
  _id: string;
  id_registry_address_type?: string;
  latitude: null | number;
  longitude: null | number;
  topon: string;
  street: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
  createdAt: string;
  updatedAt: string;
};
type Registry = {
  _id: string;
  id_registry_type: RegistryType[];
  id_contact: Contact[];
  business_name: string;
  vat_number: string;
  enabled: boolean;
  id_registry_address: RegistryAddress[];
  tenant: string;
  key_code: string;
  image?: string;
  createdAt: string;
  updatedAt: string;
};

const PaginaPubblicaAzienda = () => {
  const initialRegistryState: IRegistry = {
    contact: [],
    firstname: "",
    fiscal_code: "",
    id_auth_module: "",
    id_registry_owner: "",
    isManual: false,
    profession: "",
    birth_place: "",
    // birth_date: new Date(),
    sex_code: "",
    registry_address: [],
    registry_type: "",
    surname: "",
    _id: "",
    id_registry_type: [
      {
        _id: "",
        code: "",
        description: "",
        createdAt: "",
        updatedAt: "",
      },
    ],
    business_name: "",
    vat_number: "",
    id_contact: [
      {
        _id: "",
        id_contact_type: {
          _id: "",
          code: "",
          description: "",
          createdAt: "",
          updatedAt: "",
        },
        contact: "",
        createdAt: "",
        updatedAt: "",
      },
    ],
    customLink: [{ label: "", value: "" }],
    enabled: false,
    id_registry_address: [],
    tenant: "",
    key_code: "",
    image: "",
    createdAt: "",
    updatedAt: "",
  };

  const [registry, setRegistry] = useState<IRegistry>(initialRegistryState);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { businessName } = useParams();
  const { t } = useTranslation();
  const registryApiService = new RegistryApiService();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await registryApiService.getRegistryFromBusinessName(businessName ?? "");
        setRegistry(data ?? initialRegistryState);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [businessName]);

  function findContactByCode(registry: IRegistry, code: EContactType) {
    return registry.id_contact.find((contact) => {
      if ("code" in contact.id_contact_type) {
        return contact.id_contact_type.code === code;
      }
      return false;
    });
  }

  const webSiteObj = findContactByCode(registry, EContactType.SITO_WEB);
  const webSite = webSiteObj ? webSiteObj.contact : "Non disponibile";

  const emailObj = findContactByCode(registry, EContactType.EMAIL);
  const email = emailObj ? emailObj.contact : "Non disponibile";

  const phoneObj = findContactByCode(registry, EContactType.PHONE);
  const phone = phoneObj ? phoneObj.contact : "Non disponibile";

  const facebookObj = findContactByCode(registry, EContactType.FACEBOOK);
  const facebook = facebookObj ? facebookObj.contact : null;

  const instagramObj = findContactByCode(registry, EContactType.INSTAGRAM);
  const instagram = instagramObj ? instagramObj.contact : null;

  const twitterObj = findContactByCode(registry, EContactType.TWITTER);
  const twitter = twitterObj ? twitterObj.contact : null;

  const linkedinObj = findContactByCode(registry, EContactType.LINKEDIN);
  const linkedin = linkedinObj ? linkedinObj.contact : null;

  let sedeAddress = "";
  if (registry.id_registry_address[0]) {
    sedeAddress = `${registry.id_registry_address[0].topon} ${registry.id_registry_address[0].street} ${registry.id_registry_address[0].number}, ${registry.id_registry_address[0].postal_code} ${registry.id_registry_address[0].city} ${registry.id_registry_address[0].province},${registry.id_registry_address[0].state}`;
  }

  const formatWebSiteURL = (url: string) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const handleOpenQRModal = () => {
    setOpenQRModal(true);
  };

  const handleCloseQRModal = () => {
    setOpenQRModal(false);
  };

  const openWebSite = (webSite: string) => {
    window.open(formatWebSiteURL(webSite), "_blank");
  };

  const openEmail = () => {
    const emailLink = `mailto:${email}`;
    window.location.href = emailLink;
  };

  const openPhone = () => {
    const phoneLink = `tel:${phone}`;
    window.location.href = phoneLink;
  };

  const generateVCF = (registry: any) => {
    let vcfData = `BEGIN:VCARD\n`;
    vcfData += `VERSION:3.0\n`;
    vcfData += `N:${registry.business_name};;\n`;
    vcfData += `FN:${registry.business_name}\n`;
    // vcfData += `ORG:${registry.id_registry_owner.id_registry_owner.business_name}\n`;
    // vcfData += `TITLE:${registry.profession}\n`;
    vcfData += `TEL;TYPE=WORK,VOICE:${phone}\n`;
    vcfData += `ADR;TYPE=WORK,PREF:;;${sedeAddress}\n`;
    vcfData += `EMAIL;TYPE=work:${email}\n`;
    vcfData += `URL;CHARSET=UTF-8:${formatWebSiteURL(webSite)}\n`;
    vcfData += `URL;CHARSET=UTF-8:${facebook}\n`;
    vcfData += `URL;CHARSET=UTF-8:${instagram}\n`;
    vcfData += `URL;CHARSET=UTF-8:${twitter}\n`;
    vcfData += `URL;CHARSET=UTF-8:${linkedin}\n`;
    if (registry.image) {
      let imageType = registry.imageType;
      vcfData += `PHOTO;TYPE=${imageType};ENCODING=b:${registry.image.split(",")[1]}\n`;
    }
    vcfData += `END:VCARD`;
    return vcfData;
  };

  const downloadVCard = () => {
    const vcfText = generateVCF(registry);
    const element = document.createElement("a");
    const blob = new Blob([vcfText], { type: "text/vcard" });
    element.href = URL.createObjectURL(blob);
    element.download = `${registry.business_name}.vcf`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: window.location.href,
        });
        alert("URL condiviso con successo");
      } catch (error) {
        alert("Non è stato possibile condividere");
      }
    } else {
      alert("La funzione di condivisione non è supportata su questo browser");
    }
  };

  if (isLoading) {
    <div className={styles.loadingRow}>
      <CircularProgress size={70} />
    </div>;
  }

  if (!registry) {
    return <div>Dati non disponibili</div>;
  }

  return (
    <div className={styles.background}>
      <Container maxWidth="sm" className={styles.container}>
        <div className={styles.topSection}>
          <div className={styles.containerImgInfo}>
            <div className={styles.imageContainer}>
              {registry?.image ? (
                <Avatar src={registry?.image} alt="user" variant="rounded" sx={{ width: "10em", height: "10em" }} />
              ) : (
                <Avatar alt="user" variant="rounded" sx={{ width: "10em", height: "10em" }} />
              )}
            </div>
            <div className={styles.userName}>{registry.business_name}</div>
            {/* <div className={styles.userProfession}>{registry.profession}</div> */}
          </div>
        </div>
        <div className={styles.bottomSection}>
          {webSite && (
            <a href={webSite} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.infoBox}>
                <div>
                  <div className={styles.infoTitles}>SitoWeb</div>
                  <div className={styles.infoSubTitle}>{webSite}</div>
                </div>
                <LanguageIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
              </div>
            </a>
          )}

          <div className={styles.infoBox} onClick={openEmail}>
            <div>
              <div className={styles.infoTitles}>Email</div>
              <div className={styles.infoSubTitle}>{email}</div>
            </div>
            <MailOutlineIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
          </div>
          <div className={styles.infoBox} onClick={openPhone}>
            <div>
              <div className={styles.infoTitles}>{t("FORM.PHONE")}</div>
              <div className={styles.infoSubTitle}>{phone}</div>
            </div>
            <PhoneIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
          </div>

          {/* <div className={styles.twoInfoBox}></div> */}

          <div className={styles.infoBox}>{<GoogleMapsCard address={sedeAddress} />}</div>
          {facebook && (
            <a href={facebook} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <FacebookIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Facebook</div>
                  {/* <div className={styles.infoSubTitle}>{facebook}</div> */}
                </div>
              </div>
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <InstagramIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Instagram</div>
                  {/* <div className={styles.infoSubTitle}>{instagram}</div> */}
                </div>
              </div>
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <TwitterIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Twitter</div>
                  {/* <div className={styles.infoSubTitle}>{twitter}</div> */}
                </div>
              </div>
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <LinkedInIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Linkedin</div>
                  {/* <div className={styles.infoSubTitle}>{linkedin}</div> */}
                </div>
              </div>
            </a>
          )}

          {registry.customLink &&
            registry.customLink.map((link, index) => (
              <a key={index} href={link.value} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                <div className={styles.socialBox}>
                  <LinkIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <div className={styles.infoTitles}>{link.label}</div>
                  </div>
                </div>
              </a>
            ))}

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className={styles.buttonBars}>
              <IconButton onClick={handleShare}>
                <FileUploadOutlinedIcon className={styles.iconStyleActive} />
              </IconButton>
              <IconButton onClick={downloadVCard}>
                <AddCircleIcon className={styles.iconStyleActive} />
              </IconButton>
              <IconButton onClick={handleOpenQRModal}>
                <QrCodeRoundedIcon className={styles.iconStyleActive} />
              </IconButton>
            </div>
          </div>
        </div>
      </Container>
      <Dialog
        open={openQRModal}
        onClose={handleCloseQRModal}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div className={styles.qrDiv}>
          <QRCode />
        </div>
      </Dialog>
    </div>
  );
};
export default PaginaPubblicaAzienda;
