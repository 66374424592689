import { IBillingInfo } from "../../../../type/payments";

export default {
  country: "IT",
  city: "",
  state: undefined,
  postal_code: "",
  address: "",
  business_name: "",
  vat_number: "",
  pec: "",
  billing_sdi: false,
  code_sdi: "",
  email: "",
  phone: "",
} as IBillingInfo;
