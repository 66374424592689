import React, { useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import custom from "../add-edit-template/CustomColorPickerStyle.module.css";
import { ColorPicker, ColorService, IColor, useColor } from "react-color-palette";
import { Button, CardActions, TextField } from "@mui/material";
import styles from "../add-edit-template/AddEditTemplate.module.css";
import Divider from "@mui/material/Divider";
import { CirclePicker, ColorResult } from "react-color";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { ThemeColorCardProps } from "../../../../type/props/card-template-props";

const AddThemeColorCard: React.FC<ThemeColorCardProps> = ({ backgroundColor, openDialog, closeDialog, defaultColor }) => {
  const [color, setColor] = useColor("#000000");
  const { t } = useTranslation();

  const circleColors = ["#FFFFFF", "#979797", "#000000", "#FF0000", "#8F00FF", "#0047AB", "#FFBF00", "#50C878", "#FFAC1C"];
  const blackColor = "#000000";

  useEffect(() => {
    if (defaultColor) {
      changeColorUpd(defaultColor);
    }
  }, []);

  useEffect(() => {
    if (defaultColor) {
      changeColorUpd(defaultColor);
    }
  }, [defaultColor]);

  const resetColor = () => {
    const hex = blackColor;
    const rgb = ColorService.hex2rgb(hex);
    const hsv = ColorService.rgb2hsv(rgb);
    setColor({ rgb: rgb, hsv: hsv, hex: hex });
    backgroundColor(hex);
    closeDialog(false);
  };

  const handleConfirm = () => {
    closeDialog(false);
  };

  const changeColor = (event: any) => {
    const hex = event.target.value.toString().toUpperCase();
    const rgb = ColorService.hex2rgb(hex);
    const hsv = ColorService.rgb2hsv(rgb);
    setColor({ rgb: rgb, hsv: hsv, hex: hex });
    backgroundColor(hex);
  };

  const setColorPicker = (color: IColor) => {
    setColor(color);
    backgroundColor(color.hex);
  };

  const changeColorFromCircle = (value: ColorResult) => {
    const hex = value.hex.toUpperCase();
    const rgb = ColorService.hex2rgb(hex);
    const hsv = ColorService.rgb2hsv(rgb);
    setColor({ rgb: rgb, hsv: hsv, hex: hex });
    backgroundColor(hex);
  };

  const changeColorUpd = (hexValue: string) => {
    const hex = hexValue.toUpperCase();
    const rgb = ColorService.hex2rgb(hex);
    const hsv = ColorService.rgb2hsv(rgb);
    setColor({ rgb: rgb, hsv: hsv, hex: hex });
    backgroundColor(hex);
  };

  return (
    <>
      {openDialog && (
        <Card style={{ borderRadius: "12px" }} elevation={3}>
          <CardContent>
            <div className={custom.custom}>
              <ColorPicker color={color} onChange={setColorPicker} height={100} hideInput={["hsv", "rgb", "hex"]} />
            </div>
            <br />
            <TextField name="color" value={color.hex.toUpperCase()} onChange={changeColor} size={"small"} fullWidth className={styles.textFields} />
            <br />
            <br />
            <Divider variant="fullWidth" textAlign="left">
              {t("TEMPLATE.COLORS")}
            </Divider>
            <CirclePicker
              onChangeComplete={changeColorFromCircle}
              circleSpacing={18}
              colors={circleColors}
              width={"auto"}
              className={styles.circlePickerCustom}
            />
            <br />
          </CardContent>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Button autoFocus onClick={resetColor} sx={{ textTransform: "Capitalize" }}>
                    {t("COMMONS.RESET")}
                  </Button>
                </Box>
              </Grid>
              <Grid></Grid>
            </Grid>

            <Box>
              <Button autoFocus onClick={handleConfirm} sx={{ fontWeight: "bold", textTransform: "Capitalize" }}>
                {t("COMMONS.CONFIRM")}
              </Button>
            </Box>
          </CardActions>
        </Card>
      )}
    </>
  );
};
export default AddThemeColorCard;