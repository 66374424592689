import { Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Riepilogo.module.css";
import { useEffect, useState } from "react";

import { getCurrency, getGradientByDescription } from "./utils/commonFunctions";
import { CardTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Util } from "../../utils/util";
import { PaymentApiService } from "../../services/PaymentApiService";
import { IProduct } from "../../type/commons";

const RiepilogoAcquisti = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { productInfo } = location.state;
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [loadingProductList, setLoadingProductList] = useState<boolean>(false);
  const [prices, setPrices] = useState<any>([]);
  const paymentApiService = new PaymentApiService();

  const fetchProductList = async () => {
    setLoadingProductList(true);
    try {
      const data = await paymentApiService.fetchAllProduct();
      if (data) {
        setProductList(data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingProductList(false);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  const updatePricesForSelectedProduct = () => {
    const selectedProductID = productInfo.selectedProduct;
    const selectedProduct = productList.find((item: any) => item._id === selectedProductID);

    if (selectedProduct) {
      setPrices(selectedProduct.prices);
    }
  };

  useEffect(() => {
    updatePricesForSelectedProduct();
  }, [productInfo.selectedProduct, productList]);

  return (
    <div style={{ marginBottom: "10px" }}>
      <Typography variant="h1">{t("PURCHASE_SUMMARY.TITLE")}</Typography>
      <Card variant="outlined" className={styles.cardStyle}>
        <CardTitle>
          <Typography variant="h2" className={styles.cardTitle}>
            {t("PURCHASE_SUMMARY.SELECTED_PRODUCT")}
          </Typography>
        </CardTitle>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          {!loadingProductList ? (
            productList.map((item: any) => (
              <div key={item._id} className={`${styles.productItem} ${productInfo.selectedProduct === item._id ? styles.cardItemSelected : ""}`}>
                <div className={styles.cardImage} style={{ background: getGradientByDescription(item.description) }}></div>
                <div>{item.description}</div>
              </div>
            ))
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress size={80} />
            </div>
          )}
        </div>
      </Card>

      <Card variant="outlined" className={styles.cardStyle}>
        <CardTitle>
          <Typography variant="h2" className={styles.cardTitle}>
            {t("PURCHASE_SUMMARY.SELECTED_QUANTITY")}
          </Typography>
        </CardTitle>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          {!loadingProductList ? (
            prices.map((price: any) => (
              <div key={price._id} className={`${styles.priceItem} ${productInfo.selectedPrice === price.stripe_id ? styles.cardItemSelected : ""}`}>
                <div className={styles.radioContent}>
                  <div className={styles.radioTitle}>{`X ${price.max_unit_quantity}`}</div>
                  <div className={styles.radioSubtitle}>
                    {getCurrency(price.currency)}
                    {Util.formatStripePrice(price.amount)}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress size={80} />
            </div>
          )}
        </div>
      </Card>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" onClick={() => navigate(`/acquisti/fatturazione`, { state: { productInfo } })} sx={{ textTransform: "none" }}>
            {t("PURCHASE_SUMMARY.SUBMIT")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RiepilogoAcquisti;
