import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";

type SearchBarProps = {
  onSearch: (searchTerm: string) => void;
  placeholder: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <TextField
      // label={placeholder}
      // placeholder={placeholder}
      id="search-bar-input"
      type="text"
      variant="outlined"
      sx={{
        width: "20rem",
        backgroundColor: "var(--white)",
        marginRight: "5px",
        "& input::placeholder": {
          fontSize: "1rem",
        },
        "& input": {
          fontSize: "1rem",
          height: "3rem",
          padding: "0",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchTerm}
      onChange={handleChange}
      autoComplete="search"
      name="search-bar-input"
    />
    //
  );
};

export default SearchBar;
