import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import SearchBar from "../../../componets/barraRicerca/SearchBar";
import "../../../colors.css";
import styles from "./ListaAziende.module.css";
import TabellaListaAziende from "../../../componets/tabelle/TabellaListaAziende";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const ListaAziende: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.length >= 3) {
      setSearchTerm(searchTerm.toLocaleLowerCase().trim());
    } else {
      setSearchTerm("");
    }
  };

  return (
    <>
      <h1 className={styles.title}>{t("COMMONS.ORGANIZATION_LIST")}</h1>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0 2rem 0",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <SearchBar onSearch={handleSearch} placeholder={t("COMMONS.SEARCH")} />
        </Box>
        <Box sx={{ display: "flex", gap: "0.7rem" }}>
          <Button
            onClick={() => navigate(`/gestione-aziende/nuova-azienda`)}
            variant="contained"
            startIcon={<AddOutlinedIcon sx={{ color: "white" }} />}
          >
            {t("COMMONS.ADD")} {t("COMMONS.COMPANY")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mb: 1 }}>
        {/* Tabella Lista Aziende */}
        <TabellaListaAziende searchTerm={searchTerm} />
      </Box>
    </>
  );
};
export default ListaAziende;
