import i18n from "../i18n";

const fullLanguageCode = i18n.language;
const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
// const languageCode = "";

export const WHISTLEBASE_WEBSITE = "https://www.iubenda.com/";

const urlConfig: any = {
  it: {
    COOKIE_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/privacy-policy/59422037/cookie-policy",
    PRIVACY_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/privacy-policy/59422037",
    TERMS_AND_CONDITIONS: WHISTLEBASE_WEBSITE + languageCode + "/termini-e-condizioni/59422037",
  },
  en: {
    COOKIE_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/privacy-policy/59422037/cookie-policy",
    PRIVACY_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/privacy-policy/59422037",
    TERMS_AND_CONDITIONS: WHISTLEBASE_WEBSITE + languageCode + "/termini-e-condizioni/59422037",
  },
};

const defaultLanguage = "it";

const currentLanguageUrls = urlConfig[languageCode] || urlConfig[defaultLanguage];

export const COOKIE_POLICY = currentLanguageUrls.COOKIE_POLICY;
export const PRIVACY_POLICY = currentLanguageUrls.PRIVACY_POLICY;
export const TERMS_AND_CONDITIONS = currentLanguageUrls.TERMS_AND_CONDITIONS;
