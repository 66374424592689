import { Box, CircularProgress, Container, Dialog, IconButton, Tooltip } from "@mui/material";
import styles from "./PaginaPubblicaDipendente.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import { useParams } from "react-router-dom";
import QRCode from "../../../componets/qrcode/QRCode";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GoogleMapsCard from "../../../componets/GoogleMapsCard/GoogleMapsCard";
import { RegistryTypeEnum } from "../../../type/enum/registry-type.enum";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { IRegistry } from "../../../type/commons";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { EContactType } from "../../../type/enum/common.enum";

interface Registry {
  _id: string;
  id_registry_owner: RegistryOwner;
  id_registry_type: RegistryType[];
  firstname: string;
  surname: string;
  fiscal_code: string;
  id_contact: Contact[];
  enabled: boolean;
  id_auth_module: string;
  id_registry_address: Address[];
  tenant: string;
  isManual: boolean;
  key_code: string;
  profession: string;
  image: string;
}

interface RegistryOwner {
  _id: string;
  id_registry_owner?: RegistryOwner;
  id_registry_type: RegistryType[];
  business_name: string;
  vat_number: string;
  id_contact: Contact[];
  id_registry_address: Address[];
  tenant: string;
  isManual: boolean;
  key_code: string;
  image?: string;
}

interface RegistryType {
  _id: string;
  code: string;
  description: string;
}

interface Contact {
  _id: string;
  id_contact_type: ContactType | string;
  contact: string;
}

interface ContactType {
  _id: string;
  code: string;
  description: string;
}

interface Address {
  _id: string;
  id_registry_address_type: string;
  topon: string;
  street: string;
  number: number;
  postal_code: string;
  city: string;
  province: string;
  state: string;
  latitude?: any;
  longitude?: any;
}

const PaginaPubblicaDipendente = () => {
  const [registry, setRegistry] = useState<IRegistry | null>(null);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { businessName, displayName } = useParams();
  const { t } = useTranslation();
  const registryApiService = new RegistryApiService();

  const fetchDataRegistry = async () => {
    setIsLoading(true);
    try {
      const data = await registryApiService.getRegistryFromBusinessNameAndDisplayName(businessName ?? "", displayName ?? "");
      console.log(data);
      if (data) {
        setRegistry(data);
      } else {
        console.log(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataRegistry();
  }, []);

  function findContactByCode(registry: any, code: any) {
    if (!registry) return null;

    return registry.id_contact.find((contact: any) => {
      if (typeof contact.id_contact_type === "string") {
        return contact.id_contact_type === code;
      } else if (contact.id_contact_type && typeof contact.id_contact_type === "object" && "code" in contact.id_contact_type) {
        return contact.id_contact_type.code === code;
      }
      return false;
    });
  }
  const emailObj = registry ? findContactByCode(registry, EContactType.EMAIL) : null;
  const email = emailObj ? emailObj.contact : "Non disponibile";

  const phoneObj = registry ? findContactByCode(registry, EContactType.PHONE) : null;
  const phone = phoneObj ? phoneObj.contact : "Non disponibile";

  const facebookObj = registry ? findContactByCode(registry, EContactType.FACEBOOK) : null;
  const facebook = facebookObj ? facebookObj.contact : null;

  const instagramObj = registry ? findContactByCode(registry, EContactType.INSTAGRAM) : null;
  const instagram = instagramObj ? instagramObj.contact : null;

  const twitterObj = registry ? findContactByCode(registry, EContactType.TWITTER) : null;
  const twitter = twitterObj ? twitterObj.contact : null;

  const linkedinObj = registry ? findContactByCode(registry, EContactType.LINKEDIN) : null;
  const linkedin = linkedinObj ? linkedinObj.contact : null;

  let sedeAddress = "";
  if (registry && typeof registry?.id_registry_owner !== "string") {
    const sedeType = registry.id_registry_owner.id_registry_type.find(
      (type) => type.code === RegistryTypeEnum.SEDE || type.code === RegistryTypeEnum.AZIENDA,
    );
    if (sedeType) {
      const addressArray = registry.id_registry_owner.id_registry_address;
      if (addressArray.length > 0) {
        sedeAddress = `${addressArray[0].topon} ${addressArray[0].street} ${addressArray[0].number}, ${addressArray[0].postal_code} ${addressArray[0].city} ${addressArray[0].province}, ${addressArray[0].state}`;
      }
    }
  }

  const handleOpenQRModal = () => {
    setOpenQRModal(true);
  };

  const handleCloseQRModal = () => {
    setOpenQRModal(false);
  };

  const openEmail = () => {
    const emailLink = `mailto:${email}`;
    window.location.href = emailLink;
  };

  const openPhone = () => {
    const phoneLink = `tel:${phone}`;
    window.location.href = phoneLink;
  };

  const generateVCF = (registry: any) => {
    let vcfData = `BEGIN:VCARD\n`;
    vcfData += `VERSION:3.0\n`;
    vcfData += `N:${registry.surname};${registry.firstname};;;\n`;
    vcfData += `FN:${registry.firstname} ${registry.surname}\n`;
    // vcfData += `ORG:${registry.id_registry_owner.id_registry_owner.business_name}\n`;
    vcfData += `TITLE:${registry.profession}\n`;
    vcfData += `TEL;TYPE=WORK,VOICE:${phone}\n`;
    vcfData += `ADR;TYPE=WORK,PREF:;;${sedeAddress}\n`;
    vcfData += `EMAIL;TYPE=work:${email}\n`;
    vcfData += `URL;CHARSET=UTF-8:${facebook}\n`;
    vcfData += `URL;CHARSET=UTF-8:${instagram}\n`;
    vcfData += `URL;CHARSET=UTF-8:${twitter}\n`;
    vcfData += `URL;CHARSET=UTF-8:${linkedin}\n`;

    if (registry.image) {
      let imageType = registry.imageType;
      vcfData += `PHOTO;TYPE=${imageType};ENCODING=b:${registry.image.split(",")[1]}\n`;
    }

    vcfData += `END:VCARD`;
    return vcfData;
  };

  const downloadVCard = () => {
    const vcfText = generateVCF(registry);
    const element = document.createElement("a");
    const blob = new Blob([vcfText], { type: "text/vcard" });
    element.href = URL.createObjectURL(blob);
    element.download = `${registry?.firstname}_${registry?.surname}.vcf`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // const clickToOwner = () => {
  //   if (typeof registry?.id_registry_owner !== "string") {
  //     let businessName;
  //     if (registry?.id_registry_owner?.id_registry_owner && typeof registry?.id_registry_owner?.id_registry_owner !== "string") {
  //       businessName = registry?.id_registry_owner?.id_registry_owner?.business_name;
  //     } else {
  //       businessName = registry?.id_registry_owner?.business_name;
  //     }
  //     if (businessName) {
  //       const url = `/ecard/${businessName}`;
  //       window.open(url, "_blank");
  //     }
  //   }
  // };

  const clickToOwner = () => {
    if (businessName) {
      const url = `/ecard/${businessName}`;
      window.open(url, "_blank");
    }
  };

  const getImageOfRegistry = () => {
    if (
      typeof registry?.id_registry_owner !== "string" &&
      typeof registry?.id_registry_owner?.id_registry_owner !== "string" &&
      registry?.id_registry_owner?.id_registry_owner?.image
    ) {
      return registry?.id_registry_owner?.id_registry_owner?.image;
    } else if (typeof registry?.id_registry_owner !== "string" && !registry?.id_registry_owner?.id_registry_owner) {
      return registry?.id_registry_owner?.image;
    } else {
      return "https://ionicframework.com/docs/img/demos/avatar.svg";
    }
  };

  const getTitleTooltipFromRegistry = () => {
    if (
      typeof registry?.id_registry_owner !== "string" &&
      typeof registry?.id_registry_owner?.id_registry_owner !== "string" &&
      registry?.id_registry_owner?.id_registry_owner?.business_name
    ) {
      return registry?.id_registry_owner?.id_registry_owner?.business_name;
    } else if (typeof registry?.id_registry_owner !== "string" && !registry?.id_registry_owner?.id_registry_owner) {
      return registry?.id_registry_owner?.business_name;
    } else {
      return t("COMMONS.COMPANY");
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: window.location.href,
        });
        alert("URL condiviso con successo");
      } catch (error) {
        alert("Non è stato possibile condividere");
      }
    } else {
      alert("La funzione di condivisione non è supportata su questo browser");
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingRow}>
        <CircularProgress size={70} />
      </div>
    );
  }

  if (!registry) {
    return <div>Dati non disponibili</div>;
  }

  return (
    <div className={styles.background}>
      <Container maxWidth="sm" className={styles.container}>
        <div className={styles.iconContainer} onClick={clickToOwner}>
          <div className={styles.topRightIcon}>
            <Tooltip title={getTitleTooltipFromRegistry()} arrow>
              <Avatar src={getImageOfRegistry()} variant="rounded" onClick={clickToOwner} />
            </Tooltip>
          </div>
        </div>
        <div className={styles.topSection}>
          <div className={styles.containerImgInfo}>
            <div className={styles.imageContainer}>
              {registry?.image ? (
                <Avatar src={registry?.image} alt="user" variant="rounded" sx={{ width: "10em", height: "10em" }} />
              ) : (
                <Avatar alt="user" variant="rounded" sx={{ width: "10em", height: "10em" }} />
              )}
            </div>
            <div className={styles.userName}>
              {registry.firstname} {registry.surname}
            </div>
            <div className={styles.userProfession}>{registry.profession}</div>
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.infoBox} onClick={openEmail}>
            <div>
              <div className={styles.infoTitles}>Email</div>
              <div className={styles.infoSubTitle}>{email}</div>
            </div>
            <MailOutlineIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
          </div>
          <div className={styles.infoBox} onClick={openPhone}>
            <div>
              <div className={styles.infoTitles}>{t("FORM.PHONE")}</div>
              <div className={styles.infoSubTitle}>{phone}</div>
            </div>
            <PhoneIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
          </div>
          {sedeAddress && <div className={styles.infoBoxMap}>{<GoogleMapsCard address={sedeAddress} />}</div>}
          {facebook && (
            <a href={facebook} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <FacebookIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Facebook</div>
                  {/* <div className={styles.infoSubTitle}>{facebook}</div> */}
                </div>
              </div>
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <InstagramIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Instagram</div>
                  {/* <div className={styles.infoSubTitle}>{instagram}</div> */}
                </div>
              </div>
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <TwitterIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Twitter</div>
                  {/* <div className={styles.infoSubTitle}>{twitter}</div> */}
                </div>
              </div>
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
              <div className={styles.socialBox}>
                <LinkedInIcon sx={{ fontSize: "2.5rem", strokeWidth: 1.5 }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <div className={styles.infoTitles}>Linkedin</div>
                  {/* <div className={styles.infoSubTitle}>{linkedin}</div> */}
                </div>
              </div>
            </a>
          )}

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className={styles.buttonBars}>
              <IconButton onClick={handleShare}>
                <FileUploadOutlinedIcon className={styles.iconStyleActive} />
              </IconButton>
              <IconButton onClick={downloadVCard}>
                <AddCircleIcon className={styles.iconStyleActive} />
              </IconButton>
              <IconButton onClick={handleOpenQRModal}>
                <QrCodeRoundedIcon className={styles.iconStyleActive} />
              </IconButton>
            </div>
          </div>
        </div>
      </Container>
      <Dialog
        open={openQRModal}
        onClose={handleCloseQRModal}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div className={styles.qrDiv}>
          <QRCode />
        </div>
      </Dialog>
    </div>
  );
};
export default PaginaPubblicaDipendente;
