import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type BackButtonProps = {
  onClick: () => void;
  text?: string;
  fontSizeText?: string;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick, text, fontSizeText = "1.5rem" }) => {
	return (
		<div style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "fit-content" }} onClick={onClick}>
			<ArrowBackIcon />
			<div style={{ fontSize: fontSizeText }}>{text}</div>
		</div>
	);
};
export default BackButton;
