import { Box, Divider } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import i18n from "../../i18n";

import { RegistryApiService } from "../../services/RegistryApiService";
import { IRegistry } from "../../type/commons";
import { COOKIE_POLICY, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../../utils/legalLinks";

// export interface linksBE {
//   _id: string;
//   title: string;
//   link: string;
//   generic: boolean;
//   lang: string;
//   createdAt: string;
//   updatedAt: string;
// }

const Footer = () => {
  //TODO : TRANSLATE

  const links: { href: string; label: string }[] = [
    {
      href: COOKIE_POLICY,
      label: "COOKIE_POLICY",
    },
    {
      href: PRIVACY_POLICY,
      label: "PRIVACY_POLICY",
    },
    {
      href: TERMS_AND_CONDITIONS,
      label: "TERMS_AND_CONDITIONS",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative", // Mantiene il footer sempre visibile
        bottom: 0,
        left: 0,
        width: "100%", // Assicura che il footer si estenda per tutta la larghezza dello schermo
        backgroundColor: "background.paper", // Scegli un colore di sfondo appropriato
        // zIndex: "tooltip", // Assicura che il footer rimanga sopra agli altri elementi della pagina
        padding: "10px",
      }}
      // marginLeft="3%"
      // marginRight="3%"
      // marginY="50px"
    >
      <Box>
        <Divider />
      </Box>

      <footer className="footer">
        <Box sx={{ width: "100%" }} textAlign="end" color="grey.300">
          {links.map((link, index) => (
            <Box key={index} display="inline-block" marginX="5px">
              <a
                style={{
                  fontSize: "9px",
                  color: "black",
                }}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                title={t(`FOOTER.${link.label}`)}
              >
                {t(`FOOTER.${link.label}`)}
              </a>
              {index < links.length - 1 && " | "}
            </Box>
          ))}
        </Box>
      </footer>
    </Box>
  );
};

export default Footer;
