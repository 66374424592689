import { stringify } from "qs";

export class Util {
  public static getContact(row: any, contactType: string) {
    if (row?.id_contact) {
      const contact = row?.id_contact.filter((c: any) => c.id_contact_type.code === contactType);
      return contact[0].contact;
    } else {
      return "";
    }
  }

  public static verifyDuplicateCard(row: any, cardTypeCode: string) {
    if (row?.associated_card && row?.card_type) {
      return row?.card_type.code === cardTypeCode;
    } else {
      return false;
    }
  }

  public static getSearchParams(params: any, suffix?: string) {
    const copyParams = params;
    suffix = "?";
    Object.entries(copyParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        suffix = suffix + value.map((keyValuePair) => new URLSearchParams({ [key]: keyValuePair })).join("&") + "&";
        delete params[key];
      }
    });

    return suffix + stringify(params);
  }

  public static formatStripePrice = (price: string): string => {
    return (parseInt(price, 10) / 100).toFixed(2);
  };

  public static normalizeUrl = (url: string) => {
    const timmedUrl = url.replace(/\s/g, "");
    if (timmedUrl && !timmedUrl.startsWith("http://") && !timmedUrl.startsWith("https://")) {
      return `https://${timmedUrl}`;
    }
    return timmedUrl;
  };
}
