import { CircularProgress, IconButton, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { Table, Toast } from "react-bootstrap";
import { toast_error, toast_success } from "../../../utils/custom-toast";
import styles from "../../../componets/tabelle/TabellaListaAziende.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RegistryApiService } from "../../../services/RegistryApiService";
import { EAuthRole, ERegistryType } from "../../../type/enum/common.enum";
import { IRegistry } from "../../../type/commons";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import { use } from "i18next";
import ConfirmDialog from "../../../componets/dialog/ConfirmDialog";
import PasswordDialog from "../../../componets/dialog/PasswordDialog";
import { CoreApiService } from "../../../services/CoreApiService";
import { ToastContainer } from "react-toastify";

interface Props {
  searchTerm?: string;
  idOwner?: string;
}

const TabellaAdminAzienda: React.FC<Props> = ({ searchTerm = "", idOwner }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [dataHasLoaded, setDataHasLoaded] = useState(false);
  const [admin, setAdmin] = useState<IRegistry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const registryApiService = new RegistryApiService();
  const itemsPerPage = 6;
  const [refresh, setRefresh] = useState<boolean>(false);

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const coreApiService = new CoreApiService();

  const countData = async (): Promise<void> => {
    const params: any = {
      registry_type: ERegistryType.AMMINISTRATORE,
      searchFullText: searchTerm,
      searchFields: ["firstname", "surname"],
      id_registry_owner: id,
      // tenant: id,
    };

    if (role === EAuthRole.SUPERADMIN) {
      params.id_registry_owner = id;
      params.get_nephew = true;
    }

    const itemCount = await registryApiService.countRegistries(params);
    setTotalItems(itemCount);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params: any = {
        page: currentPage,
        limit: itemsPerPage,
        orderBy: "createdAt",
        searchFullText: searchTerm,
        searchFields: ["firstname", "surname"],
        registry_type: ERegistryType.AMMINISTRATORE,
        // id_registry_owner: id,
        // tenant: id,
      };

      if (role === EAuthRole.SUPERADMIN) {
        params.id_registry_owner = id;
        params.get_nephew = true;
      }

      const data = await registryApiService.fetchAllRegistry(params);
      if (data) {
        setAdmin(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setIsLoading(false);
      setDataHasLoaded(true);
    }
  };

  const fetch = async () => {
    await countData();
    if (!searchTerm || searchTerm.length >= 3) {
      await fetchData();
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage, searchTerm, refresh]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const handleRowClick = (id: string, idGranparent: string) => {
    navigate(`/azienda/dipendenti/dettaglio-dipendente/informazioni-personali/${id}/${idGranparent}`);
  };

  const handleOpenConfirm = (id: string) => {
    setOpenConfirmModal(true);
    setSelectedId(id);
  };

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenConfirmModal(false);
    setOpenDeleteModal(true);
  };
  const handleClosePasswordDialog = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await coreApiService.closeAccount(id);
      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        setRefresh(!refresh);
        handleClosePasswordDialog();
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setOpenConfirmModal(false);
      // handleClosePasswordDialog();
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={12} rowSpan={12} className={styles.loadingRow}>
            <CircularProgress size={70} />
          </td>
        </tr>
      );
    }

    if (dataHasLoaded && admin.length === 0) {
      return (
        <tr>
          <td colSpan={12} className={styles.rowStyle}>
            {t("COMMONS.NO_DATA")}
          </td>
        </tr>
      );
    }

    return admin.map((admin) => (
      <tr key={admin._id}>
        <td className={styles.rowStyleFirstElement}>
          <div style={{ display: "flex", verticalAlign: "middle", alignItems: "center", textTransform: "capitalize" }}>
            {admin.image ? (
              <div>
                <img className={styles.imageDiv} src={admin.image} alt="User" />
              </div>
            ) : (
              <img className={styles.imageDiv} src="https://via.placeholder.com/100" alt="User" />
            )}
            <div style={{ marginRight: "5px" }}>{admin.firstname}</div>
            <div>
              {admin.surname}
              <span style={{ textTransform: "none" }}>{admin.deleted ? "_deleted" : ""}</span>
            </div>
          </div>
        </td>
        {/* <td className={styles.rowStyle}>{admin.surname}</td> */}
        <td className={styles.rowStyle}>
          {admin.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact}
        </td>
        <td className={styles.rowStyle}>
          {admin.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "PHONE")?.contact}
        </td>

        <td className={styles.rowStyle}>
          {
            !admin.deleted &&
              (typeof admin.id_registry_owner !== "string"
                ? admin.id_registry_owner.business_name // Se sì, mostra business_name
                : "") // Altrimenti, mostra una stringa vuota
          }
        </td>
        <td className={styles.rowStyle}>
          {admin.displayName && !admin.deleted && (
            <Link to={`/ecard/${sessionStorage.getItem("business_name")}/${admin.displayName}`} target="_blank">
              <div>{t("FORM.ECARD")}</div>
            </Link>
          )}
        </td>
        <td>
          <div style={{ display: "flex" }}>
            {!admin.deleted && (
              <>
                <div>
                  <IconButton onClick={() => handleRowClick(admin._id, idOwner!)} color="default">
                    <ModeIcon />
                  </IconButton>
                </div>
                {role === EAuthRole.SUPERADMIN && (
                  <div>
                    <IconButton onClick={() => handleOpenConfirm(admin._id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <Table responsive>
        <ToastContainer />
        <thead className={styles.thead}>
          <ConfirmDialog
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            onOpen={() => setOpenConfirmModal(true)}
            onClose={handleCloseConfirm}
            onConfirm={handleOpenPasswordDialog}
            message={t("DIALOG_DELETE.ADMIN")}
          />
          <PasswordDialog
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onOpen={handleOpenPasswordDialog}
            onClose={handleClosePasswordDialog}
            onConfirm={() => handleDelete(selectedId)}
          />
          {!dataHasLoaded && isLoading ? (
            <tr>
              <th className={styles.rowStyle}></th>
            </tr>
          ) : (
            <tr>
              <th className={styles.rowStyle}>{t("FORM.NAME")}</th>
              <th className={styles.rowStyle}>{t("FORM.EMAIL")}</th>
              <th className={styles.rowStyle}>{t("FORM.PHONE")}</th>
              <th className={styles.rowStyle}>{t("COMMONS.HEADQUARTER")}</th>
              <th className={styles.rowStyle}>ECard</th>
              <th className={styles.rowStyle}></th>
            </tr>
          )}
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      {!isLoading && dataHasLoaded && admin.length > 0 && totalItems > itemsPerPage ? (
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          shape="rounded"
          showFirstButton
        />
      ) : null}
    </div>
  );
};

export default TabellaAdminAzienda;
