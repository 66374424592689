import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Image from "react-bootstrap/Image";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import styles from "../add-edit-template/AddEditTemplate.module.css";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import idConnect from "../../../../img/logoID.svg";
import qrCode from "../../../../img/qr-code-idconnect.svg";
import { BackCardTemplateProps } from "../../../../type/props/card-template-props";

const BackCard: React.FC<BackCardTemplateProps> = ({ image, color, textColor, design, color2 }) => {
  const [widthCard, setWidthCard] = useState<string | undefined>();
  const [heightCard, setHeightCard] = useState<string | undefined>();

  const defaultWidthCard = "680px";
  const defaultHeightCard = "378px";
  useEffect(() => {
    if (design !== undefined && design.card !== undefined) {
      setWidthCard(design.card.width);
      setHeightCard(design.card.height);
    } else {
      setWidthCard(defaultWidthCard);
      setHeightCard(defaultHeightCard);
    }
  }, []);

  return (
    <>
      <Card style={{ borderRadius: "12px", height: heightCard, width: widthCard, backgroundColor: color2 }} elevation={3}>
        <CardContent>
          <Grid container direction="row" spacing={2} position={"relative"}>
            <Grid item xs={8} minHeight={design ? design.imageLogo.grid.minHeight : "60px"}>
              {!image && (
                <Avatar sx={{ bgcolor: "#E5E4E2" }} variant="rounded">
                  <Image src={idConnect} />
                </Avatar>
              )}
              <Image
                src={image}
                rounded
                style={{
                  maxHeight: design ? design.imageLogo.image.maxHeight : "60px",
                  maxWidth: design ? design.imageLogo.image.maxWidth : "60px",
                }}
              />
            </Grid>
            <Grid item xs={8} marginBottom={design ? design.content.grid.marginBottom : "20px"}>
              <Box justifyContent={"flex-start"} display={"flex"}>
                {/*<PersonIcon style={{color: color, fontSize: design ? design.content.name.iconFontSize : 34}}/>*/}
                <Typography
                  color={textColor}
                  variant={design ? design.content.name.textVariant : "h4"}
                  paddingLeft={design ? design.content.name.paddingLeft : "5px"}
                >
                  Mario Rossi
                </Typography>
              </Box>
              <Box marginBottom={design ? design.content.subname.marginBottom : "30px"} justifyContent={"flex-start"} display={"flex"}>
                <Typography
                  color={textColor}
                  fontWeight={400}
                  variant={design ? design.content.subname.textVariant : "h5"}
                  paddingLeft={design ? design.content.subname.paddingLeft : "5px"}
                >
                  CEO
                </Typography>
              </Box>
              <Box marginBottom={design ? design.content.email.marginBottom : "20px"} justifyContent={"flex-start"} display={"flex"}>
                <EmailOutlinedIcon style={{ color: color, fontSize: design ? design.content.email.iconFontSize : 22 }} />
                <Typography
                  color={textColor}
                  fontWeight={400}
                  variant={design ? design.content.email.textVariant : "subtitle1"}
                  paddingLeft={design ? design.content.email.paddingLeft : "10px"}
                >
                  m.rossi@email.com
                </Typography>
              </Box>
              <Box marginBottom={design ? design.content.phone.marginBottom : "20px"} justifyContent={"flex-start"} display={"flex"}>
                <LocalPhoneOutlinedIcon style={{ color: color, fontSize: design ? design.content.phone.iconFontSize : 22 }} />
                <Typography
                  color={textColor}
                  fontWeight={400}
                  variant={design ? design.content.phone.textVariant : "subtitle1"}
                  paddingLeft={design ? design.content.phone.paddingLeft : "10px"}
                >
                  3345679186
                </Typography>
              </Box>
              <Box justifyContent={"flex-start"} display={"flex"}>
                <DesktopWindowsOutlinedIcon style={{ color: color, fontSize: design ? design.content.web.iconFontSize : 22 }} />
                <Typography
                  color={textColor}
                  fontWeight={400}
                  variant={design ? design.content.web.textVariant : "subtitle1"}
                  paddingLeft={design ? design.content.web.paddingLeft : "10px"}
                >
                  www.m.rossi.com
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} position={"absolute"} top={design ? design.imageQr.grid.top : 30} right={design ? design.imageQr.grid.right : 50}>
              <Box display="flex" justifyContent="center">
                <Image
                  src={qrCode}
                  style={{ maxHeight: design ? design.imageQr.qr.maxHeight : "150px", maxWidth: design ? design.imageQr.qr.maxWidth : "150px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActionArea disabled>
          <Grid item xs={12}>
            <Box display="flex" bgcolor={color} color={textColor} alignItems="center" justifyContent="center">
              {" "}
              <div
                className={styles.logoFooterCard}
                style={{
                  minWidth: design ? design.footer.logo.minWidth : "1.7rem",
                  minHeight: design ? design.footer.logo.minHeight : "2rem",
                  margin: design ? design.footer.logo.margin : "0.5rem",
                }}
              ></div>
              <Box fontWeight={"bold"}> </Box>
            </Box>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  );
};
export default BackCard;