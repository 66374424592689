import Cookies from "js-cookie";
import { AUTH_URL } from "../../api/api";
import { ApiService } from "./ApiService";
import { IAuthRefreshToken } from "../../type/auth";
import { IApiResponse } from "../../type/commons";
import { EAuthSessionData } from "../../type/enum/auth.enum";
import axios, { AxiosInstance } from "axios";

export class RefreshApiService {
  private _axiosInstance: AxiosInstance;
  constructor() {
    this._axiosInstance = axios.create({
      baseURL: AUTH_URL,
    });
    this._setHeaders();
  }

  private _setHeaders(): void {
    this._axiosInstance.interceptors.request.use((config) => {
      config.headers["x-access-token"] = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN) || "";
      return config;
    });
  }

  /**
   * Metodo per la gestione del refresh dei token
   * @private
   */
  async refreshTokenApi<IAuthSignInResponse>(): Promise<IAuthSignInResponse> {
    const rToken = Cookies.get(EAuthSessionData.REFRESH_TOKEN);

    if (rToken) {
      const data = { refreshToken: rToken };
      const res = await this._axiosInstance.post<IAuthRefreshToken, IApiResponse<IAuthSignInResponse>>("/auth/refresh", data);
      return res.data;
    } else {
      throw new Error();
    }
  }
}
