import React, { useEffect, useState } from "react";
import { API_GET_REGISTRIES_CARD_REQUEST } from "../../../api/api";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid";
import DataTable from "../../../componets/data-table/DataTable";
import Avatar from "@mui/material/Avatar";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectTemplateDialog from "../components/dialog/SelectTemplateDialog";
import { ITemplate } from "../../../type/template";
import { ToastContainer } from "react-toastify";
import { toast_error } from "../../../utils/custom-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Util } from "../../../utils/util";
import { CustomFilterList } from "../../../type/data-table";
import { ContactTypeEnum } from "../../../type/enum/contact-type.enum";
import { RegistryTypeEnum } from "../../../type/enum/registry-type.enum";
import CommonDialog from "../../../componets/common-dialog/CommonDialog";
import CompletePurchase from "./CompletePurchase";
import { CoreApiService } from "../../../services/CoreApiService";
import { Spinner } from "react-bootstrap";

const Request: React.FC = () => {
  const { state } = useLocation();
  const cardType = state.cardType;
  const maxCard = state.maxCard;
  const cardTypeDescription = state.cardTypeDescription;
  const [searchParams, setSearchParams] = useState({ registry_type: RegistryTypeEnum.DIPENDENTE, card_type: cardType });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  console.log("rowSelectionModel", rowSelectionModel.length);
  const { t } = useTranslation();
  const selectedUser = sessionStorage.getItem("selectedUser");
  const defaultTemplate = sessionStorage.getItem("defaultNameTemplate") ?? "";
  const [disableProceed, setDisableProceed] = useState<boolean>(true);
  const [selectTemplateDialog, setSelectTemplateDialog] = useState<boolean>(false);
  const [nameTemplate, setNameTemplate] = useState<string>("");
  const [defaultSelectedTemplate, setDefaultSelectedTemplate] = useState<string>(defaultTemplate);
  const [openSelectAdressDialog, setOpenSelectAdressDialog] = useState(false);
  const [loadingSendCard, setLoadingSendCard] = useState<boolean>(false);
  const [duplicatedCard, setDuplicatedCard] = useState<boolean>(false);
  const [completePurchase, setCompletePurchase] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate>();

  const [checked, setChecked] = useState<boolean>(false);
  const [summaryDialog, setSummaryDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const navigate = useNavigate();
  const coreApiService = new CoreApiService();

  const columns: GridColDef[] = [
    {
      field: "firstname",
      headerName: t("FORM.NAME"),
      width: 220,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Avatar
            alt={`${params.row.firstname || ""} ${params.row.surname || ""}`}
            src={params.row?.image}
            variant="rounded"
            sx={{ marginRight: "10px" }}
          />
          {`${params.row.firstname || ""} ${params.row.surname || ""}`}
        </>
      ),
    },
    {
      field: "role",
      headerName: t("FORM.ROLE"),
      width: 180,
      valueGetter: (params: GridValueGetterParams) => `${params.row.id_registry_type[0].code || ""}`,
      filterable: false,
    },
    {
      field: "email",
      headerName: t("COMMONS.EMAIL"),
      width: 270,
      filterable: false,
      valueGetter: (params: GridValueGetterParams) => `${Util.getContact(params.row, ContactTypeEnum.EMAIL)}`,
    },
    {
      field: "phone",
      headerName: t("COMMONS.TABLE.PHONE"),
      width: 170,
      filterable: false,
      valueGetter: (params: GridValueGetterParams) => `${Util.getContact(params.row, ContactTypeEnum.PHONE)}`,
    },
    {
      field: "headquarters",
      headerName: t("COMMONS.HEADQUARTERS"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => `${params.row?.id_registry_owner?.business_name || ""}`,
      filterable: false,
    },
    {
      field: "associated_card",
      headerName: t("SUPPLIER.REQUEST_STATE"),
      width: 170,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {Util.verifyDuplicateCard(params.row, cardType) && <Chip label={t("SUPPLIER.DUPLICATED_REQUEST")} color={"warning"} />}
          {!Util.verifyDuplicateCard(params.row, cardType) && <Chip label={t("SUPPLIER.NEW_REQUEST")} color={"success"} />}
        </>
      ),
      filterable: false,
    },
  ];

  const customFilterList: CustomFilterList[] = [
    {
      field: "firstname",
      headerName: t("FORM.NAME"),
    },
    { field: "surname", headerName: t("FORM.SURNAME") },
  ];

  /**
   * This is an event handler function that gets triggered when a row is selected in the data grid.
   * It is used to enable the proceed button by setting the 'disableProceed' state to false.
   * The 'disableProceed' state is initially set to true to disable the proceed button.
   * However, once a user selects a row in the data table, we want to allow them to proceed,
   * hence we need to set the 'disableProceed' state to false.
   * This function does not accept any arguments and does not return any value.
   */
  useEffect(() => {
    if (selectedUser != null) {
      const rowSelected = JSON.parse(selectedUser) as GridRowSelectionModel;
      setRowSelectionModel(rowSelected);
    }

    if (selectedUser != null || defaultTemplate != "") {
      setSelectTemplateDialog(true);
    }
  }, []);

  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      setDisableProceed(false);
    } else {
      setDisableProceed(true);
    }
  }, [rowSelectionModel]);

  const onProceed = () => {
    // setSelectTemplateDialog(true);
    setOpenDialogConfirm(true);
  };

  const closeDuplicatedCardDialog = () => {
    setDuplicatedCard(false);
  };

  const openDuplicatedCardDialog = () => {
    setDuplicatedCard(true);
  };

  const closeCompletePurchase = () => {
    setCompletePurchase(false);
  };

  const handleCancelSummary = () => {
    setChecked(false);
    setSummaryDialog(false);
    setOpenDialogConfirm(false);
  };

  const sendCard = async (force: boolean = false) => {
    try {
      setLoadingSendCard(true);
      const res = await coreApiService.sendCard(rowSelectionModel as string[], cardType, force);
      if (res) {
        setCompletePurchase(true);
        setOpenDialogConfirm(false);
        setRowSelectionModel([]);
      }
    } catch (e: any) {
      console.error(e);

      if (e === "error.ERROR_REGISTRY_ALREADY_ASSOCIATED_TO_CARD") {
        // setSelectedTemplate(template);
        openDuplicatedCardDialog();
      } else {
        toast_error(e);
      }
    } finally {
      setLoadingSendCard(false);
    }
  };

  // const confirmNewTemplate = () => {
  //   sessionStorage.setItem("selectedUser", JSON.stringify(rowSelectionModel));
  //   navigate("/card-template", { state: { name: nameTemplate, urlToNavigate: "/new-request", ...state } });
  // };

  const forceSendCard = async () => {
    closeDuplicatedCardDialog();
    // if (selectedTemplate) {
    await sendCard(true);
    // }
  };

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} />
      <Typography variant={"h1"}>{t("SUPPLIER.SELECT_EMPLOYEES")}</Typography>

      <div>
        <DataTable
          columns={columns}
          paginationUrl={API_GET_REGISTRIES_CARD_REQUEST}
          selectionEnabled={true}
          onRowSelectionChange={setRowSelectionModel}
          rowSelected={rowSelectionModel}
          searchParams={searchParams}
          onlyCustomFilter={true}
          customFilterList={customFilterList}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"flex-end"} marginTop={5}>
            <Button variant={"contained"} sx={{ textTransform: "Capitalize", borderRadius: 5 }} disabled={disableProceed} onClick={onProceed}>
              {t("COMMONS.PROCEED")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* <SelectTemplateDialog
        selectTemplateDialog={selectTemplateDialog}
        closeSelectTemplateDialog={setSelectTemplateDialog}
        selectedUser={rowSelectionModel}
        cardType={cardType}
        proceedOrder={sendCard}
        onSetNameTemplate={(name) => setNameTemplate(name)}
        onConfirmNameTemplate={confirmNewTemplate}
        defaultSelectedTemplate={defaultSelectedTemplate}
        maxCard={maxCard}
        loading={loadingSendCard}
        cardTypeDescription={cardTypeDescription}
      />

      {selectedTemplate && (
        <CommonDialog
          open={openDialogConfirm}
          onClose={closeDuplicatedCardDialog}
          title={t("SUPPLIER.CARDS_DUPLICATED")}
          descriptionContent={t("SUPPLIER.CARDS_DUPLICATED_DESCRIPTION")}
          onConfirm={forceSendCard}
        />
      )} */}

      <CommonDialog
        open={duplicatedCard}
        onClose={closeDuplicatedCardDialog}
        title={t("SUPPLIER.CARDS_DUPLICATED")}
        descriptionContent={t("SUPPLIER.CARDS_DUPLICATED_DESCRIPTION")}
        onConfirm={forceSendCard}
      />

      <Dialog
        open={openDialogConfirm}
        onClose={() => setOpenDialogConfirm(false)}
        fullWidth
        // maxWidth={"lg"}
        sx={{ "& .MuiDialogContent-root": { padding: "1px 16px" } }}
      >
        <DialogContent>
          {/* <Grid container spacing={1}> */}
          {/* <Grid item xs={6} sx={{ backgroundColor: "#E5E4E2" }}>
              <Box marginTop={4}>
                <PreviewCard />
                </Box>
            </Grid> */}

          {/* <Grid item xs={6}> */}
          <Box justifyContent={"space-between"} display={"flex"} marginTop={2}>
            <Typography variant={"subtitle1"} sx={{ fontWeight: 400 }}>
              {t("SUPPLIER.QUANTITY")}
            </Typography>
            <Typography variant={"subtitle1"}>{rowSelectionModel.length}</Typography>
          </Box>
          <Divider color={"#000000"} sx={{ marginTop: 1, marginBottom: 2 }} />
          <Box justifyContent={"space-between"} display={"flex"}>
            <Typography variant={"subtitle1"} sx={{ fontWeight: 400 }}>
              {t("SUPPLIER.FINISHING")}
            </Typography>
            <Typography variant={"subtitle1"}>{t(cardTypeDescription)}</Typography>
          </Box>
          <Divider color={"#000000"} sx={{ marginTop: 1, marginBottom: 2 }} />
          <Box justifyContent={"space-between"} display={"flex"}>
            <Typography variant={"subtitle1"} sx={{ fontWeight: 400 }}>
              {t("SUPPLIER.SHIPPING")}
            </Typography>
            <Typography variant={"subtitle1"}>3-4 gg lavorativi</Typography>
          </Box>
          <Divider color={"#000000"} sx={{ marginTop: 1, marginBottom: 2 }} />
          {/* <Box justifyContent={"space-between"} display={"flex"} marginBottom={4}> */}
          {/* <Typography variant={"subtitle1"} sx={{ fontWeight: 400 }}>
              {t("SUPPLIER.SHIPPING_ADDRESS")}
            </Typography> */}
          {/* <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant={"subtitle1"}>{`${addressRecap?.country}, ${addressRecap?.city} - ${addressRecap?.zip_code}`}</Typography>
                  <Typography variant={"subtitle1"}>{`${addressRecap?.address}`}</Typography>
                </Box> */}
          {/* </Box> */}

          <FormGroup>
            <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckChange} />} label={t("SUPPLIER.REVIEWED_ORDER")} />
          </FormGroup>
          {/* <Box marginTop={2}>
                <Alert severity={"info"} color={"warning"} sx={{ mb: 2 }}>
                  <AlertTitle sx={{ fontWeight: 800 }}>
                    {selectedUser!.length} {t("SUPPLIER.REMAINING_CARDS")}
                  </AlertTitle>
                </Alert>
              </Box> */}
          <Box display={"flex"} justifyContent={"space-between"} marginTop={5} marginBottom={2}>
            <Button autoFocus onClick={handleCancelSummary} variant={"outlined"} sx={{ textTransform: "Capitalize" }}>
              {t("COMMONS.CANCEL")}
            </Button>
            <Button autoFocus disabled={!checked} variant={"contained"} sx={{ textTransform: "none" }} onClick={() => sendCard()}>
              {!loading ? t("SUPPLIER.PROCEED_WITH_ORDER") : <Spinner animation="border" />}
            </Button>
          </Box>
          {/* </Grid> */}
          {/* </Grid> */}
        </DialogContent>
      </Dialog>

      <CompletePurchase open={completePurchase} onClose={closeCompletePurchase} />
      {/* Aggiungere dialog full screen con schermata di invio avvenuto con successo, al close mandiamo su storico richieste, al click del bottone andiamo al dettaglio della richiesta appena effettuata*/}
    </>
  );
};
export default Request;
