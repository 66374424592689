import { ApiService } from "./shared/ApiService";
import { ISearchParams } from "../type/data-table";
import {
  IApiPaginationResponse,
  IApiResponse,
  IBulkDeleteRegistryPayload,
  IContactType,
  IRegistry,
  IRegistryAddressType,
  IRegistryType,
  TApiListSearchParam,
} from "../type/commons";
import { IFilterRequestDetail } from "../type/supplier-type";
import { REGISTER_URL } from "../api/api";
import { EContactType, ERegistryAddressType, ERegistryType } from "../type/enum/common.enum";
import { Dipendente } from "../type/dipendente";

export class RegistryApiService extends ApiService {
  private _apiGetRegistries = "/registry/getRegistries";
  private _apiDeleteFullRegistry = "/registry/full/";
  private _apiInfoMe = "/registry/info/me";
  private _apiRegistryType = "/registry/registry-type";
  private _apiContactType = "/registry/contact-type";
  private _apiRegistryAddressType = "/registry/registry-address-type";
  private _apiFullRegistry = "/registry/full";
  private _apiBulkCreateEmployee = "/registry/bulkCreateEmployee";
  private _apiBulkDeleteRegistry = "/registry/bulkDelete";
  private _apiImportCsv = "/registry/import/csv";
  private _apiDownloadCsv = "/registry/download-template/csv";
  private _apiSearchRegistryFromKeyCode = "/registry/search/keyCode";
  private _apiGetRegistryById = "/registry/";
  private _apiDeleteContact = "/contact/";
  private _apiUpdateRegistryFull = "/registry/full/";
  private _apiCreateEmployee = "/registry/createEmployee";
  private _apiDeleteRegistryImage = "/registry/image/";
  private _apiCreateCompany = "/registry/createCompany";
  private _apiCreateAdmin = "/registry/createAdminUser";
  private _apiVerifyDisplayName = "/registry/verifyDisplayName";
  private _apiRegistryByDisplayName = "/registry/public/registryByDisplayName";
  private _apiRegistryByBusinessName = "/registry/public/registryByBusinessName";

  constructor() {
    super({ baseUrl: REGISTER_URL });
  }

  async getRegistries(registries: any[], searchParams: ISearchParams): Promise<IApiPaginationResponse<any>> {
    let filter: IFilterRequestDetail = {};
    if (searchParams?.searchField === "email" || searchParams.searchFields?.includes("email")) {
      filter = { ...filter, contact_type: "EMAIL", contact: searchParams.searchFullText };
    }

    if (searchParams?.searchField === "phone" || searchParams.searchFields?.includes("phone")) {
      filter = { ...filter, contact_type: "PHONE", contact: searchParams.searchFullText };
    }

    if (searchParams?.searchField === "firstname" || searchParams.searchFields?.includes("firstname")) {
      filter = { ...filter, firstname: searchParams.searchFullText };
    }

    if (searchParams?.searchField === "surname" || searchParams.searchFields?.includes("surname")) {
      filter = { ...filter, surname: searchParams.searchFullText };
    }

    if (searchParams?.searchField === "headquarters" || searchParams.searchFields?.includes("headquarters")) {
      filter = { ...filter, headquarters_name: searchParams.searchFullText, registry_type: "SEDE" };
    }

    const data = await this.post<any, any[]>(`${this._apiGetRegistries}`, { registries: registries, filter: filter }); // recupero registry dell'ordine
    const pagination = data.length; // gestisco la paginazione in base al numero di registry mandato precedentemente e mappo i dati
    const res: IApiPaginationResponse<any> = {
      data: data,
      pagination: pagination,
    };

    return res;
  }

  async countRegistriesByCode(code: ERegistryType) {
    return new Promise<number>(async (resolve, reject) => {
      try {
        const response = await this.countRegistries({ registry_type: code });
        return resolve(response);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async countRegistries(params?: TApiListSearchParam<IRegistry>): Promise<number> {
    return this.listCount<any>("registry", params);
  }

  async fetchAllRegistry(params: TApiListSearchParam<IRegistry>): Promise<IRegistry[]> {
    return this.listSearch<IRegistry>("registry", params);
  }

  async deleteRegistry(id: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await this.delete<any>(`${this._apiDeleteFullRegistry}${id}`);
        return resolve(response);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async infoMe(): Promise<any> {
    return this.get<any>(this._apiInfoMe);
  }

  async infoMeCompleteResponse() {
    return this.get(this._apiInfoMe);
  }

  async fetchRegistryTypeIdByCode(code: ERegistryType) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = await this.fetchRegistryType({ code: code });
        if (response.length > 0) {
          return resolve(response[0]._id);
        } else {
          return reject();
        }
      } catch (e) {
        return reject(e);
      }
    });
  }

  async fetchContactTypeIdByCode(code: EContactType) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = await this.fetchContactType({ code: code });
        if (response.length > 0) {
          return resolve(response[0]._id);
        } else {
          return reject();
        }
      } catch (e) {
        return reject(e);
      }
    });
  }

  async fetchRegistryAddressTypeIdByCode(code: ERegistryAddressType) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = await this.fetchRegistryAddressType({ code: code });
        if (response.length > 0) {
          return resolve(response[0]._id);
        } else {
          return reject();
        }
      } catch (e) {
        return reject(e);
      }
    });
  }

  async fetchRegistryAddressType(params?: TApiListSearchParam<IRegistryAddressType>) {
    return this.listSearch<IRegistryAddressType>(this._apiRegistryAddressType, params);
  }

  async fetchContactType(params?: TApiListSearchParam<IContactType>) {
    return this.listSearch<IContactType>(this._apiContactType, params);
  }

  async fetchRegistryType(params?: TApiListSearchParam<IRegistryType>) {
    return this.listSearch<IRegistryType>(this._apiRegistryType, params);
  }

  async createFullRegistry(body: any) {
    return this.post<any, any>(this._apiFullRegistry, body);
  }

  async bulkCreateEmployee(body: any, headers?: any) {
    return this.post<any, any>(this._apiBulkCreateEmployee, headers, body);
  }

  async bulkDelete(body: IBulkDeleteRegistryPayload) {
    return this.post<any, any>(this._apiBulkDeleteRegistry, body);
  }

  async importCsv(body: any, headers?: any) {
    return this.post<any, any>(this._apiImportCsv, headers, body);
  }

  async downloadTemplate() {
    return this.get<any>(this._apiDownloadCsv, { responseType: "blob" });
  }

  async searchRegistryFromKeycode(keycode: string) {
    return this.get<IRegistry>(this._apiSearchRegistryFromKeyCode, { headers: { "x-key-code": keycode } });
  }

  async getRegistryById(id: string) {
    return this.get<IRegistry>(this._apiGetRegistryById + id);
  }

  async deleteContactById(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await this.delete(this._apiDeleteContact + id);
        return resolve(response);
      } catch (e) {
        return reject(e);
      }
    });
  }

  async updateRegistryFull(id: string, body: any) {
    return this.patch<any, any>(this._apiUpdateRegistryFull + id, body);
  }

  async createEmployee(body: any) {
    return this.post<any, any>(this._apiCreateEmployee, body);
  }

  async deleteRegistryImage(id: string) {
    return this.delete<IApiResponse<any>>(this._apiDeleteRegistryImage + id);
  }

  async createCompany(body: any) {
    return this.post(this._apiCreateCompany, body);
  }

  async createAdmin(body: any) {
    return this.post(this._apiCreateAdmin, body);
  }

  async getEmployeeById(id: string) {
    return this.get<Dipendente>(this._apiGetRegistryById + id);
  }

  async verifyUniqueDisplayName(displayName: string) {
    return this.post(this._apiVerifyDisplayName, { displayName: displayName });
  }

  async getRegistryFromBusinessNameAndDisplayName(businessName: string, displayName: string) {
    return this.post<any, IRegistry>(this._apiRegistryByDisplayName, { displayName: displayName, businessName: businessName });
  }

  async getRegistryFromBusinessName(businessName: string) {
    return this.post<any, IRegistry>(this._apiRegistryByBusinessName, { businessName: businessName });
  }
}
