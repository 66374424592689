import React from "react";
import { CommonDialogProps } from "../../type/props/common.props";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

/**
 *
 * @constructor
 */
const CommonDialog: React.FC<CommonDialogProps> = ({ open, onClose, onConfirm, title, descriptionContent }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{descriptionContent}</DialogContent>
      <DialogActions>
        <Box marginRight={"18px"}>
          <Button sx={{ textTransform: "Capitalize", mr: 1 }} onClick={onClose}>
            {t("COMMONS.CANCEL")}
          </Button>
          <Button sx={{ textTransform: "Capitalize" }} variant={"contained"} onClick={onConfirm}>
            {t("COMMONS.CONFIRM")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default CommonDialog;