import { Button, DialogTitle, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import BackCard from "../template-card/BackCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ForeheadCard from "../template-card/ForeheadCard";
import { useTranslation } from "react-i18next";
import { PreviewCardDialogProps } from "../../../../type/props/card-template-props";

const PreviewCardDialog: React.FC<PreviewCardDialogProps> = ({
  previewDialog,
  closePreviewDialog,
  image,
  color,
  textColor,
  backCardDesign,
  foreheadCardDesign,
  color2,
}) => {
  const [value, setValue] = useState<number>(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog open={previewDialog} onClose={() => closePreviewDialog(false)}>
        <DialogTitle>
          <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
            {t("TEMPLATE.PREVIEW")}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            minHeight={"350px"}
            minWidth={"500px"}
            bgcolor={"#E5E4E2"}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            borderRadius={2}
          >
            <Stack spacing={2} justifyContent="center" alignItems="center">
              {value === 0 && <ForeheadCard image={image} color={color} design={foreheadCardDesign} />}
              {value === 1 && <BackCard image={image} color={color} textColor={textColor} design={backCardDesign} color2={color2} />}

              <Box sx={{ marginTop: "20px", marginBottom: "20px" }} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                <Tabs value={value} onChange={handleChange} centered>
                  <Tab label={t("TEMPLATE.FRONT_SIDE")} sx={{ textTransform: "Capitalize" }} />
                  <Tab label={t("TEMPLATE.REAR_SIDE")} sx={{ textTransform: "Capitalize" }} />
                </Tabs>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => closePreviewDialog(false)}>
            {t("COMMONS.CLOSE")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PreviewCardDialog;
