import sharedStyles from "../../../landingPage/Shared.module.css";
import { Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { t } from "i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import LandingPage from "../../../landingPage/LandingPage";
import { toast_error } from "../../../../utils/custom-toast";
import i18n from "../../../../i18n";
import YupPassword from "yup-password";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { AuthApiService } from "../../../../services/AuthApiService";
import { EAuthSessionData } from "../../../../type/enum/auth.enum";

YupPassword(Yup);
const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .required("ERRORS.FORM.REQUIRED")
    .min(8, "ERRORS.FORM.SHOULD_BE_AT_LEAST_8_CHARACTERS")
    .max(16, "ERRORS.FORM.SHOULD_BE_AT_MOST_16_CHARACTERS")
    .minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
    .minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
    .minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
    .minSymbols(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT"),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword")], "ERRORS.FORM.INVALID_PASSWORD_MATCH")
    .required("ERRORS.FORM.REQUIRED"),
});

const NewPassword = () => {
  const [mostraPwd, setMostraPwd] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useParams();
  sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, token ?? '');
  const navigate = useNavigate();
  const fullLanguageCode = i18n.language;
  const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const change = searchParams.get("change");
  const authApiService = new AuthApiService();

  // const authApiService = new AuthApiService();

  const recoverPassword = async (values: { newPassword: string; newPasswordConfirm: string }) => {
    setLoading(true);
    const payload = { pwd: values.newPassword, confirmPwd: values.newPasswordConfirm };
    // TODO: "Accept-Language": languageCode,
    try {
      const response = change === "true" ? await authApiService.newPassword(payload) : await authApiService.recoveryPassword(payload);
      if (response) {
        navigate("/login");
      } else {
        console.log(response);
      }
    } catch (error: any) {
      toast_error(error);
    } finally {
      setLoading(false);
      sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
    }
  };

  return (
    <LandingPage>
      <ToastContainer />
      <Formik initialValues={{ newPassword: "", newPasswordConfirm: "" }} validationSchema={ResetPasswordSchema} onSubmit={recoverPassword}>
        {({ handleSubmit, values, errors, touched, handleChange, handleBlur, isValid }) => {
          const formIsTouched = Object.keys(touched).some(
            (field) => touched[field as keyof FormikTouched<{ newPassword: string; newPasswordConfirm: string }>],
          );
          const isSubmitDisabled = !formIsTouched || !isValid;
          return (
            <Form onSubmit={handleSubmit}>
              {change === "true" ? (
                <Box>
                  <Typography variant="h3" sx={{ marginBottom: "5px" }}>
                    {t("COMMONS.NEW_PASSWORD")}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ marginBottom: "50px", fontWeight: "400", color: "#C0C0C0" }}>
                    {t("LOGIN.RESET_PASSWORD_DESCRIPTION")}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h3" sx={{ marginBottom: "5px" }}>
                    {t("COMMONS.RESET_PASSWORD")}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ marginBottom: "50px", fontWeight: "400", color: "#C0C0C0" }}>
                    {t("LOGIN.RESET_PASSWORD_DESCRIPTION")}
                  </Typography>
                </Box>
              )}
              <div className={sharedStyles.container}>
                {/* <InputGroup className={sharedStyles.inputGroupWithButton}> */}
                <Box marginBottom={"15px"}>
                  <TextField
                      id="newPassword"
                      fullWidth
                      name="newPassword"
                      type={mostraPwd ? "text" : "password"}
                      label={t("COMMONS.NEW_PASSWORD")}
                      value={values.newPassword}
                      className={sharedStyles.inputGroupWithButton}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPassword && !!errors.newPassword}
                      helperText={touched.newPassword && errors.newPassword && t(errors.newPassword)}
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Box>

                {/* </InputGroup> */}

                <Box marginBottom={"10px"}>
                  <TextField
                      id="newPasswordConfirm"
                      fullWidth
                      name="newPasswordConfirm"
                      type={mostraPwd ? "text" : "password"}
                      label={t("COMMONS.CONFIRM_PASSWORD")}
                      value={values.newPasswordConfirm}
                      className={sharedStyles.inputGroupWithButton}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPasswordConfirm && !!errors.newPasswordConfirm}
                      helperText={touched.newPasswordConfirm && errors.newPasswordConfirm && t(errors.newPasswordConfirm)}
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Box>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                    type="submit"
                    sx={{ textTransform: "Capitalize" }}
                    variant={"contained"}
                    fullWidth
                    disabled={isSubmitDisabled}
                    size={"large"}
                >{!loading ? t("COMMONS.CONFIRM") : <Spinner animation="border" />}</Button>

              </div>
            </Form>
          );
        }}
      </Formik>
    </LandingPage>
  );
};

export default NewPassword;
