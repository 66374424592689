import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import svg from "../../img/no_template.svg";
import Image from "react-bootstrap/Image";

const UserNotAuthorized: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickButton = () => {
    navigate("/homepage");
  };

  return (
    <Container style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "80vh" }} maxWidth={false}>
      <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"} width={"100%"}>
        <Grid item xs={10}>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Image src={svg} />
            <Typography variant={"h4"}>{t("COMMONS.USER_NOT_AUTHORIZED_TITLE")}</Typography>
            <Typography variant={"h6"}>{t("COMMONS.USER_NOT_AUTHORIZED_DESCRIPTION")}</Typography>
            <Typography variant={"subtitle2"} color={"#A9A9A9"} style={{ marginBottom: "10px" }}>
              {t("COMMONS.USER_NOT_AUTHORIZED_TITLE_2")}
            </Typography>
            <Button variant={"contained"} onClick={onClickButton} sx={{ textTransform: "Capitalize" }}>
              {t("COMMONS.USER_NOT_AUTHORIZED_TITLE_BUTTON")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
    // <EmptySection onClickButton={onClickButton} titleButton={t("COMMONS.USER_NOT_AUTHORIZED_TITLE")} description={t("COMMONS.USER_NOT_AUTHORIZED_DESCRIPTION")} />
  );
};

export default UserNotAuthorized;
