import * as Yup from "yup";
import YupPassword from "yup-password";
import { boolean, date, object, string } from "yup";
import { intlPhoneRegex } from "../../../../../utils/regex";

YupPassword(Yup);

const emailSchema = object({
  contact: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
});

const phoneSchema = object({
  contact: string().trim().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
});

const socialSchema = object({
  contact: string().trim(),
});

const oggi = new Date();
const diciottoAnniFa = new Date(oggi.getFullYear() - 18, oggi.getMonth(), oggi.getDate());
const centoAnniFa = new Date(oggi.getFullYear() - 100, oggi.getMonth(), oggi.getDate());

export default object().shape({
  surname: string().trim().required("ERRORS.FORM.REQUIRED").max(50, "ERRORS.FORM.SHOULD_BE_AT_MOST_50_CHARACTERS"),
  firstname: string().trim().required("ERRORS.FORM.REQUIRED").max(50, "ERRORS.FORM.SHOULD_BE_AT_MOST_50_CHARACTERS"),
  fiscal_code: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_FISCAL_CODE").max(16, "ERRORS.FORM.INVALID_FISCAL_CODE"),
  birth_date: date()
    .typeError("ERRORS.FORM.INVALID_DATE")
    .required("ERRORS.FORM.REQUIRED")
    .max(diciottoAnniFa, "ERRORS.FORM.INVALID_NOT_ADULT")
    .min(centoAnniFa, "ERRORS.FORM.INVALID_DATE"),
  birth_place: string().trim().required("ERRORS.FORM.REQUIRED"),
  email: emailSchema,
  phone: phoneSchema,
  facebook: socialSchema,
  instagram: socialSchema,
  twitter: socialSchema,
  linkedin: socialSchema,
  displayName: string().trim().required("ERRORS.FORM.REQUIRED"),
  sex_code: string().trim().required("ERRORS.FORM.REQUIRED"),
  //personalMail: string().trim().required("ERRORS.FORM.REQUIRED")
});
