import { ApiService } from "./shared/ApiService";
import { AUTH_URL } from "../api/api";
import { IAuthRefreshToken, IAuthSignIn, IAuthSignInResponse, IRequestPasswordPayload } from "../type/auth";
import { EAuthSessionData } from "../type/enum/auth.enum";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export class AuthApiService extends ApiService {
  private _apiForgotPassword = "/auth/forgotPassword";
  private _apiNewPassword = "/auth/newPassword";
  private _apiRecoveryPassword = "/auth/recoveryPassword";
  private _apiLogin = "/auth/login";
  private _apiLogout = "/auth/logout";
  private _apiVerifyPassword = "/auth/verifyPassword";

  constructor() {
    super({ baseUrl: AUTH_URL });
  }
  async forgotPassword(body: IRequestPasswordPayload) {
    return this.post<any, any>(this._apiForgotPassword, body);
  }

  async newPassword(body: any) {
    return this.post<any, any>(this._apiNewPassword, body);
  }

  async recoveryPassword(body: any) {
    return this.post<any, any>(this._apiRecoveryPassword, body);
  }

  async login(body: IAuthSignIn) {
    return new Promise<IAuthSignInResponse>(async (resolve, reject) => {
      try {
        const response = await this.post<IAuthSignIn, IAuthSignInResponse>(this._apiLogin, body);
        const { exp } = jwtDecode(response.rToken);
        let expiration = 30;
        sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, response.aToken);
        if (exp) {
          expiration = exp - Math.floor(Date.now() / 1000);
        }

        Cookies.set(EAuthSessionData.REFRESH_TOKEN, response.rToken, { expires: expiration });
        sessionStorage.setItem(EAuthSessionData.ROLE, response.role);
        sessionStorage.setItem(EAuthSessionData.USERNAME, body.email);
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }

  async logout(body: IAuthRefreshToken) {
    return this.post(this._apiLogout, body);
  }

  async verifyPassword(password: string) {
    return this.post<any, any>(this._apiVerifyPassword, { pwd: password });
  }
}
