import { ApiService } from "./shared/ApiService";
import { RESOURCE_URL } from "../api/api";
import { GenericType } from "../type/type";
export class GenericTypeService extends ApiService {
  private _apiPath = RESOURCE_URL;
  private _genericTypeApi = `${this._apiPath}/generic-type`;
  constructor() {
    super({});
  }

  fetchGenericTypesList() {
    return super.listSearch<GenericType>(this._genericTypeApi);
  }
}