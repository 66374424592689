import { Dispatch, SetStateAction, createContext, useState } from "react";
import { IRoutes } from "../type/routes";

interface RoutesContextProps {
  routes: IRoutes[],
  setRoutes: Dispatch<SetStateAction<IRoutes[]>>
}

export const RoutesContext = createContext<RoutesContextProps | null>(null);

export const RoutesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [routes, setRoutes] = useState<IRoutes[]>([]);

  return (
    <RoutesContext.Provider
      value={{ routes, setRoutes }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesContext;
