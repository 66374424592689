import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { toast_error } from "../../utils/custom-toast";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import LinkMaterial from "@mui/material/Link";
import { ToastContainer } from "react-toastify";
import { Alert, Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import LadingPage from "../landingPage/LandingPage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IAlert } from "../../type/type";
import { useTranslation } from "react-i18next";
import { IAuthSignIn } from "../../type/auth";
import { AuthApiService } from "../../services/AuthApiService";
import { EAuthRole, ERegistryType } from "../../type/enum/common.enum";

export default function Login(): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mostraPwd, setMostraPwd] = useState(false);
  const [displayLoad, setDisplayLoad] = useState<string>("d-none");
  const [displayLog, setDisplayLog] = useState<string>("");
  const [redirect, setRedirect] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: "LOGIN.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successVerification = searchParams.get("successVerification");
  const authApiService = new AuthApiService();

  useEffect(() => {
    let msg = sessionStorage.getItem("expiredSession");
    if (msg) {
      toast_error(msg);
      sessionStorage.removeItem("expiredSession");
    }
  }, []);

  useEffect(() => {
    if (successVerification) {
      setAlert({ ...alert, show: true, message: "LOGIN.SUCCESS_EMAIL_VERIFICATION", severity: "success" });
    }
  }, []);

  const onClickEnter = (event: any) => {
    if (event.key === "Enter") {
      login();
    }
  };

  function login(): void {
    const payload: IAuthSignIn = {
      email: username,
      pwd: password,
      applicationName: "idconnect",
    };
    authApiService
      .login(payload)
      .then((response) => {
        console.log(response);
        if (response.role === EAuthRole.BASIC) {
          navigate("/user/edit-profile");
        } else {
          navigate("/homepage");
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        //setDisplayLog("");
        //setDisplayLoad("d-none");
        console.log(error);
        setAlert({ ...alert, show: true, message: error, severity: "error" });
      });
  }

  function _renderInput() {
    return (
      <>
        <ToastContainer />
        <Container>
          <Typography variant="h3" sx={{ marginBottom: "5px", fontWeight: "bold", textTransform: "Capitalize" }}>
            Login
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: "50px", fontWeight: "400", color: "#C0C0C0" }}>
            {t("LOGIN.WELCOME_BACK")}
          </Typography>
          {alert.show && (
            <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
              {t(alert.message)}
            </Alert>
          )}

          <Box marginBottom={"15px"}>
            <TextField
              id="usrnm"
              type="text"
              className={styles.textFields}
              variant={"outlined"}
              label={"Username"}
              onChange={(evt) => {
                setUsername(evt.target.value);
              }}
              fullWidth
            />
          </Box>

          <Box marginBottom={"10px"}>
            <TextField
              id="psw"
              type={mostraPwd ? "text" : "password"}
              className={styles.textFields}
              variant={"outlined"}
              label={"Password"}
              onChange={(evt) => {
                setPassword(evt.target.value);
              }}
              onKeyDown={onClickEnter}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box display={"flex"} justifyContent={"space-between"}>
            {/*<FormGroup>
              <FormControlLabel control={<Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                placeholder={"Pippo"}
              />} label={t("LOGIN.REMEMBER_ME")} />
            </FormGroup>*/}

            <LinkMaterial href="/recover-password" underline="none" marginTop={"8px"} color={"black"} marginLeft={"5px"}>
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                {t("LOGIN.RECOVER_PASSWORD")}
              </Typography>
            </LinkMaterial>
          </Box>
          <Box marginTop={"20px"}>
            <Button onClick={() => login()} sx={{ textTransform: "Capitalize" }} variant={"contained"} fullWidth size={"large"}>
              Login
            </Button>
          </Box>
          <Grid container className={"mt-5"}>
            {/* <Grid item xs={12} className={"mb-3"}>
              {t("LOGIN.NEW_USER")}
              <Link to={"/registrazione"} style={{ color: "black", marginLeft: "5px" }}>
                {t("LOGIN.REGISTER_NOW")}
              </Link>
            </Grid>*/}
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <LadingPage>
      <div style={{ width: "100%", height: "100%" }}>
        <div>{_renderInput()}</div>
      </div>
    </LadingPage>
  );
}
