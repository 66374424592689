import React, { useEffect, useState } from "react";
import styles from "./Topbar.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast_error } from "../utils/custom-toast";
import Avatar from "@mui/material/Avatar";
import Image from "react-bootstrap/Image";
import { IconButton, Tooltip, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { RegistryApiService } from "../services/RegistryApiService";
import { useTranslation } from "react-i18next";
import { EAuthSessionData } from "../type/enum/auth.enum";
import Cookies from "js-cookie";
import { AuthApiService } from "../services/AuthApiService";
import { EAuthRole } from "../type/enum/common.enum";

const Topbar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [person, setPerson] = useState<any>(null);
  const role = sessionStorage.getItem(EAuthSessionData.ROLE);
  const registryApiService = new RegistryApiService();
  const authApiService = new AuthApiService();

  const handleLogout = async () => {
    await authApiService.logout({ refreshToken: Cookies.get(EAuthSessionData.REFRESH_TOKEN) ?? "" });
    sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
    sessionStorage.removeItem(EAuthSessionData.REFRESH_TOKEN);
    sessionStorage.removeItem(EAuthSessionData.AUTH_DATA);
    sessionStorage.removeItem(EAuthSessionData.USERNAME);
    sessionStorage.removeItem(EAuthSessionData.ROLE);
    Cookies.remove(EAuthSessionData.REFRESH_TOKEN);
    sessionStorage.removeItem("refreshStatus");
    sessionStorage.removeItem("route");
    navigate("/login");
  };

  const fetchIdRegistryOwner = async () => {
    try {
      const response = await registryApiService.infoMe();
      if (response) {
        setPerson(response);
        if (role === "admin") {
          let business_name = "";

          if (typeof response.id_registry_owner !== "string") {
            if (response.id_registry_owner.id_registry_owner && typeof response.id_registry_owner.id_registry_owner !== "string") {
              business_name = response.id_registry_owner.id_registry_owner.business_name;
            }
          }
          sessionStorage.setItem("business_name", business_name);
        }
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error.message);
    }
  };

  useEffect(() => {
    fetchIdRegistryOwner();
  }, []);

  return (
    <div className={styles.containerTopbar}>
      <div>{/* <div className={styles.title}>{getPageTitle()}</div> */}</div>
      <div className={styles.userInfoContainer}>
        <div className={styles.userText}>
          {/*{person?.firstname ? <div className={styles.userName}>{person.firstname}</div> : <div className={styles.userName}>No Name</div>}*/}
          {person?.firstname ? (
            <Typography variant={"h6"} sx={{ textTransform: "capitalize" }}>
              {person.firstname} {person.surname}
            </Typography>
          ) : (
            <Typography variant={"h6"}></Typography>
          )}
          {/* <div className={styles.userCompany}>Microsoft</div> */}
        </div>
        {role === EAuthRole.ADMIN && (
          <>
            {person?.image ? (
              <Avatar sx={{ bgcolor: "#E5E4E2", marginRight: "10px", width: 40, height: 40 }} variant="circular">
                <Image src={person.image} className={styles.userImage} />
              </Avatar>
            ) : (
              <Avatar sx={{ bgcolor: "#E5E4E2", marginRight: "10px", width: 40, height: 40 }} variant="circular"></Avatar>
            )}
          </>
        )}

        <Tooltip title="Esci">
          <IconButton>
            {/* <BiLogOut  onClick={handleLogout} /> */}
            <LogoutIcon onClick={handleLogout} className={styles.logoutIcon} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
export default Topbar;
