import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./SelectProduct.module.css";

const SelectProduct = ({ selectedProduct, setSelectedProduct, setProductInfo, productList, loadingProductList, setPrices }: any) => {
  const getGradientByDescription = (description: string) => {
    switch (description) {
      case "Carte di plastica":
        return "linear-gradient(110deg, #000 43.62%, #45474F 68.82%, #444 82.87%, #5D6271 95.95%, #26272B 106.12%)";
      case "Carte di metallo":
        return "linear-gradient(110deg, #A4A4A4 43.62%, #D1D1D1 68.82%, #BEBEBE 82.87%, #BEBEBE 95.95%, #D1D1D1 106.12%)";
      default:
        return "black";
    }
  };

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedID = event.target.value;
    setSelectedProduct(selectedID);

    const selectedProduct = productList.find((item: any) => item._id === selectedID);

    if (selectedProduct) {
      setPrices(selectedProduct.prices);
      setProductInfo({ selectedProduct: selectedID, selectedPrice: selectedProduct.prices });
    }
  };

  return (
    <div>
      {loadingProductList ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <div className={styles.radioGroupContainer}>
          <RadioGroup value={selectedProduct} onChange={handleSelectionChange} sx={{ flexDirection: "row" }}>
            {productList.map((item: any) => (
              <div key={item._id} className={`${styles.radioItem} ${selectedProduct === item._id ? styles.radioItemSelected : ""}`}>
                <div className={styles.radioContent}>
                  <div className={styles.radioImage} style={{ background: getGradientByDescription(item.description) }}></div>
                  <FormControlLabel
                    value={item._id}
                    sx={{ flexDirection: "column-reverse", marginLeft: "0px", marginRight: "0px" }}
                    control={<Radio />}
                    label={
                      <div>
                        <div className={styles.radioTitle}>{item.description}</div>
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};

export default SelectProduct;
