import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApiService } from "../../services/AuthApiService";
import { toast_error } from "../../utils/custom-toast";
import { ToastContainer } from "react-toastify";
import { IAlert } from "../../type/type";

interface IPasswordDialog {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

const PasswordDialog = ({ open, setOpen, onOpen, onClose, onConfirm }: IPasswordDialog) => {
  const [mostraPwd, setMostraPwd] = useState(false);
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const authApiService = new AuthApiService();
  const [alert, setAlert] = useState<IAlert>({
    show: false,
    severity: "error",
    message: "ERRORS.BILLING_INFO.SUBMIT_ERROR",
    dismissible: true,
    onClose: () => {
      setAlert({ ...alert, show: false });
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    event.preventDefault();
  };

  const handleConfirm = () => {
    authApiService
      .verifyPassword(password)
      .then(() => {
        onConfirm(); // Chiama la funzione passata dal genitore
      })
      .catch((error) => {
        setAlert({ ...alert, show: true, severity: "error", message: error });
        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 2000);
      });
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{t("DIALOG_PASSWORD.TITLE")}</DialogTitle>
        {alert.show && (
          <Alert className="mb-4" severity={alert.severity} onClose={alert.onClose}>
            {t(alert.message)}
          </Alert>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            {t("DIALOG_PASSWORD.DESCRIPTION_SECURITY")}
            <TextField
              id="emailDialog"
              name="emailDialog"
              label="Email"
              fullWidth
              variant="outlined"
              //i need to hide the component
              sx={{
                position: "absolute",
                left: "-9999px", // Posiziona il campo fuori dello schermo
                top: "auto",
                height: "1px", // Riduci l'altezza per minimizzare l'impatto sul layout
                width: "1px", // Riduci la larghezza per minimizzare l'impatto sul layout
                overflow: "hidden",
              }}
              onChange={handleChange}
              autoComplete="email"
              type="email"
            />
            <TextField
              id="passwordDialog"
              name="passwordDialog"
              label="Password"
              type={mostraPwd ? "text" : "password"}
              fullWidth
              variant="outlined"
              sx={{ marginTop: "20px" }}
              onChange={handleChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setMostraPwd(!mostraPwd)}>{mostraPwd ? <VisibilityOff /> : <Visibility />}</IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose} sx={{ textTransform: "Capitalize" }}>
            {t("COMMONS.CANCEL")}
          </Button>
          <Button color="primary" variant="contained" onClick={handleConfirm} sx={{ textTransform: "Capitalize" }}>
            {t("COMMONS.CONFIRM")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PasswordDialog;
