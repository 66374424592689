import { RESOURCE_URL } from "../api/api";

import { ApiService } from "./shared/ApiService";

export class ReportDashboardService extends ApiService {
  private _apiGetRegistryByOwner = `/report-dashboard/registry-by-owner`;
  private _apiPostCreateHomeData = `/resource`;
  private _apiGetGenericTypes = `/report-dashboard/resource-by-generic-type`;
  private _apiPath = RESOURCE_URL;

  constructor() {
    super({});
  }

  getListGroupOwner(): Promise<any[]> {
    return super.get(`${this._apiPath}${this._apiGetRegistryByOwner}`);
  }

  getListGenericTypes(genericType: string): Promise<any[]> {
    return super.get(`${this._apiPath}${this._apiGetGenericTypes}/` + genericType);
  }
}
