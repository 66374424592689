import { createTheme } from "@mui/material";

export const colors = {
  primary400: "#f7f7f7",
  primary500: "#F5F8F9",
  primary600: "#E7EEF0",
  primary700: "#D9E5E9",

  red100: "#f3b6b6",
  red200: "#ee9c9c",
  red300: " #e88282",
  red400: "#e36868",
  red500: "#de4e4e",
  red600: "#d43434",

  orangeAccent100: "#FFEFE2",
  orangeAccent200: "#FCEBCF",
  orangeAccent300: "#FFB388",
  orangeAccent400: "#f39259",
  orangeAccent500: "#EF8144",

  greenAccent100: "#EFFCEF",
  greenAccent200: "#CDF59A",

  gray300: "#E0E0E0",
  gray400: "#BDBDBD",
  gray500: "#969696",
  gray600: "#6E6E6E",
  gray700: "#4c4c4c",
  gray800: "#3A3A3A",
  gray900: "#292929",

  white: "#ffffff",
  black: "#0A0A0A",
  divider: "#bdbdbd",
};

const heading = {
  fontWeight: 700,
  color: colors.black,
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.gray700,
      contrastText: "#070707",
      light: "#F9AF4C",
      dark: "#F9AF4C",
    },
    secondary: {
      main: colors.black,
      contrastText: "#fff",
    },

    text: {
      primary: colors.black,
    },
  },
  typography: {
    // fontFamily: ["'Ubuntu', sans-serif"].join(","),
    h1: {
      ...heading,
      fontSize: "2.28rem",
    },
    h2: {
      ...heading,
      fontSize: "1.8rem",
    },
    h3: {
      ...heading,
    },
    h4: {
      ...heading,
    },
    h5: {
      ...heading,
    },
    h6: {
      ...heading,
    },
    subtitle1: {
      ...heading,
    },
    subtitle2: {
      ...heading,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "none",
          textTransform: "capitalize",
        },
        containedPrimary: {
          backgroundColor: colors.black,
          color: colors.white,
          "&:hover": {
            backgroundColor: colors.gray800,
          },
        },
        containedSecondary: {
          backgroundColor: colors.gray300,
          color: colors.black,
          "&:hover": {
            backgroundColor: colors.gray400,
          },
        },
        containedError: {
          backgroundColor: colors.red600,
          color: colors.black,
          "&:hover": {
            backgroundColor: colors.red600,
          },
        },
        outlinedPrimary: {
          borderColor: colors.black,
          color: colors.black,
          "&:hover": {
            backgroundColor: colors.gray400,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.gray500,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.gray600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          color: colors.black,
          fontSize: "14px",
        },
      },
    },
  },
});

export default theme;
