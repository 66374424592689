import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import TabellaUtenti from "../../componets/tabelle/TabellaUtenti";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import "../../colors.css";
import FilterButton from "../../componets/filterButton/FilterButton";
import TabMenu from "../../componets/tabMenu/TabMenu";
import { mockDataPeople, Person } from "../../data/mockdata";
import Timeline from "./timeline";
import Calendar from "../../componets/calendar/Calendar";
import styles from "./timbrature.module.css";
import { ResponsiveBump } from "@nivo/bump";
import { RegistryApiService } from "../../services/RegistryApiService";
import { IRegistry } from "../../type/commons";

const tabs = [
  { value: 0, label: "Tutti" },
  { value: 1, label: "Roma" },
  { value: 2, label: "Pisa" },
  { value: 3, label: "Milano" },
  { value: 4, label: "Smart working" },
];

const Timbrature: React.FC = () => {
  const [dataDipendenti, setDataDipendenti] = useState<IRegistry[]>([]);
  const [filteredData, setFilteredData] = useState<Person[]>(mockDataPeople);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isTimelineExpanded, setTimelineExpanded] = useState(false);
  //Page e Limit Server call
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 5;
  // Filtri per varie Sedi + SmartWorking //
  const filteredSedeRoma = filteredData.filter((person) => person.sede === "Roma");
  const filteredSedePisa = filteredData.filter((person) => person.sede === "Pisa");
  const filteredSedeMilano = filteredData.filter((person) => person.sede === "Milano");
  const filteredSmartWorking = filteredData.filter((person) => person.tipologia === "Smart Working");
  // headerExcludedKeys + path //
  const exclude = ["id", "foto", "ruolo", "email", "telefono", "facebook", "tweetter", "instagram", "linkedin"];
  const pathSchema = "azienda/dettaglio-dipendente";
  const registryApiService = new RegistryApiService();

  useEffect(() => {
    async function fetchData(page: number) {
      try {
        const response = await registryApiService.fetchAllRegistry({
          page: page,
          limit: perPage,
        });
        setDataDipendenti(response);
      } catch (error) {
        console.log("Errore: ", error);
      }
    }
    fetchData(currentPage);
  }, [currentPage]);

  const getMinutesFromTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  // Dati mockup per grafico //
  const dataForBump = [
    {
      id: "Entrata",
      data: filteredData.map((person) => ({
        x: person.nome,
        y: getMinutesFromTime(person.orarioEntrata),
      })),
    },
    {
      id: "Uscita",
      data: filteredData.map((person) => ({
        x: person.nome,
        y: getMinutesFromTime(person.orarioUscita),
      })),
    },
  ];
  // Filtro ricerca //
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setFilteredData(
      mockDataPeople.filter((person) => {
        const searchTermLowerCase = searchTerm.toLowerCase();
        const names = person.nome.toLowerCase().split(" ");
        return names.some((name) => name.startsWith(searchTermLowerCase));
      }),
    );
  };

  const handleTabChange = (newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <div></div>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Timbrature</h1>
          <Calendar />
        </div>
        <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs} />
        <Timeline />
        {isTimelineExpanded && (
          <div style={{ overflowX: "scroll" }}>
            {filteredData.length > 0 ? (
              <div
                style={{
                  width: `${dataForBump.length * 100}%`,
                  height: "200px",
                  marginBottom: "10px",
                }}
              >
                <ResponsiveBump
                  data={dataForBump}
                  margin={{ top: 20, right: 40, bottom: 0, left: 80 }}
                  axisBottom={{
                    legend: "",
                    legendPosition: "middle",
                    legendOffset: -60,
                    format: () => "",
                  }}
                  axisLeft={{
                    legend: "Orario",
                    legendPosition: "middle",
                    legendOffset: -60,
                    format: (d) => {
                      const hours = Math.floor(d / 60);
                      const minutes = d % 60;
                      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
                    },
                  }}
                  isInteractive={true}
                />
              </div>
            ) : (
              <p>Nessun risultato trovato.</p>
            )}
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <SearchBar onSearch={handleSearch} placeholder="Cerca dipendenti" />
            <FilterButton />
          </Box>
          <Box sx={{ display: "flex", gap: "1em" }}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "white",
                borderColor: "black",
                color: "black",
                fontSize: "12px",
                textTransform: "Capitalize",
                "&:active": {
                  borderColor: "black",
                },
                "&:hover": {
                  borderColor: "black",
                },
              }}
              onClick={() => setTimelineExpanded(!isTimelineExpanded)}
            >
              {isTimelineExpanded ? "Nascondi grafico" : "Vedi grafico"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: "65%", bgcolor: "var(--white)", borderBottom: "1px solid var(--primary-700)" }}>
          {selectedTab === 0 && <TabellaUtenti data={filteredData} headerExcludedKeys={exclude} path={pathSchema} />}
          {selectedTab === 1 && <TabellaUtenti data={filteredSedeRoma} headerExcludedKeys={exclude} path={pathSchema} />}
          {selectedTab === 2 && <TabellaUtenti data={filteredSedePisa} headerExcludedKeys={exclude} path={pathSchema} />}
          {selectedTab === 3 && <TabellaUtenti data={filteredSedeMilano} headerExcludedKeys={exclude} path={pathSchema} />}
          {selectedTab === 4 && <TabellaUtenti data={filteredSmartWorking} headerExcludedKeys={exclude} path={pathSchema} />}
        </Box>
      </div>
    </>
  );
};

export default Timbrature;
