export interface Person {
  id: number;
  nome: string;
  ruolo: string;
  email: string;
  telefono: string;
  sede: string;
  stato: string;
  foto: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  linkedin?: string;
  orarioEntrata: string;
  orarioUscita: string;
  tipologia: string;
  totaleOre:string;
}

export interface Sedi {
  id: number;
  nome: string;
  indirizzo: string;
  sitoWeb: string;
  email: string;
  telefono: string;
  dipendenti: number;
  assenti: number;
  permessi: number;
  presenze: number;
}

export const mockDataPeople: Person[] = [
  {
    id: 1,
    nome: "John Smith",
    ruolo: "CEO",
    email: "john.smith@example.com",
    telefono: "1234567890",
    tipologia : "Fulltime",
    sede: "Milano",
    orarioEntrata: "9:00",
    orarioUscita: "17:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://randomuser.me/api/portraits/lego/6.jpg",
  },
  {
    id: 2,
    nome: "Emily Johnson",
    ruolo: "Responsabile delle vendite",
    email: "emily.johnson@example.com",
    telefono: "0987654321",
    tipologia : "Smart Working",
    sede: "Milano",
    orarioEntrata: "9:00",
    orarioUscita: "15:00",
    totaleOre: "9:00",
    stato: "Non entrato",
    foto: "https://randomuser.me/api/portraits/women/67.jpg",
    facebook: "https://www.facebook.com/emilyJohnson",
  },
  {
    id: 3,
    nome: "Michael Davis",
    ruolo: "Responsabile del marketing",
    email: "michael.davis@example.com",
    telefono: "5678901234",
    tipologia : "Fulltime",
    sede: "Roma",
    orarioEntrata: "11:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 4,
    nome: "Jennifer Wilson",
    ruolo: "Responsabile delle risorse umane",
    email: "jennifer.wilson@example.com",
    telefono: "4321098765",
    tipologia : "Smart Working",
    sede: "Roma",
    orarioEntrata: "10:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Ferie",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 5,
    nome: "Daniel Thompson",
    ruolo: "Responsabile finanziario",
    email: "daniel.thompson@example.com",
    telefono: "9876543210",
    tipologia : "Smart Working",
    sede: "Milano",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "18:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 6,
    nome: "Jessica Garcia",
    ruolo: "Responsabile delle operazioni",
    email: "jessica.garcia@example.com",
    telefono: "3456789012",
    tipologia : "Smart Working",
    sede: "Milano",
    orarioEntrata: "11:00",
    orarioUscita: "19:00",
    totaleOre: "18:00",
    stato: "Ferie",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 7,
    nome: "David Martinez",
    ruolo: "Analista di dati",
    email: "david.martinez@example.com",
    telefono: "8901234567",
    tipologia : "Fulltime",
    sede: "Milano",
    orarioEntrata: "11:00",
    orarioUscita: "19:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 8,
    nome: "Sarah Thompson",
    ruolo: "Responsabile delle relazioni pubbliche",
    email: "sarah.thompson@example.com",
    telefono: "6543210987",
    tipologia : "Fulltime",
    sede: "Roma",
    orarioEntrata: "11:00",
    orarioUscita: "19:00",
    totaleOre: "9:00",
    stato: "Non entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 9,
    nome: "Christopher Clark",
    ruolo: "Sviluppatore software",
    email: "christopher.clark@example.com",
    telefono: "2345678901",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "11:00",
    orarioUscita: "19:00",
    totaleOre: "9:00",
    stato: "Non entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 10,
    nome: "Rebecca Lewis",
    ruolo: "Responsabile del servizio clienti",
    email: "rebecca.lewis@example.com",
    telefono: "1098765432",
    tipologia : "Smart Working",
    sede: "Pisa",
    orarioEntrata: "11:00",
    orarioUscita: "19:00",
    totaleOre: "9:00",
    stato: "Non entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 11,
    nome: "Andrew Wright",
    ruolo: "Responsabile delle operazioni IT",
    email: "andrew.wright@example.com",
    telefono: "9876543210",
    tipologia : "Fulltime",
    sede: "Roma",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Non entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 12,
    nome: "Stephanie Rodriguez",
    ruolo: "Analista finanziario",
    email: "stephanie.rodriguez@example.com",
    telefono: "3456789012",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 13,
    nome: "Matthew Young",
    ruolo: "Responsabile delle operazioni di produzione",
    email: "matthew.young@example.com",
    telefono: "8901234567",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 14,
    nome: "Olivia Rose Green",
    ruolo: "Analista di marketing",
    email: "olivia.green@example.com",
    telefono: "6543210987",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 15,
    nome: "Joshua Hernandez",
    ruolo: "Responsabile delle partnership",
    email: "joshua.hernandez@example.com",
    telefono: "2345678901",
    tipologia : "Fulltime",
    sede: "Roma",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 16,
    nome: "Sophia Scott",
    ruolo: "Progettista UX",
    email: "sophia.scott@example.com",
    telefono: "1098765432",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 17,
    nome: "William Jay Lee",
    ruolo: "Responsabile delle operazioni di logistica",
    email: "william.lee@example.com",
    telefono: "9876543210",
    tipologia : "Fulltime",
    sede: "Milano",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Ferie",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 18,
    nome: "Ava Perez",
    ruolo: "Specialista delle risorse umane",
    email: "ava.perez@example.com",
    telefono: "3456789012",
    tipologia : "Fulltime",
    sede: "Milano",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 19,
    nome: "James Turner",
    ruolo: "Sviluppatore web",
    email: "james.turner@example.com",
    telefono: "8901234567",
    tipologia : "Fulltime",
    sede: "Roma",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
  {
    id: 20,
    nome: "Mia Baker",
    ruolo: "Responsabile delle comunicazioni",
    email: "mia.baker@example.com",
    telefono: "6549873210",
    tipologia : "Fulltime",
    sede: "Pisa",
    orarioEntrata: "9:00",
    orarioUscita: "18:00",
    totaleOre: "9:00",
    stato: "Entrato",
    foto: "https://via.placeholder.com/100",
  },
];

export const mockDataSedi: Sedi[] = [
  {
    id: 1,	
    nome: "Roma",
    indirizzo: "Via del Corso, 00186 Roma, Italia",
    sitoWeb: "https://www.roma.it/",
    email: "roma@example.com",
    telefono: "1234567890",
    dipendenti: 50,
    assenti: 7,
    permessi: 3,
    presenze: 40,
  },
  {
    id: 2,
    nome: "Milano",
    indirizzo: "Piazza del Duomo, 91, 20154 Milano MI, Italia",
    sitoWeb: "https://www.milano.it/",
    email: "milano@example.com",	
    telefono: "1234567890",
    dipendenti: 30,
    assenti: 2,
    permessi: 1,
    presenze: 27,
  },
  {
    id: 3,
    nome: "Venezia",
    indirizzo: "Via Venezia, 1, 30171 Venezia , Italia",
    sitoWeb: "https://www.venezia.it/",
    email: "venezia@example.com",	
    telefono: "1234567890",
    dipendenti: 20,
    assenti: 5,
    permessi: 2,
    presenze: 13,
  },
  {
    id: 4,
    nome: "Firenze",
    indirizzo: "Via San Gallo, 96, 50131, Firenze, Italia ",
    sitoWeb: "https://www.firenze.it/",
    email:"firenze@example.com",
    telefono: "1234567890",
    dipendenti: 15,
    assenti: 3,
    permessi: 1,
    presenze: 11,
  },
  {
    id: 5,
    nome: "Napoli",
    indirizzo:' Via Emanuele Gianturco, 46, 80138, Napoli, Italia',
    sitoWeb: "https://www.napoli.it/",
    email:"napoli@example.com",
    telefono: "1234567890",
    dipendenti: 10,
    assenti: 1,
    permessi: 0,
    presenze: 9,
  },
  {
    id: 6,
    nome: "Bologna",
    indirizzo: "Via San Felice, 24, 40122, Bologna, Italia",
    sitoWeb: "https://www.bologna.it/",
    email:"Bologna@example.com",
    telefono: "1234567890",
    dipendenti: 45,
    assenti: 10,
    permessi: 5,
    presenze: 30,
  },
];