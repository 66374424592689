import { Button } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const tabStyle = {
  color: "black",
  fontWeight: "bold",
  fontSize: "100%",
  padding: "0.5em",
  marginLeft: "1.2em",
  textTransform: "Capitalize",
};

const FilterButton = () => {
  return (
    <>
      <Button size="small" variant="text" sx={tabStyle} startIcon={<FilterAltOutlinedIcon />}>
        Filtra ricerca
      </Button>
    </>
  );
};
export default FilterButton;
