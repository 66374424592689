import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast_error, toast_success } from "../../utils/custom-toast";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Pagination,
} from "@mui/material";
import styles from "../tabelle/TabellaListaAziende.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { RegistryApiService } from "../../services/RegistryApiService";
import { IRegistry } from "../../type/commons";
import { EAuthRole, ERegistryType } from "../../type/enum/common.enum";
import PasswordDialog from "../dialog/PasswordDialog";
import { CoreApiService } from "../../services/CoreApiService";
import { EAuthSessionData } from "../../type/enum/auth.enum";

interface Props {
  searchTerm?: string;
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  usersDeleted: boolean;
  // refreshData: boolean;
  refreshCounter: number;
  idCompany?: string;
}

//TODO: AGGIUNGERE TRADUZIONI

const TabellaUtentiGet: React.FC<Props> = ({ searchTerm = "", selectedUsers, setSelectedUsers, usersDeleted, refreshCounter, idCompany }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<IRegistry[]>([]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [dataHasLoaded, setDataHasLoaded] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const { id } = useParams();
  const role = sessionStorage.getItem("role");
  const registryApiService = new RegistryApiService();
  const coreApiService = new CoreApiService();

  const itemsPerPage = 10;

  const countData = async (): Promise<void> => {
    const params: any = {
      // registry_type: ERegistryType.DIPENDENTE,
      registry_types: [ERegistryType.DIPENDENTE, ERegistryType.AMMINISTRATORE],
      searchFullText: searchTerm,
      searchFields: ["firstname", "surname"],
    };

    if (role === EAuthRole.SUPERADMIN) {
      params.id_registry_owner = id;
      params.get_nephew = true;
    }
    const itemCount = await registryApiService.countRegistries(params);
    setTotalItems(itemCount);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params: any = {
        page: currentPage,
        limit: itemsPerPage,
        orderBy: "createdAt",
        searchFullText: searchTerm,
        searchFields: ["firstname", "surname"],
        // registry_type: ERegistryType.DIPENDENTE,
        registry_types: [ERegistryType.DIPENDENTE, ERegistryType.AMMINISTRATORE],
      };

      if (role === EAuthRole.SUPERADMIN) {
        params.id_registry_owner = id;
        params.get_nephew = true;
      }
      const data = await registryApiService.fetchAllRegistry(params);
      if (data) {
        setUsers(data);
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setIsLoading(false);
      setDataHasLoaded(true);
    }
  };

  const fetch = async () => {
    await countData();
    if (!searchTerm || searchTerm.length >= 3) {
      await fetchData();
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage, searchTerm, usersDeleted]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  // Funzione per gestire il click su una riga della tabella
  const handleRowClick = (id: string, idGranparent: string) => {
    navigate(`/azienda/dipendenti/dettaglio-dipendente/informazioni-personali/${id}/${idGranparent}`);
  };

  const handleOpenConfirm = (id: string) => {
    setOpenConfirmModal(true);
    setUserId(id);
  };

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenConfirmModal(false);
    setOpenDeleteModal(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenDeleteModal(false);
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUsers((prevSelectedUsers: string[]) =>
      prevSelectedUsers.includes(userId) ? prevSelectedUsers.filter((item) => item !== userId) : [...prevSelectedUsers, userId],
    );
  };

  const toggleAllUsersSelection = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user._id));
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await coreApiService.deleteEmployee(id);
      // const response = await registryApiService.deleteRegistry(id);
      if (response) {
        toast_success(t("COMMONS.OPERATION_SUCCESS"));
        handleClosePasswordDialog();
      } else {
        toast_error(t("COMMONS.OPERATION_ERROR"));
      }
    } catch (error: any) {
      console.log(error);
      toast_error(error);
    } finally {
      setOpenConfirmModal(false);
      fetchData();
    }
  };

  const renderTableBodyContent = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={12} rowSpan={12} className={styles.loadingRow}>
            <CircularProgress size={70} />
          </td>
        </tr>
      );
    }

    if (dataHasLoaded && users.length === 0) {
      return (
        <tr>
          <td colSpan={5} className={styles.rowStyle}>
            {t("COMMONS.NO_DATA")}
          </td>
        </tr>
      );
    }

    return users.map((user) => (
      <tr key={user._id} onDoubleClick={() => handleRowClick(user._id, idCompany!)}>
        {role === EAuthRole.ADMIN && (
          <td style={{ width: "1%" }}>
            <Checkbox checked={selectedUsers.includes(user._id)} onChange={() => toggleUserSelection(user._id)} />
          </td>
        )}
        <td className={styles.rowStyleFirstElement}>
          <div style={{ display: "flex", verticalAlign: "middle", alignItems: "center", textTransform: "capitalize" }}>
            {user.image ? (
              <div>
                <img className={styles.imageDiv} src={user.image} alt="User" />
              </div>
            ) : (
              <img className={styles.imageDiv} src="https://via.placeholder.com/100" alt="User" />
            )}
            <div style={{ marginRight: "5px" }}>{user.firstname}</div>
            <div>
              {user.surname} <span style={{ textTransform: "none" }}>{user.deleted ? "_deleted" : ""}</span>
            </div>
          </div>
        </td>
        <td className={styles.rowStyle}>{user.id_registry_type[0].code}</td>
        <td className={styles.rowStyle}>
          {user.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact}
        </td>
        <td className={styles.rowStyle}>
          {user.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "PHONE")?.contact}
        </td>
        <td className={styles.rowStyle}>
          {user.deleted ? "" : typeof user.id_registry_owner !== "string" ? user.id_registry_owner.business_name : ""}
        </td>
        <td className={styles.rowStyle}>
          {user.displayName && !user.deleted && (
            <Link to={`/ecard/${sessionStorage.getItem("business_name")}/${user.displayName}`} target="_blank">
              <div>{t("FORM.ECARD")}</div>
            </Link>
          )}
        </td>
        <td>
          <div style={{ display: "flex" }}>
            {!user.deleted && (
              <>
                <div>
                  <IconButton onClick={() => handleRowClick(user._id, idCompany!)} color="default">
                    <ModeIcon />
                  </IconButton>
                </div>
                {role === EAuthRole.ADMIN && user.id_registry_type[0].code === ERegistryType.DIPENDENTE && (
                  <div>
                    <IconButton onClick={() => handleOpenConfirm(user._id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <ToastContainer />
      {/* MODALE CONFERMA  */}
      <Dialog open={openConfirmModal} onClose={handleCloseConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>{"Sei sicuro di procedere?"}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseConfirm}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>{t("DIALOG_DELETE.EMPLOYEE")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleCloseConfirm} sx={{ textTransform: "Capitalize" }}>
            {t("COMMONS.CANCEL")}
          </Button>
          <Button color="primary" variant="contained" sx={{ textTransform: "Capitalize" }} onClick={handleOpenPasswordDialog}>
            {t("COMMONS.CONFIRM")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODALE PASSWORD */}
      <PasswordDialog
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onOpen={handleOpenPasswordDialog}
        onClose={handleClosePasswordDialog}
        onConfirm={() => handleDelete(userId)}
      />

      {/* TABELLA */}
      <Table style={{ width: "100%" }} hover responsive>
        <thead className={styles.thead}>
          {!dataHasLoaded && isLoading ? (
            <tr>
              <th className={styles.rowStyle}></th>
            </tr>
          ) : (
            <tr>
              {role === EAuthRole.ADMIN && (
                <th className={styles.rowStyle}>
                  <Checkbox checked={!!(users.length && selectedUsers.length === users.length)} onChange={toggleAllUsersSelection} />
                </th>
              )}
              <th className={styles.rowStyle}>{t("FORM.NAME")}</th>
              <th className={styles.rowStyle}>{t("COMMONS.TABLE.ROLE")}</th>
              <th className={styles.rowStyle}>Email</th>
              <th className={styles.rowStyle}>{t("FORM.PHONE")}</th>
              <th className={styles.rowStyle}>{t("COMMONS.HEADQUARTER")}</th>
              <th className={styles.rowStyle}>ECard</th>
              <th className={styles.rowStyle}></th>
            </tr>
          )}
        </thead>
        <tbody>{renderTableBodyContent()}</tbody>
      </Table>
      {!isLoading && dataHasLoaded && users.length > 0 && totalItems > itemsPerPage ? (
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          shape="rounded"
          showFirstButton
        />
      ) : null}
    </div>
  );
};

export default TabellaUtentiGet;
